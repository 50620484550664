import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  IActivo,
  IFilter,
  IGeoJSONCircle,
  IPopulate,
  IQueryParam,
  IReporte,
} from 'modelos/src';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { DateRange } from '../../../../../../auxiliares/date-range-selector/date-range-selector.component';
import { ReportesService } from '../../../../../../auxiliares/servicios/http/reportes.service';
import { LoadingService } from '../../../../../../auxiliares/servicios/loading.service';

interface vehiculoPaso {
  idVehiculo: string;
  vehiculo: IActivo;
  entro: string;
  salio?: string;
}

@Component({
  selector: 'app-busqueda-punto',
  templateUrl: './busqueda-punto.component.html',
  styleUrl: './busqueda-punto.component.scss',
  standalone: false,
})
export class BusquedaPuntoComponent implements OnChanges {
  public interaccionesBusqueda: vehiculoPaso[];
  public displayedColumns: string[] = ['vehículos'];
  public buscando: boolean = false;
  public range?: DateRange = {};
  public minutosMinimos? = new FormControl();
  @Input() public circuloParam: IGeoJSONCircle;
  @Output() public puntoDeCirculo = new EventEmitter();
  public circuloDeBusqueda: IGeoJSONCircle;
  public horasBusqueda? = new FormControl(1, [
    Validators.required,
    Validators.min(1),
    Validators.max(24),
  ]);
  constructor(
    public loading: LoadingService,
    public dialog: MatDialog,
    private reportesService: ReportesService,
  ) {}

  public async circuloDeBusquedaChange(circulito: IGeoJSONCircle) {
    if (this.horasBusqueda.value < 1 && this.horasBusqueda.value > 24) return;
    if (circulito?.coordinates && this.range.start) {
      this.circuloDeBusqueda = circulito;
      this.buscando = true;
      const hasta = new Date(this.range.start);
      const desde = new Date(this.range.start);
      hasta.setHours(hasta.getHours() + this.horasBusqueda.value);
      const radiox = (circulito.radius * 100).toFixed(2);
      const filter: IFilter<IReporte> = {
        fechaCreacion: { $gte: desde.toISOString(), $lte: hasta.toISOString() },
        geojson: {
          $geoWithin: {
            $centerSphere: [
              circulito.coordinates, // Centro del círculo
              +radiox / 6378.1, // Radio en radianes (10 km)
            ],
          },
        },
      };
      const populate: IPopulate = {
        path: 'activo',
      };
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        includeChildren: true,
        populate: JSON.stringify(populate),
        sort: 'fechaCreacion',
      };
      const res = await this.reportesService.getFiltered(query);
      this.buscando = false;
      let res2 = res.datos;
      res2 = res2.filter((r) => r.activo?.categoria === 'Vehículo');
      this.iterator5000(res2);
    } else {
      if (circulito?.coordinates) this.circuloDeBusqueda = circulito;
      else this.circuloDeBusqueda = null;
      this.interaccionesBusqueda = [];
    }
  }

  private iterator5000(reportes: IReporte[]) {
    const interacciones: vehiculoPaso[] = [];
    let idActivo: string = '';
    let interaccionActual: vehiculoPaso | null = null;

    // Usar un índice para controlar la iteración manualmente
    let index = 0;

    while (index < reportes.length) {
      const rep = reportes[index];

      if (idActivo === '' && rep.idActivo) {
        idActivo = rep.idActivo;
      }

      if (idActivo === rep.idActivo) {
        // Si es el mismo vehículo
        if (!interaccionActual) {
          // Si no se está registrando la interacción
          interaccionActual = {
            idVehiculo: idActivo,
            vehiculo: rep.activo,
            entro: rep.fechaDevice,
            salio: rep.fechaDevice,
          };
        } else {
          // Si ya está registrada la interacción
          if (
            this.hanPasadoXMinutos(interaccionActual.salio, rep.fechaDevice, 30)
          ) {
            // Si pasaron 30 min, guarda la interacción y registra una nueva
            if (
              this.minutosMinimos?.value &&
              this.hanPasadoXMinutos(
                interaccionActual.salio,
                interaccionActual.entro,
                this.minutosMinimos.value,
              )
            )
              interacciones.push(interaccionActual);
            interaccionActual = {
              vehiculo: rep.activo,
              idVehiculo: rep.idActivo,
              entro: rep.fechaDevice,
              salio: rep.fechaDevice,
            };
          } else {
            // Si no pasaron los 30 min del último reporte
            interaccionActual.salio = rep.fechaDevice;
          }
        }
      } else {
        // Si cambia el vehículo, guarda la interacción actual y reinicia
        if (interaccionActual) {
          if (
            !this.minutosMinimos?.value ||
            this.hanPasadoXMinutos(
              interaccionActual.salio,
              interaccionActual.entro,
              this.minutosMinimos?.value,
            )
          )
            interacciones.push(interaccionActual);
          interaccionActual = null;
        }
        idActivo = rep.idActivo;
      }

      index++; // Avanzar al siguiente elemento
    }

    // Guardar la última interacción si existe
    if (interaccionActual) {
      if (
        !this.minutosMinimos?.value ||
        this.hanPasadoXMinutos(
          interaccionActual.salio,
          interaccionActual.entro,
          this.minutosMinimos?.value,
        )
      )
        interacciones.push(interaccionActual);
    }

    this.interaccionesBusqueda = interacciones;
  }

  private hanPasadoXMinutos(
    fecha1Str: string,
    fecha2Str: string,
    tiempoEnMinutos: number,
  ): boolean {
    if (!tiempoEnMinutos) return true;
    // Convertir las cadenas a objetos Date
    const fecha1 = new Date(fecha1Str);
    const fecha2 = new Date(fecha2Str);

    // Calcular la diferencia en milisegundos
    const diferenciaMs = Math.abs(fecha2.getTime() - fecha1.getTime());

    // Verificar si la diferencia es mayor o igual a 30 minutos (1,800,000 ms)
    const minutosEnMs = tiempoEnMinutos * 60 * 1000; // 30 minutos en milisegundos
    return diferenciaMs >= minutosEnMs;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['circuloParam']) {
      this.circuloParam
        ? this.circuloDeBusquedaChange(this.circuloParam)
        : null;
    }
  }
}
