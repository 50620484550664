@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
      <!-- Header -->
      <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
      </ng-container>

      <!-- Content -->
      <mat-card-content style="max-height: 60vh; overflow: auto">
        <!-- Marca -->
        <mat-form-field style="width: 100%">
          <mat-label>Marca</mat-label>
          <input matInput formControlName="marca" />
        </mat-form-field>

        <!-- Modelo -->
        <mat-form-field style="width: 100%">
          <mat-label>Modelo</mat-label>
          <input matInput formControlName="modelo" />
        </mat-form-field>

        @if (data.tipo === "Comunicador") {
          <!-- Formato Mensaje  -->
          <mat-form-field>
            <mat-label>Formato del Mensaje de Configuración</mat-label>
            <mat-select formControlName="formatoMensaje">
              @for (f of formatos; track f) {
                <mat-option [value]="f">{{ f }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @if (data.tipo !== "Cámara") {
          <!-- Codigos -->
          <ng-select
            [items]="codigosDispositivos"
            bindLabel="nombre"
            bindValue="_id"
            formControlName="idCodigos"
            placeholder="Códigos"
            [notFoundText]="'No hay codigos creados'"
            multiple="false"
            appendTo="body"
          >
          </ng-select>
        }
      </mat-card-content>

      <mat-divider style="margin-top: 1em"></mat-divider>

      <!-- Acciones -->
      <mat-dialog-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="dialogRef.close()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-dialog-actions>
    </mat-card>
  </form>
}
