/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICoordenadas,
  IGeoJSONCircle,
  IGeoJSONPoint,
  ITrackeo,
  Stop,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { RecorridoReporte } from './vehiculo-exceso-velocidad/vehiculo-exceso-velocidad.component';
import {
  LineStringMapa,
  MapaFacilData,
} from '../../../../../auxiliares/mapa-facil/interfaces';
import { OpenLayersService } from '../../../../../auxiliares/servicios/openLayers.service';

@Component({
  selector: 'app-vehiculo-busquedas',
  templateUrl: './vehiculo-busquedas.component.html',
  styleUrl: './vehiculo-busquedas.component.scss',
  standalone: false,
})
export class VehiculoBusquedasComponent implements OnInit, OnDestroy {
  // Busqueda
  public selectedTab = 0;
  public mapaBusquedaDibujar: boolean = false;
  //Reportes por punto
  public circulo: IGeoJSONCircle;
  public puntoParaCiruculo: IGeoJSONPoint;
  // Busqueda detenido por vehiculo
  public paradaListado?: Stop[] = [];
  public paradaSelected?: Stop;
  // Busqueda por exceso de velocidad
  public recorridoReporte?: RecorridoReporte;
  // Busqueda de recorridos
  public mapaDeRecorrido?: boolean = false;
  public recorridoMapaFacil?: MapaFacilData;

  // Trackeo
  public trackeo?: ITrackeo;
  private trackeo$?: Subscription;

  // Cant Mapas
  public cantMapas: number;
  // garcha de angular no tiene un for como la gente
  public indiceDeMapa: number[] = [];
  public loadingMapa: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  //

  public tabChanged(event: MatTabChangeEvent): void {
    // Setea el query param  'tab' con el valor del tab seleccionado
    this.selectedTab = event.index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.selectedTab },
      queryParamsHandling: 'merge',
    });
    // Si es el tab de por zona el mapa puede dibujar
    if (event.tab.textLabel === 'Por Zona') {
      this.mapaBusquedaDibujar = true;
    } else {
      this.mapaBusquedaDibujar = false;
    }
    // Si es el tab de recorridos cambia al mapa facil
    if (event.tab.textLabel === 'Recorridos') {
      this.mapaDeRecorrido = true;
      this.recorridoMapaFacil = {};
    } else {
      this.mapaDeRecorrido = false;
    }
  }

  public armarRecorridoParaMapa(coordenadas: ICoordenadas[]) {
    const center = OpenLayersService.lonLatToCoordinate(
      coordenadas[0].lng,
      coordenadas[0].lat,
    );
    const polylines: LineStringMapa[] = this.armarRecorridos(coordenadas);
    this.recorridoMapaFacil = {
      center,
      polylines,
      zoom: 14,
      controlsOnRight: true,
    };
  }

  private armarRecorridos(coordenadas: ICoordenadas[]) {
    const recorridos: LineStringMapa[] = [];
    const coords = coordenadas.map((reporte) => {
      return OpenLayersService.lonLatToCoordinate(reporte.lng, reporte.lat);
    });
    const recorrido: LineStringMapa = {
      points: coords,
      color: 'blue',
      width: 2,
      dash: true,
      // arrow: true,
      trip: true,
    };
    recorridos.push(recorrido);
    return recorridos;
  }

  public onClose() {
    this.trackeo = undefined;
    this.trackeo$?.unsubscribe();
  }

  private hexToRgba(hex: string, opacity: number): string {
    // Elimina el símbolo '#' si está presente
    hex = hex.replace(/^#/, '');

    // Divide el valor HEX en partes de 2 caracteres
    let r = 0,
      g = 0,
      b = 0;

    if (hex.length === 3) {
      // Si el HEX es del tipo corto (#abc), lo expandimos a 6 caracteres (#aabbcc)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      // Si el HEX ya tiene 6 caracteres
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }

    // Retorna el formato RGBA
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  ///
  async ngOnInit() {
    // Obtiene el query param 'tab' y lo asigna al tab seleccionado
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTab = parseInt(params['tab']);
        // Si es el mapa por zona
        if (this.selectedTab === 0) this.mapaBusquedaDibujar = true;
        // Si es el mapa de recorrido
        if (this.selectedTab === 3) {
          this.mapaDeRecorrido = true;
          this.recorridoMapaFacil = {};
        }
      }
    });
  }

  ngOnDestroy() {
    this.trackeo$?.unsubscribe();
  }
}
