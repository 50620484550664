/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  ITracker,
  ICliente,
  IQueryParam,
  IListado,
  ICreateTracker,
  IUpdateTracker,
  TipoTracker,
  IModeloDispositivo,
  IFilter,
  Operador,
} from 'modelos/src';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { TrackersService } from '../trackers.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CrearEditarClienteComponent } from '../../clientes/crear-editar-cliente/crear-editar-cliente.component';
import { CrearEditarModeloDispositivoComponent } from '../../../../standalone/modelos-dispositivos/crear-editar-modelo-dispositivo/crear-editar-modelo-dispositivo.component';
import { LoginService } from '../../../../login/login.service';

@Component({
  selector: 'app-crear-editar-tracker',
  templateUrl: './crear-editar-tracker.component.html',
  styleUrl: './crear-editar-tracker.component.scss',
  standalone: false,
})
export class CrearEditarTrackerComponent implements OnInit, OnDestroy {
  public loading = false;
  public clientePropio = LoginService.getCliente();
  public id: string | null = null;
  public title = '';
  public tipos?: TipoTracker[] = ['Qualcomm', 'Traccar'];
  public tracker?: ITracker;
  public tracker$?: Subscription;
  public modelos?: IModeloDispositivo[] = [];
  public modelos$?: Subscription;

  public tieneImei?: boolean = false;

  public apns = [
    'igprs.claro.com.ar',
    'wap.grps.unifon.com.ar',
    'datos.personal.com',
  ];

  filteredOptionsSim1 = new Observable<string[]>();
  filteredOptionsSim2 = new Observable<string[]>();

  public operadores: Operador[] = [
    'Claro',
    'Movistar',
    'Personal',
    'Tuenti',
    'Otro',
  ];

  public clientes?: ICliente[];
  public clientes$?: Subscription;

  public form?: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CrearEditarTrackerComponent>,
    private route: ActivatedRoute,
    private listados: ListadosService,
    private service: TrackersService,
    public helper: HelperService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    public paramsService: ParamsService,
  ) {}

  get qualcomm() {
    return this.form?.get('qualcomm') as FormGroup;
  }
  get sim1() {
    return this.form?.get('sim1') as FormGroup;
  }

  get sim2() {
    return this.form?.get('sim2') as FormGroup;
  }

  private async listarClientes() {
    this.clientes$?.unsubscribe();
    const filter: IFilter<ICliente> = {
      $or: [
        {
          $and: [
            { 'config.moduloVehiculos.activo': true },
            { 'config.moduloVehiculos.compartirVehiculos': true },
          ],
        },
        {
          $and: [
            { 'config.moduloColectivos.activo': true },
            { 'config.moduloColectivos.compartirFlota': true },
          ],
        },
        {
          $and: [
            { 'config.moduloActivos.activo': true },
            { 'config.moduloActivos.compartirActivos': true },
          ],
        },
      ],
    } as any;
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
      limit: 0,
      includeChildren: true,
      filter: JSON.stringify(filter),
    };
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        // Obtiene el cliente propio
        const clientePropio = data.datos.find((c) => {
          return c._id === LoginService.getCliente()._id;
        });
        // quita el cliente propio
        const clientes = data.datos.filter((c) => {
          return c._id !== LoginService.getCliente()._id;
        });
        // Agrega el cliente propio al inicio
        clientes.unshift(clientePropio);

        this.clientes = clientes;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  private async listarModelosDispositivos() {
    const filter: IFilter<IModeloDispositivo> = {
      tipo: 'Tracker',
    };

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
    };
    this.modelos$?.unsubscribe();
    this.modelos$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.modelos = data.datos;
        console.log(`listado de modeloDispositivos`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }

  private createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.tracker?.nombre, Validators.required),
      identificacion: new FormControl(this.tracker?.identificacion),
      idCliente: new FormControl(this.tracker?.idCliente),
      uniqueId: new FormControl(this.tracker?.uniqueId),
      tipo: new FormControl(this.tracker?.tipo, Validators.required),
      qualcomm: new FormGroup({
        serialNumber: new FormControl(this.tracker?.qualcomm?.serialNumber),
      }),
      idModelo: new FormControl(this.tracker?.idModelo),
      sim1: new FormGroup({
        iccid: new FormControl(this.tracker?.sim1?.iccid),
        numero: new FormControl(this.tracker?.sim1?.numero),
        operador: new FormControl(this.tracker?.sim1?.operador),
        apn: new FormControl(this.tracker?.sim1?.apn),
        usuario: new FormControl(this.tracker?.sim1?.usuario),
        password: new FormControl(this.tracker?.sim1?.password),
      }),
      sim2: new FormGroup({
        iccid: new FormControl(this.tracker?.sim2?.iccid),
        numero: new FormControl(this.tracker?.sim2?.numero),
        operador: new FormControl(this.tracker?.sim2?.operador),
        apn: new FormControl(this.tracker?.sim2?.apn),
        usuario: new FormControl(this.tracker?.sim2?.usuario),
        password: new FormControl(this.tracker?.sim2?.password),
      }),
    });
  }

  private getData() {
    const data: ICreateTracker | IUpdateTracker = this.form?.value;
    if (this.paramsService.getParams()['idCliente'])
      data.idCliente = this.paramsService.getParams()['idCliente'];
    return data;
  }

  public crearCliente() {
    this.dialog.open(CrearEditarClienteComponent);
  }
  public crearModelo() {
    this.dialog.open(CrearEditarModeloDispositivoComponent, {
      data: { tipo: 'Tracker' },
    });
  }

  // Actions

  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title} Tracker`,
      `¿Desea ${this.title.toLowerCase()} el tracker?`,
    );
    if (!res) return;
    try {
      let res = null;
      if (this.id) {
        // Update
        const update = this.getData() as IUpdateTracker;
        if (!update) return;
        await this.service.update(this.id, update);
        this.helper.notifSuccess('Tracker Editado');
      } else {
        // Create
        const create = this.getData() as ICreateTracker;
        if (!create) return;
        res = await this.service.create(create);
        this.helper.notifSuccess('Tracker Creado');
      }
      this.dialogRef.close(res._id);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.apns.filter((option) =>
      option.toLowerCase().includes(filterValue),
    );
  }

  // Hooks
  async ngOnInit() {
    this.loading = true;
    const params: ITracker = this.paramsService.getParams()['tracker'];
    if (params) {
      this.tracker = params;
      this.id = params._id;
    }
    this.title = this.id ? `Editar ${this.tracker.nombre}` : 'Crear';

    this.createForm();
    this.filteredOptionsSim1 = this.sim1.get('apn').valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );

    this.filteredOptionsSim2 = this.sim2.get('apn').valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );

    await this.listarClientes();
    await this.listarModelosDispositivos();
    this.loading = false;
  }

  ngOnDestroy() {
    this.tracker$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.modelos$?.unsubscribe();
  }
}
