/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICreateDispositivoAlarma,
  IUpdateDispositivoAlarma,
  IDispositivoAlarma,
  IListado,
  IQueryParam,
  IModeloDispositivo,
  ICliente,
  IFilter,
  IPopulate,
  IUbicacion,
  IUpdateUbicacion,
  ICreateUbicacion,
  IGeoJSONPoint,
  TipoDispositivo,
  Operador,
  ICamaraAlarma,
  ICamara,
  IParticionZona,
  ITipoEvento,
  ICreateTipoEvento,
} from 'modelos/src';
import { firstValueFrom, map, Observable, startWith, Subscription } from 'rxjs';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { ActivatedRoute } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AlarmasDomiciliariasService } from '../alarmas-domiciliarias.service';
import { UbicacionService } from '../../../../../auxiliares/servicios/http/ubicacion.service';
import { LoginService } from '../../../../login/login.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CrearEditarCamaraComponent } from '../../camaras/crear-editar-camara/crear-editar-camara.component';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';
import { CrearEditarClienteComponent } from '../../../administracion/clientes/crear-editar-cliente/crear-editar-cliente.component';
import { CrearEditarModeloDispositivoComponent } from '../../../../standalone/modelos-dispositivos/crear-editar-modelo-dispositivo/crear-editar-modelo-dispositivo.component';
import { CrearEditarUsuarioComponent } from '../../../administracion/usuarios/crear-editar-usuario/crear-editar-usuario.component';
import { AgregarCanalCamaraComponent } from './agregar-canal-camara/agregar-canal-camara.component';
import { TiposEventosService } from '../../../../../auxiliares/servicios/http/tipos-eventos.service';

@Component({
  selector: 'app-crear-editar-alarma-domiciliaria',
  templateUrl: './crear-editar-alarma-domiciliaria.component.html',
  styleUrl: './crear-editar-alarma-domiciliaria.component.scss',
  standalone: false,
})
export class CrearEditarAlarmaDomiciliariaComponent
  implements OnInit, OnDestroy
{
  public loading = false;
  public clientePropio = LoginService.getCliente();
  // Permisos Usuarios
  public idPermisosUsuario?: string;
  public guardarPermisosUsuario = false;
  public usuarioNuevoAgregado: string;
  //
  public form?: FormGroup;
  public formDomicilio?: FormGroup;
  public title?: string;
  public id?: string | null;
  public domicilioExistente = false;

  public centrarA?: IGeoJSONPoint;
  public apns = [
    'igprs.claro.com.ar',
    'wap.grps.unifon.com.ar',
    'datos.personal.com',
  ];

  //
  public canalesDeCamara?: string[] = [];

  filteredOptionsSim1 = new Observable<string[]>();
  filteredOptionsSim2 = new Observable<string[]>();

  public operadores: Operador[] = [
    'Claro',
    'Movistar',
    'Personal',
    'Tuenti',
    'Otro',
  ];

  public dispositivoAlarma?: IDispositivoAlarma;
  public dispositivoAlarma$?: Subscription;

  public domicilio?: IUbicacion = null;
  public domicilioId: string = null;
  public domicilio$?: Subscription;

  public ubicaciones?: IUbicacion[] = [];
  public ubicaciones$?: Subscription;
  public camaras?: ICamara[] = [];
  public camaras$?: Subscription;
  public camarasDeAlarma?: ICamara[] = [];

  public modelosAlarmas?: IModeloDispositivo[] = [];
  public modelosAlarmas$?: Subscription;
  public clientes?: ICliente[] = [];
  public clientes$?: Subscription;
  public comunicadores?: IModeloDispositivo[] = [];
  public comunicadores$?: Subscription;

  public miCliente = LoginService.getCliente();
  public clientesQuePuedenAtender?: ICliente[] = [this.miCliente];

  ///Info zona
  public tiposEventos: ITipoEvento[] = [];
  public tiposEventos$: Subscription;
  //Add Tag
  public creandoNuevoTag?: boolean = false;

  get sim1() {
    return this.form?.get('sim1') as FormGroup;
  }

  get sim2() {
    return this.form?.get('sim2') as FormGroup;
  }

  get geojson() {
    return this.formDomicilio?.get('geojson') as FormGroup;
  }

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarAlarmaDomiciliariaComponent>,
    public helper: HelperService,
    private service: AlarmasDomiciliariasService,
    private ubicacionService: UbicacionService,
    public dialog: MatDialog,
    private listados: ListadosService,
    private route: ActivatedRoute,
    private paramsService: ParamsService,
    private tipoEventoService: TiposEventosService,
  ) {}

  get arrayCamaras() {
    return this.camarasForm?.controls;
  }
  get camarasForm() {
    return this.form?.get('camarasPorZona') as FormArray;
  }
  get infoZonasForm() {
    return this.form?.get('infoZonas') as FormArray;
  }
  get clienteForm() {
    const res = this.clientes.find(
      (c) => c._id === this.form?.value?.idCliente,
    );
    if (res) return res;
    else return null;
  }

  private createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(
        this.dispositivoAlarma?.nombre,
        Validators.required,
      ),
      idUnicoComunicador: new FormControl(
        this.dispositivoAlarma?.idUnicoComunicador,
      ),
      idComunicador: new FormControl(
        this.dispositivoAlarma?.idComunicador,
        Validators.required,
      ),
      passwordComunicador: new FormControl(
        this.dispositivoAlarma?.passwordComunicador,
      ),

      frecReporte: new FormControl(this.dispositivoAlarma?.frecReporte),

      idModelo: new FormControl(
        this.dispositivoAlarma?.idModelo,
        Validators.required,
      ),
      idDomicilio: new FormControl(this.dispositivoAlarma?.idDomicilio),
      idCliente: new FormControl(
        this.dispositivoAlarma?.idCliente,
        Validators.required,
      ),
      numeroAbonado: new FormControl(
        this.dispositivoAlarma?.numeroAbonado,
        Validators.required,
      ),
      sim1: new FormGroup({
        iccid: new FormControl(this.dispositivoAlarma?.sim1?.iccid),
        numero: new FormControl(this.dispositivoAlarma?.sim1?.numero),
        operador: new FormControl(this.dispositivoAlarma?.sim1?.operador),
        apn: new FormControl(this.dispositivoAlarma?.sim1?.apn),
        usuario: new FormControl(this.dispositivoAlarma?.sim1?.usuario),
        password: new FormControl(this.dispositivoAlarma?.sim1?.password),
      }),
      sim2: new FormGroup({
        iccid: new FormControl(this.dispositivoAlarma?.sim2?.iccid),
        numero: new FormControl(this.dispositivoAlarma?.sim2?.numero),
        operador: new FormControl(this.dispositivoAlarma?.sim2?.operador),
        apn: new FormControl(this.dispositivoAlarma?.sim2?.apn),
        usuario: new FormControl(this.dispositivoAlarma?.sim2?.usuario),
        password: new FormControl(this.dispositivoAlarma?.sim2?.password),
      }),
      idsClientesQuePuedenAtender: new FormControl(
        this.dispositivoAlarma?.idsClientesQuePuedenAtender || [
          this.miCliente?._id,
        ],
      ),
      idsCamaras: new FormControl(this.dispositivoAlarma?.idsCamaras),
      camarasPorZona: new FormArray(this.addNewCamara()),
      infoZonas: new FormArray(this.addNewInfoZona()),
    });
  }

  private addNewCamara() {
    const codigos = [];
    if (this.dispositivoAlarma?.camarasPorZona) {
      for (const r of this.dispositivoAlarma.camarasPorZona) {
        codigos.push(this.addCamaraFormGroup(r));
      }
    }
    return codigos;
  }

  private addNewInfoZona() {
    const codigos = [];
    if (this.dispositivoAlarma?.infoZonas) {
      for (const r of this.dispositivoAlarma.infoZonas) {
        codigos.push(this.addInfoZonaFormGroup(r));
      }
    }
    return codigos;
  }

  public addCamara() {
    const data = {
      camarasDeAlarma: this.camarasDeAlarma,
      idAlarma: this.dispositivoAlarma._id,
    };
    const config: MatDialogConfig = {
      data,
      minWidth: '600px',
    };
    const dialog = this.dialog.open(AgregarCanalCamaraComponent, config);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.camarasForm.push(this.addCamaraFormGroup(res));
      }
    });
  }

  private addCamaraFormGroup(camara?: ICamaraAlarma) {
    return new FormGroup({
      idCamara: new FormControl(camara?.idCamara, [Validators.required]),
      particion: new FormControl(camara?.particion, [Validators.required]),
      zona: new FormControl(camara?.zona, [Validators.required]),
      canal: new FormControl(camara?.canal, [Validators.required]),
    });
  }

  private addInfoZonaFormGroup(infozona?: IParticionZona) {
    return new FormGroup({
      nombre: new FormControl(infozona?.nombre, [Validators.required]),
      particion: new FormControl(infozona?.particion, [Validators.required]),
      zona: new FormControl(infozona?.zona, [Validators.required]),
    });
  }

  private createFormDomicilio() {
    this.formDomicilio = new FormGroup({
      idCliente: new FormControl(
        this.domicilio?.idCliente || this.dispositivoAlarma?.idCliente,
      ),
      direccion: new FormControl(
        this.domicilio?.direccion,
        Validators.required,
      ),
      identificacion: new FormControl(this.domicilio?.identificacion),
      geojson: new FormGroup({
        type: new FormControl('Point'),
        coordinates: new FormControl(this.domicilio?.geojson?.coordinates),
      }),
      categoria: new FormControl('Domicilio'),
    });
  }

  public async listar(): Promise<void> {
    const filter: IFilter<IDispositivoAlarma> = {
      _id: this.id,
    };
    const populate: IPopulate[] = [
      {
        path: 'modelo',
      },
      {
        path: 'domicilio',
      },
      {
        path: 'cliente',
      },
      {
        path: 'camaras',
      },
    ];
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };

    this.dispositivoAlarma$?.unsubscribe();
    this.dispositivoAlarma$ = this.listados
      .subscribe<IListado<IDispositivoAlarma>>('dispositivosAlarmas', query)
      .subscribe((data) => {
        if (data.datos[0]) this.dispositivoAlarma = data.datos[0];
        if (this.dispositivoAlarma.camaras)
          this.camarasDeAlarma = this.dispositivoAlarma?.camaras;
        console.log(`listado de dispositivosAlarmas`, data);
      });
    await this.listados.getLastValue('dispositivosAlarmas', query);
  }
  public async listarModelos(): Promise<void> {
    const tipo: TipoDispositivo = 'Alarma';
    const filter: IFilter<IModeloDispositivo> = { tipo };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };
    this.modelosAlarmas$?.unsubscribe();
    this.modelosAlarmas$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.modelosAlarmas = data.datos;
        console.log(`listado de modelos de alarma`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }
  public async listarComunicadores(): Promise<void> {
    const tipo: TipoDispositivo = 'Comunicador';
    const filter: IFilter<IModeloDispositivo> = { tipo };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };
    this.comunicadores$?.unsubscribe();
    this.comunicadores$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.comunicadores = data.datos;
        console.log(`listado de comunicadores`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }
  public async listarClientes(): Promise<void> {
    const filter: IFilter<ICliente> = {
      'config.moduloAlarmasDomiciliarias.activo': true,
      'config.moduloAlarmasDomiciliarias.compartirAlarmas': true,
    } as any;
    const query: IQueryParam = {
      includeChildren: true,
      filter: JSON.stringify(filter),
      limit: 0,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        // Obtiene el cliente propio
        const clientePropio = data.datos.find((c) => {
          return c._id === LoginService.getCliente()._id;
        });
        // quita el cliente propio
        const clientes = data.datos.filter((c) => {
          return c._id !== LoginService.getCliente()._id;
        });
        // Agrega el cliente propio al inicio
        clientes.unshift(clientePropio);

        this.clientes = clientes;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  public async listarDomicilios(): Promise<void> {
    const filter: IFilter<IUbicacion> = { categoria: 'Domicilio' };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };
    this.ubicaciones$ = this.listados
      .subscribe<IListado<IUbicacion>>('ubicacions', query)
      .subscribe((data) => {
        this.ubicaciones = data.datos;
        console.log(`listado de ubicacions`, data);
      });
    await this.listados.getLastValue('ubicacions', query);
  }
  public async listarCamaras(): Promise<void> {
    // Determinar el idCliente de acuerdo al estado (creando o editando)
    const idClienteDeAlarma = this.dispositivoAlarma
      ? this.dispositivoAlarma.idCliente // Si estamos editando, usamos el cliente asociado al dispositivo
      : this.form?.value?.idCliente; // Si estamos creando, usamos el idCliente del formulario
    const query: IQueryParam = {
      filter: JSON.stringify({ idCliente: idClienteDeAlarma }),
      includeChildren: true,
    };
    this.camaras$ = this.listados
      .subscribe<IListado<ICamara>>('camaras', query)
      .subscribe((data) => {
        this.camaras = data.datos;
        console.log('listado de camaras: ', data);
      });
    await this.listados.getLastValue('camaras', query);
    if (this.dispositivoAlarma?.idsClientesQuePuedenAtender) {
      this.clientesQuePuedenAtender = this.clientes.filter((c) =>
        this.dispositivoAlarma.idsClientesQuePuedenAtender.includes(c._id),
      );
    }
  }

  private async listarTipoEvento(): Promise<void> {
    const filter: IFilter<ITipoEvento> = {
      categoria: 'Alarma',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'nombre',
      limit: 0,
    };
    this.tiposEventos$?.unsubscribe();
    this.tiposEventos$ = this.listados
      .subscribe<IListado<ITipoEvento>>('tiposEventos', query)
      .subscribe((data) => {
        this.tiposEventos = data.datos;
        console.log(`listado de tiposEventos`, data);
      });
    await this.listados.getLastValue('tiposEventos', query);
  }

  ///

  public onCheckboxChange(e: MatCheckboxChange) {
    this.domicilioExistente = e.checked;
    if (this.domicilioExistente) {
      this.domicilio = this.dispositivoAlarma?.domicilio;
    } else {
      this.domicilio = null;
    }
    this.formDomicilio.reset();
  }

  public onClick(e: IUbicacion) {
    if (e) {
      this.centrarA = e.geojson as IGeoJSONPoint;
      this.domicilio = e;
    }
  }

  public onClienteChange(cliente: ICliente) {
    const clientesPadres: ICliente[] = [cliente];
    let idPadre = cliente.idPadre;
    while (idPadre) {
      const clientePadre = this.clientes.find((c) => c._id === idPadre);
      if (clientePadre) {
        clientesPadres.push(clientePadre);
      }
      idPadre = clientePadre?.idPadre;
    }
    this.clientesQuePuedenAtender = clientesPadres;

    // Resetea los clientes que pueden atender
    this.form
      ?.get('idsClientesQuePuedenAtender')
      ?.setValue([this.miCliente._id]);
    this.form?.get('idCliente')?.setValue(cliente._id);
    this.listarCamaras();
  }

  /// Drag and Drop
  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.arrayCamaras, event.previousIndex, event.currentIndex);
    // Patch formArray
    this.camarasForm.patchValue(this.arrayCamaras);
  }

  public editCamaraAlarma(i: number) {
    const valor = this.camarasForm.value[i];
    if (!valor) return;
    const data = {
      camarasDeAlarma: this.camarasDeAlarma,
      idAlarma: this.dispositivoAlarma._id,
      infoEditar: valor,
    };
    const config: MatDialogConfig = {
      data,
      minWidth: '600px',
    };
    const dialog = this.dialog.open(AgregarCanalCamaraComponent, config);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.camarasForm.push(this.addCamaraFormGroup(res));
      }
    });
  }

  public deleteCamaraAlarma(i: number) {
    this.camarasForm.removeAt(i);
  }

  public deleteInfoZona(i: number) {
    this.infoZonasForm.removeAt(i);
  }

  public addInfoZona() {
    this.infoZonasForm.push(this.addInfoZonaFormGroup());
  }

  public asignarCamarasDeAlarma(camaras: ICamara[]) {
    this.camarasDeAlarma = camaras;
  }

  public nombreCanalCamara(id: string, canal: string) {
    const camara = this.camaras.find((c) => c._id === id);
    if (camara)
      return camara.canales.length > 0
        ? camara.canales.find((c) => c.numero == canal).nombre
        : null;
    return null;
  }

  public getCanales(index: number) {
    const camarasForm: ICamaraAlarma[] = this.camarasForm?.value;
    if (camarasForm.length - 1 <= index && camarasForm[index].idCamara) {
      const camara = this.camaras.find(
        (c) => c._id === this.camarasForm.value[index].idCamara,
      );
      const a = camara.canales?.map((c) => c.numero);
      return a;
    } else {
      const algo: string[] = [];
      return algo;
    }
  }

  public nombreCamara(idCamara: string) {
    return this.camarasDeAlarma.find((c) => c._id === idCamara).identificacion;
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = await this.getData();
      console.log(data);
      if (this.id) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Actualizado correctamente');
        this.helper.volver();
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
        this.helper.volver();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  private async getIdDomicilio() {
    // Es un domicilio nuevo
    if (!this.domicilioExistente) {
      const dataCreateDomicilio = this.getDataUbicacion();
      console.log(dataCreateDomicilio);

      if (dataCreateDomicilio?.direccion) {
        const created = await this.ubicacionService.create(dataCreateDomicilio);
        return created._id;
      }
      return null;
      // Es un domicilio existente
    } else {
      return this.domicilio?._id;
    }
  }

  public async getData(): Promise<
    ICreateDispositivoAlarma | IUpdateDispositivoAlarma
  > {
    const data: ICreateDispositivoAlarma | IUpdateDispositivoAlarma =
      this.form?.value;
    data.idDomicilio = await this.getIdDomicilio();
    return data;
  }

  public getDataUbicacion() {
    const data: ICreateUbicacion | IUpdateUbicacion = this.formDomicilio?.value;
    data.identificacion = data.direccion;
    return data;
  }

  // Opciones para crear

  public crearCliente() {
    const dialog = this.dialog.open(CrearEditarClienteComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idCliente: res });
      }
    });
  }
  public crearModeloAlarma() {
    const dialog = this.dialog.open(CrearEditarModeloDispositivoComponent, {
      data: { tipo: 'Alarma' },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idModelo: res });
      }
    });
  }
  public crearModeloComunicador() {
    const dialog = this.dialog.open(CrearEditarModeloDispositivoComponent, {
      data: { tipo: 'Comunicador' },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idComunicador: res });
      }
    });
  }
  public crearUsuario() {
    this.paramsService.setParams({
      idCliente: this.form?.value?.idCliente,
      externo: true,
    });
    const dialog = this.dialog.open(CrearEditarUsuarioComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        console.log(res);
        this.usuarioNuevoAgregado = res;
      }
    });
  }
  public crearCamara() {
    this.paramsService.setParams({
      desdeAlarma: true,
      idCliente: this.form.value.idCliente,
    });
    const dialog = this.dialog.open(CrearEditarCamaraComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        const camaras = this.form?.value?.idsCamaras || [];
        camaras.push(res);
        this.form.patchValue({ idsCamaras: camaras });
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.apns.filter((option) =>
      option.toLowerCase().includes(filterValue),
    );
  }

  public async addTag(t: string) {
    if (!this.creandoNuevoTag) {
      this.creandoNuevoTag = true;
      const tipoEvento: ICreateTipoEvento = {
        nombre: t,
        categoria: 'Alarma',
      };
      try {
        const res = await this.tipoEventoService.create(tipoEvento);
        for (const zona of this.infoZonasForm.controls) {
          const value = zona.value?.nombre;
          if (!value) {
            zona.patchValue({ nombre: res.nombre });
          }
        }
        this.creandoNuevoTag = false;
      } catch {
        this.creandoNuevoTag = false;
      }
    }
  }

  public volver() {
    this.helper.volver();
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    if (this.id) {
      this.idPermisosUsuario = this.id;
      await this.listar();
      this.domicilio = this.dispositivoAlarma?.domicilio;
      this.domicilioExistente = this.dispositivoAlarma?.domicilio
        ? true
        : false;
    }

    await Promise.all([
      this.listarClientes(),
      this.listarModelos(),
      this.listarDomicilios(),
      this.listarComunicadores(),
      this.listarCamaras(),
      this.listarTipoEvento(),
    ]);

    this.title = this.id
      ? `Editar ${this.dispositivoAlarma?.nombre}`
      : 'Crear Alarma';
    this.createFormDomicilio();
    this.createForm();
    this.filteredOptionsSim1 = this.sim1.get('apn').valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );

    this.filteredOptionsSim2 = this.sim2.get('apn').valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );
  }

  ngOnDestroy(): void {
    this.modelosAlarmas$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.ubicaciones$.unsubscribe();
    this.camaras$.unsubscribe();
    this.dispositivoAlarma$?.unsubscribe();
    this.comunicadores$?.unsubscribe();
  }
}
