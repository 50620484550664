<div (click)="bringToFront()">
  <mat-card style="border: 1px solid black; overflow-x: hidden">
    <!-- Header -->
    <mat-card-header
      style="border-radius: 2px 2px 0 0"
      [style.background-color]="data.valores?.color"
      [style.color]="helper.colorDeTextoSegunColor(data.valores?.color)"
    >
      <!-- [cdkDragBoundary]="dragBoundary" -->
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
        (cdkDragStarted)="bringToFront()"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <!-- Titulo evento y cliente -->
        <span style="padding-left: 8px">
          {{ data?.valores.titulo }} -
          {{
            data?.alarma?.nombre ||
              data?.activo?.identificacion ||
              data?.tracker?.nombre
          }}
          -
          {{ data?.cliente?.nombre }}
        </span>

        <!-- Estado de cuenta -->
        @if (estadoDeCuenta) {
          <span>({{ estadoDeCuenta }})</span>
        }

        <!--DEBUG:  Estado actual -->
        <!-- <span>{{ estadoActual }} tratando: {{ tratando }}</span> -->

        <!-- Cerrar -->
        <div (click)="closeDialog()" class="icon-close">
          <mat-icon> close </mat-icon>
        </div>
      </mat-card-title>
    </mat-card-header>
    <!-- Content -->
    <mat-card-content
      style="
        margin: 1em 0;
        overflow: auto;
        max-height: 80vh;
        overflow-x: hidden;
      "
    >
      <!--  -->
      <div>
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 1.5em;
            justify-content: space-between;
            margin-bottom: 1em;
          "
        >
          <!-- Informacion del evento -->
          <div style="display: flex; flex-direction: column">
            <!-- Detalles -->
            <span>
              {{ data.valores?.mensaje || data.valores?.["detalles"] }}
            </span>

            <!-- Activo -->
            @if (data.activo) {
              <span>{{ data.activo.identificacion }}</span>
            }

            <!-- Vehiculo -->
            @if (data.activo?.vehiculo) {
              <span>
                {{ data.activo?.vehiculo?.tipo }} -
                {{ data.activo?.vehiculo?.patente }}
              </span>
            }

            <!-- Alarma -->
            @if (data.alarma) {
              <!-- Comunicador - Alarma -->
              @if (data.alarma?.comunicador || data.alarma?.modelo) {
                <span>
                  {{ data.alarma?.comunicador?.marca }}
                  {{ data.alarma?.comunicador?.modelo }} -
                  {{ data.alarma?.modelo?.marca }}
                  {{ data.alarma?.modelo?.modelo }}
                </span>
              }
              <!-- Domicilio -->
              @if (data.alarma?.domicilio) {
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  {{ data.alarma?.domicilio.identificacion }}
                  @if (data.alarma?.domicilio.fotos?.length) {
                    <div
                      (click)="verFotos(); $event.stopPropagation()"
                      class="icon-map"
                      matTooltip="Ver fotos"
                      style="scale: 0.75"
                    >
                      <mat-icon>image</mat-icon>
                    </div>
                  }
                </div>
              }
            }
          </div>

          <!-- Ubicacion -->
          <div
            (click)="verUbicacion(); $event.stopPropagation()"
            class="icon-map"
            matTooltip="Ver ubicación"
          >
            <mat-icon>map</mat-icon>
          </div>

          <!-- Servicios Contratados -->
          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 0.5em;
              justify-content: flex-end;
            "
          >
            @for (servicio of serviciosContratados; track servicio) {
              <img
                [src]="servicio.icono"
                [alt]="servicio.nombre"
                [matTooltip]="servicio.nombre | titlecase"
                width="35px"
                height="35px"
              />
            }
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- Notas, contactos, eventos y tratamientos | CAMARAS -->
        <div
          class="row"
          style="
            justify-content: start;
            align-items: flex-start;
            gap: 0.5em;
            margin-top: 1em;
          "
        >
          <!-- Notas, contactos, eventos y tratamientos -->
          <div style="width: 50%">
            <!-- Notas y contactos -->
            <div
              style="
                display: flex;
                flex-direction: row;
                gap: 0.5em;
                margin-bottom: 0.5em;
              "
            >
              <!-- Notas -->
              <div
                style="
                  max-height: 300px;
                  width: 50%;
                  overflow-x: hidden;
                  border: 1px solid black;
                  border-radius: 10px;
                  padding: 0.5em;
                "
              >
                <span class="titulo">Notas</span>
                <div style="margin-top: 0.5em">
                  @if (notas.length) {
                    @for (nota of notas; track nota) {
                      <div
                        style="display: flex; flex-direction: column"
                        [class.even]="$even"
                      >
                        <span>{{ nota.informacion?.nota }}</span>
                      </div>
                      <mat-divider class="divider"></mat-divider>
                    }
                  } @else {
                    <span>No hay notas</span>
                  }
                </div>
              </div>

              <!-- Contactos -->
              <div
                style="
                  max-height: 300px;
                  width: 50%;
                  overflow-x: hidden;
                  border: 1px solid black;
                  border-radius: 10px;
                  padding: 0.5em;
                "
              >
                <span class="titulo">Contactos</span>
                <div style="margin-top: 0.5em">
                  @if (contactos.length) {
                    @for (contacto of contactos; track contacto) {
                      <div
                        style="display: flex; flex-direction: column"
                        [class.even]="$even"
                      >
                        <!-- Nombre del conctacto / telefono -->
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <span class="estado">
                            {{ contacto.informacion?.contacto }}
                          </span>
                          <span style="font-size: 14px">
                            {{ contacto.informacion?.telefono }}
                          </span>
                        </div>
                        <!-- Nota -->
                        @if (contacto.informacion?.nota) {
                          <span class="usuario">
                            {{ contacto.informacion?.nota }}
                          </span>
                        }

                        <!-- Palabras de la alarma -->
                        @if (
                          contacto.informacion?.palabraSeguridadNormal ||
                          contacto.informacion?.palabraSeguridadEmergencia
                        ) {
                          <span>
                            Clave:
                            {{ contacto.informacion?.palabraSeguridadNormal }}
                          </span>
                          <span>
                            Contra clave:
                            {{
                              contacto.informacion?.palabraSeguridadEmergencia
                            }}
                          </span>
                        }
                        <!-- <span>Número de usuario: {{ contacto.informacion?.numeroUsuarioAlarma }}</span> -->
                      </div>
                      <mat-divider class="divider"></mat-divider>
                    }
                  } @else {
                    <span>No hay contactos</span>
                  }
                </div>
              </div>
            </div>

            <!-- Eventos y tratamientos -->
            <div
              style="
                display: flex;
                flex-direction: row;
                gap: 0.5em;
                margin-bottom: 0.5em;
              "
            >
              <!-- Otros Eventos -->
              <div
                style="
                  max-height: 300px;
                  width: 50%;
                  overflow-x: hidden;
                  border: 1px solid black;
                  border-radius: 10px;
                  padding: 0.5em;
                "
              >
                <span class="titulo"> Eventos a tratar </span>
                <mat-checkbox
                  [checked]="true"
                  (change)="marcarTodos($event.checked)"
                  >Todos</mat-checkbox
                >
                <div style="margin-top: 0.5em">
                  @for (evento of eventosDispositivo; track evento) {
                    <div
                      style="display: flex; flex-direction: column"
                      [class.even]="$even"
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <span class="estado">{{ evento.valores.titulo }}</span>
                        <div style="white-space: nowrap">
                          <span style="font-size: 14px">
                            {{ evento.fechaCreacion | date: "hh:mm" }}
                          </span>
                          <mat-checkbox
                            [checked]="marcado(evento)"
                            (change)="cambiarMarcado($event.checked, evento)"
                          ></mat-checkbox>
                        </div>
                      </div>
                      <span class="usuario">
                        {{ evento.valores.mensaje }}
                        <!-- <span>
                            - Ocurrencias: {{ evento.repetido + 1 }}
                          </span> -->
                      </span>
                    </div>
                    <mat-divider class="divider"></mat-divider>
                  }
                </div>
              </div>
              <!-- Tratamientos -->
              <div
                style="
                  max-height: 300px;
                  width: 50%;
                  overflow-x: hidden;
                  border: 1px solid black;
                  border-radius: 10px;
                  padding: 0.5em;
                "
              >
                <span class="titulo"> Tratamientos </span>
                <div style="margin-top: 0.5em">
                  @if (tratamientosEvento?.length) {
                    @for (
                      tratamiento of tratamientosEvento;
                      track tratamiento
                    ) {
                      <div
                        style="display: flex; flex-direction: column"
                        [class.even]="$even"
                      >
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <span class="estado">{{ tratamiento.estado }}</span>
                          <span style="font-size: 14px">
                            {{ tratamiento.fechaCreacion | date: "short" }}
                          </span>
                        </div>
                        <span class="usuario">
                          {{ tratamiento.usuario?.usuario || "Sistema" }}
                        </span>
                        @if (tratamiento.nota) {
                          <span style="margin-top: 0.25em">
                            {{ tratamiento.nota }}
                          </span>
                        }
                        @if (tratamiento.notaInterna) {
                          <span style="margin-top: 0.25em">
                            Nota Interna: {{ tratamiento.notaInterna }}
                          </span>
                        }
                      </div>
                      <mat-divider class="divider"></mat-divider>
                    }
                  } @else {
                    <span>No hay tratamientos</span>
                  }
                </div>
              </div>
            </div>

            <!-- Ultimos eventos -->
            <div
              class="row"
              style="
                justify-content: start;
                align-items: flex-start;
                gap: 0.5em;
                margin-bottom: 0.5em;
              "
            >
              <div
                style="
                  max-height: 500px;
                  width: 100%;
                  overflow-x: hidden;
                  border: 1px solid black;
                  border-radius: 10px;
                  padding: 0.5em;
                "
              >
                <span class="titulo"> Últimos eventos </span>

                <div style="margin-top: 0.5em">
                  @for (evento of ultimosEventos; track evento) {
                    <div
                      style="display: flex; flex-direction: column"
                      [class.even]="$even"
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <span class="estado">{{ evento.valores.titulo }}</span>
                        <div style="white-space: nowrap">
                          <span style="font-size: 14px">
                            {{ evento.fechaCreacion | date: "short" }}
                          </span>
                        </div>
                      </div>
                      <span class="usuario">
                        {{ evento.valores.mensaje }}
                        <!-- <span>
                          - Ocurrencias: {{ evento.repetido + 1 }}
                        </span> -->
                      </span>
                    </div>
                    <mat-divider class="divider"></mat-divider>
                  }
                </div>
              </div>
            </div>
          </div>
          <!-- Camaras -->
          <div style="width: 50%">
            <app-camaras-multiple
              [data]="data?.alarma?.idsCamaras"
              [zonaInicial]="camaraInicialAlarma"
              [fechaEvento]="data.fechaCreacion"
            ></app-camaras-multiple>
          </div>
        </div>
      </div>
    </mat-card-content>

    <!-- Acciones -->
    <mat-dialog-actions
      style="
        display: flex;
        justify-content: space-between;
        padding: 6px;
        gap: 8px;
      "
    >
      <!-- Tratar -->
      @if (
        !tratando &&
        estadoActual !== "Finalizada" &&
        estadoActual !== "En Espera"
      ) {
        <app-button
          matType="mat-fab extended"
          color="accent"
          [text]="eventosDispositivo?.length ? 'Tratar' : 'No hay eventos'"
          icon="monitor_heart"
          [disabled]="loading || !eventosDispositivo?.length"
          (click)="
            !(loading || !eventosDispositivo?.length) ? tratar() : null;
            $event.stopPropagation()
          "
        ></app-button>
      }

      <!-- Tratamientos -->
      @if (
        tratando &&
        estadoActual !== "Finalizada" &&
        estadoActual !== "En Espera"
      ) {
        <!-- Agregar Nota  -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          (click)="agregarNota(); $event.stopPropagation()"
          text="Agregar nota"
          icon="add"
        ></app-button>
        <!-- Liberar -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Liberar"
          icon="arrow_upward_alt"
          (click)="liberar(); $event.stopPropagation()"
        ></app-button>
        @if (tieneEnvioMovil()) {
          <!-- Movil -->
          <app-button
            matType="mat-fab extended"
            color="accent"
            text="Enviar Movil"
            icon="moped"
            (click)="enviarMovil(); $event.stopPropagation()"
          >
          </app-button>
        }
        <!-- Espera -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Espera"
          icon="undo"
          (click)="espera(); $event.stopPropagation()"
        >
        </app-button>
        <!-- Finalizar -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Finalizar"
          icon="check_circle"
          (click)="finalizar(); $event.stopPropagation()"
        ></app-button>
      }

      <!-- Finalizada -->
      @if (estadoActual === "Finalizada") {
        <h2>Evento finalizado</h2>
      }

      <!-- En espera -->
      @if (estadoActual === "En Espera") {
        <h2>Evento en espera</h2>

        <!-- Quitar de espera  -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          (click)="tratar(); $event.stopPropagation()"
          text="Quitar de espera"
          icon="lock_open"
        ></app-button>
      }
    </mat-dialog-actions>
  </mat-card>
</div>
