<div style="display: flex; align-items: center; justify-content: space-around">
  @if (controles) {
    <div style="position: relative; width: 100%; height: 100%">
      <video
        #video
        [id]="name()"
        muted
        crossorigin
        controls
        style="width: 100%; height: auto"
      ></video>
      @if (botonMultiple) {
        @if (maximizado) {
          <app-button
            matType="mat-mini-fab"
            class="custom-btn"
            (click)="botonClick()"
            icon="apps"
          ></app-button>
        } @else {
          <app-button
            matType="mat-mini-fab"
            class="custom-btn"
            (click)="botonClick()"
            icon="fullscreen"
          ></app-button>
        }
      }
    </div>
  } @else {
    <div style="position: relative">
      <video
        [id]="name()"
        muted
        crossorigin
        style="width: 100%; height: auto"
      ></video>
      @if (botonMultiple) {
        <button
          aling="end"
          class="custom-btn"
          style="background-color: none"
          (click)="botonClick()"
        >
          @if (maximizado) {
            <mat-icon style="border: 2px; border-color: black">apps</mat-icon>
          } @else {
            <mat-icon style="border: 2px; border-color: black">
              fullscreen
            </mat-icon>
          }
        </button>
      }
    </div>
  }
</div>
