import { Injectable } from '@angular/core';
import {
  INota,
  IListado,
  IQueryParam,
  IUpdateNota,
  ICreateNota,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class NotasService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<INota>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/notas`, { params });
  }

  public getById(id: string): Promise<INota> {
    return this.http.get(`/notas/${id}`);
  }

  public create(dato: ICreateNota): Promise<INota> {
    return this.http.post(`/notas`, dato);
  }

  public createMany(dato: ICreateNota[]): Promise<INota[]> {
    return this.http.post(`/notas/many`, dato);
  }

  public update(id: string, dato: IUpdateNota): Promise<INota> {
    return this.http.put(`/notas/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/notas/${id}`);
  }
}
