import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICamara,
  ICamaraAlarma,
  IConfigEventoUsuario,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../../../auxiliares/servicios';

@Component({
  selector: 'app-agregar-canal-camara',
  templateUrl: './agregar-canal-camara.component.html',
  styleUrl: './agregar-canal-camara.component.scss',
  standalone: false,
})
export class AgregarCanalCamaraComponent implements OnInit {
  public form?: FormGroup;
  public title?: string;
  public canalesCamaraElegida?: { numero: string; nombre: string }[];
  public personalizado?: boolean = false;
  //
  public configsEventos?: IConfigEventoUsuario[];
  private configsEventos$: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      camarasDeAlarma: ICamara[];
      idAlarma?: string;
      infoEditar?: ICamaraAlarma;
    },
    public helper: HelperService,
    public dialogRef: MatDialogRef<AgregarCanalCamaraComponent>,
    private listados: ListadosService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      idCamara: new FormControl(
        this.data?.infoEditar?.idCamara,
        Validators.required,
      ),
      canal: new FormControl(this.data?.infoEditar?.canal, Validators.required),
      particion: new FormControl(
        this.data?.infoEditar?.particion,
        Validators.required,
      ),
      zona: new FormControl(this.data?.infoEditar?.zona, Validators.required),
    });
  }

  public async listar(): Promise<void> {
    if (this.data.idAlarma) {
      const filter: IFilter<IConfigEventoUsuario> = {
        idEntidad: this.data.idAlarma,
        configZona: { $exists: true },
      };
      const query: IQueryParam = { filter: JSON.stringify(filter) };
      //
      this.configsEventos$?.unsubscribe();
      this.configsEventos$ = this.listados
        .subscribe<
          IListado<IConfigEventoUsuario>
        >('configEventoUsuarios', query)
        .subscribe((data) => {
          this.configsEventos = data.datos;
          console.log(`listado de configEventoUsuarios`, data);
        });
      await this.listados.getLastValue('configEventoUsuarios', query);
    }
  }

  public camaraElegida() {
    if (this.form.value?.idCamara) {
      const camara = this.data.camarasDeAlarma.find(
        (c) => c._id === this.form.value?.idCamara,
      );
      this.canalesCamaraElegida = [];
      camara.canales.forEach((c) =>
        this.canalesCamaraElegida.push({ numero: c.numero, nombre: c.nombre }),
      );
    }
  }

  public configElegida(config: IConfigEventoUsuario) {
    if (!config) {
      this.form.patchValue({ particion: null });
      this.form.patchValue({ zona: null });
    } else {
      this.form.patchValue({ particion: config.configZona?.particion });
      this.form.patchValue({ zona: config.configZona?.zona });
    }
  }

  public cambiarPersonalizado(value: boolean) {
    this.personalizado = value;
    this.form.patchValue({ particion: null });
    this.form.patchValue({ zona: null });
  }

  //

  public async onSubmit() {
    this.dialogRef.close(this.getData());
  }

  public getData(): ICamaraAlarma {
    const data: ICamaraAlarma = this.form?.value;
    return data;
  }

  async ngOnInit() {
    this.title = this.data.infoEditar ? 'Editar Canal' : 'Agregar Canal';
    await this.listar();
    if (this.data?.infoEditar) this.personalizado = true;
    this.createForm();
    console.log('a');
  }
}
