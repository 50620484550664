@if (!this.loading) {
  <!-- Título -->
  <div style="display: flex; align-items: center; width: 100%">
     <!-- Botón de Volver alineado a la izquierda -->
  <app-button
  matType="mat-fab extended"
  color="warn"
  (click)="this.volver()"
  text="Volver"
  icon="arrow_back"
  style="margin-right: 10px"
></app-button>
<!-- Ajusta el espacio entre el botón y el título -->
<span class="title1" style="flex-grow: 1; text-align: center">{{ activo?.identificacion }}</span>
  </div>
  <mat-tab-group
    [(selectedIndex)]="selectedTab"
    (selectedTabChange)="tabChanged($event)"
  >
    <!-- Info -->
    <mat-tab label="Info">
      <ng-template matTabContent>
        <app-detalles-activo-info></app-detalles-activo-info>
      </ng-template>
    </mat-tab>
    <!-- Historial de Eventos -->
    <mat-tab label="Historial Eventos">
      <ng-template matTabContent>
        <app-listado-eventos-historico
          [id]="activo._id"
          tipo="Activo"
        ></app-listado-eventos-historico>
      </ng-template>
    </mat-tab>
    <!-- Codigos -->
    <mat-tab label="Códigos Eventos">
      <ng-template matTabContent>
        <app-listado-configs-eventos
          [dispositivo]="activo"
          tipo="Activo"
          [botonCrearMostrar]="botonCrearMostrar()"
        ></app-listado-configs-eventos>
      </ng-template>
    </mat-tab>
    <!--Eventos Personalizados -->
    <mat-tab label="Eventos Personalizados">
      <ng-template matTabContent>
        @if (params) {
          <app-listado-config-evento-usuario
            [params]="params"
          ></app-listado-config-evento-usuario>
        }
      </ng-template>
    </mat-tab>
    <!-- Notas -->
    <mat-tab label="Notas">
      <ng-template matTabContent>
        <app-listado-notas
          [idAsignado]="activo._id"
          tipo="Nota"
          tipoDispositivo="Activo"
        ></app-listado-notas>
      </ng-template>
    </mat-tab>
    <!-- Contactos -->
    <mat-tab label="Contactos">
      <ng-template matTabContent>
        <app-listado-notas
          [idAsignado]="this.activo._id"
          tipo="Contacto"
          tipoDispositivo="Activo"
        ></app-listado-notas>
      </ng-template>
    </mat-tab>
    <!-- Servicio Técnicos-->
    <mat-tab label="Servicio Técnicos">
      <ng-template matTabContent>
        <app-eventos-tecnicos [activo]="activo"></app-eventos-tecnicos>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
}
<!-- Botones -->
<div class="row-flex-end" style="gap: 10px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.volver()"
    text="Volver"
    icon="arrow_back"
    z
  ></app-button>
</div>
