import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IEstadoEntidad,
  ICreateEstadoEntidad,
  IUpdateEstadoEntidad,
} from 'modelos/src';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';

@Injectable({
  providedIn: 'root',
})
export class EstadoEntidadsService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IEstadoEntidad>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/estadoentidads`, { params });
  }

  public getById(id: string): Promise<IEstadoEntidad> {
    return this.http.get(`/estadoentidads/${id}`);
  }

  public create(dato: ICreateEstadoEntidad): Promise<IEstadoEntidad> {
    return this.http.post(`/estadoentidads`, dato);
  }

  public update(
    id: string,
    dato: IUpdateEstadoEntidad,
  ): Promise<IEstadoEntidad> {
    return this.http.put(`/estadoentidads/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/estadoentidads/${id}`);
  }
}
