@if (!loading) {
  <br />
  <div class="main-container">
    <span class="subtitle1">Información de la cámara</span>
  </div>
  
  <br />
  <div class="row-center" style="gap: 15px">


    <table class="tabla-camara">
      <!-- Identificación -->
      <tr>
        <td class="tabla-label">Identificación:</td>
        <td>{{ camara?.identificacion }}</td>
      </tr>

      <!-- Cliente -->
      <tr>
        <td class="tabla-label">Cliente:</td>
        <td>{{ camara?.cliente.nombre }}</td>
      </tr>
      <tr>

       <!-- Host -->
       <tr>
        <td class="tabla-label">Host:</td>
        <td>{{ camara?.host }}</td>
      </tr>

      <!-- Marcada y modelo -->
       @if (camara?.modeloDispositivo){
      <tr>
        <td class="tabla-label">Marca y modelo:</td>
        <td>{{ camara?.modeloDispositivo.marca }} - {{ camara?.modeloDispositivo.modelo }}</td>
      </tr>
      <tr>
      }
      
      <!-- Fecha de creación -->
      @if (camara?.fechaCreacion){
     <tr>
        <td class="tabla-label">Fecha de creación:</td>
        <td>{{ camara?.fechaCreacion | date:'dd/MM/yyyy' }}</td>
      </tr>
    }
      <!-- Número de canales -->
      <tr>
        <td class="tabla-label">Número de Canales:</td>
        <td>{{ camara?.canales?.length || 0 }}</td>
      </tr>

      @if (camara?.puertoHTTP){
      <!-- Puerto HTTP -->
      <tr>
        <td class="tabla-label">Puerto HTTP:</td>
        <td>{{ camara?.puertoHTTP }}</td>
      </tr>
      <tr>
      }
      <!-- Puerto RTSP -->
      @if (camara?.puertoRTSP){
      <tr>
        <td class="tabla-label">Puerto RTSP:</td>
        <td>{{ camara?.puertoRTSP }}</td>
      </tr>
      <tr>
      }
      <!-- Usuario -->
      <tr>
        <td class="tabla-label">Usuario:</td>
        <td>{{ camara?.usuario }}</td>
      </tr>
      <tr>
    </table>
  </div>
}
