import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IQueryParam,
  IListado,
  ICamara,
  IFilter,
  IPopulate,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';

@Component({
  selector: 'app-detalles-camara-info',
  templateUrl: './detalles-camara-info.component.html',
  styleUrl: './detalles-camara-info.component.scss',
  standalone: false,
})
export class DetallesCamaraInfoComponent implements OnInit {
  public id: string | null = null;
  public title = '';
  public camara: ICamara;
  public camaras$: Subscription;

  public loading = true;

  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    const filter: IFilter<ICamara> = { _id: this.id };
    const populate: IPopulate[] = [
      {
        path: 'cliente',
        select: 'nombre',
      },
      {
        path: 'modeloDispositivo',
        select: 'modelo marca',
      },
    ];
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };
    this.camaras$?.unsubscribe();
    this.camaras$ = this.listados
      .subscribe<IListado<ICamara>>('camaras', query)
      .subscribe((data) => {
        this.camara = data.datos[0];
        console.log(`listado de camaras`, data);
      });
    await this.listados.getLastValue('camaras', query);
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    await this.listar();
    this.loading = false;
  }
}
