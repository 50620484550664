import { IGeoJSONPoint, IListado, IPopulate } from 'modelos/src';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Feature, Map, View } from 'ol';
import { IQueryParam, IReporte } from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { Geometry, LineString, Point } from 'ol/geom';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';

import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import VectorSource from 'ol/source/Vector';

@Component({
  selector: 'app-mapa-activos',
  templateUrl: './mapa-activos.component.html',
  styleUrl: './mapa-activos.component.scss',
  standalone: false,
})
export class MapaActivosComponent implements OnInit, OnDestroy {
  public ctrl = false;

  //Activos
  public reportesActivos?: IReporte[] = [];
  public reporteActivos?: IReporte;
  public reportesActivos$?: Subscription;

  // Activos
  private activosMostrar: boolean = true;

  /// OPENLAYERS
  public map?: Map;

  //POPULATE
  private populateActivos: IPopulate[] = [
    {
      path: 'activo',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  private queryActivos: IQueryParam = {
    populate: JSON.stringify(this.populateActivos),
    includeChildren: true,
  };

  // Punto Activo
  public activoSimpleLayer = OpenLayersService.activoVectorLayer();
  /// Centro
  private puntos: [number, number][] = [];

  constructor(
    private listados: ListadosService,
    private helper: HelperService,
  ) {}

  /// Activos
  public async listarActivos(): Promise<void> {
    try {
      const query: IQueryParam = {
        filter: JSON.stringify({ tipo: 'Activo' }),
        populate: JSON.stringify(this.populateActivos),
      };

      this.reportesActivos$?.unsubscribe();
      this.reportesActivos$ = this.listados
        .subscribe<IListado<IReporte>>('ultimoReportes', query)
        .subscribe(async (data) => {
          this.reportesActivos = data.datos;
          this.addMarkersActivos();
          console.log(
            `listado de reportesActivos ${new Date().toISOString()}`,
            data,
          );
        });
      await this.listados.getLastValue('ultimoReportes', query);
    } catch (error: any) {
      this.helper.notifError('Error al listar activos');
    }
  }
  // Punto Activos
  private addMarkersActivos() {
    const source = this.activoSimpleLayer.getSource();
    if (!source) return;
    source.clear();
    if (!this.activosMostrar) return;
    if (this.reportesActivos) {
      for (const reporteActivos of this.reportesActivos) {
        if (!reporteActivos.geojson) continue;
        this.puntos.push((reporteActivos.geojson as IGeoJSONPoint).coordinates);
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          (reporteActivos.geojson as IGeoJSONPoint).coordinates[0],
          (reporteActivos.geojson as IGeoJSONPoint).coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporteActivos,
          tipo: 'activo',
        });
        source.addFeature(feature);
      }
    }
  }

  public async onActivosChange() {
    this.activosMostrar = !this.activosMostrar;
    this.addMarkersActivos();
  }

  private async initMap() {
    const position = await OpenLayersService.getCurrentPosition();
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'map',
      controls: [],
      layers: [OpenLayersService.mapTile(), this.activoSimpleLayer],
      view: new View({
        center: position,
        zoom: 16,
      }),
    });

    this.map.on('error', (e) => {
      console.error('Error en el mapa', e);
    });

    await this.handleClick();
    const sourceres: VectorSource<Feature<Geometry>> = new VectorSource();

    if (this.reportesActivos?.length) {
      const sourcePoint = this.activoSimpleLayer.getSource();
      sourcePoint.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (sourceres) {
      this.setBounds(sourceres);
    }
  }

  private async handleClick() {
    if (!this.map) return;
    this.map.on('singleclick', async (evt) => {
      const feature = this.map?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          const geometry = feature.getGeometry();
          if (geometry instanceof Point) {
            if (feature.get('tipo') === 'vehiculo') {
              /// ES UN VEHICULO
              return feature;
            }
            if (feature.get('tipo') === 'activo') {
              /// ES UN ACTIVO
              return feature;
            }
            /// ES UNA PARADA
            return null;
          } else if (geometry instanceof LineString) {
            return null;
          }
          return null;
        },
      );

      if (!feature) return;

      const data = feature.get('data') as IReporte;
      if (feature.get('tipo') === 'activo') {
        this.reporteActivos = data;
        if (!this.reporteActivos?.geojson?.coordinates) return;
      }
    });
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    if (!extent) return;
    this.map?.getView().fit(extent, { padding: [200, 200, 200, 200] });
    if (this.map?.getView().getZoom() > 17) {
      this.map?.getView().setZoom(17);
    }
  }

  public onClose() {}
  ///
  async ngOnInit() {
    await Promise.all([await this.listarActivos()]);
    await this.initMap();
  }

  ngOnDestroy() {
    this.reportesActivos$?.unsubscribe();
  }
}
