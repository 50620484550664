<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        {{ title }}
      </mat-card-title>
    } @else {
      <mat-card-title>
        {{ title }}
      </mat-card-title>
    }
  </mat-card-header>

  <form
    *ngIf="form"
    id="form"
    [formGroup]="form"
    autocomplete="off"
    (ngSubmit)="onSubmit()"
  >
    <!-- Form -->
    <mat-card-content
      style="margin: 2em 0; overflow: auto"
      [class.cardContentDialog]="dialogRef"
      [class.cardContentFullscreen]="!dialogRef"
    >
      <!-- Cliente - Numero Abonado - Nombre -->
      <section class="row-center" style="width: 100%; gap: 16px">
        @if (!camara && !this.paramsService.getParams()?.["desdeAlarma"]) {
          <div style="width: 100%; display: flex">
            <!-- Cliente-->
            <ng-select
              [items]="clientes"
              bindLabel="nombre"
              bindValue="_id"
              formControlName="idCliente"
              placeholder="Cliente"
              [notFoundText]="'No hay clientes creados'"
              style="width: 100%"
              multiple="false"
              searchable="true"
              appendTo="body"
            >
            </ng-select>
            @if (helper.verModuloAdministracion()) {
              <app-button
                style="margin-left: 5px"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="crearCliente()"
              ></app-button>
            }
          </div>
        }
        <!--Identificación-->
        <mat-form-field>
          <mat-label>Identificación de la cámara</mat-label>
          <input matInput formControlName="identificacion" />
        </mat-form-field>
      </section>

      <!-- Modelo Dispositivo  -->
      <section class="row-center" style="width: 100%; gap: 16px">
        <!-- Modelo Modelo-->
        <div style="width: 100%; display: flex">
          <ng-select
            [items]="modelosDispositivos"
            bindValue="_id"
            formControlName="idModeloDispositivo"
            placeholder="Modelo de cámara"
            [notFoundText]="'No hay modelos de cámaras'"
            style="width: 100%"
            multiple="false"
            searchable="true"
            appendTo="body"
          >
            <ng-template ng-label-tmp let-item="item">
              <span>{{ item.marca }} - {{ item.modelo }} </span>
            </ng-template>

            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <div style="padding-bottom: 5px">
                <span>{{ item.marca }} - {{ item.modelo }} </span>
              </div>
            </ng-template>
          </ng-select>
          <app-button
            [disabled]="!this.form.value.idCliente"
            style="margin-left: 5px"
            matType="mat-fab"
            color="accent"
            icon="add"
            (click)="form?.value?.idCliente ? crearModeloCamara() : null"
          ></app-button>
        </div>

        <!--Host-->
        <mat-form-field>
          <mat-label>IP / Dominio</mat-label>
          <input matInput formControlName="host" />
        </mat-form-field>
      </section>
      <section class="row-center" style="width: 100%; gap: 16px">
        <!--Puerto-->
        <mat-form-field>
          <mat-label>Puerto RTSP</mat-label>
          <input matInput type="number" formControlName="puertoRTSP" />
        </mat-form-field>

        <!--Puerto-->
        <mat-form-field>
          <mat-label>Puerto HTTP</mat-label>
          <input matInput type="number" formControlName="puertoHTTP" />
        </mat-form-field>

        <!--Usuario-->
        <mat-form-field>
          <mat-label>Usuario</mat-label>
          <input matInput formControlName="usuario" />
        </mat-form-field>

        <!--Host-->
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" />
        </mat-form-field>
      </section>

      <!-- Canales -->
      <section>
        <div
          class="mat-elevation-z4"
          style="padding: 0.5em; border-radius: 5px"
          formArrayName="canales"
          cdkDropList
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 15px;
            "
          >
            <span class="recorrido-titulo">Canales</span>

            <app-button
              (click)="camara ? detectarCanales() : null"
              matType="mat-fab extended"
              color="warn"
              text="Detectar canales"
              [matTooltip]="
                camara
                  ? null
                  : 'Debe guardar la cámara primero para detectar los canales en el modo editar'
              "
              align="end"
              [disabled]="!camara"
            ></app-button>
          </div>
          <div
            [style]="
              dialogRef
                ? 'overflow-x: hidden; overflow-y: scroll; max-height:250px'
                : ''
            "
          >
            @for (f of canales.controls; track f; let i = $index) {
              <section
                class="layout"
                [formGroupName]="i"
                style="align-items: baseline"
              >
                <div
                  class="row-center grow1"
                  style="gap: 1em; margin: 0 8px"
                  cdkDrag
                >
                  <!-- Drag indicator -->
                  <div style="height: 46px">
                    <div class="custom-placeholder" *cdkDragPlaceholder></div>
                    <mat-icon cdkDragHandle color="accent" style="cursor: move">
                      drag_indicator
                    </mat-icon>
                  </div>

                  <!-- Número de canal -->
                  <mat-form-field>
                    <mat-label>Número de canal</mat-label>
                    <input
                      matInput
                      formControlName="numero"
                      placeholder="1"
                      (change)="ajustarCanal($event, i)"
                    />
                  </mat-form-field>

                  <!-- Nombre de canal -->
                  <mat-form-field>
                    <mat-label>Nombre de canal</mat-label>
                    <input
                      matInput
                      formControlName="nombre"
                      placeholder="Puerta delantera"
                    />
                  </mat-form-field>

                  <!-- Tipo de habilitacion -->
                  <mat-form-field>
                    <mat-label>Tipo de habilitación</mat-label>
                    <mat-select formControlName="tipoHabilitacion" required>
                      <mat-option
                        *ngFor="let dato of tiposHabilitacion"
                        [value]="dato"
                      >
                        {{ dato }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Detalles -->
                  <button
                    mat-mini-fab
                    color="warn"
                    type="button"
                    (click)="detallesCanal(i)"
                    style="margin-bottom: 22px"
                  >
                    <mat-icon>search</mat-icon>
                  </button>

                  <!-- Eliminar -->
                  <button
                    mat-mini-fab
                    color="warn"
                    type="button"
                    (click)="removeCanal(i)"
                    style="margin-bottom: 22px"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </section>
            }
          </div>
          <div class="row-center">
            <app-button
              [matType]="'mat-fab extended'"
              color="accent"
              (click)="addCanal()"
              text="Agregar canal"
              icon="add"
            ></app-button>
          </div>
        </div>
      </section>
    </mat-card-content>
    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </mat-card-actions>
  </form>
</mat-card>
