import { Injectable } from '@angular/core';
import {
  ICreateEnvioVehiculo,
  IEnvioVehiculo,
  IListado,
  IQueryParam,
  IUpdateEnvioVehiculo,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class EnviosVehiculosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IEnvioVehiculo>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/enviosvehiculos`, { params });
  }

  public getById(id: string): Promise<IEnvioVehiculo> {
    return this.http.get(`/enviosvehiculos/${id}`);
  }

  public create(dato: ICreateEnvioVehiculo): Promise<IEnvioVehiculo> {
    return this.http.post(`/enviosvehiculos`, dato);
  }

  public update(
    id: string,
    dato: IUpdateEnvioVehiculo,
  ): Promise<IEnvioVehiculo> {
    return this.http.put(`/enviosvehiculos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/enviosvehiculos/${id}`);
  }
}
