<div style="display: flex; flex-direction: column">
  <small style="max-width: 80%; color: gray"
    >Búsqueda de las veces que se detuvo un vehículo</small
  >
  <ng-select
    [items]="vehiculos"
    [(ngModel)]="vehiculo"
    bindLabel="identificacion"
    placeholder="Vehículo"
    [notFoundText]="'No hay Vehículos'"
    multiple="false"
    searchable="true"
    appendTo="body"
  >
  </ng-select>
  <div style="display: flex; gap: 3px">
    <div style="width: 40%">
      <app-date-range-selector
        [(dateRange)]="range"
        (dateRangeChange)="listarParadas()"
        [maxDate]="null"
        [placeholder]="'Fecha'"
        [selectMode]="'single'"
        matTooltip="La fecha se busca con ±12 horas a lo seleccionado"
      ></app-date-range-selector>
    </div>
    <mat-form-field>
      <input
        type="number"
        matInput
        [formControl]="minutosMinimos"
        matTooltip="Tiempo mínimo que un vehículo debe permanecer detenido"
        placeholder="Tiempo detenido (minutos)"
        (change)="listarParadas()"
      />
    </mat-form-field>
  </div>
  <div style="max-height: fit-content; overflow: auto">
    @for (inter of interaccionesBusqueda; track inter) {
      <mat-expansion-panel
        hideToggle
        [expanded]="interaccionesBusquedaSelected === inter"
        [ngClass]="interaccionesBusquedaSelected === inter ? 'ac-85' : ''"
        (expandedChange)="onParadasSelected(inter, $event)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ inter.startTime | date: "short" }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        Durante:
        {{ helper.parseMs(inter.duration) }}
      </mat-expansion-panel>
    }
  </div>

  @if (buscando) {
    <div style="height: 100%; width: 100%; margin-top: 10%" align="center">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  }
</div>
