/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICliente,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';

import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/modules/treemap';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from '../../../../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { ClientesService } from '../../../../../../auxiliares/servicios/http/clientes.service';
import { IRegExpSearch } from '../../../../../../auxiliares/tabla/filtro/filtro.component';
import { IColumnas } from '../../../../../../auxiliares/tabla/tabla.component';
import { LoginService } from '../../../../../login/login.service';
HC_more(Highcharts);

@Component({
  selector: 'app-clientes-grafico',
  templateUrl: './clientes.component.html',
  styleUrl: './clientes.component.scss',
  standalone: false,
})
export class ListadoClientesGraficoComponent implements OnInit, OnDestroy {
  private idClientePropio = LoginService.getCliente()?._id;
  //// Tabla nueva
  public name = ListadoClientesGraficoComponent.name;
  public nombreCliente: string = '';
  public idCliente: string = '';
  public clientes: ICliente[] = [];
  public clientes$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<ICliente>[];
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  public filter: IFilter<ICliente> = {};
  public populate: IPopulate = {};
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };
  right: any;

  public loading: boolean = true;
  public chartOptions?: Highcharts.Options;
  constructor(
    public helper: HelperService,
    private navigator: Router,
    private listados: ListadosService,
    private service: ClientesService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) {}

  public async listar(): Promise<void> {
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.clientes = data.datos.filter(
          (cliente) => cliente._id !== this.idClientePropio,
        );
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', this.query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar() {
    this.navigator.navigate(['editar', this.idCliente], {
      relativeTo: this.route,
    });
  }
  public async notas() {
    this.navigator.navigate(['detalles', this.idCliente], {
      relativeTo: this.route,
    });
  }

  public async eliminar() {
    const res = await this.dialogService.confirm(
      'Eliminar Cliente',
      `¿Desea eliminar el cliente ${this.nombreCliente}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(this.idCliente);
      this.helper.notifSuccess('Cliente eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  private mostrarCrear() {
    if (LoginService.getCliente()?.tipoCliente === 'Final') return false;
    return true;
  }

  public async graficoHijos() {
    (window as any).myComponentInstance = this;
    const data = this.clientes;

    let clienteP, cliente: ICliente;

    const points: any[] = [];

    for (cliente of data) {
      clienteP = {
        id: cliente?._id,
        name: cliente?.nombre,
        parent: cliente?.idPadre,
        //color: HelperService.textToColor(cliente.nombre) + '66',
        states: {
          hover: {
            color: '#000000ff',
          },
        },
        color: LoginService.getCliente().config?.tema?.accentColor + '44',
        value: 1,
      };
      points.push(clienteP);
    }
    this.chartOptions = {
      chart: {
        margin: 0,
        height: 600,
      },
      tooltip: { enabled: false },

      series: [
        {
          name: 'Hijos',
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          allowDrillToNode: true,
          animationLimit: 30,
          colorByPoint: false,
          dataLabels: {
            enabled: false,
          },
          borderColor: LoginService.getCliente().config?.tema?.primaryColor,
          borderWidth: 1,
          borderHeight: 1,
          states: {
            hover: {
              borderColor: 'white',
            },
          },
          levels: [
            {
              level: 1,
              borderWidth: 5,
              borderHeight: 5,
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '14px',
                },
              },
              levelIsConstant: false,
            } as any,
          ],
          accessibility: {
            exposeAsGroupOnly: true,
          },
          data: points,
        },
      ] as any,
      plotOptions: {
        treemap: {
          point: {
            events: {
              click: function () {
                const component = (window as any).myComponentInstance;
                component.hiceClick(this);
              },
            },
          },
        },
      },
      subtitle: {
        text: '',
        align: 'left',
      },
      title: {
        text: '',
        align: 'left',
      },
    };
  }

  public hiceClick(point: Highcharts.Point) {
    this.nombreCliente = point.name;
    this.idCliente = (point as any).id;
  }

  // Hooks

  async ngOnInit() {
    await this.listar();
    this.loading = false;
    if (this.clientes?.length) {
      await this.graficoHijos();
    }
  }

  ngOnDestroy() {
    this.clientes$?.unsubscribe();
  }
}
