<!-- Filtros -->
<div
  style="
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
  "
>
  <mat-button-toggle-group
    [(ngModel)]="modoAgrupacion"
    (change)="cambioModoAgrupacion($event)"
  >
    <mat-button-toggle value="1">No Agrupar</mat-button-toggle>
    <mat-button-toggle value="2">Agrupar por Categoría</mat-button-toggle>
    <mat-button-toggle value="3">Agrupar por Dispositivo</mat-button-toggle>
  </mat-button-toggle-group>

  <app-button
    matType="mat-fab extended"
    color="accent"
    icon="filter_alt"
    text="Filtro"
    [matMenuTriggerFor]="filterMenu"
  ></app-button>
  <mat-menu #filterMenu="matMenu">
    <div (click)="$event.stopPropagation()" style="padding: 1em">
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 16px;
          justify-content: end;
        "
      >
        <div class="hijos">
          <mat-checkbox
            [(ngModel)]="query.includeChildren"
            [ngModelOptions]="{ standalone: true }"
            (change)="cambioFiltro()"
          >
            Datos de mis clientes
          </mat-checkbox>
          <input
            class="input-number"
            type="number"
            min="0"
            [(ngModel)]="query.childrenLevel"
            [disabled]="
              query.includeChildren === false || !query.includeChildren
            "
            placeholder="Niveles"
            (change)="cambioFiltro()"
          />
        </div>
      </div>
    </div>
  </mat-menu>
</div>

<!-- Tabla Sin agrupar -->
@if (modoAgrupacion === "1") {
  <mat-table [dataSource]="eventosVivo" class="mat-elevation-z8">
    <!-- Nombre Evento -->
    <ng-container matColumnDef="categoria">
      <mat-header-cell *matHeaderCellDef>Categoría</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          'background-color': element.valores.color,
          color: this.helper.colorDeTextoSegunColor(element.valores.color),
        }"
      >
        {{ element.codigoEvento }}
      </mat-cell>
    </ng-container>

    <!-- Nombre Evento -->
    <ng-container matColumnDef="evento">
      <mat-header-cell *matHeaderCellDef>Evento</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          'background-color': element.valores.color,
          color: this.helper.colorDeTextoSegunColor(element.valores.color),
        }"
      >
        {{ element.valores?.titulo }}
      </mat-cell>
    </ng-container>

    <!-- Detalles Evento -->
    <ng-container matColumnDef="detallesEvento">
      <mat-header-cell *matHeaderCellDef>Detalles</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          'background-color': element.valores.color,
          color: this.helper.colorDeTextoSegunColor(element.valores.color),
        }"
      >
        {{ element.valores?.detalles || element.valores?.mensaje }}
      </mat-cell>
    </ng-container>

    <!-- Activo / Alarma -->
    <ng-container matColumnDef="activo / alarma">
      <mat-header-cell *matHeaderCellDef>Activo / Alarma</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          'background-color': element.valores.color,
          color: this.helper.colorDeTextoSegunColor(element.valores.color),
        }"
      >
        @if (element.activo) {
          {{ element.activo?.identificacion }}
        }
        @if (element.alarma) {
          {{ element.alarma?.nombre }}
        }
      </mat-cell>
    </ng-container>

    <!-- Dispositivo -->
    <ng-container matColumnDef="dispositivo">
      <mat-header-cell *matHeaderCellDef>Dispositivo</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          'background-color': element.valores.color,
          color: this.helper.colorDeTextoSegunColor(element.valores.color),
        }"
      >
        {{
          element.tracker?.identificacion ||
            element.alarma?.nombre +
              " - " +
              this.ajustarDireccion(element.alarma?.domicilio?.direccion)
        }}
      </mat-cell>
    </ng-container>

    <!-- Estado -->
    <ng-container matColumnDef="estado">
      <mat-header-cell *matHeaderCellDef>Estado</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          'background-color': element.valores.color,
          color: this.helper.colorDeTextoSegunColor(element.valores.color),
        }"
      >
        {{ element.estado }}
      </mat-cell>
    </ng-container>

    <!-- Cliente -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef>Cliente</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          'background-color': element.valores.color,
          color: this.helper.colorDeTextoSegunColor(element.valores.color),
        }"
      >
        {{ element.cliente?.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Fecha -->
    <ng-container matColumnDef="fecha">
      <mat-header-cell *matHeaderCellDef>Fecha </mat-header-cell>

      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          'background-color': element.valores.color,
          color: this.helper.colorDeTextoSegunColor(element.valores.color),
        }"
      >
        @if (
          sonFechasIguales(element.fechaUltimoRepetido, element.fechaCreacion)
        ) {
          {{ element.fechaCreacion | date: "short" }}
        } @else {
          {{ element.fechaCreacion | date: "short" }} --
          {{ element.fechaUltimoRepetido | date: "short" }}
        }
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Accion </mat-header-cell>>
      <mat-cell
        *matCellDef="let row"
        [ngStyle]="{
          'background-color': row.valores.color,
        }"
      >
        <div>
          <app-button
            matType="mat-mini-fab"
            (click)="tratar(row)"
            color="accent"
            matTooltip="Ver"
            icon="troubleshoot"
          ></app-button>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnas"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"></mat-row>
  </mat-table>
}

<!-- Agrupado por categoria -->
@if (modoAgrupacion === "2") {
  <mat-accordion displayMode="flat">
    @for (categoria of categorias; track categoria) {
      <mat-expansion-panel
        [expanded]="categoria.nombre === panelExpanded"
        (afterExpand)="panelExpanded = categoria.nombre"
      >
        <mat-expansion-panel-header
          [style.background-color]="categoria.color"
          style="border-radius: 0"
        >
          <mat-panel-title> {{ categoria.nombre }} </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Contenido de administracion -->
        <div class="expansion-content">
          <mat-table
            [dataSource]="eventosPorCategoria?.[categoria.nombre] || []"
            class="mat-elevation-z8"
          >
            <!-- Nombre Evento -->
            <ng-container matColumnDef="categoria">
              <mat-header-cell *matHeaderCellDef>Categoría</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.codigoEvento }}
              </mat-cell>
            </ng-container>

            <!-- Nombre Evento -->
            <ng-container matColumnDef="evento">
              <mat-header-cell *matHeaderCellDef>Evento</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.valores?.titulo }}
              </mat-cell>
            </ng-container>

            <!-- Activo / Alarma -->
            <ng-container matColumnDef="activo / alarma">
              <mat-header-cell *matHeaderCellDef
                >Activo / Alarma</mat-header-cell
              >
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                @if (element.activo) {
                  {{ element.activo?.identificacion }}
                }
                @if (element.alarma) {
                  {{ element.alarma?.nombre }}
                }
              </mat-cell>
            </ng-container>

            <!-- Detalles Evento -->
            <ng-container matColumnDef="detallesEvento">
              <mat-header-cell *matHeaderCellDef>Detalles</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.valores?.detalles || element.valores?.mensaje }}
              </mat-cell>
            </ng-container>

            <!-- Dispositivo -->
            <ng-container matColumnDef="dispositivo">
              <mat-header-cell *matHeaderCellDef>Dispositivo</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{
                  element.tracker?.identificacion ||
                    element.alarma?.nombre +
                      " - " +
                      this.ajustarDireccion(
                        element.alarma?.domicilio?.direccion
                      )
                }}
              </mat-cell>
            </ng-container>

            <!-- Estado -->
            <ng-container matColumnDef="estado">
              <mat-header-cell *matHeaderCellDef>Estado</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.estado }}
              </mat-cell>
            </ng-container>

            <!-- Cliente -->
            <ng-container matColumnDef="cliente">
              <mat-header-cell *matHeaderCellDef>Cliente</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.cliente?.nombre }}
              </mat-cell>
            </ng-container>

            <!-- Fecha -->
            <ng-container matColumnDef="fecha">
              <mat-header-cell *matHeaderCellDef>Fecha </mat-header-cell>

              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.fechaCreacion | date: "short" }}
              </mat-cell>
            </ng-container>

            <!-- Columna Acciones -->
            <ng-container matColumnDef="acciones">
              <mat-header-cell *matHeaderCellDef> Accion </mat-header-cell>>
              <mat-cell
                *matCellDef="let row"
                [ngStyle]="{
                  'background-color': row.valores.color,
                }"
              >
                <div>
                  <app-button
                    matType="mat-mini-fab"
                    (click)="tratar(row)"
                    color="accent"
                    matTooltip="Ver"
                    icon="troubleshoot"
                  ></app-button>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row
              [style.background-color]="categoria.color"
              *matHeaderRowDef="columnasAgrupadoCategoria"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: columnasAgrupadoCategoria"
            ></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>
    }
  </mat-accordion>
}

<!-- Agrupado por dispositivo -->
@if (modoAgrupacion === "3") {
  <mat-accordion displayMode="flat">
    @for (entidad of entidades; track entidad) {
      <mat-expansion-panel
        [expanded]="entidad.nombre === panelExpanded"
        (afterExpand)="panelExpanded = entidad.nombre"
      >
        <mat-expansion-panel-header style="border-radius: 0">
          <mat-panel-title>
            {{ entidad.nombre }}
            <small style="margin-left: 4px; font-size: 13px" class="no-info">
              {{
                eventosPorEntidad?.[entidad.nombre]?.length > 0
                  ? "(Eventos: " +
                    eventosPorEntidad?.[entidad.nombre].length +
                    ")"
                  : ""
              }}</small
            ></mat-panel-title
          >
          <div style="align-self: self-end; margin-right: 30px">
            <app-button
              matType="mat-mini-fab"
              (click)="
                tratar(
                  eventosPorEntidad?.[entidad.nombre][
                    eventosPorEntidad?.[entidad.nombre].length - 1
                  ]
                )
              "
              color="accent"
              matTooltip="Ver"
              icon="troubleshoot"
              style="margin-right: 5px"
            ></app-button>
            <app-button
              matType="mat-mini-fab"
              (click)="
                irADetalles(eventosPorEntidad?.[entidad.nombre][0] || null)
              "
              color="accent"
              matTooltip="Detalles"
              icon="search"
            ></app-button>
          </div>
        </mat-expansion-panel-header>
        <!-- Contenido de administracion -->
        <div class="expansion-content">
          <mat-table
            [dataSource]="eventosPorEntidad?.[entidad.nombre] || []"
            class="mat-elevation-z8"
          >
            <!-- Categoria -->
            <ng-container matColumnDef="categoria">
              <mat-header-cell *matHeaderCellDef>Categoría</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.codigoEvento }}
              </mat-cell>
            </ng-container>

            <!-- Nombre Evento -->
            <ng-container matColumnDef="evento">
              <mat-header-cell *matHeaderCellDef>Evento</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.valores?.titulo }}
              </mat-cell>
            </ng-container>

            <!-- Detalles Evento -->
            <ng-container matColumnDef="detallesEvento">
              <mat-header-cell *matHeaderCellDef>Detalles</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.valores?.detalles || element.valores?.mensaje }}
              </mat-cell>
            </ng-container>

            <!-- Dispositivo -->
            <ng-container matColumnDef="dispositivo">
              <mat-header-cell *matHeaderCellDef>Dispositivo</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{
                  element.tracker?.identificacion ||
                    element.alarma?.nombre +
                      " - " +
                      this.ajustarDireccion(
                        element.alarma?.domicilio?.direccion
                      )
                }}
              </mat-cell>
            </ng-container>

            <!-- Estado -->
            <ng-container matColumnDef="estado">
              <mat-header-cell *matHeaderCellDef>Estado</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.estado }}
              </mat-cell>
            </ng-container>

            <!-- Cliente -->
            <ng-container matColumnDef="cliente">
              <mat-header-cell *matHeaderCellDef>Cliente</mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.cliente?.nombre }}
              </mat-cell>
            </ng-container>

            <!-- Fecha -->
            <ng-container matColumnDef="fecha">
              <mat-header-cell *matHeaderCellDef>Fecha </mat-header-cell>

              <mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  'background-color': element.valores.color,
                  color: this.helper.colorDeTextoSegunColor(
                    element.valores.color
                  ),
                }"
              >
                {{ element.fechaCreacion | date: "short" }}
              </mat-cell>
            </ng-container>

            <!-- Columna Acciones -->
            <ng-container matColumnDef="acciones">
              <mat-header-cell *matHeaderCellDef> Accion </mat-header-cell>>
              <mat-cell
                *matCellDef="let row"
                [ngStyle]="{
                  'background-color': row.valores.color,
                }"
              >
                <div>
                  <app-button
                    matType="mat-mini-fab"
                    (click)="tratar(row)"
                    color="accent"
                    matTooltip="Ver"
                    icon="troubleshoot"
                  ></app-button>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="columnasAgrupadoEntidad"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: columnasAgrupadoEntidad"
            ></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>
    }
  </mat-accordion>
}

<!-- Loading -->
@if (loading) {
  <div style="display: flex; justify-content: center; margin: 1em 0">
    <mat-spinner color="accent"></mat-spinner>
  </div>
}
