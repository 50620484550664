import { CommonModule } from '@angular/common';
import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { AuxiliaresModule } from '../auxiliares.module';
import { Coordinate } from 'ol/coordinate';
import { OpenLayersService } from '../servicios/openLayers.service';
import { Feature, Map, View } from 'ol';
import { Geometry, LineString, Point } from 'ol/geom';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import Stroke from 'ol/style/Stroke';
import Icon from 'ol/style/Icon';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getVectorContext } from 'ol/render';
import RenderEvent from 'ol/render/Event';
import { MapaFacilData } from '../mapa-facil/interfaces';
import VectorSource from 'ol/source/Vector';
import { GoogleMapsService } from '../servicios/google-maps.service';

@Component({
  selector: 'app-mapa-facil-dialog',
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './mapa-facil-dialog.component.html',
  styleUrl: './mapa-facil-dialog.component.scss',
})
export class MapaFacilDialogComponent
  implements OnInit, OnChanges, AfterViewInit
{
  /// OPENLAYERS
  public map?: Map;
  public tileLayer = OpenLayersService.mapTile();
  public tileSateliteLayer = OpenLayersService.mapTileSatelite();
  // Vehiculos
  public vehiculosVectorLayer = OpenLayersService.vehiculosVectorLayer();
  public orientationVectorOptions =
    OpenLayersService.vehiculosCompassVectorLayer();
  public hayVehiculos = false;
  // Paradas
  public paradasVectorLayer = OpenLayersService.paradasVectorLayer();
  public paradasTextVectorLayer = OpenLayersService.paradasTextVectorLayer();
  public hayParadas = false;
  /// Punto Simple
  public puntoSimpleLayer = OpenLayersService.ubicacionVectorLayer();
  public hayPuntosSimples = false;
  /// LineStrings --- Polylines
  public polylineVectorOptions = OpenLayersService.polylineVectorLayer();
  public hayPolylines = false;
  /// TRIP
  public startLayer = OpenLayersService.startTripLayer();
  public endLayer = OpenLayersService.endTripLayer();
  public geoMarkerLayer = OpenLayersService.geoMarkerLayer();

  public currentTrip: LineString | undefined;
  private lastTime = 0;
  private distance = 0;
  private animating = false;
  public speed = 30;

  public isMenuOpen = false;
  public ready = false;

  constructor(
    private googleMapsService: GoogleMapsService,
    @Inject(MAT_DIALOG_DATA) public data?: MapaFacilData,
  ) {}

  private async initMap() {
    if (!this.data) {
      return;
    }
    console.log(`Mapa Facil Dialog Activated`);

    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'mapa-facil-dialog',
      controls: [],
      layers: [
        this.tileLayer,
        this.polylineVectorOptions,
        this.paradasVectorLayer,
        this.paradasTextVectorLayer,
        this.orientationVectorOptions,
        this.vehiculosVectorLayer,
        this.puntoSimpleLayer,
        // Trip
        this.startLayer,
        this.endLayer,
        this.geoMarkerLayer,
      ],
      view: new View({
        center:
          this.data.center || (await OpenLayersService.getCurrentPosition()),
        zoom: this.data.zoom || 16,
      }),
    });

    if (this.data.markers) {
      this.addMarkers();
    }

    if (this.data.polylines) {
      this.addPolylines();
    }

    this.ready = true;

    // if (this.data.polygons) {
    //   this.addPolygons();
    // }
  }

  private addMarkers() {
    if (!this.data?.markers) return;
    for (const m of this.data.markers) {
      if (m.label === 'vehiculo') {
        // Bus
        const source = this.vehiculosVectorLayer.getSource();
        if (!source) continue;
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        source.addFeature(feature);
        if (!this.data.center) this.map?.getView().setCenter(m.coor);
        // Compass
        const orientationSource = this.orientationVectorOptions.getSource();
        if (!orientationSource) continue;
        const orientationFeature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        let s: Style;
        if (m.orientation) {
          const radian = (m.orientation * Math.PI) / 180;
          s = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/brujula.png',
              height: 40,
              width: 40,
              rotation: radian,
            }),
          });
        } else {
          s = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/circle.png',
              height: 40,
              width: 40,
            }),
          });
        }
        orientationFeature.setStyle(s);
        orientationSource.addFeature(orientationFeature);
        this.hayVehiculos = true;
        if (m.centrar) {
          this.setMapCenter(m.coor);
        }
      }
      if (m.label === 'parada') {
        // Icon
        const source = this.paradasVectorLayer.getSource();
        if (!source) continue;
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        source.addFeature(feature);
        if (!this.data.center) this.map?.getView().setCenter(m.coor);
        // Text
        if (!m.text) continue;
        const textSource = this.paradasTextVectorLayer.getSource();
        if (!textSource) continue;
        const textFeature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        const text = new Text({
          text: `#${m.text}`,
          font: 'bold 12px sans-serif',
          offsetY: 5,
        });
        textFeature.setStyle(new Style({ text }));
        textSource.addFeature(textFeature);
        this.hayParadas = true;
        if (m.centrar) {
          this.setMapCenter(m.coor);
        }
      }
      if (m.label === 'puntoSimple') {
        // Icon
        const source = this.puntoSimpleLayer.getSource();
        if (!source) continue;
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        source.addFeature(feature);
        if (!this.data.center) this.map?.getView().setCenter(m.coor);
        this.hayPuntosSimples = true;
        if (m.centrar) {
          this.setMapCenter(m.coor);
        }
      }
    }
  }

  private setMapCenter(c: Coordinate) {
    this.map?.getView().setCenter(c);
  }

  private panTo(c: Coordinate) {
    this.map?.getView().animate({ center: c });
  }

  private addPolylines() {
    if (!this.data?.polylines) return;
    let centrar;
    for (const p of this.data.polylines) {
      if (!p.points) return;
      const source = this.polylineVectorOptions.getSource();
      if (!source) return;
      const lineStringFeature: Feature<Geometry> = new Feature({
        geometry: new LineString(p.points),
      });
      const s = new Style({
        stroke: new Stroke({
          color: p.color || 'red',
          width: p.width || 4,
          lineDash: p.dash ? [4, 8] : undefined,
        }),
      });
      lineStringFeature.setStyle(s);
      this.hayPolylines = true;
      if (p.arrow)
        lineStringFeature.setStyle(this.styleFunctionArrow(lineStringFeature));
      source.addFeature(lineStringFeature);
      // TRIP
      if (p.trip) {
        this.crearTrip(p.points);
      }
      centrar = p.centrar;
    }
    if (centrar === true) {
      this.setBounds(this.polylineVectorOptions.getSource());
    }
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    this.map?.getView().fit(extent, { padding: [50, 50, 50, 50] });
  }

  private crearTrip(trip: Coordinate[]) {
    this.map?.getView().setCenter(trip[0]);
    this.currentTrip = new LineString(trip);

    this.addStartAndEnd(this.currentTrip);

    this.addGeoMarker(this.currentTrip);

    //
    this.startAnimation();
  }

  private addStartAndEnd(route: LineString) {
    /// Start
    const startSource = this.startLayer.getSource();
    if (!startSource) return;
    const start = new Point(route.getFirstCoordinate());
    const startMarker = new Feature({
      geometry: start,
    });
    startSource.addFeature(startMarker);
    /// End
    const endSource = this.endLayer.getSource();
    if (!endSource) return;
    const end = new Point(route.getLastCoordinate());
    const endMarker = new Feature({
      geometry: end,
    });
    endSource.addFeature(endMarker);
  }

  private addGeoMarker(route: LineString) {
    const source = this.geoMarkerLayer.getSource();
    if (!source) return;
    const position = new Point(route.getFirstCoordinate());
    const geoMarker = new Feature({
      geometry: position,
    });
    source.addFeature(geoMarker);
  }

  private moveFeature(event: RenderEvent) {
    if (!this.animating) return;
    const time = event.frameState?.time;
    const elapsedTime = time! - this.lastTime;

    const position = this.geoMarkerLayer
      ?.getSource()
      ?.getFeatures()[0]
      .getGeometry() as Point;

    this.distance = (this.distance + (this.speed * elapsedTime) / 1e6) % 2;
    this.lastTime = time!;
    if (!this.currentTrip) return;
    const currentCoordinate = this.currentTrip.getCoordinateAt(
      this.distance > 1 ? 2 - this.distance : this.distance,
    );
    position?.setCoordinates(currentCoordinate);
    const vectorContext = getVectorContext(event);
    vectorContext.drawGeometry(position);
    // tell OpenLayers to continue the postrender animation
    this.map?.render();
    if (this.distance > 1) {
      this.distance = 0;
      position.setCoordinates(this.currentTrip.getFirstCoordinate());
    }
  }

  private startAnimation() {
    this.animating = true;
    this.lastTime = Date.now();
    this.geoMarkerLayer.on('postrender', (e) => this.moveFeature(e));
  }

  private stopAnimation() {
    this.animating = false;
    this.lastTime = 0;
    this.geoMarkerLayer.un('postrender', (e) => this.moveFeature(e));
  }

  private styleFunctionArrow(feature: Feature<Geometry>) {
    const geometry = feature.getGeometry() as LineString;
    const oldStyles = feature.getStyle() as Style;
    const stroke = oldStyles.getStroke();
    const styles = [
      // linestring
      new Style({
        stroke: new Stroke({
          color: 'red',
          width: 2,
          lineDash: stroke?.getLineDash() ? [4, 8] : undefined,
        }),
      }),
    ];

    geometry?.forEachSegment((start, end) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      // arrows
      styles.push(
        new Style({
          geometry: new Point(end),
          image: new Icon({
            src: 'assets/map/arrow.png',
            anchor: [0.75, 0.5],
            rotateWithView: true,
            rotation: -rotation,
          }),
        }),
      );
    });

    return styles;
  }

  private async reloadLayers() {
    if (!this.map) return;
    this.stopAnimation();
    this.map.removeLayer(this.vehiculosVectorLayer);
    this.map.removeLayer(this.paradasVectorLayer);
    this.map.removeLayer(this.paradasTextVectorLayer);
    this.map.removeLayer(this.polylineVectorOptions);
    this.map.removeLayer(this.puntoSimpleLayer);
    // Trip
    this.map.removeLayer(this.startLayer);
    this.map.removeLayer(this.endLayer);
    this.map.removeLayer(this.geoMarkerLayer);

    this.vehiculosVectorLayer = OpenLayersService.vehiculosVectorLayer();
    this.paradasVectorLayer = OpenLayersService.paradasVectorLayer();
    this.paradasTextVectorLayer = OpenLayersService.paradasTextVectorLayer();
    this.polylineVectorOptions = OpenLayersService.polylineVectorLayer();
    this.puntoSimpleLayer = OpenLayersService.ubicacionVectorLayer();
    // Trip
    this.startLayer = OpenLayersService.startTripLayer();
    this.endLayer = OpenLayersService.endTripLayer();
    this.geoMarkerLayer = OpenLayersService.geoMarkerLayer();

    this.map.addLayer(this.vehiculosVectorLayer);
    this.map.addLayer(this.paradasVectorLayer);
    this.map.addLayer(this.paradasTextVectorLayer);
    this.map.addLayer(this.polylineVectorOptions);
    this.map.addLayer(this.puntoSimpleLayer);
    // Trip
    this.map.addLayer(this.startLayer);
    this.map.addLayer(this.endLayer);
    this.map.addLayer(this.geoMarkerLayer);

    this.addMarkers();
    this.addPolylines();

    this.map.render();
  }

  public layerToggle(layer: string) {
    if (!this.map) return;
    if (layer === 'vehiculos') {
      this.vehiculosVectorLayer.setVisible(
        !this.vehiculosVectorLayer.getVisible(),
      );
      this.orientationVectorOptions.setVisible(
        !this.orientationVectorOptions.getVisible(),
      );
    }

    if (layer === 'paradas') {
      this.paradasVectorLayer.setVisible(!this.paradasVectorLayer.getVisible());
      this.paradasTextVectorLayer.setVisible(
        !this.paradasTextVectorLayer.getVisible(),
      );
    }

    if (layer === 'puntosSimples') {
      this.puntoSimpleLayer.setVisible(!this.puntoSimpleLayer.getVisible());
    }

    if (layer === 'polylines') {
      this.polylineVectorOptions.setVisible(
        !this.polylineVectorOptions.getVisible(),
      );
    }

    if (layer === 'trip') {
      this.startLayer.setVisible(!this.startLayer.getVisible());
      this.endLayer.setVisible(!this.endLayer.getVisible());
      this.geoMarkerLayer.setVisible(!this.geoMarkerLayer.getVisible());
    }
  }

  public async changeView(satelital: boolean) {
    this.map.setLayers([
      satelital ? this.tileSateliteLayer : this.tileLayer,
      this.polylineVectorOptions,
      this.paradasVectorLayer,
      this.paradasTextVectorLayer,
      this.orientationVectorOptions,
      this.vehiculosVectorLayer,
      this.puntoSimpleLayer,
      // Trip
      this.startLayer,
      this.endLayer,
      this.geoMarkerLayer,
    ]);
  }

  public toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  initStreetView() {
    const coordenada = OpenLayersService.coordinateToCoordenada(
      this.data.markers[0].coor,
    );
    const location = {
      lng: coordenada.lng,
      lat: coordenada.lat,
    };
    new google.maps.StreetViewPanorama(
      document.getElementById('street-view') as HTMLElement,
      {
        position: location,
        pov: { heading: 165, pitch: 0 },
        zoom: 1,
      },
    );
  }

  async ngAfterViewInit() {
    await this.googleMapsService.loadApi();
    this.initStreetView();
  }

  async ngOnInit(): Promise<void> {
    this.map?.dispose();
    await this.initMap();
  }

  async ngOnChanges(c: SimpleChanges): Promise<void> {
    if (c['data'].currentValue !== c['data'].previousValue) {
      this.reloadLayers();
    }
  }
}
