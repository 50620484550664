<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
    <!-- Header -->
    <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
      <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
    </ng-container>

<mat-card>

  <form *ngIf="form" [formGroup]="form" autocomplete="off">
<!-- Form -->
<mat-card-content
style="margin: 2em 0; overflow: auto"
>
<!-- Nombre - deveui - joineui -->
<section class="row-center" style="width: 100%; gap: 16px">
  <!--Nombre-->
  <mat-form-field>
    <mat-label>Nombre</mat-label>
    <input matInput formControlName="name" />
  </mat-form-field>

  <!--deveui-->
  <div style="width: 100%; display: flex">
  <mat-form-field>
    <mat-label>DevEUI</mat-label>
    <input matInput formControlName="deveui" [readonly]="isEditing">
  </mat-form-field>
  <app-button
  style="margin-left: 5px"
  matType="mat-fab"
  color="accent"
  icon="shuffle"
  (click)="crearRandomDeveui()"
  [disabled]="isEditing" 
></app-button>
</div>

  <!--joineui-->
  <div style="width: 100%; display: flex">
  <mat-form-field>
    <mat-label>JoinEUI</mat-label>
    <input matInput formControlName="joineui" />
  </mat-form-field>
  <app-button
  style="margin-left: 5px"
  matType="mat-fab"
  color="accent"
  icon="shuffle"
  (click)="crearRandomJoineui()"
></app-button>
</div>

</section>

<section class="row-center" style="width: 100%; gap: 16px">
<!-- Description -->
<mat-form-field>
  <mat-label>Descripción</mat-label>
  <textarea
    matInput
    formControlName="description"
    style="min-height: 100px"
  ></textarea>
</mat-form-field>
</section>

<!-- Device Profile -->
<section class="row-center" style="width: 100%; gap: 16px">
  <ng-select
  style="width: 90%"
  [items]="deviceprofiles"
  bindLabel="name"
  bindValue="id"
  formControlName="deviceProfileId"
  placeholder="Perfil del dispositivo"
  [notFoundText]="'No hay perfiles que coincidan'"
  multiple="false"
  searchable="true"
  appendTo="body"
  [readonly]="isEditing"
>
</ng-select>
</section>

<!-- ############################################################################## -->
<section>
  @if(supportsOTAA !== null && supportsOTAA !== undefined){
  @if(supportsOTAA) {
    <form [formGroup]="formOTAA">
      <section class="row-center" style="width: 100%; gap: 16px">

        <!-- Appkey -->
        <div style="width: 100%; display: flex">
        <mat-form-field>
          <mat-label>Application Key</mat-label>
          <input matInput formControlName="appkey" />
        </mat-form-field>
        <app-button
        style="margin-left: 5px"
        matType="mat-fab"
        color="accent"
        icon="shuffle"
        (click)="crearRandomAppkey()"
      ></app-button>
    </div>
      </section>
    </form>
  }
  @else{
    <form [formGroup]="formABP">
      <section class="row-center" style="width: 100%; gap: 16px">

        <!-- DevAddr -->
        <div style="width: 100%; display: flex">
        <mat-form-field>
          <mat-label>DevAddr</mat-label>
          <input matInput formControlName="devAddr" />
        </mat-form-field>
        <app-button
        style="margin-left: 5px"
        matType="mat-fab"
        color="accent"
        icon="shuffle"
        (click)="crearRandomDevAddr()"
      ></app-button>
        </div>

        <!-- AppSKey -->
        <div style="width: 100%; display: flex">
        <mat-form-field>
          <mat-label>AppSKey</mat-label>
          <input matInput formControlName="appSKey" />
        </mat-form-field>
        <app-button
        style="margin-left: 5px"
        matType="mat-fab"
        color="accent"
        icon="shuffle"
        matTooltip="valor aleatorio"
        (click)="crearRandomAppSKey()"
      ></app-button>
    </div>

      <!-- NwkSKey -->
      <div style="width: 100%; display: flex">
        <mat-form-field>
          <mat-label>NwkSKey</mat-label>
          <input matInput formControlName="nwkskey" />
        </mat-form-field>
        <app-button
        style="margin-left: 5px"
        matType="mat-fab"
        color="accent"
        icon="shuffle"
        (click)="crearRandomNwkSKey()"
      ></app-button>
    </div>

      </section>
      <section class="row-center" style="width: 100%; gap: 16px">
        <mat-form-field>
          <mat-label>Uplink frame-counter</mat-label>
          <input matInput formControlName="fCntUp" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Downlink frame-counter</mat-label>
          <input matInput formControlName="nFCntDown" />
        </mat-form-field>
      </section>
    </form>
  }
  }
  
  </section>
<!-- #################################################################### -->
<section class="row-center" style="width: 100%; gap: 16px">
<div>
  <!-- isDisabled  -->
  <mat-checkbox formControlName="isDisabled">¿Dispositivo deshabilitado?</mat-checkbox>
</div>
<div>
  <!-- isDisabled  -->
  <mat-checkbox formControlName="skipFcntCheck">¿Frame-counter validation deshabilitado?</mat-checkbox>
</div>
</section>

</mat-card-content>
</form>


<!-- ###################################################################### -->
<mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
  <!-- Volver -->
  <app-button
  matType="mat-fab extended"
  color="warn"
  (click)="this.helper.volver()"
  text="Volver"
  icon="arrow_back"
></app-button>

<app-button
  matType="mat-fab extended"
  [disabled]="!(this.form.valid && (!!this.formABP?.valid || !!this.formOTAA?.valid))"
  color="accent"
  text="Guardar"
  icon="save"
  (click)="onSubmit()"
></app-button>
</mat-card-actions>
</mat-card>

<!-- El disabled es una cosa medio confusa, pero la lógica es la siguiente. En primer lugar, se fija sí el formulario general es válido (this.form) -->
<!-- Además, del formulario general, uno de los formularios OTAA o ABP deben ser válidos -->
<!-- Los formularios OTAA o ABP pueden ser nulos en un principio hasta que el usuario elija el deviceProfile, que es el que determina si es OTAA o ABP. Si es nulo, se lo considera falso -->
  