import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { IActivo, IFilter, IListado, IQueryParam, IReporte } from 'modelos/src';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DateRange } from '../../../../../../auxiliares/date-range-selector/date-range-selector.component';
import { ListadosService } from '../../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { ReportesService } from '../../../../../../auxiliares/servicios/http/reportes.service';
import { LoadingService } from '../../../../../../auxiliares/servicios/loading.service';

export interface RecorridoReporte {
  fechaInicio: string;
  fechaFin: string;
  velocidadPromedio: number;
  reportes: IReporte[];
}
@Component({
  selector: 'app-vehiculo-exceso-velocidad',
  templateUrl: './vehiculo-exceso-velocidad.component.html',
  styleUrl: './vehiculo-exceso-velocidad.component.scss',
  standalone: false,
})
export class VehiculoExcesoVelocidadComponent implements OnInit, OnDestroy {
  public interaccionesBusqueda: RecorridoReporte[] = [];
  public interaccionesBusquedaSelected: RecorridoReporte;
  @Output() public interaccionesBusquedaSelectedChange =
    new EventEmitter<RecorridoReporte>();
  public displayedColumns: string[] = ['Ocurrencias'];
  public buscando: boolean = false;
  public range?: DateRange = {};
  public velocidadMinima? = new FormControl();

  public query: IQueryParam = {
    sort: 'nombre',
    page: 0,
    includeChildren: true,
    childrenLevel: 0,
  };

  public vehiculos: IActivo[] = [];
  public vehiculo: IActivo = null;
  public vehiculos$?: Subscription;

  constructor(
    public loading: LoadingService,
    public dialog: MatDialog,
    private reportesService: ReportesService,
    private listados: ListadosService,
    public helper: HelperService,
  ) {}

  public async listarVehiculos(): Promise<void> {
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', this.query)
      .subscribe(async (data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos `, data);
      });
    await this.listados.getLastValue('vehiculos', this.query);
  }
  public async listarReportes() {
    if (!this.range || !this.vehiculo || !this.velocidadMinima.value) {
      this.interaccionesBusqueda = [];
      return;
    }
    const date = new Date(this.range.start);
    const start = new Date(date.getTime() - 12 * 60 * 60 * 1000).toISOString();
    const end = new Date(date.getTime() + 12 * 60 * 60 * 1000).toISOString();
    const filter: IFilter<IReporte> = {
      idActivo: this.vehiculo._id!,
      fechaCreacion: { $gte: start, $lte: end },
      velocidad: { $gte: this.velocidadMinima.value },
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };
    const data = await this.reportesService.getFiltered(query);
    if (data) {
      console.log(`listado de reportes`, data);
      this.procesarReportes(data.datos);
    }
  }

  public procesarReportes(data: IReporte[]) {
    this.interaccionesBusqueda = [];
    this.interaccionesBusquedaSelectedChange.emit(null);
    //
    if (data.length === 0) return;
    let recorridoExedido: RecorridoReporte;
    data.map((r) => {
      if (!recorridoExedido) {
        recorridoExedido = {
          fechaInicio: r.fechaCreacion,
          fechaFin: r.fechaCreacion,
          velocidadPromedio: Math.floor(r.velocidad),
          reportes: [r],
        };
      } else {
        if (
          !this.hanPasadoXMinutos(recorridoExedido.fechaFin, r.fechaCreacion, 5)
        ) {
          recorridoExedido.fechaFin = r.fechaCreacion;
          recorridoExedido.reportes.push(r);
          recorridoExedido.velocidadPromedio += r.velocidad;
        } else {
          recorridoExedido.velocidadPromedio = Math.floor(
            recorridoExedido.velocidadPromedio /
              recorridoExedido.reportes.length,
          );
          this.interaccionesBusqueda.push(recorridoExedido);
          recorridoExedido = null;
        }
      }
    });
    if (recorridoExedido) {
      this.interaccionesBusqueda.push(recorridoExedido);
    }
    console.log('procesado: ', this.interaccionesBusqueda);
  }
  private hanPasadoXMinutos(
    fecha1Str: string,
    fecha2Str: string,
    tiempoEnMinutos: number,
  ): boolean {
    if (!tiempoEnMinutos) return true;

    const fecha1 = new Date(fecha1Str);
    const fecha2 = new Date(fecha2Str);

    // Calcular la diferencia en milisegundos
    const diferenciaMs = Math.abs(fecha2.getTime() - fecha1.getTime());

    const minutosEnMs = tiempoEnMinutos * 60 * 1000;
    return diferenciaMs >= minutosEnMs;
  }

  public onRecorridoSelected(recorrido: RecorridoReporte, expanded: boolean) {
    if (expanded) {
      this.interaccionesBusquedaSelected = recorrido;
      this.interaccionesBusquedaSelectedChange.emit(recorrido);
    }
  }

  async ngOnInit() {
    await this.listarVehiculos();
  }

  ngOnDestroy() {
    this.vehiculos$?.unsubscribe();
    this.interaccionesBusquedaSelectedChange.emit(null);
  }
}
