@if (formTratamiento && formEnvio) {
  <form (ngSubmit)="onSubmit()">
    <mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
      <!-- Header -->
      <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <h2 mat-dialog-title cdkDragHandle>Enviar Movil</h2>
      </ng-container>

      <!-- Content -->
      <mat-card-content style="max-height: 60vh; overflow: auto">
        <small style="font-size: 13px" class="no-info"
          >Informacion del tratamiento</small
        >
        <mat-divider style="margin-bottom: 15px"></mat-divider>
        <form [formGroup]="formTratamiento">
          <!-- Nota -->
          <mat-form-field>
            <mat-label>Nota</mat-label>
            <textarea matInput formControlName="nota"></textarea>
          </mat-form-field>

          <!-- Nota Interna -->
          <mat-form-field>
            <mat-label>Nota Interna</mat-label>
            <textarea matInput formControlName="notaInterna"></textarea>
          </mat-form-field>
        </form>
        <small style="font-size: 13px" class="no-info"
          >Informacion del envio movil</small
        >
        <mat-divider style="margin-bottom: 15px"></mat-divider>

        <form [formGroup]="formEnvio">
          <!-- Vehiculo -->
          <ng-select
            [items]="vehiculos"
            bindLabel="modelo"
            bindValue="_id"
            placeholder="Moviles disponibles"
            [notFoundText]="'No hay moviles disponibles'"
            style="width: 100%"
            multiple="false"
            [searchFn]="buscarVehiculo"
            searchable="true"
            appendTo="body"
            (change)="asignarVehiculoConductor($event)"
          >
            <ng-template ng-label-tmp let-item="item">
              <span
                >{{ item.identificacion }} (
                {{
                  item?.vehiculo?.chofer?.datosPersonales?.nombre ||
                    item?.vehiculo?.chofer?.usuario
                }})
              </span>
            </ng-template>

            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <div style="padding-bottom: 5px">
                <span
                  >{{ item.identificacion }} ({{
                    item.vehiculo.chofer.datosPersonales.nombre ||
                      item.vehiculo.chofer.usuario
                  }})</span
                >
              </div>
            </ng-template>
          </ng-select>

          <!-- Detalles -->
          <mat-form-field>
            <mat-label>Detalles</mat-label>
            <textarea matInput formControlName="detalles"></textarea>
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-divider style="margin-top: 1em"></mat-divider>

      <!-- Acciones -->
      <mat-dialog-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="dialogRef.close()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="
            !this.formTratamiento.valid || !this.formEnvio.valid || loading
          "
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-dialog-actions>
    </mat-card>
  </form>
}
