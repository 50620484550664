<mat-card>
  <!-- Headers -->
  <mat-card-header>
    <mat-card-title>
      <span>Crear Alarma</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content
    style="margin: 2em 0; overflow: auto"
    [class.cardContentFullscreen]="true"
  >
    <mat-stepper #stepper>
      <!-- Cliente - Numero Abonado - Nombre -->
      <mat-step [stepControl]="formBasica" errorMessage="Campos incompletos">
        <form [formGroup]="formBasica" style="margin-top: 1em">
          <ng-template matStepLabel>Basica</ng-template>

          <div style="display: flex; flex-direction: row; gap: 10px">
            <div style="width: 60%">
              <!-- Cliente-->
              <div style="display: flex">
                <ng-select
                  [items]="clientes"
                  bindLabel="nombre"
                  bindValue="_id"
                  formControlName="idCliente"
                  placeholder="Cliente"
                  [notFoundText]="'No hay clientes creados'"
                  style="width: 100%"
                  multiple="false"
                  searchable="true"
                  (change)="onClienteChange($event)"
                  appendTo="body"
                >
                  <!-- Listado de opciones -->
                  <ng-template ng-option-tmp let-item="item">
                    <div>
                      <span>
                        {{ item.nombre }}
                        @if (miCliente._id === item._id) {
                          (Propio)
                        }
                      </span>
                      @if (miCliente._id === item._id) {
                        <mat-divider></mat-divider>
                      }
                    </div>
                  </ng-template>
                </ng-select>
                @if (helper.verModuloAdministracion()) {
                  <app-button
                    style="margin-left: 5px"
                    matType="mat-fab"
                    color="accent"
                    icon="add"
                    (click)="crearCliente()"
                  ></app-button>
                }
              </div>
              <!-- Clientes que pueden atender-->
              <ng-select
                [items]="clientesQuePuedenAtender"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idsClientesQuePuedenAtender"
                placeholder="Clientes que atienden los eventos"
                [notFoundText]="'no se encontraron clientes'"
                style="width: 100%"
                multiple="true"
                searchable="true"
                appendTo="body"
              >
              </ng-select>
              <!--Número de abonado-->
              <mat-form-field>
                <mat-label>Número de abonado</mat-label>
                <input matInput formControlName="numeroAbonado" required />
              </mat-form-field>
              <!--Nombre-->
              <mat-form-field>
                <mat-label>Identificación de la alarma</mat-label>
                <input matInput formControlName="nombre" required />
              </mat-form-field>
            </div>
            <!-- Grafico de jerarquia de clientes -->
            <div style="width: 40%">
              <app-jerarquia-cliente
                [clientes]="clientes"
                [clienteElegido]="clienteElegido"
              ></app-jerarquia-cliente>
            </div>
          </div>
        </form>
      </mat-step>

      <!-- Ubicacion -->
      <mat-step [stepControl]="formUbicacion" errorMessage="Campos incompletos">
        <form [formGroup]="formUbicacion">
          <ng-template matStepLabel>Ubicación</ng-template>
          <section class="column-center" style="width: 100%; gap: 16px">
            <!-- Dirección -->
            <app-autocomplete-direccion
              [form]="formDomicilio"
              formControlDireccion="direccion"
              formControlGeoJSON="circleGeoJSON"
              [direccion]="domicilio?.direccion"
              (direccionChange)="patchDireccion($event)"
              (geoJSONChange)="centrarA = $event"
              style="width: 100%"
            ></app-autocomplete-direccion>

            <div style="width: 100%; height: auto">
              <!-- Formulario para marcar el domicilio -->
              <app-map-draw
                [form]="geojson"
                tipo="Point"
                [centrarA]="centrarA"
              ></app-map-draw>
            </div>
          </section>
        </form>
      </mat-step>

      <!-- Detallada -->
      <mat-step [stepControl]="formDetallada" errorMessage="Campos incompletos">
        <form [formGroup]="formDetallada">
          <ng-template matStepLabel>Detallada</ng-template>
          <div style="width: 100%; display: flex; gap: 15px">
            <!-- Id Unico comunicador -->
            <mat-form-field style="width: 33%">
              <mat-label>ID único de comunicador</mat-label>
              <input matInput formControlName="idUnicoComunicador" />
            </mat-form-field>

            <!-- Password comunicador -->
            <mat-form-field style="width: 33%">
              <mat-label>Password comunicador</mat-label>
              <input matInput formControlName="passwordComunicador" />
            </mat-form-field>
            <!-- Modelo Comunicador-->
            <div style="width: 33%; display: flex">
              <ng-select
                [items]="modelosComunicadores"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idComunicador"
                placeholder="Modelo de comunicador"
                [notFoundText]="'No hay comunicadores creados'"
                style="width: 100%"
                multiple="false"
                searchable="true"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ item.marca }} - {{ item.modelo }} </span>
                </ng-template>
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div style="padding-bottom: 5px">
                    <span>{{ item.marca }} - {{ item.modelo }} </span>
                  </div>
                </ng-template>
              </ng-select>
              <app-button
                [disabled]="!this.formBasica.value.idCliente"
                style="margin-left: 5px"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="
                  formBasica?.value?.idCliente ? crearModeloComunicador() : null
                "
              ></app-button>
            </div>
          </div>
          <div>
            <!-- Modelo de Alarma -->
            <div style="width: 100%; display: flex">
              <ng-select
                [items]="modelosAlarmas"
                bindLabel="modelo"
                bindValue="_id"
                formControlName="idModelo"
                placeholder="Modelo de alarma"
                [notFoundText]="'No hay modelos de alarmas creados'"
                style="width: 100%"
                multiple="false"
                searchable="true"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ item.marca }} - {{ item.modelo }} </span>
                </ng-template>

                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div style="padding-bottom: 5px">
                    <span>{{ item.marca }} - {{ item.modelo }} </span>
                  </div>
                </ng-template>
              </ng-select>
              <app-button
                [disabled]="!this.formBasica.value.idCliente"
                style="margin-left: 5px"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="
                  formBasica?.value?.idCliente ? crearModeloAlarma() : null
                "
              ></app-button>
            </div>
            <!-- frecuencia de comunicación -->
            <mat-form-field>
              <mat-label>Frecuencia de comunicación (minutos)</mat-label>
              <input matInput formControlName="frecReporte" required />
            </mat-form-field>

            <section class="row-center" style="width: 100%; gap: 16px">
              <!-- Sim 1 -->
              <div [formGroup]="formSim1">
                <h3>SIM 1</h3>

                <mat-form-field>
                  <mat-label>ICCID</mat-label>
                  <input matInput formControlName="iccid" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Número de teléfono</mat-label>
                  <input matInput formControlName="numero" type="tel" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Operador</mat-label>
                  <mat-select formControlName="operador">
                    @for (s of operadores; track s) {
                      <mat-option [value]="s">{{ s }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>APN</mat-label>
                  <input
                    type="text"
                    placeholder="datos.personal.com"
                    matInput
                    formControlName="apn"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete #auto="matAutocomplete">
                    @for (option of filteredOptionsSim1 | async; track option) {
                      <mat-option [value]="option">{{ option }}</mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Usuario</mat-label>
                  <input matInput formControlName="usuario" type="text" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" type="text" />
                </mat-form-field>
              </div>

              <!-- Sim 2 -->
              <div [formGroup]="formSim2">
                <h3>SIM 2</h3>

                <mat-form-field>
                  <mat-label>ICCID</mat-label>
                  <input matInput formControlName="iccid" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Número de teléfono</mat-label>
                  <input matInput formControlName="numero" type="tel" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Operador</mat-label>
                  <mat-select formControlName="operador">
                    @for (s of operadores; track s) {
                      <mat-option [value]="s">{{ s }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>APN</mat-label>
                  <input
                    type="text"
                    placeholder="datos.personal.com"
                    matInput
                    formControlName="apn"
                    [matAutocomplete]="auto1"
                  />
                  <mat-autocomplete #auto1="matAutocomplete">
                    @for (option of filteredOptionsSim2 | async; track option) {
                      <mat-option [value]="option">{{ option }}</mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Usuario</mat-label>
                  <input matInput formControlName="usuario" type="text" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" type="text" />
                </mat-form-field>
              </div>
            </section>
          </div>
        </form>
      </mat-step>

      <!-- Zonas -->
      <mat-step [stepControl]="formInfoZonas" optional>
        <form [formGroup]="formInfoZonas">
          <ng-template matStepLabel>Zonas</ng-template>
          <!-- Contenido -->
          <div>
            <!-- Listado de zonas -->
            <div
              style="padding: 0.5em; border-radius: 5px"
              formArrayName="infoZonas"
              cdkDropList
              (cdkDropListDropped)="dropInfoZonas($event)"
            >
              @for (f of infoZonas.controls; track f; let i = $index) {
                <div
                  class="layout"
                  [formGroupName]="i"
                  style="align-items: baseline"
                >
                  <div class="grow1 div-camaraalarma" cdkDrag>
                    <!-- Drag indicator   -->
                    <div>
                      <div class="custom-placeholder" *cdkDragPlaceholder></div>
                      <mat-icon
                        cdkDragHandle
                        color="accent"
                        style="cursor: move"
                      >
                        drag_indicator
                      </mat-icon>
                    </div>
                    <!-- Nombre -->
                    <ng-select
                      [items]="tiposEventos"
                      bindLabel="nombre"
                      bindValue="nombre"
                      formControlName="nombre"
                      placeholder="Nombre"
                      [notFoundText]="'No hay tipo de eventos creados'"
                      style="width: 100%"
                      multiple="false"
                      searchable="true"
                      appendTo="body"
                      [addTag]="addTag.bind(this)"
                      [loading]="creandoNuevoTag"
                      required
                    >
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.nombre | titlecase }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        {{ item.nombre | titlecase }}
                      </ng-template>
                    </ng-select>
                    <!-- Partición -->
                    <mat-form-field
                      style="
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Partición</mat-label>
                      <input
                        matInput
                        placeholder="1"
                        formControlName="particion"
                        type="number"
                      />
                    </mat-form-field>
                    <!-- Zona -->
                    <mat-form-field
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Zona</mat-label>
                      <input
                        matInput
                        placeholder="1"
                        formControlName="zona"
                        type="number"
                      />
                    </mat-form-field>
                    <!-- Eliminar -->
                    <button
                      mat-mini-fab
                      color="warn"
                      type="button"
                      (click)="deleteInfoZona(i)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              }
              <div class="row-center">
                <app-button
                  [matType]="'mat-fab extended'"
                  color="accent"
                  (click)="addInfoZona()"
                  text="Info Zona"
                  icon="add"
                ></app-button>
              </div>
            </div>
          </div>
        </form>
      </mat-step>

      <!-- Camaras -->
      <mat-step [stepControl]="formCamaras" optional>
        <form [formGroup]="formCamaras">
          <ng-template matStepLabel>Cámaras</ng-template>
          <section class="layout">
            <!-- Cámaras-->
            <ng-select
              [items]="camaras"
              bindLabel="identificacion"
              bindValue="_id"
              formControlName="idsCamaras"
              placeholder="Cámaras"
              [notFoundText]="'No hay cámaras creadas'"
              style="width: 100%"
              multiple="true"
              searchable="true"
              (change)="asignarCamarasDeAlarma($event)"
              appendTo="body"
              [disabled]="!this.formBasica.value.idCliente"
            >
            </ng-select>
            <app-button
              [disabled]="!this.formBasica.value.idCliente"
              matType="mat-fab"
              color="accent"
              icon="add"
              (click)="formBasica?.value?.idCliente ? crearCamara() : null"
            ></app-button>
          </section>
          <!-- Contenido -->
          <div>
            <!-- Listado de Códigos -->
            <div
              style="padding: 0.5em; border-radius: 5px"
              formArrayName="camaras"
              cdkDropList
              (cdkDropListDropped)="drop($event)"
            >
              @for (f of camarasForm.controls; track f; let i = $index) {
                <div
                  class="layout"
                  [formGroupName]="i"
                  style="align-items: baseline"
                >
                  <div class="grow1 div-camaraalarma" cdkDrag>
                    <div
                      style="
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Cámara</mat-label>
                      <mat-label>{{
                        nombreCamara(f.value.idCamara)
                      }}</mat-label>
                    </div>

                    <!-- Canal -->
                    <div
                      style="
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Canal</mat-label>
                      <mat-label>{{
                        nombreCanalCamara(f.value.idCamara, f.value.canal)
                      }}</mat-label>
                    </div>

                    <!-- Partición -->
                    <div
                      style="
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Partición</mat-label>
                      <mat-label>{{ f.value.particion }}</mat-label>
                    </div>
                    <!-- Zona -->
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Zona</mat-label>
                      <mat-label>{{ f.value.zona }}</mat-label>
                    </div>
                    <!-- Editar -->
                    <button
                      mat-mini-fab
                      color="warn"
                      type="button"
                      (click)="editCamaraAlarma(i)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <!-- Eliminar -->
                    <button
                      mat-mini-fab
                      color="warn"
                      type="button"
                      (click)="deleteCamaraAlarma(i)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              }
              <div class="row-center">
                <app-button
                  [matType]="'mat-fab extended'"
                  color="accent"
                  (click)="addCamara()"
                  text="Cámara por Zona"
                  icon="add"
                ></app-button>
              </div>
            </div>
          </div>
        </form>
      </mat-step>

      <!-- Contactos -->
      <mat-step errorMessage="Campos incompletos" optional>
        <ng-template matStepLabel>Contactos</ng-template>
        <!-- Listado de Códigos -->
        <div style="padding: 0.5em; border-radius: 5px">
          @for (f of contactosForm.controls; track f; let i = $index) {
            <div class="layout" style="align-items: baseline">
              <div class="grow1 div-camaraalarma">
                <!-- <div>
                  <div class="custom-placeholder" *cdkDragPlaceholder></div>
                  <mat-icon cdkDragHandle color="accent" style="cursor: move">
                    drag_indicator
                  </mat-icon>
                </div> -->
                <div
                  style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <mat-label>Contacto</mat-label>
                  <mat-label>{{
                    f.value.informacion?.contacto || ""
                  }}</mat-label>
                </div>

                <div
                  style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <mat-label>Email</mat-label>
                  <mat-label>{{ f.value.informacion?.email || "" }}</mat-label>
                </div>

                <div
                  style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <mat-label>Telefono</mat-label>
                  <mat-label>{{
                    f.value.informacion?.telefono || ""
                  }}</mat-label>
                </div>

                <div
                  style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <mat-label>Prioridad</mat-label>
                  <mat-label>{{ f.value.orden || "" }}</mat-label>
                </div>
                <button
                  mat-mini-fab
                  color="warn"
                  type="button"
                  (click)="deleteNota_Contacto(i)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          }
          <div class="row-center">
            <app-button
              [matType]="'mat-fab extended'"
              color="accent"
              (click)="addNota_Contacto()"
              text="Agregar Contacto"
              icon="add"
            ></app-button>
          </div>
        </div>
      </mat-step>

      <!-- Notas -->
      <mat-step errorMessage="Campos incompletos" optional>
        <ng-template matStepLabel>Notas</ng-template>
        <!-- Listado de Códigos -->
        <div style="padding: 0.5em; border-radius: 5px">
          @for (f of notasForm.controls; track f; let i = $index) {
            <div class="layout" style="align-items: baseline">
              <div class="grow1 div-camaraalarma">
                <!-- <div>
                  <div class="custom-placeholder" *cdkDragPlaceholder></div>
                  <mat-icon cdkDragHandle color="accent" style="cursor: move">
                    drag_indicator
                  </mat-icon>
                </div> -->
                <div
                  style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <mat-label>Nota</mat-label>
                  <mat-label>{{ f.value?.informacion?.nota || "" }}</mat-label>
                </div>
                <div
                  style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <mat-label>Prioridad</mat-label>
                  <mat-label>{{ f.value?.orden || "" }}</mat-label>
                </div>

                <button
                  mat-mini-fab
                  color="warn"
                  type="button"
                  (click)="deleteNota_Nota(i)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          }
          <div class="row-center">
            <app-button
              [matType]="'mat-fab extended'"
              color="accent"
              (click)="addNota_Nota()"
              text="Agregar Nota"
              icon="add"
            ></app-button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </mat-card-content>
  <!-- Botones -->
  <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
    <div style="display: flex; width: 100%; flex-direction: column">
      <div
        style="
          margin-bottom: 5px;
          gap: 10px;
          display: flex;
          justify-content: space-between;
        "
      >
        <button
          mat-mini-fab
          color="accent"
          (click)="stepper.previous()"
          [disabled]="stepper.selectedIndex === 0"
        >
          <mat-icon>keyboard_double_arrow_left</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="accent"
          [disabled]="
            stepper.selectedIndex !== 6
              ? false
              : !this.formBasica.valid ||
                !this.formDetallada.valid ||
                !this.formDomicilio.valid
          "
          (click)="stepper.selectedIndex !== 6 ? stepper.next() : crearTodo()"
        >
          @if (stepper.selectedIndex !== 6) {
            <mat-icon>keyboard_double_arrow_right</mat-icon>
          } @else {
            <mat-icon>done_all</mat-icon>
          }
        </button>
      </div>
      <mat-progress-bar
        color="accent"
        [mode]="this.loading.getLoading() ? 'indeterminate' : 'determinate'"
      >
      </mat-progress-bar>
    </div>
  </mat-card-actions>
</mat-card>
