<!-- <div
  style="
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1%;
  "
> -->
<div
  style="
    order: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  "
>
  <div style="position: relative; width: 65%; height: 100%">
    @if (!mapaDeRecorrido) {
      <app-mapa-busqueda
        [tipo]="'Circle'"
        [recorridoReporte]="recorridoReporte"
        (circleChange)="circulo = $event"
        [centrarA]="puntoParaCiruculo"
        [puedeDibujar]="mapaBusquedaDibujar"
        [paradas]="paradaListado"
        [paradaSelectedIn]="paradaSelected"
        (paradaSelectedOut)="paradaSelected = $event"
      ></app-mapa-busqueda>
    } @else {
      <app-mapa-facil [data]="recorridoMapaFacil"></app-mapa-facil>
    }
  </div>
  <div
    style="
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      width: 34.5%;
    "
  >
    <mat-tab-group
      [(selectedIndex)]="selectedTab"
      (selectedTabChange)="tabChanged($event)"
    >
      <!-- Busqueda por Zona -->
      <mat-tab label="Por Zona">
        <ng-template matTabContent>
          <app-busqueda-punto
            [circuloParam]="circulo"
            (puntoDeCirculo)="puntoParaCiruculo = $event"
          ></app-busqueda-punto>
        </ng-template>
      </mat-tab>
      <!-- Vehiculo detenido -->
      <mat-tab label="Detenido">
        <ng-template matTabContent>
          <app-vehiculo-detenido
            (interaccionesBusquedaQuery)="paradaListado = $event"
            (interaccionesBusquedaSelectedChange)="paradaSelected = $event"
            [interaccionesBusquedaSelected]="paradaSelected"
          ></app-vehiculo-detenido>
        </ng-template>
      </mat-tab>
      <!-- Vehiculo Exeso de velocidad -->
      <mat-tab label="Exceso de velocidad">
        <ng-template matTabContent>
          <app-vehiculo-exceso-velocidad
            (interaccionesBusquedaSelectedChange)="recorridoReporte = $event"
          ></app-vehiculo-exceso-velocidad>
        </ng-template>
      </mat-tab>
      <!-- Vehiculo Exeso de velocidad -->
      <mat-tab label="Recorridos">
        <ng-template matTabContent>
          <app-vehiculo-recorridos
            (interaccionesBusquedaSelectedChange)="
              armarRecorridoParaMapa($event)
            "
          ></app-vehiculo-recorridos>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<!-- </div> -->
