import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICliente,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';

import { UsuariosService } from '../usuarios.service';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import { LoginService } from '../../../../login/login.service';
import {
  IParamsService,
  ParamsService,
} from '../../../../../auxiliares/servicios/params.service';

@Component({
  selector: 'app-listado-usuarios',
  templateUrl: './listado-usuarios.component.html',
  styleUrl: './listado-usuarios.component.scss',
  standalone: false,
})
export class ListadoUsuariosComponent implements OnInit, OnDestroy {
  public idUsuario = LoginService.getUsuario()?._id;
  //// Tabla nueva
  public name = ListadoUsuariosComponent.name;
  public usuarios: IUsuario[] = [];
  public usuarios$?: Subscription;
  public cliente: ICliente;

  public clientes$: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IUsuario>[];
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Usuario',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['usuario'],
  };
  // FILTROS
  // public estado: IFiltroTabla = {
  //   elementos: [
  //     { nombre: 'Activos', _id: 'true' },
  //     { nombre: 'Inactivos', _id: 'false' },
  //   ],
  //   filter: {
  //     field: 'activo',
  //     value: true,
  //   },
  //   label: 'Estado',
  //   selectLabel: 'nombre',
  //   selectValue: '_id',
  //   tipo: 'select',
  // };
  public clienteFiltro: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [this.clienteFiltro];

  public filter: IFilter<ICliente> = {};
  public populate: IPopulate = {
    path: 'cliente',
    select: 'nombre',
  };
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: UsuariosService,
    private navigator: Router,
    private route: ActivatedRoute,
    private paramsService: ParamsService,
  ) {}

  public async listar(): Promise<void> {
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.usuarios = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listados.getLastValue('usuarios', this.query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: true,
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.clienteFiltro.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  // Acciones
  public async crear() {
    const params: IParamsService = {};
    this.paramsService.setParams(params);
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(usuario: IUsuario) {
    const params: IParamsService = {
      usuario,
      editando: true,
    };
    this.paramsService.setParams(params);

    this.navigator.navigate(['editar', usuario._id], {
      relativeTo: this.route,
    });
  }

  public async eliminar(usuario: IUsuario) {
    const res = await this.dialogService.confirm(
      'Eliminar Usuario',
      `¿Desea eliminar el usuario ${usuario.usuario}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(usuario._id!);
      this.helper.notifSuccess('Usuario eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IUsuario>[] = [
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'usuario',
        },
      },
      {
        header: {
          label: 'Fecha de Creación',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: {
          label: 'Roles',
        },
        row: {
          html(dato) {
            let text = '<div>';
            dato.roles.forEach((r) => (text += r + '<br>'));
            text += '</div>';
            return text;
          },
        },
      },
      {
        header: {
          label: 'Módulos Habilitados',
        },
        row: {
          html(dato) {
            let text = '<div>';
            if (dato.permisos?.moduloActivos) text = text + 'Activos' + '<br>';
            if (dato.permisos?.moduloAdministracion)
              text = text + 'Administracion' + '<br>';
            if (dato.permisos?.moduloAlarmasDomiciliarias)
              text = text + 'Alarmas' + '<br>';
            if (dato.permisos?.moduloColectivos)
              text = text + 'Colectivos' + '<br>';
            if (dato.permisos?.moduloEventosTecnicos)
              text = text + 'Servicio Técnico' + '<br>';
            if (dato.permisos?.moduloVehiculos)
              text = text + 'Vehículos' + '<br>';
            text = text + '</div>';
            return text;
          },
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled(dato) {
              const usuario = LoginService.getUsuario();
              const consultor = usuario.roles.includes('Consultor');
              return (
                dato.usuario === 'admin' ||
                dato._id === LoginService.getUsuario()?._id ||
                consultor
              );
            },
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    await this.listar();
    // await this.listarClientes();
    this.cliente = LoginService.getCliente();
  }

  ngOnDestroy() {
    this.usuarios$?.unsubscribe();
  }
}
