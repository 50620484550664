/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../../../login/login.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CrearEditarClienteComponent } from '../../../administracion/clientes/crear-editar-cliente/crear-editar-cliente.component';
import {
  ICamara,
  ICamaraAlarma,
  ICliente,
  ICreateDispositivoAlarma,
  ICreateNota,
  ICreateTipoEvento,
  ICreateUbicacion,
  IDispositivoAlarma,
  IFilter,
  IGeoJSONPoint,
  IListado,
  IModeloDispositivo,
  IParticionZona,
  IQueryParam,
  ITipoEvento,
  IUbicacion,
  Operador,
  TipoDispositivo,
} from 'modelos/src';
import { Observable, Subscription } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios';
import { CrearEditarModeloDispositivoComponent } from '../../../../standalone/modelos-dispositivos/crear-editar-modelo-dispositivo/crear-editar-modelo-dispositivo.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TiposEventosService } from '../../../../../auxiliares/servicios/http/tipos-eventos.service';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';
import { CrearEditarCamaraComponent } from '../../camaras/crear-editar-camara/crear-editar-camara.component';
import { AgregarCanalCamaraCompletoComponent } from './agregar-canal-camara-completo/agregar-canal-camara-completo.component';
import { CrearNotaAlarmaCompletoComponent } from './crear-editar-nota-alarma-completo/crear-editar-nota-alarma-completo.component';
import { MatStepperIntl } from '@angular/material/stepper';
import { LoadingService } from '../../../../../auxiliares/servicios/loading.service';
import { UbicacionService } from '../../../../../auxiliares/servicios/http/ubicacion.service';
import { AlarmasDomiciliariasService } from '../alarmas-domiciliarias.service';
import { NotasService } from '../../../../../auxiliares/servicios/http/notas.service';

@Component({
  selector: 'app-crear-alarma-domiciliaria-completo',
  templateUrl: './crear-alarma-domiciliaria-completo.component.html',
  styleUrl: './crear-alarma-domiciliaria-completo.component.scss',
  providers: [{ provide: MatStepperIntl }],
  standalone: false,
})
export class CrearAlarmaDomiciliariaCompletoComponent
  implements OnInit, OnDestroy
{
  private _matStepperIntl = inject(MatStepperIntl);
  public formNotas: FormGroup[];
  public miCliente = LoginService.getCliente();
  ///Listars
  public clientes: ICliente[];
  public camaras: ICamara[];
  public clientesQuePuedenAtender?: ICliente[] = [this.miCliente];
  public modelosComunicadores: IModeloDispositivo[];
  public modelosAlarmas: IModeloDispositivo[];
  public tiposEventos: ITipoEvento[] = [];
  public ubicaciones?: IUbicacion[] = [];

  //Subs
  private camaras$: Subscription;
  private clientes$: Subscription;
  private modelosComunicadores$: Subscription;
  private modelosAlarmas$: Subscription;
  public tiposEventos$: Subscription;
  public domicilio$?: Subscription;
  public ubicaciones$?: Subscription;

  //Basica
  public formBasica: FormGroup = new FormGroup({
    nombre: new FormControl(null, Validators.required),
    numeroAbonado: new FormControl(null, Validators.required),
    idCliente: new FormControl(null, Validators.required),
    idsClientesQuePuedenAtender: new FormControl(
      [this.miCliente?._id],
      Validators.required,
    ),
  });
  public clienteElegido?: ICliente;
  // Detallada
  public formDetallada = new FormGroup({
    idUnicoComunicador: new FormControl(null, Validators.required),
    idComunicador: new FormControl(null, Validators.required),
    passwordComunicador: new FormControl(null, Validators.required),
    idModelo: new FormControl(null, Validators.required),
    sim1: new FormGroup({
      iccid: new FormControl(),
      numero: new FormControl(),
      operador: new FormControl(),
      apn: new FormControl(),
      usuario: new FormControl(),
      password: new FormControl(),
    }),
    sim2: new FormGroup({
      iccid: new FormControl(),
      numero: new FormControl(),
      operador: new FormControl(),
      apn: new FormControl(),
      usuario: new FormControl(),
      password: new FormControl(),
    }),
    frecReporte: new FormControl(null),
  });

  ///Sims

  get formSim1() {
    return this.formDetallada?.get('sim1') as FormGroup;
  }
  get formSim2() {
    return this.formDetallada?.get('sim2') as FormGroup;
  }

  //Zonas
  public formInfoZonas = new FormGroup({ infoZonas: new FormArray([]) });
  get infoZonas() {
    return this.formInfoZonas?.get('infoZonas') as FormArray;
  }
  public arrayZonas = this.camarasForm?.value.infoZonas.controls;
  filteredOptionsSim1 = new Observable<string[]>();
  filteredOptionsSim2 = new Observable<string[]>();
  public creandoNuevoTag?: boolean = false;

  public operadores: Operador[] = [
    'Claro',
    'Movistar',
    'Personal',
    'Tuenti',
    'Otro',
  ];
  //camaras
  public formCamaras = new FormGroup({
    idsCamaras: new FormControl(null),
    camaras: new FormArray([]),
  });
  get camarasForm() {
    return this.formCamaras?.get('camaras') as FormArray;
  }
  get arrayCamaras() {
    return this.camarasForm.controls;
  }
  public camarasDeAlarma?: ICamara[] = [];
  //ubication
  public formUbicacion = new FormGroup({
    geojson: new FormGroup({
      type: new FormControl('Point'),
      coordinates: new FormControl(),
    }),
    domicilio: new FormControl(null, Validators.required),
  });
  public domicilioExistente = false;
  get formDomicilio() {
    return this.formUbicacion?.get('domicilio') as FormGroup;
  }
  public domicilio?: IUbicacion = null;
  public domicilioId: string = null;
  public centrarA?: IGeoJSONPoint;
  get geojson() {
    return this.formUbicacion?.get('geojson') as FormGroup;
  }
  //notas
  public notasForm = new FormArray([]);
  get arrayNotas() {
    return this.contactosForm.controls;
  }
  //Contactos
  public contactosForm = new FormArray([]);
  get arrayContactos() {
    return this.contactosForm.controls;
  }
  constructor(
    public helper: HelperService,
    public dialog: MatDialog,
    private listados: ListadosService,
    private tipoEventoService: TiposEventosService,
    private paramsService: ParamsService,
    public loading: LoadingService,
    private ubicacionService: UbicacionService,
    private alarmasService: AlarmasDomiciliariasService,
    private notasService: NotasService,
  ) {}

  //Crears
  public crearCliente() {
    const dialog = this.dialog.open(CrearEditarClienteComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.formBasica.patchValue({ idCliente: res });
      }
    });
  }
  public crearModeloAlarma() {
    const dialog = this.dialog.open(CrearEditarModeloDispositivoComponent, {
      data: { tipo: 'Alarma' },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.formDetallada.patchValue({ idModelo: res });
      }
    });
  }
  public crearModeloComunicador() {
    const dialog = this.dialog.open(CrearEditarModeloDispositivoComponent, {
      data: { tipo: 'Comunicador' },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.formDetallada.patchValue({ idComunicador: res });
      }
    });
  }
  public crearCamara() {
    this.paramsService.setParams({
      desdeAlarma: true,
      idCliente: this.formBasica.value.idCliente,
    });
    const dialog = this.dialog.open(CrearEditarCamaraComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        const camaras = this.formCamaras?.value?.idsCamaras || [];
        camaras.push(res);
        this.formCamaras.patchValue({ idsCamaras: camaras });
      }
    });
  }

  //Listars
  public async listarClientes(): Promise<void> {
    const query: IQueryParam = { includeChildren: true };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  public async listarCamaras(): Promise<void> {
    // Determinar el idCliente de acuerdo al estado (creando o editando)
    const idClienteDeAlarma = this.formBasica?.value?.idCliente;
    const query: IQueryParam = {
      filter: JSON.stringify({ idCliente: idClienteDeAlarma }),
      includeChildren: true,
    };
    this.camaras$ = this.listados
      .subscribe<IListado<ICamara>>('camaras', query)
      .subscribe((data) => {
        this.camaras = data.datos;
        console.log('listado de camaras: ', data);
      });
    await this.listados.getLastValue('camaras', query);
  }
  public async listarModelosComunicadores(): Promise<void> {
    const tipo: TipoDispositivo = 'Comunicador';
    const filter: IFilter<IModeloDispositivo> = { tipo };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };
    this.modelosComunicadores$?.unsubscribe();
    this.modelosComunicadores$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.modelosComunicadores = data.datos;
        console.log(`listado de comunicadores`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }
  public async listarModelos(): Promise<void> {
    const tipo: TipoDispositivo = 'Alarma';
    const filter: IFilter<IModeloDispositivo> = { tipo };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };
    this.modelosAlarmas$?.unsubscribe();
    this.modelosAlarmas$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.modelosAlarmas = data.datos;
        console.log(`listado de modelos de alarma`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }

  private async listarTipoEvento(): Promise<void> {
    const filter: IFilter<ITipoEvento> = {
      categoria: 'Alarma',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'nombre',
      limit: 0,
    };
    this.tiposEventos$?.unsubscribe();
    this.tiposEventos$ = this.listados
      .subscribe<IListado<ITipoEvento>>('tiposEventos', query)
      .subscribe((data) => {
        this.tiposEventos = data.datos;
        console.log(`listado de tiposEventos`, data);
      });
    await this.listados.getLastValue('tiposEventos', query);
  }
  //
  public nombreCamara(idCamara: string) {
    return this.camarasDeAlarma.find((c) => c._id === idCamara).identificacion;
  }
  public nombreCanalCamara(id: string, canal: string) {
    const camara = this.camaras.find((c) => c._id === id);
    if (camara)
      return camara.canales.length > 0
        ? camara.canales.find((c) => c.numero == canal).nombre
        : null;
    return null;
  }
  private addCamaraFormGroup(camara?: ICamaraAlarma) {
    return new FormGroup({
      idCamara: new FormControl(camara?.idCamara, [Validators.required]),
      particion: new FormControl(camara?.particion, [Validators.required]),
      zona: new FormControl(camara?.zona, [Validators.required]),
      canal: new FormControl(camara?.canal, [Validators.required]),
    });
  }
  public editCamaraAlarma(i: number) {
    const valor = this.camarasForm.value[i];
    if (!valor) return;
    const data = {
      camarasDeAlarma: this.camarasDeAlarma,
      infoEditar: valor,
    };
    const config: MatDialogConfig = {
      data,
      minWidth: '600px',
    };
    const dialog = this.dialog.open(
      AgregarCanalCamaraCompletoComponent,
      config,
    );
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.camarasForm.push(this.addCamaraFormGroup(res));
      }
    });
  }
  public deleteCamaraAlarma(i: number) {
    this.camarasForm.removeAt(i);
  }
  public addCamara() {
    const data = {
      camarasDeAlarma: this.camarasDeAlarma,
    };
    const config: MatDialogConfig = {
      data,
      minWidth: '600px',
    };
    const dialog = this.dialog.open(
      AgregarCanalCamaraCompletoComponent,
      config,
    );
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.camarasForm.push(this.addCamaraFormGroup(res));
      }
    });
  }
  public addNota_Contacto() {
    const data = {
      tipo: 'Contacto',
    };
    const config: MatDialogConfig = {
      data,
      minWidth: '600px',
    };
    const dialog = this.dialog.open(CrearNotaAlarmaCompletoComponent, config);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.contactosForm.push(res);
      }
    });
  }
  public addNota_Nota() {
    const data = {
      tipo: 'Nota',
    };
    const config: MatDialogConfig = {
      data,
      minWidth: '600px',
    };
    const dialog = this.dialog.open(CrearNotaAlarmaCompletoComponent, config);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.notasForm.push(res);
      }
    });
  }

  public deleteNota_Contacto(i: number) {
    this.contactosForm.removeAt(i);
  }
  public deleteNota_Nota(i: number) {
    this.notasForm.removeAt(i);
  }

  /// Drag and Drop INFOZONA
  public dropInfoZonas(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.arrayZonas, event.previousIndex, event.currentIndex);
    this.formInfoZonas.patchValue(this.arrayZonas);
  }
  /// Drag and Drop CONTACTOS
  public dropContactos(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.arrayContactos,
      event.previousIndex,
      event.currentIndex,
    );
    this.contactosForm.patchValue(this.arrayContactos);
  }
  /// Drag and Drop NOTAS
  public dropNotas(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.arrayNotas, event.previousIndex, event.currentIndex);
    this.notasForm.patchValue(this.arrayNotas);
  }
  /// Drag and Drop CAMARAS
  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.arrayCamaras, event.previousIndex, event.currentIndex);
    this.camarasForm.patchValue(this.arrayCamaras);
  }
  public onClienteChange(cliente: ICliente) {
    this.clienteElegido = cliente;
    const clientesPadres: ICliente[] = [cliente];
    let idPadre = cliente.idPadre;
    while (idPadre) {
      const clientePadre = this.clientes.find((c) => c._id === idPadre);
      if (clientePadre) {
        clientesPadres.push(clientePadre);
      }
      idPadre = clientePadre?.idPadre;
    }
    this.clientesQuePuedenAtender = clientesPadres;

    // Resetea los clientes que pueden atender
    this.formBasica
      ?.get('idsClientesQuePuedenAtender')
      ?.setValue([this.miCliente._id]);
    this.formBasica?.get('idCliente')?.setValue(cliente._id);
    this.listarCamaras();
  }

  public async addTag(t: string) {
    if (!this.creandoNuevoTag) {
      this.creandoNuevoTag = true;
      const tipoEvento: ICreateTipoEvento = {
        nombre: t,
        categoria: 'Alarma',
      };
      try {
        const res = await this.tipoEventoService.create(tipoEvento);
        for (const zona of this.infoZonas.controls) {
          const value = zona.value?.nombre;
          if (!value) {
            zona.patchValue({ nombre: res.nombre });
          }
        }
        this.creandoNuevoTag = false;
      } catch {
        this.creandoNuevoTag = false;
      }
    }
  }
  public patchDireccion(domicilio: string) {
    this.formUbicacion.patchValue({ domicilio });
  }
  ///
  public deleteInfoZona(i: number) {
    this.infoZonas.removeAt(i);
  }

  public addInfoZona() {
    this.infoZonas.push(this.addInfoZonaFormGroup());
  }

  private addInfoZonaFormGroup(infozona?: IParticionZona) {
    return new FormGroup({
      nombre: new FormControl(infozona?.nombre, [Validators.required]),
      particion: new FormControl(infozona?.particion, [Validators.required]),
      zona: new FormControl(infozona?.zona, [Validators.required]),
    });
  }
  ///
  public asignarCamarasDeAlarma(camaras: ICamara[]) {
    this.camarasDeAlarma = camaras;
  }
  //
  public deleteContactosForm(i: number) {
    this.infoZonas.removeAt(i);
  }

  public addContactosForm() {
    this.infoZonas.push(this.addInfoZonaFormGroup());
  }
  //
  public async crearTodo() {
    this.loading.setLoading(true);
    let resUbicacion;
    try {
      const ubicacionNueva: ICreateUbicacion = {
        idCliente: this.formBasica.value?.idCliente,
        direccion: this.formUbicacion.value?.domicilio,
        geojson: {
          type: 'Point',
          coordinates: this.formUbicacion.value?.geojson.coordinates,
        },
        identificacion: this.formUbicacion.value?.domicilio,
        categoria: 'Domicilio',
      };
      resUbicacion = await this.ubicacionService.create(ubicacionNueva);
    } catch (error) {
      this.helper.notifWarn('No pudo Crearse la nueva ubicacion');
      console.log(error);
      this.loading.setLoading(false);
    }
    let resAlarma: IDispositivoAlarma;
    if (resUbicacion) {
      try {
        const dataAlarma: ICreateDispositivoAlarma = {
          nombre: this.formBasica.value?.nombre,
          numeroAbonado: this.formBasica.value?.numeroAbonado,
          idCliente: this.formBasica.value?.idCliente,
          idsClientesQuePuedenAtender:
            this.formBasica.value?.idsClientesQuePuedenAtender,
          idUnicoComunicador: this.formDetallada.value?.idUnicoComunicador,
          idComunicador: this.formDetallada.value?.idComunicador,
          passwordComunicador: this.formDetallada.value?.passwordComunicador,
          idModelo: this.formDetallada.value?.idModelo,
          frecReporte: this.formDetallada.value?.frecReporte,
          sim1: {
            iccid: this.formSim1.value?.iccid,
            numero: this.formSim1.value?.numero,
            operador: this.formSim1.value?.operador,
            apn: this.formSim1.value?.apn,
            usuario: this.formSim1.value?.usuario,
            password: this.formSim1.value?.password,
          },
          sim2: {
            iccid: this.formSim2.value?.iccid,
            numero: this.formSim2.value?.numero,
            operador: this.formSim2.value?.operador,
            apn: this.formSim2.value?.apn,
            usuario: this.formSim2.value?.usuario,
            password: this.formSim2.value?.password,
          },
          infoZonas: this.formInfoZonas.value?.infoZonas,
          idsCamaras: this.formCamaras.value?.idsCamaras,
          camarasPorZona: this.formCamaras.value?.camaras,
          idDomicilio: resUbicacion._id,
        };
        resAlarma = await this.alarmasService.create(dataAlarma);
      } catch (error) {
        this.helper.notifWarn('No pudo Crearse la nueva alarma');
        console.log(error);
        this.loading.setLoading(false);
      }
    }
    if (resAlarma) {
      try {
        const notasData: ICreateNota[] = this.notasForm.value;
        notasData.map((n) => {
          n.idAsignado = resAlarma._id;
        });
        const contactosData: ICreateNota[] = this.contactosForm.value;
        contactosData.map((c) => {
          c.idAsignado = resAlarma._id;
        });
        await this.notasService.createMany(notasData);
        await this.notasService.createMany(contactosData);
      } catch (error) {
        this.helper.notifWarn('No pudo Crearse los contactos y notas');
        console.log(error);
        this.loading.setLoading(false);
        this.helper.volver();
      }
    }
    this.loading.setLoading(false);
    this.helper.volver();
  }
  //
  ngOnInit(): void {
    this.loading.setLoading(true);
    this._matStepperIntl.optionalLabel = 'Opcional';
    this._matStepperIntl.changes.next();
    Promise.all([
      this.listarClientes(),
      this.listarCamaras(),
      this.listarModelosComunicadores(),
      this.listarModelos(),
      this.listarTipoEvento(),
    ]);
    this.loading.setLoading(false);
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
    this.camaras$?.unsubscribe();
    this.modelosComunicadores$?.unsubscribe();
    this.modelosAlarmas$?.unsubscribe();
    this.tiposEventos$?.unsubscribe();
  }
}
