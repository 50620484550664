<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
</ng-container>

<mat-dialog-content style="min-height: 50px">
  <app-date-range-selector
    #dateRangeSelector
    [dateRange]="{ start, end }"
  ></app-date-range-selector>
</mat-dialog-content>

<br />

<mat-dialog-actions align="end" style="gap: 8px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="matDialogRef.close(false)"
    [text]="btnCancelText"
  ></app-button>

  <app-button
    matType="mat-fab extended"
    color="accent"
    [text]="btnOkText"
    (click)="matDialogRef.close(dateRangeSelector.dateRange)"
  ></app-button>
</mat-dialog-actions>
