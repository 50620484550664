<div style="height: 100%">
  <div class="header">
    <!-- Título -->
    <div class="row-space-between">
      <span class="titulo">Despachos</span>
      <button
        mat-icon-button
        color="accent"
        matTooltip="Listado de Despachos"
        [routerLink]="['listado']"
      >
        <mat-icon>list</mat-icon>
      </button>
      <span class="fecha">{{ hoy | date: "short" }}</span>
    </div>
    <br />
    <br />
    <!-- Cronogramas -->
    <div style="width: 100%; display: flex">
      @if (cronogramas) {
        <ng-select
          [items]="cronogramas"
          bindLabel="nombre"
          [(ngModel)]="cronograma"
          placeholder="Cronogramas"
          [notFoundText]="'No hay cronogramas disponibles'"
          appendTo="body"
          style="width: 70%; margin-right: 10px"
          (ngModelChange)="seleccionarCronograma()"
        >
        </ng-select>
        <ng-select
          [(ngModel)]="filtro"
          placeholder="Selecciona un estado"
          style="width: 30%"
        >
          <ng-option [value]="1">Modificado</ng-option>
          <ng-option [value]="2">Completado</ng-option>
          <ng-option [value]="3">Cancelado</ng-option>
          <ng-option [value]="4">No Despachado</ng-option>
        </ng-select>
      }
    </div>
    @if (cronograma) {
      <div class="row-center" style="width: 100%">
        <div>
          @if (cronograma?.ubicacion?.identificacion) {
            <span class="terminal-borde"></span>
            <span class="terminal-nombre">{{
              cronograma?.ubicacion?.identificacion
            }}</span>
          } @else {
            <span class="no-info">Sin Terminal</span>
          }
        </div>
      </div>
    }
  </div>
  <section class="layout">
    <div class="periodos">
      <!-- Períodos del Cronograma  -->
      @for (p of cronograma?.periodos; track $index) {
        @if (
          filtro == null ||
          (checkHora(p) &&
            ((!checkCancelado(p) &&
              ((filtro == 1 && !checkCompletado(p)) ||
                (filtro == 2 && checkCompletado(p)))) ||
              (filtro == 3 && checkCancelado(p)))) ||
          (!checkHora(p) && filtro == 4)
        ) {
          <div
            [ngStyle]="{
              background: checkHora(p)
                ? p.datos['modificado']
                  ? 'rgba(255, 255, 204, 0.3)'
                  : 'rgba(128, 128, 128, 0.3)'
                : 'transparent',
            }"
            class="row-space-around periodo"
            (click)="seleccionarPeriodo(p)"
            [ngClass]="p === periodoSeleccionado ? 'selected' : ''"
          >
            @if (checkHora(p)) {
              @if (!checkCancelado(p)) {
                @if (checkCompletado(p) === true) {
                  <mat-icon style="color: green" matTooltip="Completado"
                    >check_circle</mat-icon
                  >
                } @else {
                  <mat-icon
                    style="
                      color: yellow;
                      border-radius: 60%;
                      transition: box-shadow 0.3s ease;
                      margin: 0;
                      padding: 0;
                      align-items: center;
                      display: flex;
                      justify-content: center;
                      width: 20px;
                      height: 20px;
                    "
                    matTooltip="Modificado"
                    (click)="clickModificado(p)"
                    onmouseover="this.style.boxShadow='0 0 10px rgba(255, 255, 0, 0.8)'"
                    onmouseout="this.style.boxShadow='0 0 10px rgba(255, 255, 0, 0)'"
                    >{{
                      p.datos["modificado"] ? "arrow_circle_left" : "cancel"
                    }}</mat-icon
                  >
                }
              } @else {
                <mat-icon style="color: red" matTooltip="Cancelado"
                  >error</mat-icon
                >
              }
            }
            @if (!p.datos["modificado"]) {
              <!-- Sale  -->
              <div class="column-center">
                <span>Sale</span>
                <span class="hora">{{ p.desde }}</span>
              </div>
              <!-- Coche  -->
              <div class="column-center">
                <span>Coche</span>
                <span class="hora">{{
                  completarVehiculo(p.datos["idVehiculo"])
                }}</span>
              </div>
              <!-- Chofer  -->
              <div class="column-center">
                <span>Chofer</span>
                @if (completarChofer(p.datos["idChofer"])) {
                  <span class="hora">{{
                    completarChofer(p.datos["idChofer"])
                  }}</span>
                } @else {
                  <span class="no-info">Sin Chofer</span>
                }
              </div>
              <!-- Recorridos  -->
              <div class="column-center">
                <span>Recorridos</span>
                @for (r of p.datos["idsRecorridos"]; track $index) {
                  <span class="hora"> {{ completarRecorrido(r) }}</span>
                }
              </div>
              <!-- ID Recorrido  -->
              <div class="column-center">
                <span>Tag Recorrido</span>
                <span class="hora">{{ p.datos["idRecorridoActual"] }}</span>
              </div>
            } @else {
              <!-- Sale  -->
              <div class="column-center">
                <span>Salió</span>
                <span class="hora">{{ p.datos["salio"] }}</span>
              </div>
              <!-- Coche  -->
              <div class="column-center">
                <span>Coche</span>
                <span class="hora">{{
                  completarVehiculo(p.datos["idVehiculoD"])
                }}</span>
              </div>
              <!-- Chofer  -->
              <div class="column-center">
                <span>Chofer</span>
                @if (completarChofer(p.datos["idChoferD"])) {
                  <span class="hora">{{
                    completarChofer(p.datos["idChoferD"])
                  }}</span>
                } @else {
                  <span class="no-info">Sin Chofer</span>
                }
              </div>
              <!-- Recorridos  -->
              <div class="column-center">
                <span>Recorridos</span>
                @for (r of p.datos["idsRecorridosD"]; track $index) {
                  <span class="hora"> {{ completarRecorrido(r) }}</span>
                }
              </div>
              <!-- ID Recorrido  -->
              <div class="column-center">
                <span>Tag Recorrido</span>
                <span class="hora">{{ p.datos["idRecorridoActual"] }}</span>
              </div>
            }
          </div>
        }
      } @empty {
        <div class="row-center">
          <span class="no-info">No hay período seleccionado</span>
        </div>
      }
      <br />
    </div>
    <div class="despacho">
      @if (cronograma) {
        <div class="column-center" style="width: 100%">
          <div class="row-center" style="width: 100%; gap: 8px">
            <mat-icon
              color="accent"
              style="cursor: pointer"
              (click)="modificarFecha(-1)"
              >keyboard_arrow_left</mat-icon
            >
            <mat-form-field class="no-hint" style="width: 25%">
              <mat-label>Día</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [ngModel]="fecha"
                readonly
                (dateChange)="parseFecha($event)"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-icon
              color="accent"
              style="cursor: pointer"
              (click)="modificarFecha(1)"
              >keyboard_arrow_right</mat-icon
            >
          </div>
        </div>
      }
      @if (periodoSeleccionado) {
        <div class="row-center" style="width: 100%">
          <span class="terminal-nombre" style="padding-right: 0.2em"
            >Estado</span
          >
          <mat-icon [color]="completado ? 'accent' : 'warn'">{{
            checkearDespacho()
          }}</mat-icon>
        </div>
        <br />
        <div class="row-center" style="width: 100%; gap: 5px">
          <!-- Sale -->
          <mat-form-field style="width: 10%">
            <mat-label>Sale</mat-label>
            <input
              matInput
              placeholder="12:15"
              [(ngModel)]="hora"
              pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"
              (ngModelChange)="validarDespacho()"
              readonly
            />
            <mat-icon matSuffix>schedule</mat-icon>
          </mat-form-field>
          <!-- Salió -->
          <mat-form-field style="width: 15%">
            <mat-label>Salió</mat-label>
            <input
              matInput
              placeholder="12:15"
              [(ngModel)]="salio"
              pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"
              (ngModelChange)="validarDespacho()"
            />
            <mat-icon matSuffix>schedule</mat-icon>
          </mat-form-field>
          <!-- Vehículo -->
          <div style="width: 20%">
            @if (vehiculos) {
              <ng-select
                [items]="vehiculos"
                bindLabel="identificacion"
                bindValue="_id"
                [(ngModel)]="idVehiculo"
                placeholder="Vehículo"
                [notFoundText]="'No hay vehículos disponibles'"
                appendTo="body"
                style="width: 100%"
                (change)="validarDespacho()"
              >
              </ng-select>
            }
          </div>
          <!-- Chofer -->
          <div style="width: 20%">
            @if (choferes) {
              <ng-select
                [(ngModel)]="idChofer"
                [notFoundText]="'No hay choferes disponibles'"
                placeholder="Chofer"
                appendTo="body"
                style="width: 100%"
                (change)="validarDespacho()"
              >
                @for (c of choferes; track c._id) {
                  <ng-option [value]="c._id"
                    >{{ c.identificacionInterna }} -
                    {{ c.datosPersonales.nombre }}</ng-option
                  >
                }
              </ng-select>
            }
          </div>
          <!-- Recorridos -->
          <div style="width: 20%">
            @if (recorridos) {
              <ng-select
                [items]="recorridos"
                bindLabel="nombre"
                bindValue="_id"
                [(ngModel)]="idsRecorridos"
                placeholder="Recorridos"
                [notFoundText]="'No hay recorridos disponibles'"
                appendTo="body"
                style="width: 100%"
                multiple="true"
                (change)="validarDespacho()"
                readonly
              >
              </ng-select>
            }
          </div>
          <!-- Tag -->
          <mat-form-field style="width: 20%">
            <mat-label>Tag Recorrido</mat-label>
            <input matInput [(ngModel)]="idRecorridoActual" readonly />
          </mat-form-field>
        </div>
        <div class="row-center" style="gap: 8px">
          <app-button
            matType="mat-fab extended"
            color="warn"
            (click)="cancelarDespacho()"
            text="Cancelar"
            icon="clear"
          ></app-button>
          <app-button
            matType="mat-fab extended"
            color="accent"
            (click)="crearDespacho()"
            text="Confirmar"
            icon="add"
          ></app-button>
        </div>
      } @else {
        <span class="no-info"> No hay período seleccionado</span>
      }
    </div>
  </section>
</div>
