import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  IApikey,
  ICliente,
  ICreateApikey,
  IListado,
  IQueryParam,
  IUpdateApikey,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { ApikeysService } from '../apikeys.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

@Component({
  selector: 'app-crear-editar-apikey',
  templateUrl: './crear-editar-apikey.component.html',
  styleUrl: './crear-editar-apikey.component.scss',
  standalone: false,
})
export class CrearEditarApikeyComponent implements OnInit, OnDestroy {
  public id: string | null = null;
  public title = '';
  public apikey?: IApikey;
  public apikey$?: Subscription;

  public clientes?: ICliente[];
  public clientes$?: Subscription;

  public form?: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private listados: ListadosService,
    private service: ApikeysService,
    public helper: HelperService,
    private dialogService: DialogService,
  ) {}

  private async listar() {
    this.apikey$?.unsubscribe();
    this.apikey$ = this.listados
      .subscribe<IApikey>('apikey', this.id!)
      .subscribe((data) => {
        this.apikey = data;
        console.log(`listado de apikey`, data);
      });
    await this.listados.getLastValue('apikey', this.id!);
  }

  private async listarClientes() {
    this.clientes$?.unsubscribe();
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
      limit: 0,
      includeChildren: true,
      childrenLevel: 0,
    };
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  private createForm() {
    // identificacion?: string;
    // // Permisos
    // global?: boolean;
    // idClientes?: string[];
    this.form = new FormGroup(
      {
        identificacion: new FormControl(
          this.apikey?.identificacion,
          Validators.required,
        ),
        global: new FormControl(
          this.apikey?.global || false,
          Validators.required,
        ),
        idClientes: new FormControl(this.apikey?.idClientes),
      },
      { validators: this.clientesValidator() },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private clientesValidator(): any {
    return (group: FormGroup): ValidationErrors | null => {
      // NULL si es valido
      const clientes = group.get('idClientes')?.value as string[];
      const global = group.get('global')?.value as boolean;
      // Sino es global y no tiene clientes es invalido
      const forbidden = !global && (!clientes || clientes.length === 0);
      return forbidden
        ? { forbiddenIdClientes: { value: group.get('idClientes')?.value } }
        : null;
    };
  }

  private getData() {
    const data: ICreateApikey | IUpdateApikey = this.form?.value;
    return data;
  }

  // Actions

  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title} Apikey`,
      `¿Desea ${this.title.toLowerCase()} la apikey?`,
    );
    if (!res) return;
    try {
      if (this.id) {
        // Update
        const update = this.getData() as IUpdateApikey;
        if (!update) return;
        await this.service.update(this.id, update);
        this.helper.notifSuccess('Apikey Editada');
      } else {
        // Create
        const create = this.getData() as ICreateApikey;
        if (!create) return;
        await this.service.create(create);
        this.helper.notifSuccess('Apikey Creada');
      }
      this.helper.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Hooks
  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar' : 'Crear';
    await this.listarClientes();
    if (this.id) {
      await this.listar();
    }
    this.createForm();
  }

  ngOnDestroy() {
    this.apikey$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
