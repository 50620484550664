<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
</ng-container>

<mat-dialog-content>
  {{ message }}

  @if (type === "text") {
    <mat-form-field>
      <mat-label>{{ inputName }}</mat-label>
      <input matInput [(ngModel)]="input" />
    </mat-form-field>
  }

  @if (type === "datetime") {
    <app-datetime-picker [(date)]="input"></app-datetime-picker>
  }
</mat-dialog-content>

<br />

<mat-dialog-actions align="end">
  <button
    mat-fab
    extended
    color="accent"
    (click)="aceptar()"
    [disabled]="!input"
  >
    {{ btnOkText }}
  </button>
  <button mat-fab extended color="warn" (click)="matDialogRef.close(false)">
    {{ btnCancelText }}
  </button>
</mat-dialog-actions>
