import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import {
  ICreateTratamientoEvento,
  IEvento,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IColumnas,
  IRowIcon,
} from '../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { TratamientoEventosService } from '../../../../auxiliares/servicios/http/tratamientos-eventos.service';
import { TratamientoComponent } from '../tratar-evento/tratamiento/tratamiento.component';

@Component({
  selector: 'app-eventos-espera',
  templateUrl: './eventos-espera.component.html',
  styleUrl: './eventos-espera.component.scss',
  standalone: false,
})
export class EventosEsperaComponent implements OnInit, OnDestroy {
  public listando = false;
  public loading = signal(false);
  //// Tabla nueva
  public name = EventosEsperaComponent.name;
  public eventosEspera: IEvento[] = [];
  public eventosEspera$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IEvento>[];
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['valores.titulo', 'valores.mensaje'],
  };
  private activo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idActivo',
    },
    label: 'Activo',
    selectLabel: 'identificacion',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarActivos$',
    searchOn: ['identificacion'],
  };

  /////////////////////////////////////////////////////////
  //Filtro alarma

  private alarma: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idAlarma',
    },
    label: 'Alarma',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarDispositivosAlarmas$',
    searchOn: ['nombre'],
  };

  ///////////////////////////////////////////////////////////

  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };

  private titulo: IFiltroTabla = {
    elementos: [
      'Activo fuera de ubicación',
      'Activo dentro de ubicación',
      'Alarma Activada',
    ],
    filter: {
      field: 'valores.titulo',
    },
    label: 'Evento',
    tipo: 'select',
  };
  private fecha: IFiltroTabla = {
    filter: {
      field: 'fechaCreacion',
    },
    label: 'Fecha',
    tipo: 'dateRange',
  };
  public filtros: IFiltroTabla[] = [
    this.titulo,
    this.fecha,
    this.activo,
    this.alarma,
    this.cliente,
  ];

  //////////////////////////////////////////////////////////
  // FILTROS

  public populate: IPopulate[] = [
    {
      path: 'alarma',
      populate: [
        {
          path: 'domicilio',
          select: 'direccion',
        },
        {
          path: 'modelo',
          select: 'marca modelo',
        },
        {
          path: 'comunicador',
          select: 'marca modelo',
        },
      ],
    },
    {
      path: 'activo',
      select: 'identificacion',
    },
    {
      path: 'tracker',
      select: 'identificacion',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  private filter: IFilter<IEvento> = {
    estado: 'En Espera',
  };
  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    filter: JSON.stringify(this.filter),
    sort: '-fechaCreacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private service: TratamientoEventosService,
  ) {}

  public async listar(): Promise<void> {
    this.listando = true;
    this.eventosEspera$?.unsubscribe();
    this.eventosEspera$ = this.listados
      .subscribe<IListado<IEvento>>('eventos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.eventosEspera = data.datos;
        console.log(`listado de eventos`, data);
        this.listando = false;
      });
    await this.listados.getLastValue('eventos', this.query);
  }
  // Acciones
  public async detalles(evento: IEvento) {
    this.navigator.navigate(['detalles', evento._id], {
      relativeTo: this.route,
    });
  }

  public async liberar(evento: IEvento) {
    const res = await this.dialogService.confirm(
      'Liberar Evento',
      '¿Desea liberar el evento evento?',
    );
    if (!res) return;
    const tratamiento: ICreateTratamientoEvento = {
      idsEventos: [evento._id],
      estado: 'Liberada',
    };
    try {
      this.loading.set(true);
      await this.service.create(tratamiento);
      this.helper.notifSuccess('Evento liberado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    } finally {
      this.loading.set(false);
    }
  }

  public async tratar(evento: IEvento) {
    const dialogs = this.dialog.openDialogs;
    const idAbierto = dialogs.find(
      (d) => d.componentInstance.data._id === evento._id,
    );
    if (!idAbierto) {
      const config: MatDialogConfig = {
        data: evento,
        hasBackdrop: false,
        minWidth: '1100px',
        maxWidth: '1500px',
        closeOnNavigation: false,
        disableClose: true,
      };
      this.dialog.open(TratamientoComponent, config);
    }
  }
  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IEvento>[] = [
      // Fecha
      {
        header: {
          label: 'Fecha',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: ['dd/MM/yyyy HH:mm'],
          },
        },
      },
      // Categoria Codigo evento
      {
        header: {
          label: 'Categoría',
        },
        row: {
          html: (dato) => {
            if (dato.codigoEvento) {
              let html = `<div style="display: flex; gap: 1em; align-items: center">`;
              if (dato.valores?.color) {
                html += `<span style="color: ${dato.valores?.color}; font-size: 2em; overflow: visible"> &#x2B24; </span>`;
              }
              html += `<span>${dato.codigoEvento}</span>`;
              html += `</div>`;
              return html;
            }
            return '';
          },
          noData: 'Sin Categoría',
        },
      },
      // Nombre evento
      {
        header: {
          label: 'Evento',
        },
        row: {
          parse(dato) {
            return dato.valores?.titulo;
          },
        },
      },
      // Detalles
      {
        header: {
          label: 'Detalles',
        },
        row: {
          parse(dato) {
            return dato.valores?.['mensaje'];
          },
        },
      },
      // Activo / Alarma
      {
        header: {
          label: 'Activo / Alarma',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.activo) {
              return `${dato.activo?.identificacion}`;
            }
            if (dato.alarma) {
              // let text = `${dato.alarma?.nombre} - ${dato.alarma?.domicilio?.direccion}`;
              let text = `${dato.alarma?.nombre}`;
              if (text.length >= 50) {
                text = text.slice(0, 50);
                text += '...';
              }
              return text;
            }
            return '';
          },
          noData: 'Sin asignar',
        },
      },
      // Dispositivo
      {
        header: {
          label: 'Dispositivo',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.tracker) {
              return `${dato.tracker?.identificacion}`;
            }
            if (dato.alarma) {
              let text = '';
              const comunicador = dato.alarma?.comunicador;
              if (comunicador) {
                text = `${comunicador?.marca} ${comunicador?.modelo}`;
              }
              const alarma = dato.alarma.modelo;
              if (alarma) {
                text += ` - ${alarma.marca} ${alarma.modelo}`;
              }
              if (text.length >= 50) {
                text = text.slice(0, 50);
                text += '...';
              }
              return text;
            }
            return '';
          },
          noData: 'Sin identificación',
        },
      },
      // Notificar
      {
        header: {
          label: 'Requiere Atención',
          sortable: true,
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.notificar ? iconCheck : iconClose;
          },
        },
      },
      {
        header: {
          label: 'Aplazado hasta',
        },
        row: {
          field: 'posponerHasta',
          pipe: {
            pipe: DatePipe,
            args: ['dd/MM/yyyy HH:mm'],
          },
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
            sortable: true,
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'search',
            tooltip: 'Detalles',
            click: (dato) => this.detalles(dato),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'lock_open',
            tooltip: 'Liberar',
            click: (dato) => this.liberar(dato),
            disabled: () => this.loading(),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'troubleshoot',
            tooltip: 'Tratar',
            click: (dato) => this.tratar(dato),
            disabled: () => this.loading(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    await Promise.all([this.listar()]);
  }

  async ngOnDestroy() {
    this.eventosEspera$?.unsubscribe();
  }
}
