import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IActivo,
  IEnvioVehiculo,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ConfigEventoUsuarioService } from '../../../../../auxiliares/servicios/http/config-evento-usuario.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { IColumnas } from '../../../../../auxiliares/tabla/tabla.component';

@Component({
  selector: 'app-listado-envios-vehiculos',
  templateUrl: './listado.component.html',
  standalone: false,
})
export class ListadoEnviosVehiculosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoEnviosVehiculosComponent.name;
  public EnviosVehiculos: IEnvioVehiculo[] = [];
  public EnviosVehiculos$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IEnvioVehiculo>[];
  public search: IRegExpSearch = {
    fields: [],
  };
  //
  public vehiculos: IActivo[] = [];
  public usuarios: IUsuario[] = [];
  public vehiculos$: Subscription;
  public usuarios$: Subscription;
  // FILTROS
  public vehiculosFilter: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idActivo',
    },
    label: 'Vehiculo',
    selectLabel: 'identificacion',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarActivos$',
    searchOn: ['identificacion'],
  };
  public conductor: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idConductor',
    },
    label: 'Conductor',
    selectLabel: 'datosPersonales.nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarUsuarios$',
    searchOn: ['datosPersonales.nombre'],
  };

  public filtros: IFiltroTabla[] = [this.vehiculosFilter, this.conductor];
  public filter: IFilter<IEnvioVehiculo> = {};

  public populate: IPopulate[] = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'usuario',
      select: 'usuario datosPersonales.nombre',
    },
    {
      path: 'conductor',
      select: 'usuario datosPersonales.nombre',
    },
    {
      path: 'activo',
      select: 'identificacion',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };
  //
  //
  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ConfigEventoUsuarioService,
    public dialog: MatDialog,
  ) {}

  public async listar(): Promise<void> {
    let filter: IFilter<IEnvioVehiculo> = {};
    if (this.query.filter) {
      filter = JSON.parse(this.query.filter);
    }
    this.query.filter = JSON.stringify(filter);
    //
    this.EnviosVehiculos$?.unsubscribe();
    this.EnviosVehiculos$ = this.listados
      .subscribe<IListado<IEnvioVehiculo>>('enviosVehiculos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.EnviosVehiculos = data.datos;
        console.log(`listado de EnviosVehiculos`, data);
      });
    await this.listados.getLastValue('enviosVehiculos', this.query);
  }

  public async eliminar(estado: IEnvioVehiculo) {
    const res = await this.dialogService.confirm(
      'Confirme la Acción',
      `¿Desea eliminar el estado?`,
    );
    if (!res) return;
    try {
      await this.service.delete(estado._id!);
      this.helper.notifSuccess('estado eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const cols: IColumnas<IEnvioVehiculo>[] = [
      {
        header: {
          label: 'Fecha de Creación',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: {
          label: 'Fecha de Finalizacion',
          sortable: true,
        },
        row: {
          field: 'fechaFinalizacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
          noData: 'Sin Fecha de Finalizacion',
        },
      },
      {
        header: {
          label: 'Asignado Por',
          sortable: true,
        },
        row: {
          parse(dato) {
            return (
              dato?.usuario?.datosPersonales?.nombre || dato.usuario.usuario
            );
          },
          noData: 'Sin Asignador',
        },
      },
      {
        header: {
          label: 'Conductor Asignado',
          sortable: true,
        },
        row: {
          parse(dato) {
            return (
              dato?.conductor?.datosPersonales?.nombre || dato.conductor.usuario
            );
          },
          noData: 'Sin Conductor',
        },
      },
      {
        header: {
          label: 'Vehículo',
          sortable: true,
        },
        row: {
          parse(dato) {
            return dato?.activo?.identificacion;
          },
          noData: 'Sin Vehículo',
        },
      },
    ];

    this.columnas = cols;
  }

  // Hooks

  async ngOnInit() {
    console.log(this.vehiculosFilter);

    this.setColumnas();
    Promise.all([this.listar()]);
  }

  async ngOnDestroy() {
    this.EnviosVehiculos$?.unsubscribe();
    this.vehiculos$?.unsubscribe();
    this.usuarios$?.unsubscribe();
  }
}
