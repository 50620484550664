<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>
  @if (this.form) {
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
        <div style="margin-bottom: 1em">
          <!-- Permanente -->
          <mat-label>Permanente</mat-label>
          <mat-checkbox formControlName="permanente"> </mat-checkbox>
        </div>

        @if (!form?.value?.permanente) {
          <!-- Desde -->
          <mat-form-field>
            <mat-label>Desde</mat-label>
            <input
              matInput
              [owlDateTime]="dt1"
              formControlName="vigenciaDesde"
              [disabled]="true"
            />
            <button
              mat-icon-button
              matSuffix
              [owlDateTimeTrigger]="dt1"
              type="button"
            >
              <mat-icon>schedule</mat-icon>
            </button>
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
          </mat-form-field>
          <mat-form-field>
            <!-- Hasta -->
            <mat-label>Hasta</mat-label>
            <input
              matInput
              [owlDateTime]="dt2"
              formControlName="vigenciaHasta"
              [disabled]="true"
            />
            <button
              mat-icon-button
              matSuffix
              [owlDateTimeTrigger]="dt2"
              type="button"
            >
              <mat-icon>schedule</mat-icon>
            </button>
            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
          </mat-form-field>
        }

        @if (form?.value?.tipo === "Contacto") {
          <div [formGroup]="informacion">
            <!--<h3>CONTACTO</h3>-->

            <mat-form-field>
              <mat-label>Nombre</mat-label>
              <input matInput formControlName="contacto" />
            </mat-form-field>

            <!-- Telefono-->
            <mat-form-field>
              <mat-label>Telefono</mat-label>
              <input matInput formControlName="telefono" />
            </mat-form-field>

            <!--email-->
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" />
            </mat-form-field>
            <!-- Numero de Usuario-->
            <mat-form-field>
              <mat-label>Numero de Usuario</mat-label>
              <input
                matInput
                type="number"
                formControlName="numeroUsuarioAlarma"
              />
            </mat-form-field>
            <!-- palabra Seguridad Normal-->
            <mat-form-field>
              <mat-label>Palabra de Seguridad</mat-label>
              <input matInput formControlName="palabraSeguridadNormal" />
            </mat-form-field>

            <!-- palabraSeguridadEmergencia-->
            <mat-form-field>
              <mat-label>Palabra de Seguridad de Emergencia</mat-label>
              <input matInput formControlName="palabraSeguridadEmergencia" />
            </mat-form-field>
          </div>
        }
        @if (form?.value?.tipo === "Nota" || form?.value?.tipo === "Contacto") {
          <div [formGroup]="informacion">
            <!-- NOTA -->
            <mat-form-field>
              <mat-label>Nota</mat-label>
              <textarea
                matInput
                formControlName="nota"
                style="min-height: 200px"
              ></textarea>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Orden de prioridad</mat-label>
              <input matInput formControlName="orden" type="number" />
            </mat-form-field>
          </div>
        }
      </mat-card-content>
      <!-- Botones -->
      <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="this.dialog.closeAll()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading"
          color="accent"
          text="Guardar"
          icon="save"
          (click)="onSubmit()"
        ></app-button>
      </mat-card-actions>
    </form>
  }
</mat-card>
