/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  standalone: false,
})
export class DateRangeComponent {
  title: string;
  message: string;
  btnOkText: string;
  btnCancelText: string;
  start: string;
  end: string;

  constructor(
    public matDialogRef: MatDialogRef<DateRangeComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    const startDefault = new Date();
    startDefault.setDate(startDefault.getDate() - 1);

    this.title = this.data.title;
    this.message = this.data.message;
    this.btnOkText = this.data.btnOkText || 'Si';
    this.btnCancelText = this.data.btnCancelText || 'No';
    this.start = this.data.start || startDefault.toISOString();
    this.end = this.data.end || new Date().toISOString();
  }
}
