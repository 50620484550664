import { CommonModule } from '@angular/common';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { IQueryParam, IListado, ICliente } from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../auxiliares/servicios';
import { CodigosDispositivosService } from '../../../auxiliares/servicios/http/codigos-dispositivos.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { LoginService } from '../../login/login.service';
import { LoadingService } from '../../../auxiliares/servicios/loading.service';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-clonador',
  templateUrl: './clonador.component.html',
  styleUrls: ['./clonador.component.scss'],
})
export class ClonadorComponent implements OnInit, OnDestroy {
  title: string;
  message: string;
  btnOkText: string;
  btnCancelText: string;

  public clientes: ICliente[];
  public cliente: string;
  private clientes$: Subscription;

  constructor(
    public matDialogRef: MatDialogRef<ClonadorComponent>,
    private listados: ListadosService,
    private codigosService: CodigosDispositivosService,
    private helper: HelperService,
    public loading: LoadingService,
    @Inject(MAT_DIALOG_DATA)
    private data: { idEntidad: string; entidad: string },
    /// entidades: 'codigoDispositivo' |
  ) {
    this.title;
    this.btnOkText = 'Guardar';
    this.btnCancelText = 'Cerrar';
  }

  public async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      includeChildren: true,
      limit: 0,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos.filter(
          (c) => c._id !== LoginService.getCliente()._id,
        );
      });
    await this.listados.getLastValue('clientes', query);
  }

  public async clonar() {
    if (this.data.entidad === 'codigoDispositivo' && this.cliente) {
      this.loading.setLoading(true);
      await this.codigosService.clonar(this.data.idEntidad, this.cliente);
    }
    this.loading.setLoading(false);
    this.matDialogRef.close();
  }

  async ngOnInit() {
    if (this.data.entidad === 'codigoDispositivo') {
      this.title = 'Clonar codigos';
    }
    await this.listarClientes();
  }

  async ngOnDestroy() {
    this.clientes$?.unsubscribe();
  }
}
