import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IActivo,
  IDispositivoAlarma,
  IEventoTecnico,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  ITracker,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { CommonModule, DatePipe } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { IColumnas } from '../../../auxiliares/tabla/tabla.component';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../auxiliares/servicios';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import {
  IRegExpSearch,
  IFiltroTabla,
} from '../../../auxiliares/tabla/filtro/filtro.component';
import { EventosTecnicosService } from '../../entidades/administracion/eventos-tecnicos/eventos-tecnicos.service';
import {
  CrearEditarEventosTecnicosComponent,
  ICEETData,
} from '../../entidades/administracion/eventos-tecnicos/crear-editar-eventos-tecnicos/crear-editar-eventos-tecnicos.component';
import { MatDialog } from '@angular/material/dialog';
import { AsignarComponent } from '../../entidades/administracion/eventos-tecnicos/asignar/asignar.component';

@Component({
  selector: 'app-eventos-tecnicos',
  templateUrl: './eventos-tecnicos.component.html',
  styleUrl: './eventos-tecnicos.component.scss',
  imports: [CommonModule, AuxiliaresModule],
})
export class EventosTecnicosComponent implements OnInit, OnDestroy {
  @Input() public alarma?: IDispositivoAlarma;
  @Input() public tracker?: ITracker;
  @Input() public activo?: IActivo;
  //// Tabla nueva
  public name = EventosTecnicosComponent.name;
  public datos: IEventoTecnico[] = [];
  public datos$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IEventoTecnico>[];

  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];

  public filter: IFilter<IEventoTecnico> = {};
  public populate: IPopulate[] = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'activo',
      select: 'identificacion',
    },
    {
      path: 'alarma',
      select: 'nombre',
    },
    {
      path: 'tracker',
      select: 'identificacion',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: '-fechaCreacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
  };

  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private dialogService: DialogService,
    private service: EventosTecnicosService,
    private dialog: MatDialog,
  ) {}

  public async listar(): Promise<void> {
    const filter = { ...this.filter };
    if (this.alarma) {
      filter.idAlarma = this.alarma._id;
    }
    if (this.tracker) {
      filter.idTracker = this.tracker._id;
    }
    if (this.activo) {
      filter.idActivo = this.activo._id;
    }
    this.query.filter = JSON.stringify(filter);
    this.datos$?.unsubscribe();
    this.datos$ = this.listados
      .subscribe<IListado<IEventoTecnico>>('eventoTecnicos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de eventoTecnicos`, data);
      });
    await this.listados.getLastValue('eventoTecnicos', this.query);
  }

  // Acciones
  public async editar(
    dato?: IEventoTecnico,
    alarma?: IDispositivoAlarma,
    tracker?: ITracker,
    activo?: IActivo,
  ) {
    if (!alarma && !tracker && !activo && !dato) {
      this.helper.notifError('No se puede editar el evento');
    }
    const categoria = alarma ? 'Alarma' : 'Tracker';
    const data: ICEETData = {
      id: dato?._id || null, // Evento nuevo
      categoria,
      idAlarma: alarma?._id || null,
      idTracker: tracker?._id || null,
      idActivo: activo?._id || null,
      idsCQAET:
        alarma?.idsClientesQuePuedenAtenderEventosTecnicos ||
        activo?.idsClientesQuePuedenAtenderEventosTecnicos ||
        tracker?.idsClientesQuePuedenAtenderEventosTecnicos ||
        null,
    };
    this.dialog.open(CrearEditarEventosTecnicosComponent, {
      data,
      width: '850px',
    });
  }

  public async asignar(evento: IEventoTecnico) {
    const data = {
      id: evento._id,
      idTecnico: evento.idTecnicoAsignado,
    };

    this.dialog.open(AsignarComponent, {
      data,
      width: '850px',
    });
  }

  public async eliminar(dato: IEventoTecnico) {
    const res = await this.dialogService.confirm(
      'Eliminar Servicio Técnico',
      `¿Desea eliminar el evento?`,
    );
    if (!res) return;
    try {
      await this.service.delete(dato._id!);
      this.helper.notifSuccess('Evento eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IEventoTecnico>[] = [
      // Fecha
      {
        header: {
          label: 'Fecha',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      // Estado
      {
        header: {
          label: 'Estado',
          sortable: true,
        },
        row: {
          field: 'estado',
        },
      },
      // Título
      {
        header: {
          label: 'Título',
          sortable: true,
        },
        row: {
          field: 'titulo',
          noData: 'Sin Título',
        },
      },
      // Categoría
      {
        header: {
          label: 'Categoría',
          sortable: true,
        },
        row: {
          field: 'categoria',
        },
      },
      // Tracker o Alarma o Activo
      {
        header: {
          label: 'Elemento',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato?.tracker) {
              return dato?.tracker?.identificacion;
            }
            if (dato?.alarma) {
              return dato?.alarma?.nombre;
            }
            if (dato.activo) {
              return dato?.activo?.identificacion;
            }
            return undefined;
          },
          noData: 'Sin Elemento',
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
            sortable: true,
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'engineering',
            tooltip: 'Asignar',
            click: (dato) => this.asignar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.datos$?.unsubscribe();
  }
}
