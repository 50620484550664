<div class="isla-contenedor magico-100">
  <div (click)="close()" class="isla-close">
    <mat-icon style="transform: scale(0.7, 0.7)" color="warn">close</mat-icon>
  </div>
  @if (reporte) {
    <!-- Loading -->
    @if (this.loading.getLoading()) {
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    }
    <!-- Identificacion -->

    <span
      class="isla-titulo"
      style="cursor: pointer"
      [routerLink]="['../activos/detalles', reporte?.activo?._id]"
    >
      <span>🧭</span>
      {{ reporte?.activo?.identificacion}}
    </span>

    <!-- Cliente -->
    @if (cliente?.tipoCliente != "Final") {
      <div class="isla-dato">
        <div>Cliente:</div>
        <div>
          {{ reporte?.cliente.nombre }}
        </div>
      </div>
    }
    <!-- grupo -->
    @if (reporte?.grupo?.nombre) {
      <div class="isla-dato">
        <div>Grupo:</div>
        <div>{{ reporte?.grupo?.nombre }}</div>
      </div>
    } @else {
      <div class="isla-dato-no-info">
        <span class="no-info">Sin grupo</span>
      </div>
    }
    <!-- Fecha  -->
    @if (reporte?.fechaCreacion) {
      <div class="isla-dato">
        <div>Último Reporte:</div>
        <div>{{ reporte?.fechaCreacion | date: "short" }}</div>
      </div>
    } @else {
      <div class="isla-dato-no-info">
        <span class="no-info">Sin Fecha</span>
      </div>
    }
    <!-- Bateria  -->
    @if (reporte?.bateria) {
      <div class="isla-dato">
        <div>Bateria:</div>
        <div>
          {{ reporte?.bateria }}
          %
        </div>
      </div>
    } @else {
      <div class="isla-dato-no-info">
        <span class="no-info">Sin Bateria Reportada</span>
      </div>
    }

       <!-- Editar -->
       <div style="display: flex; flex-direction: row; gap: 1em">
        <div class="isla-editar">
          <!-- Editar Activo -->
          <app-button
                matType="mat-fab extended"
                color="primary"
                text="Editar"
                icon="edit"
                (click)="editar(reporte.activo)"
              ></app-button>
        </div>
    
        <!-- Detalles -->
         <div class="isla-detalles">
          <app-button
          matType="mat-fab extended"
          color="primary"
          text="Detalles"
          icon="info"
          [routerLink]="[
            '../activos/detalles',
            reporte?.activo?._id
          ]"
        ></app-button>
         </div>
         </div>
  
  }
</div>
