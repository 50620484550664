<div class="isla-contenedor magico-100">
  <div (click)="close()" class="isla-close">
    <mat-icon style="transform: scale(0.7, 0.7)" color="warn">close</mat-icon>
  </div>
  @if (reporte?.activo) {
    <!-- Loading -->
    @if (this.loading.getLoading()) {
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    }
    <!-- Identificacion -->
    <span
      class="isla-titulo"
      style="cursor: pointer"
      [routerLink]="['../vehiculos/detalles', reporte?.activo?._id]"
    >
      <span>🚐</span>
      {{ reporte?.activo?.identificacion }}
    </span>

    <!-- Vehiculo -->
    @if (reporte?.activo?.categoria === "Vehículo") {
      <div class="isla-dato">
        <!-- Tipo -->
        @if (reporte?.activo.vehiculo?.tipo) {
          <div>Tipo:</div>
          <div>
            {{ reporte?.activo?.vehiculo?.tipo }}
          </div>
        }
        <!-- Patente -->
        @if (reporte?.activo.vehiculo?.patente) {
          <div>Patente:</div>
          <div>
            {{ reporte?.activo.vehiculo?.patente }}
          </div>
        }
        <div>
          <button
            mat-icon-button
            color="primary"
            (click)="reporteVer()"
            [value]="botonSeguir"
            style="transform: scale(0.7)"
          >
            <mat-icon color="accent" s>search</mat-icon>
          </button>
        </div>

        <div>
          <button
            mat-icon-button
            color="primary"
            (click)="seguir()"
            [style]="color()"
          >
            <mat-icon color="accent">play_for_work</mat-icon>
          </button>
        </div>
      </div>
    }

    <!-- Editar -->
    <div style="display: flex; flex-direction: row; gap: 1em">
      <div class="isla-editar">
        <!-- Editar Activo -->
        <app-button
              matType="mat-fab extended"
              color="primary"
              text="Editar"
              icon="edit"

              (click)="editar(reporte.activo)"
            ></app-button>
      </div>
  
      <!-- Detalles -->
       <div class="isla-detalles">
        <app-button
        matType="mat-fab extended"
        color="primary"
        text="Detalles"
        icon="info"
        [routerLink]="[
          '../vehiculos/detalles',
          reporte.activo?._id
        ]"
      ></app-button>
       </div>
       </div>

  }
</div>
