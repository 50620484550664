import { Injectable } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { LineString } from 'ol/geom';
import { getLength } from 'ol/sphere';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, transform } from 'ol/proj';
import { OSM, Vector } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style, { StyleLike } from 'ol/style/Style';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import { MouseWheelZoom, Select } from 'ol/interaction';
import { defaults as defaultInteractions } from 'ol/interaction';
import CircleStyle from 'ol/style/Circle';
import { ICoordenadas, IGeoJSONPolygon } from 'modelos/src';
import { FlatStyleLike } from 'ol/style/flat';

@Injectable({ providedIn: 'root' })
export class OpenLayersService {
  public static mapTile() {
    return new TileLayer({
      source: new OSM(),
    });
  }

  public static mapTileSatelite() {
    return new TileLayer({
      source: new OSM({
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        attributions: '',
        maxZoom: 15,
      }),
    });
  }

  public static drawVectorLayer() {
    return new VectorLayer({
      source: new VectorSource({ wrapX: false }),
      style: new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      }),
    });
  }

  public static styles: { [tipo: string]: StyleLike | FlatStyleLike } = {
    Point: {
      'circle-radius': 5,
      'circle-fill-color': 'red',
    },
    LineString: {
      'circle-radius': 4,
      'circle-fill-color': 'red',
      'stroke-color': 'black',
      'stroke-width': 3,
    },
    Polygon: {
      'circle-radius': 5,
      'circle-fill-color': 'red',
      'stroke-color': 'yellow',
      'stroke-width': 2,
      'fill-color': 'rgba(0, 0, 255, 0.1)',
    },
    Circle: {
      'circle-radius': 5,
      'circle-fill-color': 'red',
      'stroke-color': 'blue',
      'stroke-width': 2,
      'fill-color': 'yellow',
    },
  };

  public static vehiculosVectorLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/map/bus.png',
          height: 40,
          width: 40,
        }),
      }),
    });
  }

  public static vehiculosNormalesVectorLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchor: [0.47, 0.53],
          src: 'assets/map/vehiculo.png',
          height: 18,
          width: 18,
        }),
      }),
    });
  }

  public static trackersVectorLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/map/tracker.png',
          height: 20,
          width: 20,
        }),
      }),
    });
  }

  public static vehiculosCompassVectorLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/maps/navigation.png',
          height: 40,
          width: 40,
          rotation: 0,
        }),
      }),
    });
  }

  public static ubicacionVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'assets/pins/pin-blanco.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static alarmaCasaVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'assets/pins/pin-alarma-casa-piola.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static pinsVectorlayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'assets/pins/pin.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static smallUbicacionVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'assets/pins/pin-blanco-activo.png',
          height: 25,
          width: 24,
        }),
      }),
    });
  }
  public static smallUbicacionAgrupadoVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'assets/pins/pin-blanco-activo2.png',
          height: 25,
          width: 24,
        }),
      }),
    });
  }

  public static activoVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'assets/pins/pin-activo.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static paradasVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'assets/pins/parada.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static paradasTextVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        text: new Text({
          fill: new Fill({ color: 'black' }),
        }),
      }),
    });
  }

  public static polylineVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 4,
        }),
      }),
    });
  }

  public static paradasChicasVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'assets/pins/parada.png',
          height: 15,
          width: 15,
        }),
      }),
    });
  }

  public static circleVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(0, 255, 76, 0.1)',
        }),
        stroke: new Stroke({
          color: 'green',
          width: 3,
        }),
      }),
    });
  }

  public static polygonsVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
        stroke: new Stroke({
          color: 'blue',
          width: 3,
        }),
      }),
    });
  }

  //// TRIPS ////

  public static stylesTrip = {
    route: new Style({
      stroke: new Stroke({
        width: 6,
        color: [237, 212, 0, 0.8],
        lineDash: [4, 8],
      }),
    }),
    start: new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: 'assets/pins/start.png',
        width: 30,
        height: 30,
      }),
    }),
    end: new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: 'assets/pins/end.png',
        width: 30,
        height: 30,
      }),
    }),
    geoMarker: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({
          color: 'white',
          width: 2,
        }),
      }),
    }),
    // geoMarker: new Style({
    //   image: new Icon({
    //     anchor: [0.5, 1],
    //     src: 'assets/pins/vehiculo.png',
    //     width: 30,
    //     height: 30,
    //   }),
    // }),
  };

  public static geoMarkerLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/vehiculo.png',
          height: 40,
          width: 40,
        }),
      }),
    });
  }

  public static startTripLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/start.png',
          height: 40,
          width: 40,
        }),
      }),
    });
  }

  public static endTripLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/end.png',
          height: 40,
          width: 40,
        }),
      }),
    });
  }

  public static paradasSelectEvent() {
    return new Select({
      style: OpenLayersService.paradasSelectedStyle(),
      filter: (feature) => {
        return feature.get('parada') === true;
      },
    });
  }

  public static paradasSelectedStyle() {
    return new Style({
      image: new Icon({
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        anchor: [0.5, 1],
        src: 'assets/pins/pin-azul.png',
        height: 40,
        width: 40,
      }),
    });
  }

  public static wheelInteraction() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const f = (e: any) => {
      if (e.type === 'wheel' && e.originalEvent.ctrlKey) {
        return true;
      }
      return false;
    };

    const mouseWheelOptions = new MouseWheelZoom({
      condition: f,
    });

    return mouseWheelOptions;
  }

  public static interactions() {
    return defaultInteractions({
      mouseWheelZoom: false,
      doubleClickZoom: false,
    }).extend([OpenLayersService.wheelInteraction()]);
  }

  constructor() {}

  public static async getCurrentPosition(): Promise<Coordinate> {
    return new Promise((resolve) => {
      const ubicacionBase = OpenLayersService.lonLatToCoordinate(
        -58.0128784,
        -35.5836812,
      );
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion = OpenLayersService.lonLatToCoordinate(
              position.coords.longitude,
              position.coords.latitude,
            );
            resolve(ubicacion);
          },
          () => {
            console.error('Ubicacion no aceptada');
            resolve(ubicacionBase);
          },
          {
            timeout: 5000,
            enableHighAccuracy: true,
          },
        );
      } else {
        resolve(ubicacionBase);
      }
    });
  }

  public static async getCurrentPositionGeoJSON(): Promise<Coordinate> {
    return new Promise((resolve) => {
      const ubicacionBase = [-58.0128784, -35.5836812];
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion = [
              position.coords.longitude,
              position.coords.latitude,
            ];
            resolve(ubicacion);
          },
          () => {
            console.error('Ubicacion no aceptada');
            resolve(ubicacionBase);
          },
          {
            timeout: 5000,
            enableHighAccuracy: true,
          },
        );
      } else {
        resolve(ubicacionBase);
      }
    });
  }

  public static lonLatToCoordinate(lon: number, lat: number): Coordinate {
    const coord = fromLonLat([lon, lat]);
    return coord;
  }

  public static distanceBetweenPoints(p1: Coordinate, p2: Coordinate): number {
    return getLength(new LineString([p1, p2]));
  }

  public static leastDistanceFromLineString(c: Coordinate, line: Coordinate[]) {
    const ls = new LineString(line);
    const cp = ls.getClosestPoint(c);
    const distance = OpenLayersService.distanceBetweenPoints(c, cp);
    return distance;
  }

  public static cordinateToGeoJSONPoint(coord: Coordinate): {
    type: 'Point';
    coordinates: [number, number];
  } {
    coord = transform(coord, 'EPSG:3857', 'EPSG:4326');
    return {
      type: 'Point',
      coordinates: [coord[0], coord[1]],
    };
  }

  public static cordinatesToGeoJSONLineString(coords: Coordinate[]): {
    type: 'LineString';
    coordinates: [number, number][];
  } {
    const coordinates: [number, number][] = coords.map((coord) => {
      coord = transform(coord, 'EPSG:3857', 'EPSG:4326');
      return [coord[0], coord[1]];
    });
    return {
      type: 'LineString',
      coordinates,
    };
  }

  public static cordinatesToGeoJSONPolygon(
    coords: Coordinate[],
  ): IGeoJSONPolygon {
    const coordinates: [number, number][] = coords.map((coord) => {
      coord = transform(coord, 'EPSG:3857', 'EPSG:4326');
      return [coord[0], coord[1]];
    });

    return {
      type: 'Polygon',
      coordinates: [coordinates],
    };
  }

  public static polylineToCoordinates(
    polyline: [number, number][],
  ): Coordinate[] {
    return polyline.map((p) => fromLonLat(p));
  }

  public static polygonToCoordinates(
    polygon: [[number, number][]],
  ): Coordinate[][] {
    return polygon.map((p) => p.map((c) => fromLonLat(c)));
  }

  public static coordinateToCoordenada(c?: Coordinate): ICoordenadas | null {
    if (!c) return null;
    const coord = transform(c, 'EPSG:3857', 'EPSG:4326');
    return { lat: coord[1], lng: coord[0] };
  }
}
