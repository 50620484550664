/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuPanel } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
  ],
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() matType?: 'mat-fab' | 'mat-mini-fab' | 'mat-fab extended';
  @Input() type?: 'button' | 'submit' = 'button';
  @Input() matMenuTriggerFor: MatMenuPanel<any> | null = null;
  @Input() icon: string | null = null;
  @Input() text: string | null = null;
  @Input() ngClass: string | null = null;
  @Input() color?: 'primary' | 'accent' | 'warn';
  @Input() style: string = '';
  @Input() matTooltip: string = '';
  @Input() disabled: boolean = false;
  constructor() {}
}
