import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { DialogService } from './dialog.service';
import { PromptComponent } from './prompt/prompt.component';
import { FormsModule } from '@angular/forms';
import { DialogComponent } from './dialog/dialog.component';
import { MaterialModule } from '../material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ButtonComponent } from '../button/button.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { DateRangeSelectorComponent } from '../date-range-selector/date-range-selector.component';
import { DatetimePickerComponent } from '../datetime-picker/datetime-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    DragDropModule,
    ButtonComponent,
    DateRangeSelectorComponent,
    DatetimePickerComponent,
  ],
  declarations: [
    ConfirmationComponent,
    PromptComponent,
    DialogComponent,
    DateRangeComponent,
  ],
  providers: [DialogService],
})
export class DialogModule {}
