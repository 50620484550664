import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
  standalone: false,
})
export class PromptComponent {
  title: string;
  message: string;
  inputName: string;
  btnOkText: string;
  btnCancelText: string;
  type: 'text' | 'datetime';
  input?: string;

  public aceptar() {
    if (this.type === 'datetime') {
      this.matDialogRef.close(this.input);
    }
    if (this.type === 'text') {
      this.matDialogRef.close(this.input?.trim());
    }
  }

  constructor(
    public matDialogRef: MatDialogRef<PromptComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      title: string;
      message: string;
      inputName: string;
      btnOkText: string;
      btnCancelText: string;
      type: 'text' | 'datetime';
    },
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.inputName = this.data.inputName;
    this.btnOkText = this.data.btnOkText || 'Si';
    this.btnCancelText = this.data.btnCancelText || 'No';
    this.type = this.data.type || 'text';
  }
}
