import { Component, OnDestroy, OnInit } from '@angular/core';
import { IApikey, IFilter, IListado, IQueryParam } from 'modelos/src';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { ApikeysService } from '../apikeys.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { IRegExpSearch } from '../../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';

@Component({
  selector: 'app-apikeys',
  templateUrl: './apikeys.component.html',
  styleUrl: './apikeys.component.scss',
  standalone: false,
})
export class ApikeysComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ApikeysComponent.name;
  public apikeys: IApikey[] = [];
  public apikeys$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IApikey>[];
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Apikey',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['identificacion'],
  };

  public filter: IFilter<IApikey> = {};
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    sort: 'identificacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ApikeysService,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    this.apikeys$?.unsubscribe();
    this.apikeys$ = this.listados
      .subscribe<IListado<IApikey>>('apikeys', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.apikeys = data.datos;
        console.log(`listado de apikeys`, data);
      });
    await this.listados.getLastValue('apikeys', this.query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(apikey: IApikey) {
    this.navigator.navigate(['editar', apikey._id], {
      relativeTo: this.route,
    });
  }

  public async eliminar(apikey: IApikey) {
    const res = await this.dialogService.confirm(
      'Eliminar Apikey',
      `¿Desea eliminar el apikey ${apikey.key}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(apikey._id!);
      this.helper.notifSuccess('Apikey eliminada');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Identificación',
        },
        row: {
          field: 'identificacion',
        },
      },
      {
        header: {
          label: 'Global',
        },
        row: {
          // html(dato) {
          //   return dato.global
          //     ? `<span class="affimative">
          //   <mat-icon>done</mat-icon>
          // </span>`
          //     : `<span class="negative">
          //     <mat-icon>close</mat-icon>
          //   </span>`;
          // },

          parse(dato) {
            return dato.global ? '✔️' : '❌';
          },
        },
      },
      {
        header: {
          label: 'Llave',
        },
        row: {
          field: 'key',
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.apikeys$?.unsubscribe();
  }
}
