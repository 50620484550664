import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IActivo,
  IFilter,
  IListado,
  IQueryParam,
  IUpdateActivo,
  IUsuario,
} from 'modelos/src';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { ActivosService } from '../../../../auxiliares/servicios/http/activos.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-asignar-vehiculo-chofer',
  templateUrl: './asignar-vehiculo-chofer.component.html',
  styleUrl: './asignar-vehiculo-chofer.component.scss',
  standalone: true,
})
export class AsignarVehiculoChoferComponent implements OnInit {
  public vehiculoInicialID: string = '';
  private vehiculoInicial: IActivo = {};
  public vehiculos: IActivo[] = [];
  public vehiculos$?: Subscription;
  public totalCount = 0;
  public form?: FormGroup;
  //public vehiculos$?: Subscription;
  public vehiculo: IActivo = {};

  public filter: IFilter<IActivo> = {};

  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    sort: 'nombre',
    page: 0,
    includeChildren: false,
    childrenLevel: 0,
  };

  public filterVehiculos: IFilter<IActivo> = {};
  public queryVehiculos: IQueryParam = {
    filter: JSON.stringify(this.filterVehiculos),
    page: 0,
    includeChildren: false,
    childrenLevel: 0,
  };
  public usuario: IUsuario = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public param: { usuario: IUsuario; tipo: string },
    private listados: ListadosService,
    private helper: HelperService,
    private activosService: ActivosService,
    private dialogRef: MatDialogRef<AsignarVehiculoChoferComponent>,
  ) {}
  public vehiculoActual() {
    const vehiculo = this.vehiculos.find(
      (v) => v.vehiculo.idChofer === this.usuario._id,
    );
    if (vehiculo) this.vehiculo = vehiculo;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getData(): IUpdateActivo {
    const data: IUpdateActivo = this.form?.value;
    return data;
  }

  public async asignarVehiculo() {
    try {
      const v = this.vehiculo.vehiculo;
      v.idChofer = this.param.usuario._id;
      await this.activosService.update(this.vehiculo._id, {
        vehiculo: v,
      } as IUpdateActivo);
      this.helper.notifSuccess('Conductor Asignado');
    } catch {
      this.helper.notifError('Error al Asignar');
    }
    this.close();
  }

  public async listarVehiculos(): Promise<void> {
    // Si es asignar colectivo
    if (this.param.tipo === 'Colectivos') {
      this.vehiculos$?.unsubscribe();
      this.vehiculos$ = this.listados
        .subscribe<IListado<IActivo>>('colectivos', this.queryVehiculos)
        .subscribe((data) => {
          this.vehiculos = data.datos;
          console.log(`listado de colectivos`, data);
        });
      await this.listados.getLastValue('colectivos', this.queryVehiculos);
    } else {
      // Si no es asignar vehiculo
      this.vehiculos$?.unsubscribe();
      this.vehiculos$ = this.listados
        .subscribe<IListado<IActivo>>('vehiculos', this.queryVehiculos)
        .subscribe((data) => {
          this.vehiculos = data.datos;
          console.log(`listado de vehiculos`, data);
        });
      await this.listados.getLastValue('vehiculos', this.queryVehiculos);
    }
  }

  async ngOnInit() {
    this.usuario = this.param.usuario;
    await this.listarVehiculos();
    this.vehiculoActual();
  }

  async ngOnDestoy() {
    this.vehiculos$.unsubscribe();
  }
}
