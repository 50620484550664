import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { AuxiliaresModule } from '../../../../../auxiliares/auxiliares.module';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { IFilter, IListado, IQueryParam, IUsuario } from 'modelos/src';
import { ListadosService } from '../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { EventosTecnicosService } from '../eventos-tecnicos.service';
import { CrearTecnicoRapidoComponent } from '../../tecnicos/crear-tecnico-rapido/crear-tecnico-rapido.component';

@Component({
  selector: 'app-asignar',
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './asignar.component.html',
  styleUrl: './asignar.component.scss',
})
export class AsignarComponent implements OnInit, OnDestroy {
  public loading = signal(false);
  // Component
  @Input() public id?: string;
  @Input() public idTecnico?: string;
  // Dialog
  public data = inject(MAT_DIALOG_DATA) as {
    id?: string;
    idTecnico?: string;
  };

  /// Data
  public tecnicos: IUsuario[] = [];
  public idTecnicoSeleccionado?: string;
  public tecnico?: IUsuario;
  public tecnicos$?: Subscription;
  constructor(
    public matDialogRef: MatDialogRef<AsignarComponent>,
    private listados: ListadosService,
    private dialogService: DialogService,
    private service: EventosTecnicosService,
    private helper: HelperService,
    private dialog: MatDialog,
  ) {}

  public async asignar() {
    if (!this.id && !this.data) {
      return;
    }
    let sentence;
    if (!this.tecnico) {
      sentence = `¿Está seguro de desasignar el servicio técnico?`;
    } else {
      sentence = `¿Está seguro de asignar el Servicio Técnico a ${this.tecnico.datosPersonales?.nombre || this.tecnico.usuario}?`;
    }
    const res = await this.dialogService.confirm('Asignación', sentence);
    if (!res) {
      return;
    }
    try {
      this.loading.set(true);
      const id = this.id || this.data.id;
      const idTecnico = this.idTecnicoSeleccionado || null;
      await this.service.asignar(id, idTecnico);
      this.helper.notifSuccess('Servicio Técnico asignado correctamente');
      this.cerrar();
    } catch (error) {
      this.helper.notifError(error);
      console.error(error);
    } finally {
      this.loading.set(false);
    }
  }

  private cerrar() {
    this.matDialogRef.close();
  }

  public async crear() {
    this.dialog.open(CrearTecnicoRapidoComponent, {});
  }

  private async listar(): Promise<void> {
    const filter: IFilter<IUsuario> = {
      roles: 'Técnico',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'datosPersonales.nombre',
      limit: 0,
      select: 'datosPersonales usuario',
    };
    this.tecnicos$?.unsubscribe();
    this.tecnicos$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.tecnicos = data.datos;
        console.log(`listado de tecnicos`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }

  public onChange(tecnico: IUsuario) {
    this.tecnico = tecnico;
    this.idTecnicoSeleccionado = tecnico?._id;
  }

  public onClear() {
    this.tecnico = undefined;
    this.idTecnicoSeleccionado = undefined;
  }

  //// HOOKS

  async ngOnInit() {
    await this.listar();
  }

  ngOnDestroy() {
    this.tecnicos$?.unsubscribe();
  }
}
