<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
</ng-container>

<mat-dialog-content style="min-height: 50px">
  <div [innerHTML]="message"></div>
  <ng-select
    [items]="clientes"
    [(ngModel)]="cliente"
    bindLabel="nombre"
    bindValue="_id"
    placeholder="Cliente Destino"
    [notFoundText]="'No hay clientes'"
    style="width: 100%"
    multiple="false"
    searchable="true"
    appendTo="body"
  ></ng-select>
</mat-dialog-content>

<br />

<mat-dialog-actions align="end" style="gap: 8px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="matDialogRef.close(false)"
    [disabled]="loading.getLoading()"
    [text]="btnCancelText"
  ></app-button>

  <app-button
    matType="mat-fab extended"
    color="accent"
    (click)="clonar()"
    [disabled]="loading.getLoading()"
    [text]="btnOkText"
  ></app-button>

  <mat-progress-bar
    color="accent"
    [mode]="loading.getLoading() ? 'indeterminate' : 'determinate'"
  >
  </mat-progress-bar>
</mat-dialog-actions>
