<mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
  <!-- Header -->
  <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
  </ng-container>
  @if (form) {
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card-content style="max-height: 60vh; overflow: auto">
        <!-- Tipo del Evento -->
        <ng-select
          [items]="tiposEventos"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idTipoEvento"
          placeholder="Tipo de Evento"
          [notFoundText]="'No hay tipo de eventos creados'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          appendTo="body"
          [addTag]="addTag.bind(this)"
          [loading]="creandoNuevoTag"
        >
        </ng-select>
        <!-- Codigos -->
        <ng-select
          [items]="codigosEvento"
          bindLabel="codigo"
          bindValue="codigo"
          formControlName="codigoReportado"
          placeholder="Códigos"
          [notFoundText]="'No hay Códigos'"
          style="width: 100%"
          multiple="false"
          [searchFn]="searchCodigo"
          searchable="true"
          appendTo="body"
          (change)="setCodigo($event)"
          required
        >
          <ng-template ng-label-tmp let-item="item">
            {{ item.codigo }} - {{ item.descripcion }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{ item.codigo }} - {{ item.descripcion }}
          </ng-template>
        </ng-select>
        @if (mostrarZona) {
          <div class="row-flex-start" [formGroup]="configZona">
            <!-- Parti -->
            <mat-form-field style="width: 49%; margin-right: 2%">
              <mat-label>Partición</mat-label>
              <input
                matInput
                formControlName="particion"
                type="number"
                required
              />
            </mat-form-field>
            <!-- Zona -->
            <mat-form-field style="width: 49%">
              <mat-label>Zona</mat-label>
              <input matInput formControlName="zona" type="number" required />
            </mat-form-field>
          </div>
        }

        <!-- Categoria -->
        <ng-select
          [items]="categoriasEvento"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idCategoriaEvento"
          placeholder="Categoría"
          [notFoundText]="'No hay categorías creadas'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          (change)="setCategoria($event)"
          appendTo="body"
          required
        >
        </ng-select>

        <!-- Mas opciones -->
        <div class="row-center">
          <div style="margin: 1em">
            <mat-label> Notificación Push</mat-label>
            <mat-checkbox formControlName="notificar"> </mat-checkbox>
          </div>
          <div style="margin: 1em">
            <mat-label> Requiere Atención</mat-label>
            <mat-checkbox formControlName="atender"> </mat-checkbox>
          </div>
          <div style="margin: 1em">
            <mat-label> No Derivar</mat-label>
            <mat-checkbox formControlName="noDerivar"> </mat-checkbox>
          </div>
        </div>
        @if (form?.value?.notificar) {
          <div>
            <mat-label>Notificar a los usuarios </mat-label>
            <!-- USUARIOS -->
            <ng-select
              [items]="usuarios"
              placeholder="Usuarios"
              loadingText="Cargando..."
              typeToSearchText="Buscar..."
              notFoundText="No se encontraron resultados"
              formControlName="idsUsuarios"
              [multiple]="true"
              [closeOnSelect]="false"
              [bindValue]="'_id'"
              [appendTo]="'body'"
              [searchFn]="searchUsuario"
              required
            >
              <ng-template ng-label-tmp let-item="item">
                <span>{{ item.usuario }} </span>
              </ng-template>

              <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
                <div style="padding-bottom: 5px">
                  <span>{{ item.usuario }} </span>
                  <span style="font-size: 0.7em">
                    ({{ item.datosPersonales?.telefono }})
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        }
        @if (form?.value?.atender) {
          <div>
            <!-- Clientes -->
            <app-clientes-hasta-selector
              [clienteDispositivo]="clienteDispositivo"
              [clientesIn]="form?.value?.idsClientesQuePuedenAtender"
              (clientesOut)="setIdsClientesQuePuedenAtender($event)"
            ></app-clientes-hasta-selector>
          </div>
        }
      </mat-card-content>
      <!-- Botones -->
      <mat-card-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="this.dialog.closeAll()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || this.loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-card-actions>
    </form>
  }
</mat-card>
