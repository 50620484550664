@if (!loading) {
  <div class="row-center">
    <span class="subtitle1">Información del activo</span>
  </div>
  <br />
  <div class="main-container">
    <!-- Información General -->
    <table class="tabla-activos">
      @if (activo?.grupo?.nombre) {
        <tr>
          <td class="tabla-label">Grupo:</td>
          <td>{{ activo?.grupo?.nombre }}</td>
        </tr>
      }
      @if (activo?.cliente?.nombre) {
        <tr>
          <td class="tabla-label">Cliente:</td>
          <td>{{ activo?.cliente?.nombre }}</td>
        </tr>
      }
      @if (activo?.categoria) {
        <tr>
          <td class="tabla-label">Categoria:</td>
          <td>{{ activo?.categoria }}</td>
        </tr>
      }
      

      <!-- ESTO ES NECESARIO? EN LA PARTE DE ACTIVOS SE MEUSTRAN VEHÍCULOS???? -->
      @if (activo?.categoria === "Vehículo") {
        @if (activo?.vehiculo?.tipo) {
          <tr>
            <td class="tabla-label">Tipo:</td>
            <td>{{ activo?.vehiculo?.tipo }}</td>
          </tr>
        }
        @if (activo?.vehiculo?.patente) {
          <tr>
            <td class="tabla-label">Patente:</td>
            <td>{{ activo?.vehiculo?.patente }}</td>
          </tr>
        }
        @if (activo?.vehiculo?.estado) {
          <tr>
            <td class="tabla-label">Estado:</td>
            <td>{{ activo?.vehiculo?.estado }}</td>
          </tr>
        }
        
      }
    </table>
    </div>

  @if (this.reporte) {
    <br />
    <div><strong>Última Ubicación Reportada</strong></div>
    <div class="map-container">
      <app-mapa-facil [data]="datoMapa" class="mapita"></app-mapa-facil>
    </div>
  }
}