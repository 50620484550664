import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { EventosRoutingModule } from './eventos.routing.module';
import { MapDrawComponent } from '../../../auxiliares/map-draw/map-draw.component';
import { MapaFacilComponent } from '../../../auxiliares/mapa-facil/mapa-facil.component';
import { MatMenu } from '@angular/material/menu';
import { EventosVivoComponent } from './listado-eventos-vivo/eventos-vivo.component';
import { AgregarNotaComponent } from './tratar-evento/agregar-nota/agregar-nota.component';
import { TratamientoComponent } from './tratar-evento/tratamiento/tratamiento.component';
import { EventosComponent } from './eventos/eventos.component';
import { EventosHistoricoComponent } from './listado-eventos-historico/eventos-historico.component';
import { PonerEnEsperaComponent } from './tratar-evento/poner-en-espera/poner-en-espera.component';
import { EventosEsperaComponent } from './listado-eventos-espera/eventos-espera.component';
import { EventoDetallesComponent } from './listado-eventos-historico/eventos-detalles/evento-detalles.component';
import { EventoDetallesEsperaComponent } from './listado-eventos-espera/eventos-detalles/evento-detalles.component';
import { StreamCamaraComponent } from '../../standalone/stream-camara/stream-camara.component';
import { CamarasMultipleComponent } from '../../standalone/camaras-multiple/camaras-multiple.component';
import { EnviarMovilComponent } from './tratar-evento/enviar-movil/enviar-movil.component';

@NgModule({
  declarations: [
    EventosVivoComponent,
    AgregarNotaComponent,
    TratamientoComponent,
    EventosComponent,
    EventosHistoricoComponent,
    AgregarNotaComponent,
    PonerEnEsperaComponent,
    EventoDetallesComponent,
    EventoDetallesEsperaComponent,
    EventosEsperaComponent,
    EnviarMovilComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    EventosRoutingModule,
    MapDrawComponent,
    MapaFacilComponent,
    MatMenu,
    StreamCamaraComponent,
    CamarasMultipleComponent,
  ],
})
export class EventosModule {}
