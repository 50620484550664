import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { ConfiguracionRoutingModule } from './routing';
import { ListadoCategoriasEventosComponent } from './categorias-eventos/listado-categorias-eventos/listado-categorias-eventos.component';
import { CrearEditarCategoriaEventoComponent } from './categorias-eventos/crear-editar-categoria-evento/crear-editar-categoria-evento.component';
import { ListadoTiposEventosComponent } from './tipos-eventos/listado-tipos-eventos/listado-tipos-eventos.component';
import { CrearEditarTipoEventoComponent } from './tipos-eventos/crear-editar-tipo-evento/crear-editar-tipo-evento.component';
import { AgregarCanalCamaraComponent } from '../alarmas/alarmas-domiciliarias/crear-editar-alarma-domiciliaria/agregar-canal-camara/agregar-canal-camara.component';

@NgModule({
  declarations: [
    ListadoCategoriasEventosComponent,
    CrearEditarCategoriaEventoComponent,
    ListadoTiposEventosComponent,
    CrearEditarTipoEventoComponent,
    AgregarCanalCamaraComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, ConfiguracionRoutingModule],
})
export class ConfiguracionModule {}
