import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICreateTipoEvento,
  IUpdateTipoEvento,
  ICategoriaEvento,
  SonidoEvento,
} from 'modelos/src';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { CategoriaEventosService } from '../../../../../auxiliares/servicios/http/categoria-eventos.service';

@Component({
  selector: 'app-crear-editar-categoria-evento',
  templateUrl: './crear-editar-categoria-evento.component.html',
  styleUrl: './crear-editar-categoria-evento.component.scss',
  standalone: false,
})
export class CrearEditarCategoriaEventoComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public sonidos: SonidoEvento[] = ['Silencio', 'Campana', 'Sirena'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICategoriaEvento,
    public dialogRef: MatDialogRef<CrearEditarCategoriaEventoComponent>,
    public helper: HelperService,
    private service: CategoriaEventosService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.data?.nombre, Validators.required),
      prioridad: new FormControl(this.data?.prioridad, Validators.required),
      color: new FormControl(this.data?.color, Validators.required),
      sonido: new FormControl(
        this.data?.sonido || 'Campana',
        Validators.required,
      ),
      atender: new FormControl(
        this.data?.atender || false,
        Validators.required,
      ),
      noDerivar: new FormControl(
        this.data?.noDerivar || false,
        Validators.required,
      ),
      notificar: new FormControl(
        this.data?.notificar || false,
        Validators.required,
      ),
    });
    if (this.data.default) {
      this.form.get('nombre').disable();
    }
  }

  public async onSubmit() {
    this.loading = true;
    let res = null;
    try {
      const data = this.getData();
      if (this.data?._id) {
        // Update
        await this.service.update(this.data._id, data);
        this.helper.notifSuccess('Código de Evento actualizado');
      } else {
        // Create
        res = await this.service.create(data);
        this.helper.notifSuccess('Código de Evento creado');
      }
      this.dialogRef.close(res?._id);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateTipoEvento | IUpdateTipoEvento {
    const data: ICreateTipoEvento | IUpdateTipoEvento = this.form?.value;
    return data;
  }

  async ngOnInit() {
    this.title = this.data?._id
      ? `Editar ${this.data.nombre}`
      : 'Crear categoría de evento';
    this.createForm();
  }
}
