import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IConfigReenvio,
  ICreateConfigReenvio,
  IUpdateConfigReenvio,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigReenviosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IConfigReenvio>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/configreenvios`, { params });
  }

  public getById(id: string): Promise<IConfigReenvio> {
    return this.http.get(`/configreenvios/${id}`);
  }

  public create(dato: ICreateConfigReenvio): Promise<IConfigReenvio> {
    return this.http.post(`/configreenvios`, dato);
  }

  public update(
    id: string,
    dato: IUpdateConfigReenvio,
  ): Promise<IConfigReenvio> {
    return this.http.put(`/configreenvios/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/configreenvios/${id}`);
  }
}
