<h2 mat-dialog-title>Detalles</h2>
<mat-dialog-content
  class="mat-typography column-center"
  style="margin: 2em 0; max-height: 65vh; overflow: auto"
>
  @if (data.nota) {
    <div>
      <span class="key">Nota</span>
      <br />
      <span class="value" style="margin-left: 3%">{{ data.nota }}</span>
    </div>
  }
  @if (data.contacto) {
    <div>
      <span class="key">Contacto:</span>
      <span class="value" style="margin-left: 3%">{{ data.contacto }}</span>
    </div>
    <div>
      <span class="key">Telefono:</span>
      <span class="value" style="margin-left: 3%">{{ data.telefono }}</span>
    </div>
    <div>
      <span class="key">Email:</span>
      <span class="value" style="margin-left: 3%">{{ data.email }}</span>
    </div>

    @if (data.numeroUsuarioAlarma) {
      <div>
        <span class="key">Numero de Usuario:</span>
        <span class="value" style="margin-left: 3%">{{
          data.numeroUsuarioAlarma
        }}</span>
      </div>
    }
    @if (data.palabraSeguridadNormal) {
      <div>
        <span class="key">Palabra de seguridad:</span>
        <span class="value" style="margin-left: 3%">{{
          data.palabraSeguridadNormal
        }}</span>
      </div>
    }
    @if (data.palabraSeguridadEmergencia) {
      <div>
        <span class="key">Palabra de seguridad de Emergencia:</span>
        <span class="value" style="margin-left: 3%">{{
          data.palabraSeguridadEmergencia
        }}</span>
      </div>
    }
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-fab extended mat-dialog-close color="accent">Volver</button>
</mat-dialog-actions>
