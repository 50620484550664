@if (!loading) {
  <div class="row-center">
    <span class="subtitle1">Información del vehículo</span>
  </div>
  <br />
  <div class="main-container">
    <!-- Información General -->
    <table class="tabla-vehiculos">
      @if (activo?.grupo?.nombre) {
        <tr>
          <td class="tabla-label">Flota:</td>
          <td>{{ activo?.grupo?.nombre }}</td>
        </tr>
      } @else {
        <tr>
          <td class="tabla-label">Flota:</td>
          <td class="no-info">No asignado</td>
        </tr>
      }
      
      @if (activo?.cliente?.nombre) {
        <tr>
          <td class="tabla-label">Cliente:</td>
          <td>{{ activo?.cliente?.nombre }}</td>
        </tr>
      }
      
      @if (activo?.funcion) {
        <tr>
          <td class="tabla-label">Función:</td>
          <td>{{ activo?.funcion }}</td>
        </tr>
      }
      
      @if (activo?.tracker) {
        <tr>
          <td class="tabla-label">Tracker:</td>
          <td>{{ activo?.tracker?.nombre }}</td>
        </tr>
      } @else {
        <tr>
          <td class="tabla-label">Tracker:</td>
          <td class="no-info">No asignado</td>
        </tr>
      }

      @if (activo?.categoria === "Vehículo") {
        @if (activo?.vehiculo?.tipo) {
          <tr>
            <td class="tabla-label">Tipo:</td>
            <td>{{ activo?.vehiculo?.tipo }}</td>
          </tr>
        }

        @if (activo?.vehiculo?.patente) {
          <tr>
            <td class="tabla-label">Patente:</td>
            <td>{{ activo?.vehiculo?.patente }}</td>
          </tr>
        }
        @if(reporte){
        <tr>
          <td class="tabla-label">Último Reporte:</td>
          <td>
            <span [ngStyle]="{ color: reporte.color, 'font-size': '1.5em' }">
              &#x2B24;
            </span>
            {{ reporte.fechaCreacion | date:'short' }}
          </td>
        </tr>}
        @else{
        
          <tr>
            <td class="tabla-label">Último Reporte:</td>
            <td class="no-info">Sin Comunicación</td>
          </tr>
        }

        @if (activo?.vehiculo?.estado) {
          <tr>
            <td class="tabla-label">Estado:</td>
            <td>{{ activo?.vehiculo?.estado }}</td>
          </tr>
        }

        @if (activo?.vehiculo?.marca) {
          <tr>
            <td class="tabla-label">Marca:</td>
            <td>{{ activo?.vehiculo?.marca }}</td>
          </tr>
        }

        @if (activo?.vehiculo?.modelo) {
          <tr>
            <td class="tabla-label">Modelo:</td>
            <td>{{ activo?.vehiculo?.modelo }}</td>
          </tr>
        }

        @if (activo?.vehiculo?.anio) {
          <tr>
            <td class="tabla-label">Año:</td>
            <td>{{ activo?.vehiculo?.anio }}</td>
          </tr>
        }
      }
    </table>
  </div>

  @if (this.reporte) {
    <br />
    <div><strong>Última Ubicación Reportada</strong></div>
    <div class="map-container">
      <app-mapa-facil [data]="datoMapa" class="mapita"></app-mapa-facil>
    </div>
  }
}

