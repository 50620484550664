<div style="display: flex; flex-direction: row">
  <mat-dialog-content>
    @if (data) {
      <div
        style="
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 1em;
          justify-content: center;
        "
      >
        <div id="mapa-facil-dialog" class="map-container">
          <div class="mapa-facil-flotante">
            <div class="row-flex-start" style="gap: 8px">
              @if (ready && data.satelliteView) {
                <!-- Botón principal -->
                <button mat-mini-fab color="primary" (click)="toggleMenu()">
                  <mat-icon color="accent">layers</mat-icon>
                </button>

                <!-- Botones adicionales del dial (aparecen al hacer click en el botón principal) -->
                <div class="fab-actions" *ngIf="isMenuOpen">
                  <button
                    mat-mini-fab
                    color="white"
                    (click)="changeView(false); toggleMenu()"
                    matTooltip="Normal"
                    matTooltipPosition="right"
                  >
                    <mat-icon color="accent">map</mat-icon>
                  </button>
                  <button
                    mat-mini-fab
                    color="white"
                    (click)="changeView(true); toggleMenu()"
                    matTooltip="Satelital"
                    matTooltipPosition="right"
                  >
                    <mat-icon color="accent">satellite_alt</mat-icon>
                  </button>
                </div>
              }
              @if (currentTrip) {
                <div class="column-center magico-85" style="border-radius: 8px">
                  <mat-slider color="accent" max="200" [discrete]="true">
                    <input matSliderThumb [(ngModel)]="speed" />
                  </mat-slider>
                </div>

                <button
                  mat-mini-fab
                  color="primary"
                  (click)="layerToggle('trip')"
                  matTooltip="Viaje"
                >
                  <mat-icon color="accent">airline_stops</mat-icon>
                </button>
              }
              @if (hayVehiculos) {
                <button
                  mat-mini-fab
                  color="primary"
                  (click)="layerToggle('vehiculos')"
                  matTooltip="Vehiculos"
                >
                  <mat-icon color="accent">directions_bus</mat-icon>
                </button>
              }
              @if (hayParadas) {
                <button
                  mat-mini-fab
                  color="primary"
                  (click)="layerToggle('paradas')"
                  matTooltip="Paradas"
                >
                  <mat-icon color="accent">hail</mat-icon>
                </button>
              }
              @if (hayPuntosSimples) {
                <button
                  mat-mini-fab
                  color="primary"
                  (click)="layerToggle('puntosSimples')"
                  matTooltip="Puntos"
                >
                  <mat-icon color="accent">location_on</mat-icon>
                </button>
              }
              @if (hayPolylines) {
                <button
                  mat-mini-fab
                  color="primary"
                  (click)="layerToggle('polylines')"
                  matTooltip="Recorridos"
                >
                  <mat-icon color="accent">route</mat-icon>
                </button>
              }
            </div>
          </div>
        </div>

        <div
          id="street-view"
          style="width: 450px; height: 450px; align-items: center"
        ></div>
      </div>
    } @else {
      <div class="column-center" style="width: 500%; height: 100%">
        <mat-card>
          <mat-card-content> No hay datos </mat-card-content>
        </mat-card>
      </div>
    }
  </mat-dialog-content>
</div>
<mat-dialog-actions align="end">
  <button mat-button color="accent" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
