<!-- @if (data) {
  <div id="mapa-facil" class="map-container"></div>
} @else {
  <div class="column-center" style="width: 100%; height: 100%">
    <mat-card>
      <mat-card-content> No hay datos </mat-card-content>
    </mat-card>
  </div>
} -->

<div id="mapa-facil" class="map-container">
  <div
    class="mapa-facil-flotante"
    [style]="data?.controlsOnRight ? 'right: 0' : null"
  >
    <div class="row-flex-start" style="gap: 8px">
      @if (currentTrip) {
        <div class="column-center magico-85" style="border-radius: 8px">
          <mat-slider color="accent" max="200" [discrete]="true">
            <input matSliderThumb [(ngModel)]="speed" />
          </mat-slider>
        </div>

        <button
          mat-mini-fab
          color="primary"
          (click)="layerToggle('trip')"
          matTooltip="Viaje"
        >
          <mat-icon color="accent">airline_stops</mat-icon>
        </button>
      }
      @if (hayVehiculos) {
        <button
          mat-mini-fab
          color="primary"
          (click)="layerToggle('vehiculos')"
          matTooltip="Vehiculos"
        >
          <mat-icon color="accent">directions_bus</mat-icon>
        </button>
      }
      @if (hayParadas) {
        <button
          mat-mini-fab
          color="primary"
          (click)="layerToggle('paradas')"
          matTooltip="Paradas"
        >
          <mat-icon color="accent">hail</mat-icon>
        </button>
      }
      @if (hayPuntosActivos) {
        <button
          mat-mini-fab
          color="primary"
          (click)="layerToggle('puntosActivos')"
          matTooltip="PuntosActivos"
        >
          <mat-icon color="accent">location_on</mat-icon>
        </button>
      }
      @if (hayPolylines) {
        <button
          mat-mini-fab
          color="primary"
          (click)="layerToggle('polylines')"
          matTooltip="Recorridos"
        >
          <mat-icon color="accent">route</mat-icon>
        </button>
      }
    </div>
  </div>
</div>
