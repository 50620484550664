@if (!loading) {
  <div class="row-center">
    <span class="subtitle1">Información de la alarma</span>
  </div>
  <br />
  <div class="main-container">
    <!-- Columna izquierda con información de la alarma y mapa -->
    <div class="left-column">
      <!-- Título para la tabla de la alarma -->
      <div><strong>Información General</strong></div>
      <table class="tabla-alarmas">
        <tr>
          <td class="tabla-label">Nombre:</td>
          <td>{{ alarma?.nombre }}</td>
        </tr>

        <tr>
          <td class="tabla-label">Número abonado:</td>
          <td>{{ alarma?.numeroAbonado }}</td>
        </tr>
        <tr>
          <td class="tabla-label">Habilitada:</td>
          <td>{{ alarma?.estadoCuenta === 'Habilitado' || alarma?.estadoCuenta == null ? '✔️' : '❌' }}</td>
        </tr>
        <tr>
          <td class="tabla-label">Activada:</td>
          <td>{{ alarma?.modoDesactivado?.dispositivoDesactivado == false || alarma?.modoDesactivado?.dispositivoDesactivado == null ? '✔️' : '❌' }}</td>
        </tr>
        
        <tr>
          <td class="tabla-label">Armada:</td>
          <td>{{ alarma?.armado === true ? 'Sí' : 'No' }}</td>
        </tr>
        <tr>
          <td class="tabla-label">Marca y modelo:</td>
          <td>{{ alarma?.modelo?.marca }} - {{ alarma?.modelo?.modelo }}</td>
        </tr>

        <tr>
          <td class="tabla-label">Cliente:</td>
          <td>{{ alarma?.cliente?.nombre }}</td>
        </tr>

        <tr>
          <td class="tabla-label">Fecha de creación:</td>
          <td>{{ alarma?.fechaCreacion | date:'dd/MM/yyyy' }}</td>
        </tr>
<!-- ################################################## -->
 @if(alarma?.fechaUltimaComunicacion){
        <tr>
          <td class="tabla-label">Última comunicación:</td>
          <td>
            <span [ngStyle]="{ color: alarma.color, 'font-size': '1.5em' }">
              &#x2B24;
            </span>
            {{ alarma.fechaUltimaComunicacion| date:'short' }}
          </td>
        </tr>
      } @else{
        
        <tr>
          <td class="tabla-label">Último comunicación:</td>
          <td class="no-info">Sin Comunicación</td>
        </tr>
      }
  
        <!-- ########################################################### -->
@if(alarma?.fechaAlta){
        <tr>
          <td class="tabla-label">Fecha de alta:</td>
          <td>{{ alarma?.fechaAlta  | date:'dd/MM/yyyy HH:mm'}}</td>
        </tr>
} @else {
  <tr>
    <td class="tabla-label">Fecha de alta:</td>
    <td class="no-info">Sin alta</td>
  </tr>
}
      @if(alarma?.frecReporte){
        <tr>
          <td class="tabla-label">Frecuencia de reporte:</td>
          <td>{{ alarma?.frecReporte }}</td>
        </tr>
      }
      @else {
        <tr>
          <td class="tabla-label">Frecuencia de reporte:</td>
          <td class="no-info">No asignada</td>
        </tr>
      }

        @if (alarma?.comunicador) {
          <tr>
            <td class="tabla-label">Comunicador:</td>
            <td>{{ alarma?.comunicador?.marca }} - {{ alarma?.comunicador?.modelo }}</td>
          </tr>
          <tr>
            <td class="tabla-label">ID Único comunicador:</td>
            <td>{{ alarma?.idUnicoComunicador }}</td>
          </tr>
        }

        <tr>
          <td class="tabla-label">Sim1:</td>
          <td>{{ alarma?.sim1?.numero || "Sin Sim1 Info" }} - {{ alarma?.sim1?.operador || "Sin Operador Info" }} - {{ alarma?.sim1?.iccid || "Sin ICCID Info" }}</td>
        </tr>

        <tr>
          <td class="tabla-label">Sim2:</td>
          <td>{{ alarma?.sim2?.numero || "Sin Sim2 Info" }} - {{ alarma?.sim2?.operador || "Sin Operador Info" }} - {{ alarma?.sim2?.iccid || "Sin ICCID Info" }}</td>
        </tr>

        @if (alarma?.domicilio) {
          <tr>
            <td class="tabla-label">Ubicación:</td>
            <td>{{ alarma?.domicilio?.direccion }}</td>
          </tr>
        }
        @else {
          <tr>
            <td class="tabla-label">Ubicación:</td>
            <td class="no-info">Sin Domicilio</td>
          </tr>
        }
      </table>

      <!-- Título para el mapa -->
      @if (alarma?.domicilio) {
        <div><strong>Mapa</strong></div>
        <div class="map-container">
          <app-mapa-facil [data]="datoMapa" class="mapita"></app-mapa-facil>
        </div>
      }
    </div>

     <!-- Columna derecha con cámaras -->
     <div class="right-column">
      <!-- Título general para las cámaras -->
      <div><strong>Información de cámaras asociadas </strong></div>
      @if (alarma?.camaras && alarma?.camaras.length > 0) {
        <div class="camaras-table-container">
          <!-- Para cada cámara, una tabla con dos columnas -->
          <div *ngFor="let camara of alarma?.camaras">
            <!-- Título para cada cámara -->
            <div class="subtitle2">{{ camara.identificacion }}:</div>
            <table class="tabla-camaras">
              <tr>
                <td class="tabla-label">Fecha de Creación:</td>
                <td>{{ camara.fechaCreacion | date:'dd/MM/yyyy' }}</td>
              </tr>

              <tr>
                <td class="tabla-label">Número de Canales:</td>
                <td>{{ camara?.canales?.length || 0 }}</td>
              </tr>
              <tr>
                <td class="tabla-label">Marca y modelo:</td>
                <td>{{ camara?.modeloDispositivo?.marca}}-{{ camara?.modeloDispositivo?.modelo}}</td>
              </tr>
            </table>
          </div>
        </div>
      } @else {
        <!-- Si no hay cámaras asociadas -->
        <div class="no-cameras-message">
          <p class="no-info">No hay cámaras asociadas a esta alarma</p>
        </div>
      }
    </div>
  </div>
}
