<div id="map-draw" class="map-container">
  <div class="flotante">
    <div class="row-flex-start" style="gap: 8px">
      @if (puedeDibujar) {
        <button
          mat-raised-button
          color="accent"
          style="margin-left: 0.5em"
          type="button"
          (click)="dibujar()"
          [disabled]="!tipo || dibujando"
        >
          Dibujar
        </button>
        @if (dibujando) {
          <button
            mat-mini-fab
            color="warn"
            type="button"
            (click)="desactivarModoEdicion()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        }
        <button
          mat-mini-fab
          color="warn"
          type="button"
          (click)="borrarDibujos()"
        >
          <mat-icon>delete</mat-icon>
        </button>
      }
    </div>
  </div>
</div>
