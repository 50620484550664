<mat-card>
  <!-- Headers -->
  <mat-card-header>
    <mat-card-title>
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <form
    *ngIf="form"
    id="form"
    [formGroup]="form"
    autocomplete="off"
    (ngSubmit)="onSubmit()"
  >
    <!-- Form -->
    <mat-card-content
      style="margin: 2em 0; overflow: auto"
      [class.cardContentDialog]="dialogRef"
      [class.cardContentFullscreen]="!dialogRef"
    >
      <!-- Selector de agrupación -->
      <mat-form-field>
        <mat-label>Agrupación</mat-label>
        <mat-select formControlName="agrupacionReenvio">
          @for (s of agrupacionesReenvio; track s) {
            <mat-option [value]="s">{{ s }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <!-- Selector tipo de entidad / Alarma - Tracker -->
      @if (form?.value?.agrupacionReenvio === "Entidad") {
        <mat-form-field>
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="tipoEntidad">
            @for (s of tiposEntidades; track s) {
              <mat-option [value]="s">{{ s }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      <!-- Trackers -->
      @if (
        form?.value?.tipoEntidad === "Tracker" &&
        form?.value?.agrupacionReenvio === "Entidad"
      ) {
        <ng-select
          [items]="trackers"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idEntidadReenvio"
          placeholder="Tracker"
          [notFoundText]="'No hay Trackers'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          appendTo="body"
        >
        </ng-select>
      }

      <!-- Alarmas -->
      @if (
        form?.value?.tipoEntidad === "Alarma" &&
        form?.value?.agrupacionReenvio === "Entidad"
      ) {
        <ng-select
          [items]="alarmas"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idEntidadReenvio"
          placeholder="Alarma"
          [notFoundText]="'No hay Alarmas'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          appendTo="body"
        >
        </ng-select>
      }

      <!-- Clientes -->
      @if (form?.value?.agrupacionReenvio !== "Entidad") {
        <ng-select
          [items]="clientes"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idClienteReenvio"
          placeholder="Cliente"
          [notFoundText]="'No hay Clientes'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          appendTo="body"
        >
        </ng-select>
      }

      <!-- Método de reenvio -->
      <mat-form-field [formGroup]="opcionesReenvio">
        <mat-label>Método de reenvio</mat-label>
        <mat-select formControlName="metodo" required>
          <mat-option *ngFor="let dato of metodosReenvio" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
        <mat-hint>
          @if (form?.value?.opcionesReenvio?.metodo === "Básico") {
            Se reenvia el mensaje tal como se recibe por el mismo protocolo
          }
          @if (
            form?.value?.opcionesReenvio?.metodo === "Seguridad Evento Externo"
          ) {
            Envia los eventos a la APP de seguridad para hacer sonar las sirenas
            y/o generar alertas
          }
        </mat-hint>
      </mat-form-field>

      <!-- Opciones de reenvio 'BASICO' -->
      @if (form?.value?.opcionesReenvio?.metodo === "Básico") {
        <div [formGroup]="opcionesReenvio">
          <h2>Opciones de reenvio</h2>

          <div style="display: flex; flex-direction: row; gap: 1em">
            <!-- Host -->
            <mat-form-field style="width: 70%">
              <mat-label>Host</mat-label>
              <input matInput formControlName="host" />
            </mat-form-field>

            <!-- Puerto -->
            <mat-form-field style="width: 30%">
              <mat-label>Puerto</mat-label>
              <input matInput formControlName="puerto" type="number" />
            </mat-form-field>
          </div>
        </div>
      }

      <!-- Opciones de reenvio 'SEGURIDAD EVENTO EXTERNO' -->
      @if (
        form?.value?.opcionesReenvio?.metodo === "Seguridad Evento Externo"
      ) {
        <div [formGroup]="opcionesReenvio">
          <h2>Opciones de reenvio</h2>

          <div style="display: flex; flex-direction: row; gap: 1em">
            <!-- Host -->
            <mat-form-field>
              <mat-label>Api Key</mat-label>
              <input matInput formControlName="apikey" />
            </mat-form-field>
          </div>
        </div>
      }
    </mat-card-content>

    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </mat-card-actions>
  </form>
</mat-card>
