/* eslint-disable @typescript-eslint/no-explicit-any */
import { ParamsService } from './../../../auxiliares/servicios/params.service';
import {
  Component,
  Input,
  OnInit,
  Optional,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  ICamara,
  ICamaraAlarma,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Observable, Subscription } from 'rxjs';
import { ListadosService } from '../../../auxiliares/servicios';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { StreamCamaraComponent } from '../stream-camara/stream-camara.component';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface CamaraRes {
  camara: ICamara;
  canal: string;
}
@Component({
  selector: 'app-camaras-multiple',
  templateUrl: './camaras-multiple.component.html',
  styleUrl: './camaras-multiple.component.scss',
  standalone: true,
  imports: [
    StreamCamaraComponent,
    CommonModule,
    AuxiliaresModule,
    MatPaginator,
  ],
})
export class CamarasMultipleComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: MatTableDataSource<CamaraRes> =
    new MatTableDataSource<CamaraRes>();
  public paginatedItems: any = [];
  public loading: boolean = true;
  public camarasRes: CamaraRes[] = [];
  public camaras: Observable<any>;
  private camaras$: Subscription;
  public camaraActual: ICamara;
  public canalActual: string;
  public maximizado: boolean = false;
  public fecha: string;

  @Input() public data: string[] = [];
  @Input() public zonaInicial: ICamaraAlarma;
  @Input() public fechaEvento?: string;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<StreamCamaraComponent>,
    public helper: HelperService,
    private listados: ListadosService,
    public paramsService: ParamsService,
  ) {}

  //

  public async listarCamaras(): Promise<void> {
    const filter: IFilter<ICamara> = {
      _id: { $in: this.data },
    };

    const query: IQueryParam = {
      includeChildren: true,
      filter: JSON.stringify(filter),
    };
    this.camaras$?.unsubscribe();
    this.camaras$ = this.listados
      .subscribe<IListado<ICamara>>('camaras', query)
      .subscribe((data) => {
        data.datos.map((camara) => {
          camara.canales.map((canal) => {
            this.camarasRes.push({ camara: camara, canal: canal.numero });
          });
        });
        this.dataSource.data = this.camarasRes;
        this.dataSource.paginator = this.paginator;
        this.camaras = this.dataSource.connect();
        console.log(`listado de camaras`, data);
      });
    await this.listados.getLastValue('camaras', query);
  }

  public select(camara?: ICamara, canal?: string) {
    this.maximizado = !this.maximizado;
    if (this.maximizado) {
      this.camaraActual = camara;
      this.canalActual = canal;
    } else {
      this.camaraActual = null;
      this.canalActual = null;
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public volverAtras(min: number) {
    // Obtener la fecha actual
    const now = new Date(this.fechaEvento);

    // Restar los minutos especificados
    now.setMinutes(now.getMinutes() - min);

    // Formatear la fecha en el formato deseado (YYYYMMDDTHHMMSSZ)
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Mes con 2 dígitos
    const day = String(now.getDate()).padStart(2, '0'); // Día con 2 dígitos
    const hours = String(now.getHours()).padStart(2, '0'); // Hora con 2 dígitos
    const minutesFormatted = String(now.getMinutes()).padStart(2, '0'); // Minutos con 2 dígitos
    const seconds = String(now.getSeconds()).padStart(2, '0'); // Segundos con 2 dígitos

    // Construir la cadena en el formato "YYYYMMDDTHHMMSSZ"
    this.fecha = `${year}${month}${day}T${hours}${minutesFormatted}${seconds}Z`;
  }

  public fechaParaCamara(fecha: string) {
    // Convertir la cadena ISO en un objeto Date
    const date = new Date(fecha);

    // Formatear la fecha en el formato "YYYYMMDDTHHMMSSZ"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes con 2 dígitos
    const day = String(date.getDate()).padStart(2, '0'); // Día con 2 dígitos
    const hours = String(date.getHours()).padStart(2, '0'); // Hora con 2 dígitos
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Minutos con 2 dígitos
    const seconds = String(date.getSeconds()).padStart(2, '0'); // Segundos con 2 dígitos

    // Construir la cadena en el formato "YYYYMMDDTHHMMSSZ"
    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.updatePaginatedItems();
  }

  onPageChange(event: PageEvent) {
    this.updatePaginatedItems(event.pageIndex);
  }

  updatePaginatedItems(pageIndex: number = 0) {
    const startIndex = pageIndex * 4;
    const endIndex = startIndex + 4;
    const items = this.dataSource.data.slice(startIndex, endIndex);

    // Agrupar en filas de 2 elementos (2 columnas)
    this.paginatedItems = [];
    for (let i = 0; i < items.length; i += 2) {
      this.paginatedItems.push(items.slice(i, i + 2));
    }
  }

  async ngOnInit() {
    if (this.dialogRef && this.paramsService.getParams()['dialog']) {
      const cam: ICamara = this.paramsService.getParams()['camara'][0];
      if (cam) {
        cam.canales.map((canal) => {
          this.camarasRes.push({ camara: cam, canal: canal.numero });
        });
        this.dataSource.data = this.camarasRes;
        this.dataSource.paginator = this.paginator;
        this.camaras = this.dataSource.connect();
      }
    } else {
      await this.listarCamaras();
    }

    if (this.zonaInicial) {
      const camara = this.camarasRes.find(
        (c) => c.camara._id === this.zonaInicial.idCamara,
      )?.camara;
      const canal = camara.canales.find(
        (can) => can.numero === this.zonaInicial.canal,
      );
      if (camara && canal) {
        this.camaraActual = camara;
        this.canalActual = canal.numero;
        this.maximizado = true;
      }
    }
    this.loading = false;
  }

  async ngOnDestroy() {
    this.camaras$?.unsubscribe();
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }
}
