import { Component, OnDestroy, OnInit } from '@angular/core';
import { IFilter, IListado, IPopulate, IQueryParam } from 'modelos/src';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { IDispositivoLorawan } from 'modelos/src';
import { DispositivosLorawanService } from '../luminarias.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { IRegExpSearch } from '../../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';

@Component({
  selector: 'app-dispositivoslorawan',
  templateUrl: './luminarias.component.html',
  styleUrl: './luminarias.component.scss',
  standalone: false,
})
export class DispositivosLorawanComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = DispositivosLorawanComponent.name;
  public dispositivoslorawan: IDispositivoLorawan[] = [];
  public dispositivosLorawan$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IDispositivoLorawan>[];
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Luminaria',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['identificacion'],
  };

  public filter: IFilter<IDispositivoLorawan> = {};
  public populate: IPopulate[] = [
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'name',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: DispositivosLorawanService,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    this.dispositivosLorawan$?.unsubscribe();
    this.dispositivosLorawan$ = this.listados
      .subscribe<
        IListado<IDispositivoLorawan>
      >('dispositivosLorawan', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dispositivoslorawan = data.datos;
        console.log(`listado de luminarias`, data);
      });
    await this.listados.getLastValue('dispositivosLorawan', this.query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(dispositivolorawan: IDispositivoLorawan) {
    this.navigator.navigate(['editar', dispositivolorawan._id], {
      relativeTo: this.route,
    });
  }

  public async eliminar(dispositivolorawan: IDispositivoLorawan) {
    const res = await this.dialogService.confirm(
      'Eliminar Luminaria',
      `¿Desea eliminar la luminaria ${dispositivolorawan.name}?`,
    );
    if (!res) return;
    try {
      console.log('Esto se tiene que eliminar', dispositivolorawan._id);
      await this.service.deleteById(dispositivolorawan._id!);
      this.helper.notifSuccess('Luminaria eliminada');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'DevEUI',
        },
        row: {
          field: 'deveui',
        },
      },
      {
        header: {
          label: 'Nombre',
        },
        row: {
          field: 'name',
        },
      },
      {
        header: {
          label: 'joinEUI',
        },
        row: {
          field: 'joineui',
        },
      },

      //Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.dispositivosLorawan$?.unsubscribe();
  }
}
