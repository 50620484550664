/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IFilter,
  IGrupo,
  IListado,
  IQueryParam,
  ITracker,
  IUsuario,
  IActivo,
  ICreateActivo,
  IUpdateActivo,
  ICliente,
  TipoVehiculo,
  FuncionActivo,
  IPopulate,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { ActivosService } from '../../../../auxiliares/servicios/http/activos.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CrearEditarClienteComponent } from '../../administracion/clientes/crear-editar-cliente/crear-editar-cliente.component';
import { ParamsService } from '../../../../auxiliares/servicios/params.service';
import { CrearEditarTrackerComponent } from '../../administracion/trackers/crear-editar-tracker/crear-editar-tracker.component';
import { CrearEditarAgrupacionesComponent } from '../../../standalone/grupos/crear-editar-grupos/crear-editar-grupos.component';
import { CrearEditarUsuarioComponent } from '../../administracion/usuarios/crear-editar-usuario/crear-editar-usuario.component';
import { LoginService } from '../../../login/login.service';

@Component({
  selector: 'app-crear-editar-vehiculo',
  templateUrl: './crear-editar-vehiculo.component.html',
  styleUrl: './crear-editar-vehiculo.component.scss',
  standalone: false,
})
export class CrearEditarVehiculoComponent implements OnInit, OnDestroy {
  public loading = false;
  public clientePropio = LoginService.getCliente();

  //////////////////////////////////////////////////////////////
  public form?: FormGroup;
  public id?: string | null;

  /////////////////////////////////////////////////////////////////
  public vehiculo?: IActivo;
  public vehiculo$?: Subscription;

  public trackers?: ITracker[];
  public trackers$?: Subscription;

  public clientes?: ICliente[] = [];
  public clientes$?: Subscription;

  public grupos?: IGrupo[];
  public grupos$?: Subscription;

  public choferes?: IUsuario[];
  public choferes$?: Subscription;

  public estados = ['Operativo', 'En mantenimiento', 'Fuera de servicio'];
  public tipos: TipoVehiculo[] = ['Auto', 'Moto', 'Camion', 'Grua', 'Otro'];
  public funciones: FuncionActivo[] = [
    'Particular',
    'Seguridad Privada',
    'Mantenimiento',
    'Transporte',
    'Bomberos',
    'Policia',
    'Ambulancia',
    'Otro',
  ];

  ////Esto es algo que tiene que ver con el chofer
  get vehiculoForm() {
    return this.form?.get('vehiculo') as FormGroup;
  }

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarVehiculoComponent>,
    public helper: HelperService,
    private service: ActivosService,
    private listados: ListadosService,
    private dialog: MatDialog,
    private paramsService: ParamsService,
  ) {}

  ////////////////////////////////////////////////////////////////////////////
  //FORMULARIO

  public createForm() {
    this.form = new FormGroup({
      identificacion: new FormControl(
        this.vehiculo?.identificacion,
        Validators.required,
      ),
      idGrupo: new FormControl(this.vehiculo?.idGrupo),
      idTracker: new FormControl(this.vehiculo?.idTracker),
      idUnicoTraccar: new FormControl(this.vehiculo?.idUnicoTraccar),
      idCliente: new FormControl(this.vehiculo?.idCliente, Validators.required),
      categoria: new FormControl('Vehículo'),
      funcion: new FormControl(this.vehiculo?.funcion),
      vehiculo: new FormGroup({
        idExterno: new FormControl(this.vehiculo?.vehiculo?.idExterno),
        idChofer: new FormControl(this.vehiculo?.vehiculo?.idChofer),
        patente: new FormControl(this.vehiculo?.vehiculo?.patente),
        estado: new FormControl(this.vehiculo?.vehiculo?.estado),
        tipo: new FormControl(this.vehiculo?.vehiculo?.tipo),
        marca: new FormControl(this.vehiculo?.vehiculo?.marca),
        modelo: new FormControl(this.vehiculo?.vehiculo?.modelo),
        anio: new FormControl(this.vehiculo?.vehiculo?.anio),
      }),
    });
  }

  //////////////////////////////////////////////////////////////////////////////
  ///LISTADOS
  public async listar(): Promise<void> {
    const filter: IFilter<IActivo> = {
      categoria: 'Vehículo',
      idCliente: this.form.value.idCliente,
    };
    const populate: IPopulate[] = [
      {
        path: 'tracker',
      },
      {
        path: 'grupo',
      },
      {
        path: 'cliente',
      },
    ];
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };

    this.vehiculo$?.unsubscribe();
    this.vehiculo$ = this.listados
      .subscribe<IListado<IActivo>>('activos', query)
      .subscribe((data) => {
        if (data.datos[0]) this.vehiculo = data.datos[0];
        console.log(`listado de vehiculos`, data);
      });
    await this.listados.getLastValue('activos', query);
  }

  public async listarGrupos() {
    if (this.form?.value?.idCliente) {
      const filter: IFilter<IGrupo> = {
        categoria: 'Flota',
        idCliente: this.form.value.idCliente,
      };
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        sort: 'nombre',
        select: 'nombre idCliente',
        limit: 0,
        includeChildren: true,
      };
      this.grupos$?.unsubscribe();
      this.grupos$ = this.listados
        .subscribe<IListado<IGrupo>>('grupos', query)
        .subscribe((data) => {
          this.grupos = data.datos;
          console.log(`listado de grupos`, data);
        });
      await this.listados.getLastValue('grupos', query);
    }
  }

  public async listarTrackers() {
    if (this.form?.value?.idCliente) {
      const filter: IFilter<ITracker> = {
        tipo: 'Traccar',
        $and: [
          { idCliente: this.form?.value?.idCliente },
          {
            $or: [
              {
                asignadoA: null,
              },
              {
                asignadoA: { $exists: false },
              },
            ],
          },
        ],
      };
      if (this.vehiculo?._id) {
        filter.$and[1].$or.push({
          _id: this.vehiculo?.idTracker,
        });
      }
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        sort: 'identificacion',
        limit: 0,
        includeChildren: true,
      };
      //
      this.trackers$?.unsubscribe();
      this.trackers$ = this.listados
        .subscribe<IListado<ITracker>>('trackers', query)
        .subscribe((data) => {
          const trackers = JSON.parse(JSON.stringify(data.datos));
          this.trackers = trackers;
          console.log(`listado de trackers`, data);
        });
      await this.listados.getLastValue('trackers', query);
    }
  }

  public async listarChoferes() {
    if (this.form?.value?.idCliente) {
      const filter: IFilter<IUsuario> = {
        idCliente: this.form.value.idCliente,
        roles: 'Conductor',
      };
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        sort: 'usuario',
        select: 'usuario idCliente',
        limit: 0,
      };
      this.choferes$?.unsubscribe();
      this.choferes$ = this.listados
        .subscribe<IListado<IUsuario>>('usuarios', query)
        .subscribe((data) => {
          this.choferes = data.datos;
          console.log(`listado de choferes`, data);
        });
      await this.listados.getLastValue('usuarios', query);
    }
  }

  private async listarClientes(): Promise<void> {
    const filter: IFilter<ICliente> = {
      'config.moduloVehiculos.activo': true,
      'config.moduloVehiculos.compartirVehiculos': true,
    } as any;
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      includeChildren: true,
      limit: 0,
      filter: JSON.stringify(filter),
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        // Obtiene el cliente propio
        const clientePropio = data.datos.find((c) => {
          return c._id === LoginService.getCliente()._id;
        });
        // quita el cliente propio
        const clientes = data.datos.filter((c) => {
          return c._id !== LoginService.getCliente()._id;
        });
        // Agrega el cliente propio al inicio
        clientes.unshift(clientePropio);

        this.clientes = clientes;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  ////////////////////////////////////////////////////////////////////////
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.id) {
        // Editar
        if (data) {
          await this.service.update(this.id, data);
          this.helper.notifSuccess('Vehiculo actualizado correctamente');
          this.helper.volver();
        }
      } else {
        // Crear
        const data = this.getData();
        if (data) {
          await this.service.create(data);
          this.helper.notifSuccess('Vehiculo creado correctamente');
          this.helper.volver();
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public async clienteElegido(cliente: ICliente) {
    this.form.patchValue({ idTracker: null });
    this.form.patchValue({ idGrupo: null });
    this.form.patchValue({ idRecorrido: null });
    this.vehiculoForm.patchValue({ idChofer: null });
    if (cliente) {
      await Promise.all([
        this.listarGrupos(),
        this.listarTrackers(),
        this.listarChoferes(),
      ]);
    } else {
      this.grupos = [];
      this.trackers = [];
      this.choferes = [];
    }
  }

  private getData() {
    const data: ICreateActivo | IUpdateActivo = this.form?.value;
    data.categoria = 'Vehículo';
    if (data.idTracker) {
      const tracker = this.trackers?.find((t) => t._id === data.idTracker);
      if (tracker.traccar?.id) {
        data.idUnicoTraccar = tracker.traccar.id;
      } else {
        data.idUnicoTraccar = null;
      }
    } else {
      data.idUnicoTraccar = null;
    }
    console.log(`data`, data);
    return data;
  }

  /////////////////////////////////////////////////////////////////////////////
  //CREAR

  public crearCliente() {
    const dialog = this.dialog.open(CrearEditarClienteComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idCliente: res });
      }
    });
  }
  public crearTracker() {
    this.paramsService.setParams({
      desdeActivo: true,
      idCliente: this.form.value.idCliente,
    });
    const dialog = this.dialog.open(CrearEditarTrackerComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idTracker: res });
      }
      this.paramsService.setParams({ vehiculo: this.vehiculo });
    });
  }
  public crearGrupo() {
    const dialog = this.dialog.open(CrearEditarAgrupacionesComponent, {
      data: { categoria: 'Flota' },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idGrupo: res });
      }
    });
  }
  public crearChofer() {
    this.paramsService.setParams({
      externo: true,
      idCliente: this.form.value.idCliente,
      rol: 'Conductor',
      modulo: 'moduloVehiculos',
    });
    const dialog = this.dialog.open(CrearEditarUsuarioComponent, {
      data: { categoria: 'Flota' },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.vehiculoForm.patchValue({ idChofer: res });
      }
      this.paramsService.setParams({ vehiculo: this.vehiculo });
    });
  }

  ///////////////////////////////////////////////////////////
  public volver() {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      HelperService.volver();
    }
  }

  /// HOOKS

  async ngOnInit() {
    this.loading = true;
    this.vehiculo = this.paramsService.getParams()['vehiculo'];
    this.id = this.vehiculo?._id || null;
    this.createForm();
    await Promise.all([
      this.listarTrackers(),
      this.listarGrupos(),
      this.listarChoferes(),
    ]);
    if (!this.helper.esClienteFinal()) {
      await this.listarClientes();
    }
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.vehiculo$?.unsubscribe();
    this.grupos$?.unsubscribe();
    this.choferes$?.unsubscribe();
    this.trackers$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
