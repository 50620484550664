import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  IActivo,
  ICoordenadas,
  IFilter,
  IListado,
  IQueryParam,
  IQueryTraccar,
  IReporte,
  Trip,
} from 'modelos/src';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DateRange } from '../../../../../../auxiliares/date-range-selector/date-range-selector.component';
import { ListadosService } from '../../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { ReportesService } from '../../../../../../auxiliares/servicios/http/reportes.service';
import { TraccarService } from '../../../../../../auxiliares/servicios/http/traccar.service';
import { LoadingService } from '../../../../../../auxiliares/servicios/loading.service';

@Component({
  selector: 'app-vehiculo-recorridos',
  templateUrl: './vehiculo-recorridos.component.html',
  styleUrl: './vehiculo-recorridos.component.scss',
  standalone: false,
})
export class VehiculoRecorridosComponent implements OnInit, OnDestroy {
  public interaccionesBusqueda: Trip[] = [];
  public interaccionesBusquedaSelected: Trip;
  @Output() public interaccionesBusquedaSelectedChange = new EventEmitter<
    ICoordenadas[]
  >();
  public displayedColumns: string[] = ['Recorridos'];
  public buscando: boolean = false;
  public range?: DateRange = {};

  public query: IQueryParam = {
    sort: 'nombre',
    page: 0,
    includeChildren: true,
    childrenLevel: 0,
  };

  public vehiculos: IActivo[] = [];
  public vehiculo: IActivo = null;
  public vehiculos$?: Subscription;

  constructor(
    public loading: LoadingService,
    public dialog: MatDialog,
    private reportesService: ReportesService,
    private listados: ListadosService,
    private traccarService: TraccarService,
    public helper: HelperService,
  ) {}

  public async listarVehiculos(): Promise<void> {
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', this.query)
      .subscribe(async (data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos `, data);
      });
    await this.listados.getLastValue('vehiculos', this.query);
  }
  public async listarViajes() {
    if (!this.range.start || !this.vehiculo) {
      this.interaccionesBusqueda = [];
      return;
    }
    const date = new Date(this.range.start);
    const start = new Date(date.getTime() - 12 * 60 * 60 * 1000).toISOString();
    const end = new Date(date.getTime() + 12 * 60 * 60 * 1000).toISOString();
    const query: IQueryTraccar = {
      idVehiculo: this.vehiculo._id!,
      from: start,
      to: end,
    };
    const data = await this.traccarService.getTrips(query);
    if (data) {
      this.interaccionesBusqueda = data.sort((a, b) => {
        return a.startTime! > b.startTime! ? -1 : 1;
      });
      console.log(`listado de trips`, data);
    }
  }

  public async listarReportes() {
    const filter: IFilter<IReporte> = {
      idActivo: this.vehiculo._id!,
      fechaCreacion: {
        $gte: this.interaccionesBusquedaSelected.startTime,
        $lte: this.interaccionesBusquedaSelected.endTime,
      },
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: '-fechaCreacion',
      includeChildren: true,
    };
    const data = await this.reportesService.getReportesSnappeados(query);
    console.log(`listado de reportes`, data);
    this.interaccionesBusquedaSelectedChange.emit(data);
  }

  public async onRecorridoSelected(trip: Trip, expanded: boolean) {
    if (expanded) {
      this.interaccionesBusquedaSelected = trip;
      this.listarReportes();
    }
  }

  async ngOnInit() {
    await this.listarVehiculos();
  }

  ngOnDestroy() {
    this.vehiculos$?.unsubscribe();
  }
}
