import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IActivo, ICliente, IReporte } from 'modelos/src';
import { LoadingService } from '../../../../../auxiliares/servicios/loading.service';
import { LoginService } from '../../../../login/login.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CrearEditarActivoComponent } from '../../../activos/activos/crear-editar-activo/crear-editar-activo.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-isla-activos',
  templateUrl: './isla-activos.component.html',
  styleUrl: './isla-activos.component.scss',
  standalone: false,
})
export class IslaActivosComponent {
  @Input() public reporte?: IReporte;
  @Output() public reporteChange = new EventEmitter<IReporte>();

  public cliente?: ICliente;
  navigator: Router;

  constructor(
    public loading: LoadingService,
    public dialog: MatDialog,
  ) {
    this.cliente = LoginService.getCliente();
  }

  public close() {
    this.reporte = undefined;
    this.reporteChange.emit(this.reporte);
  }
  public async editar(activo: IActivo) {
    const config: MatDialogConfig = {
      data: {
        edit: activo,
        tipo: 'Normal',
      },
      minWidth: '600px',
      disableClose: true,
    };
    this.dialog.open(CrearEditarActivoComponent, config);
  }
}
