import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ILogReenvio,
  IFilter,
  IListado,
  IQueryParam,
  IPopulate,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  ICrearBoton,
  IRowIcon,
} from '../../../../../auxiliares/tabla/tabla.component';
import { LogReenviosService } from '../../../../../auxiliares/servicios/http/log-reenvios.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listado-log-reenvios',
  templateUrl: './listado-log-reenvios.component.html',
  styleUrl: './listado-log-reenvios.component.scss',
  standalone: false,
})
export class ListadoLogReenviosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoLogReenviosComponent.name;
  public datos: ILogReenvio[] = [];
  public datos$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<ILogReenvio>[];
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear configuración de reenvio',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['body'],
  };

  private activo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idEntidad',
    },
    label: 'Activo',
    selectLabel: 'identificacion',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarActivos$',
    searchOn: ['identificacion'],
  };
  private alarma: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idEntidad',
    },
    label: 'Alarma',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarDispositivosAlarmas$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [this.activo, this.alarma];

  private filter: IFilter<ILogReenvio> = {};
  private populate: IPopulate = [
    {
      path: 'dispositivoAlarma',
      select: 'nombre',
    },
    {
      path: 'tracker',
      select: 'nombre',
    },
  ];

  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: '-fecha',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: LogReenviosService,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listados
      .subscribe<IListado<ILogReenvio>>('logReenvios', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de logreenvios`, data);
      });
    await this.listados.getLastValue('logReenvios', this.query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(logreenvio: ILogReenvio) {
    this.navigator.navigate(['editar', logreenvio._id], {
      relativeTo: this.route,
    });
  }

  public async eliminar(logreenvio: ILogReenvio) {
    const res = await this.dialogService.confirm(
      'Eliminar log de reenvio',
      `¿Desea eliminar el log de reenvio?`,
    );
    if (!res) return;
    try {
      await this.service.delete(logreenvio._id!);
      this.helper.notifSuccess('log de reenvio eliminada');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      // Fecha
      {
        header: {
          label: 'Fecha',
        },
        row: {
          field: 'fecha',
          pipe: {
            pipe: DatePipe,
            args: 'dd/MM/yyyy HH:mm',
          },
        },
      },
      // Entidad
      {
        header: {
          label: 'Entidad',
        },
        row: {
          parse(dato) {
            return dato.dispositivoAlarma?.nombre || dato.tracker?.nombre;
          },
        },
      },
      // Destino
      {
        header: {
          label: 'Destino',
        },
        row: {
          parse(dato) {
            return `${dato.protocolo} ${dato.host}:${dato.puerto}`;
          },
        },
      },
      // Body
      {
        header: {
          label: 'Body',
        },
        row: {
          field: 'body',
        },
      },
      // Ack
      {
        header: {
          label: 'Ack',
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
              tooltip: dato.error,
            };
            return dato.ack ? iconCheck : iconClose;
          },
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.datos$?.unsubscribe();
  }
}
