<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <!-- Header para dialog con drag -->
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        {{ title }}
      </mat-card-title>
    } @else {
      <!-- Header para fullscreen sin drag -->
      <mat-card-title>
        {{ title }}
      </mat-card-title>
    }
  </mat-card-header>

  <form
    *ngIf="form"
    id="form"
    [formGroup]="form"
    autocomplete="off"
    (ngSubmit)="onSubmit()"
  >
    <!-- Form -->
    <mat-card-content
      style="margin: 2em 0; overflow: auto"
      [class.cardContentDialog]="dialogRef"
      [class.cardContentFullscreen]="!dialogRef"
    >
      <!-- Datos de usuario -->
      <div
        class="column-center mat-elevation-z1"
        style="gap: 15px; padding: 0.5em; margin-bottom: 1em"
      >
        <div class="row-flex-start" style="width: 100%">
          <span class="subtitulo"> Datos de Usuario </span>
        </div>
        <!-- Nombre y Contraseña -->
        <section class="layout">
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Nombre</mat-label>
              <input matInput formControlName="usuario" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Contraseña</mat-label>
              <input matInput formControlName="hash" />
            </mat-form-field>
          </div>
        </section>

        <!-- Cliente // Roles -->
        <section class="column-center" style="width: 100%; gap: 16px">
          <!-- Cliente -->
          @if (!usuario?._id && !this.paramsService.getParams()["externo"]) {
            <div style="display: flex; width: 100%">
              <ng-select
                [items]="clientes"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idCliente"
                placeholder="Cliente"
                [notFoundText]="'No hay clientes creados'"
                style="width: 100%"
                multiple="false"
                searchable="true"
                (change)="onClienteChange($event)"
              >
              </ng-select>
              <!-- Crear Cliente -->
              @if (helper.verModuloAdministracion()) {
                <app-button
                  style="margin-left: 5px"
                  matType="mat-fab"
                  color="accent"
                  icon="add"
                  (click)="crearCliente()"
                ></app-button>
              }
            </div>
          }
          <!-- Roles -->
          @if (!paramsService.getParams()["rol"]) {
            <ng-select
              [items]="roles"
              formControlName="roles"
              [multiple]="true"
              placeholder="Rol"
              style="width: 100%"
              [hideSelected]="true"
              searchable="false"
              required
            >
            </ng-select>
          }
        </section>
      </div>

      <!-- Entidades de consultor -->
      @if (form.get("roles")?.value?.includes("Consultor")) {
        <div
          class="column-center mat-elevation-z1"
          style="gap: 15px; padding: 0.5em; margin-bottom: 1em"
          [formGroup]="permisos"
        >
          <div class="row-flex-start" style="width: 100%">
            <span class="subtitulo"> Dispositivos a Consultar </span>
          </div>
          <ng-select
            [items]="entidades"
            bindLabel="nombre"
            bindValue="id"
            groupBy="grupo"
            formControlName="idsEntidades"
            multiple
            style="width: 100%"
            (change)="onEntidadesChange($event)"
          >
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.grupo || "Sin Grupo" }}
            </ng-template>
          </ng-select>
        </div>
      }

      <!-- Modulos -->
      @if (!this.paramsService.getParams()["modulo"]) {
        <div
          class="column-center mat-elevation-z1"
          style="gap: 15px; padding: 0.5em; margin-bottom: 1em"
          [formGroup]="permisos"
        >
          <div class="row-flex-start" style="width: 100%">
            <span class="subtitulo"> Módulos </span>
          </div>
          <div class="row-space-around" style="width: 100%">
            @if (helper.verModuloActivos()) {
              <mat-checkbox formControlName="moduloActivos" color="primary">
                Gestión de Activos
              </mat-checkbox>
            }
            @if (helper.verModuloColectivos()) {
              <mat-checkbox formControlName="moduloColectivos" color="primary">
                Trasporte Público
              </mat-checkbox>
            }
            @if (helper.verModuloAlarmas()) {
              <mat-checkbox
                formControlName="moduloAlarmasDomiciliarias"
                color="primary"
              >
                Gestión de Alarma
              </mat-checkbox>
            }
            @if (helper.verModuloVehiculos()) {
              <mat-checkbox formControlName="moduloVehiculos" color="primary">
                Vehículos
              </mat-checkbox>
            }
            @if (
              helper.verModuloAdministracion() &&
              rolesElegidos.value &&
              !rolesElegidos.value.includes("Final")
            ) {
              <mat-checkbox
                formControlName="moduloAdministracion"
                color="primary"
              >
                Administración
              </mat-checkbox>
            }
            @if (
              helper.verModuloEventosTecnicos() &&
              rolesElegidos.value &&
              !rolesElegidos.value.includes("Final")
            ) {
              <mat-checkbox
                formControlName="moduloEventosTecnicos"
                color="primary"
              >
                Servicio Técnico
              </mat-checkbox>
            }
          </div>
        </div>
      }
      <!-- Datos personales -->
      <div
        class="column-center mat-elevation-z1"
        style="gap: 15px; padding: 0.5em; margin-bottom: 1em"
        [formGroup]="datosPersonales"
      >
        <div class="row-flex-start" style="width: 100%">
          <span class="subtitulo"> Datos Personales </span>
        </div>
        <!-- Nombre - DNI - SEXO -->
        <section class="layout">
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Nombre</mat-label>
              <input matInput formControlName="nombre" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>DNI</mat-label>
              <input matInput formControlName="dni" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Sexo</mat-label>
              <mat-select formControlName="sexo">
                @for (s of sexos; track s) {
                  <mat-option [value]="s.value">{{ s.label }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </section>

        <!-- EMAIL - DIRECCIÓN - PAÍS -->
        <section class="layout">
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" type="email" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Dirección</mat-label>
              <input matInput formControlName="direccion" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>País</mat-label>
              <input matInput formControlName="pais" />
            </mat-form-field>
          </div>
        </section>

        <!-- TELÉFONO - FECHA DE NACIMIENTO -->
        <section class="layout">
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Teléfono</mat-label>
              <input matInput formControlName="telefono" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Fecha de Nacimiento</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="fechaNacimiento"
              />
              <mat-hint>DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker startView="multi-year"></mat-datepicker>
            </mat-form-field>
          </div>
        </section>
      </div>
    </mat-card-content>

    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </mat-card-actions>
  </form>
</mat-card>
