import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IReporte,
  IGrupo,
  IRecorrido,
  ITracker,
  IUsuario,
  IActivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SetearOdometroComponent } from './setear-odometro/setear-odometro.component';
import { fromLonLat } from 'ol/proj';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MapaFacilData } from '../../../../../auxiliares/mapa-facil/interfaces';
import { MapaFacilDialogComponent } from '../../../../../auxiliares/mapa-facil-dialog/mapa-facil-dialog.component';
import { ActivosService } from '../../../../../auxiliares/servicios/http/activos.service';
import {
  CrearEditarEventosTecnicosComponent,
  ICEETData,
} from '../../../administracion/eventos-tecnicos/crear-editar-eventos-tecnicos/crear-editar-eventos-tecnicos.component';
import { LoginService } from '../../../../login/login.service';
import { ModoDesactivadoDispositivoComponent } from '../../../../standalone/modo-desactivado-dispositivo/modo-desactivado-dispositivo.component';

interface IVehiculoTabla extends IActivo {
  ultimoReporte?: IReporte;
  color?: string;
}

@Component({
  selector: 'app-listado-colectivos',
  templateUrl: './listado-colectivos.component.html',
  styleUrl: './listado-colectivos.component.scss',
  standalone: false,
})
export class ListadoColectivosComponent implements OnInit, OnDestroy {
  public clientePropio = LoginService.getCliente();
  //// Tabla nueva
  public name = ListadoColectivosComponent.name;
  public totalCount = 0;
  public columnas?: IColumnas<IVehiculoTabla>[];
  public botonCrear: ICrearBoton = {
    mostrar:
      this.helper.puedeEditar() &&
      HelperService.getConfigCliente().moduloColectivos?.crearDispositivos,
    tooltip: 'Crear Vehiculo',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  // Query Tabla
  public vehiculos: IVehiculoTabla[] = [];
  public filter: IFilter<IActivo> = {};
  public populate: IPopulate[] = [
    {
      path: 'tracker',
    },
    { path: 'vehiculo.recorrido', select: 'nombre' },
    { path: 'vehiculo.recorridos', select: 'nombre' },
    { path: 'grupo', select: 'nombre' },
    { path: 'vehiculo.chofer', select: 'usuario' },
    { path: 'cliente', select: 'nombre' },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'identificacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };
  // FILTROS TABLA
  public search: IRegExpSearch = {
    fields: ['identificacion', 'vehiculo.patente'],
  };
  public estado: IFiltroTabla = {
    elementos: [
      { value: 'Operativo' },
      { value: 'En mantenimiento' },
      { value: 'Fuera de servicio' },
    ],
    filter: {
      field: 'vehiculo.estado',
    },
    label: 'Estado',
    selectLabel: 'value',
    selectValue: 'value',
    tipo: 'select',
  };
  public grupo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idGrupo',
    },
    label: 'Grupo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarGrupos$',
    searchOn: ['nombre'],
  };
  public recorrido: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'vehiculo.idRecorrido',
    },
    label: 'Recorrido',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarRecorridos$',
    searchOn: ['nombre'],
  };
  public chofer: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'vehiculo.idChofer',
    },
    label: 'Chofer',
    selectLabel: 'usuario',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarUsuarios$',
    searchOn: ['usuario'],
  };
  public tracker: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idTracker',
    },
    label: 'Tracker',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarTrackers$',
    searchOn: ['nombre'],
  };
  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [
    this.estado,
    this.grupo,
    this.recorrido,
    this.tracker,
    this.chofer,
  ];

  // Subscripciones
  public vehiculos$?: Subscription;
  public reportes$?: Subscription;
  public grupos$?: Subscription;
  public recorridos$?: Subscription;
  public trackers$?: Subscription;
  public choferes$?: Subscription;
  public clientes$?: Subscription;

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ActivosService,
    private navigator: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  // Listados para filtros
  public async listarGrupos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0, // Sin limite
      includeChildren: true,
    };

    this.grupos$?.unsubscribe();
    this.grupos$ = this.listados
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupo.elementos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listados.getLastValue('grupos', query);
  }
  public async listarRecorridos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0, // Sin limite
      includeChildren: true,
    };

    this.recorridos$?.unsubscribe();
    this.recorridos$ = this.listados
      .subscribe<IListado<IRecorrido>>('recorridos', query)
      .subscribe((data) => {
        this.recorrido.elementos = data.datos;
        console.log(`listado de recorridos`, data);
      });
    await this.listados.getLastValue('recorridos', query);
  }
  public async listarTrackers(): Promise<void> {
    const filter: IFilter<ITracker> = {
      $or: [{ tipo: 'Traccar' }, { tipo: { $exists: false } }],
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      select: 'nombre identificacion traccar.id',
      sort: 'nombre',
      limit: 0, // Sin limite
      includeChildren: true,
    };

    this.trackers$?.unsubscribe();
    this.trackers$ = this.listados
      .subscribe<IListado<ITracker>>('trackers', query)
      .subscribe((data) => {
        this.tracker.elementos = data.datos;
        this.tracker.elementos.forEach(
          (e) => (e.idUnicoTraccar = e.traccar?.id),
        );
        console.log(`listado de trackers`, data);
      });
    await this.listados.getLastValue('trackers', query);
  }
  public async listarChoferes(): Promise<void> {
    const filter: IFilter<IUsuario> = {
      roles: 'Chofer Colectivo',
    };

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      select: 'usuario',
      sort: 'usuario',
      limit: 0, // Sin limite
      includeChildren: true,
    };

    this.choferes$?.unsubscribe();
    this.choferes$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.chofer.elementos = data.datos;
        console.log(`listado de choferes`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: true,
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ITracker>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  //

  public async listar(): Promise<void> {
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('colectivos', this.query)
      .subscribe(async (data) => {
        this.totalCount = data.totalCount;
        this.vehiculos = data.datos;
        await this.completaUltimoReporte();
      });
    await this.listados.getLastValue('colectivos', this.query);
  }

  public async completaUltimoReporte(): Promise<void> {
    if (!this.vehiculos.length) return;

    const idsActivos = this.vehiculos.map((v) => v._id);
    const filter: IFilter<IReporte> = {
      tipo: 'Colectivo',
      idActivo: { $in: idsActivos },
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };

    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IListado<IReporte>>('ultimoReportes', query)
      .subscribe((data) => {
        console.log(`Listado de reportes`, data);
        for (const vehiculo of this.vehiculos) {
          const findReporte = data.datos.find(
            (r) => r.idActivo === vehiculo._id,
          );
          vehiculo.ultimoReporte = findReporte;
          if (vehiculo.ultimoReporte) {
            const timeReporte = new Date(
              vehiculo.ultimoReporte.fechaCreacion,
            ).getTime();
            const timeNow = new Date().getTime();
            const diff = timeNow - timeReporte;
            // 1 hora
            if (diff > 1000 * 60 * 60) {
              vehiculo.color = 'red';
            } else if (diff > 1000 * 60 * 30) {
              vehiculo.color = 'yellow';
            } else {
              vehiculo.color = 'green';
            }
          }
        }
        console.log(`listado de vehiculos`, this.vehiculos);
      });
    await this.listados.getLastValue('ultimoReportes', query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(vehiculo: IActivo) {
    this.navigator.navigate(['editar', vehiculo._id], {
      relativeTo: this.route,
    });
  }

  public async modoDesactivado(activo: IActivo) {
    this.dialog.open(ModoDesactivadoDispositivoComponent, {
      data: { activo: activo },
      width: '850px',
    });
  }

  public async detalles(vehiculo: IActivo) {
    this.navigator.navigate(['detalles', vehiculo._id], {
      relativeTo: this.route,
    });
  }

  public async ubicar(c: IVehiculoTabla) {
    if (!c.ultimoReporte?.geojson?.coordinates) return;
    const coord = fromLonLat([
      c.ultimoReporte?.geojson?.coordinates[0],
      c.ultimoReporte?.geojson?.coordinates[1],
    ]);
    const data: MapaFacilData = {
      markers: [{ coor: coord, label: 'vehiculo' }],
    };
    const config: MatDialogConfig = {
      data,
      maxHeight: '600px',
      maxWidth: '600px',
    };
    this.dialog.open(MapaFacilDialogComponent, config);
  }

  public async eliminar(activo: IActivo) {
    const res = await this.dialogService.confirm(
      'Eliminar Vehiculo',
      `¿Desea eliminar el vehiculo ${activo.identificacion}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(activo._id!);
      this.helper.notifSuccess('Vehiculo eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public async setearOdometro(vehiculo: IActivo) {
    const config: MatDialogConfig = {
      data: vehiculo,
      maxHeight: '600px',
      maxWidth: '600px',
    };
    this.dialog.open(SetearOdometroComponent, config);
  }

  public ventanaDocumentos(data: IActivo) {
    this.navigator.navigate([
      '/',
      'main',
      'documentos',
      'listar',
      'vehiculo',
      data._id,
    ]);
  }

  public async eventoTecnico(activo: IActivo) {
    const data: ICEETData = {
      id: null, // Evento nuevo
      categoria: 'Tracker',
      idActivo: activo._id,
      idsCQAET:
        activo?.idsClientesQuePuedenAtender ||
        this.clientePropio.config?.idsClientesQuePuedenAtenderEventosTecnicos ||
        null,
    };
    this.dialog.open(CrearEditarEventosTecnicosComponent, {
      data,
      width: '850px',
    });
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IVehiculoTabla>[] = [
      // Identificacion
      {
        header: {
          label: 'Identificación',
          sortable: true,
        },
        row: {
          field: 'identificacion',
        },
      },
      // Patente
      {
        header: {
          label: 'Patente',
          sortable: true,
        },
        row: {
          parse(dato) {
            return dato.vehiculo.patente;
          },
          noData: 'Sin Patente',
        },
      },
      // Tracker
      {
        header: {
          label: 'Tracker',
        },
        row: {
          parse(dato) {
            return dato.tracker?.nombre || dato.tracker?.identificacion;
          },
          noData: 'Sin tracker',
        },
      },
      // Recorrido
      {
        header: {
          label: 'Recorrido',
        },
        row: {
          parse(dato) {
            return dato.vehiculo.recorrido?.nombre;
          },
          noData: 'Sin Recorrido',
        },
      },
      // Recorrido
      {
        header: {
          label: 'Recorridos',
        },
        row: {
          parse(dato) {
            return dato.vehiculo.recorridos?.map((r) => r.nombre).join(', ');
          },
          noData: 'Sin Recorrido',
        },
      },
      // Chofer
      {
        header: {
          label: 'Chofer',
        },
        row: {
          parse(dato) {
            return dato.vehiculo.chofer?.usuario;
          },
          noData: 'Sin Chofer',
        },
      },

      // Grupo
      {
        header: {
          label: 'Linea',
        },
        row: {
          parse(dato) {
            return dato.grupo?.nombre;
          },
          noData: 'Sin Linea',
        },
      },
      // Estado
      {
        header: {
          label: 'Estado',
        },
        row: {
          parse(dato) {
            return dato.vehiculo.estado;
          },
          noData: 'Sin Estado',
        },
      },
      // Ultima Comunicacion
      {
        header: {
          label: 'Última Comunicación',
        },
        row: {
          html: (dato) => {
            if (dato.ultimoReporte) {
              const time = new Date(dato.ultimoReporte?.fechaCreacion);
              const fecha = HelperService.parseDate(time);

              let html = `<div style="display: flex; gap: 1em; align-items: center">`;
              if (dato.color) {
                html += `<span style="color: ${dato.color}; font-size: 2em; overflow: visible"> &#x2B24; </span>`;
              }
              html += `<span>${fecha.toLocaleString()} </span>`;
              html += `</div>`;
              return html;
            }
            return '';
          },
          noData: 'Sin Comunicación',
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        accionesGrouped: [
          {
            tipo: 'material',
            color: 'primary',
            icon: 'search',
            tooltip: 'Detalles',
            click: (dato) => this.detalles(dato),
          },
          {
            tipo: 'material',
            color: 'primary',
            icon: 'place',
            tooltip: 'Ubicar',
            disabled(dato) {
              if (!dato.ultimoReporte?.geojson?.coordinates) return true;
              else return false;
            },
            click: (dato) => this.ubicar(dato),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'speed',
            tooltip: 'Setear Odometro',
            click: (dato) => this.setearOdometro(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'engineering',
            tooltip: 'Servicio Técnico',
            click: (dato) => this.eventoTecnico(dato),
            disabled: () => !this.helper.verModuloEventosTecnicos(),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'do_not_disturb_on',
            tooltip: 'Modo Desactivado',
            click: (dato) => this.modoDesactivado(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }

    this.setColumnas();
    await Promise.all([
      this.listar(),
      this.listarTrackers(),
      this.listarChoferes(),
    ]);
  }

  ngOnDestroy() {
    this.vehiculos$?.unsubscribe();
    this.reportes$?.unsubscribe();
    this.trackers$?.unsubscribe();
    this.grupos$?.unsubscribe();
    this.recorridos$?.unsubscribe();
    this.choferes$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
