import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IDispositivoAlarma,
  IFilter,
  IListado,
  IQueryParam,
  IPopulate,
  ICliente,
  IModeloDispositivo,
  IEvento,
  IUpdateDispositivoAlarma,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlarmasDomiciliariasService } from '../alarmas-domiciliarias.service';
import { ConfigurarComunicadorComponent } from '../../configurar-comunicador/configurar-comunicador.component';
import {
  CrearEditarEventosTecnicosComponent,
  ICEETData,
} from '../../../administracion/eventos-tecnicos/crear-editar-eventos-tecnicos/crear-editar-eventos-tecnicos.component';
import { LoginService } from '../../../../login/login.service';
import { ModoDesactivadoDispositivoComponent } from '../../../../standalone/modo-desactivado-dispositivo/modo-desactivado-dispositivo.component';
import { AccionesAlarmaComponent } from '../acciones-alarma/acciones-alarma.component';
import { EventosService } from '../../../../../auxiliares/servicios/http/eventos.service';
import { CrearEditarEstadoEntidadComponent } from '../../../administracion/estado-entidad/crear-editar/crear-editar.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listado-alarmas-domiciliarias',
  templateUrl: './listado-alarmas-domiciliarias.component.html',
  styleUrl: './listado-alarmas-domiciliarias.component.scss',
  standalone: false,
})
export class ListadoAlarmasDomiciliariasComponent implements OnInit, OnDestroy {
  public loading = false;
  public clientePropio = LoginService.getCliente();
  //// Tabla nueva
  public name = ListadoAlarmasDomiciliariasComponent.name;
  public alarmasDomiciliarias: IDispositivoAlarma[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<IDispositivoAlarma>[];
  //Subs
  public modelo$?: Subscription;
  public alarmasDomiciliarias$?: Subscription;
  public clientes$?: Subscription;

  ///////////////////////////////////////////////
  public eventos$: Subscription;
  ////////////////////////////////////////////////
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar:
      this.helper.puedeEditar() &&
      HelperService.getConfigCliente().moduloAlarmasDomiciliarias
        ?.crearDispositivos,
    tooltip: 'Crear Alarma Domiciliaria',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // FILTROS
  //Cliente
  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  //Modelo
  public modelo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idModelo',
    },
    label: 'Modelo',
    selectLabel: 'modelo',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarMarcas$',
    searchOn: ['modelo'], // Chusmear esta cosa que agregué en el corchete. Estaba solo marca. Preguntar Fer
  };

  //Marca
  public marca: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idModelo',
    },
    label: 'Marca',
    selectLabel: 'marca',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarMarcas$',
    searchOn: ['marca'], // Chusmear esta cosa que agregué en el corchete. Estaba solo marca. Preguntar Fer
  };

  //Estado de la cuenta
  private habilitado: IFiltroTabla = {
    elementos: [
      {
        label: 'Habilitado ✔️',
        filter: [{ estadoCuenta: null }, { estadoCuenta: 'Habilitado' }],
      },
      { label: 'Suspendido ❌', filter: [{ estadoCuenta: 'Suspendido' }] },
    ],
    filter: {
      field: '$or',
    },
    label: 'Habilitación',
    tipo: 'select',
    selectLabel: 'label',
    selectValue: 'filter',
  };

  //Alta o no alta
  private Alta: IFiltroTabla = {
    elementos: [
      {
        label: 'Dado de alta',
        value: [{ fechaAlta: { $exists: true, $ne: null } }],
      },
      { label: 'Sin alta', value: [{ fechaAlta: null }] },
    ],
    filter: {
      field: '$or',
    },
    label: 'Alta',
    tipo: 'select',
    selectLabel: 'label',
    selectValue: 'value',
  };

  //Modo desactivado
  private activacion: IFiltroTabla = {
    elementos: [
      {
        label: 'Activada ✔️',
        filter: [
          { 'modoDesactivado.dispositivoDesactivado': null },
          { 'modoDesactivado.dispositivoDesactivado': false },
        ],
      },
      {
        label: 'Desactivada ❌',
        filter: [{ 'modoDesactivado.dispositivoDesactivado': true }],
      },
    ],
    filter: {
      field: '$or',
    },
    label: 'Activación',
    tipo: 'select',
    selectLabel: 'label',
    selectValue: 'filter',
  };

  //Fecha de alta
  private fechaAlta: IFiltroTabla = {
    filter: {
      field: 'fechaAlta',
    },
    label: 'Buscar fecha de alta',
    tipo: 'dateRange',
  };

  public filtros: IFiltroTabla[] = [
    this.modelo,
    this.marca,
    this.habilitado,
    this.Alta,
    this.fechaAlta,
    this.activacion,
  ];
  //Query
  public filter: IFilter<IDispositivoAlarma> = {};
  public populate: IPopulate[] = [
    {
      path: 'domicilio',
    },
    {
      path: 'modelo',
      populate: { path: 'codigos' },
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'comunicador',
    },
    {
      path: 'camaras',
      select: 'identificacion',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: AlarmasDomiciliariasService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
    private eventosService: EventosService,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes(), this.listarModelos()]);
    } else {
      await this.listar();
    }
  }

  public async listar(): Promise<void> {
    this.alarmasDomiciliarias$?.unsubscribe();
    this.alarmasDomiciliarias$ = this.listados
      .subscribe<
        IListado<IDispositivoAlarma>
      >('dispositivosAlarmas', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.alarmasDomiciliarias = data.datos;
        console.log(`listado de dispositivosAlarmas`, data);
      });
    await this.listados.getLastValue('dispositivosAlarmas', this.query);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  private async listarModelos(): Promise<void> {
    const query: IQueryParam = {
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.modelo$?.unsubscribe();
    this.modelo$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.modelo.elementos = data.datos;
        console.log(`listado de modleosDispositivos`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(dispositivoAlarma: IDispositivoAlarma) {
    this.navigator.navigate(['editar', dispositivoAlarma._id], {
      relativeTo: this.route,
    });
  }

  public async crearEstadoEntidad(alarma: IDispositivoAlarma) {
    this.dialog.open(CrearEditarEstadoEntidadComponent, {
      data: { alarma },
    });
  }

  public async detalles(dispositivoAlarma: IDispositivoAlarma) {
    this.navigator.navigate(['detallesAlarma', dispositivoAlarma._id], {
      relativeTo: this.route,
    });
  }

  public async exportarEventos(dispositivoAlarma: IDispositivoAlarma) {
    const rango = await this.dialogService.dateRange(
      'Exportar Eventos',
      'Seleccione el rango de fechas para exportar los eventos',
    );
    if (!rango) return;

    const filter: IFilter<IEvento> = {
      idAlarma: dispositivoAlarma._id,
      fechaCreacion: {
        $gte: rango?.start,
        $lte: rango?.end,
      },
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'fechaCreacion',
      page: 0,
      limit: 10000,
      includeChildren: true,
      select: 'valores.titulo valores.mensaje fechaCreacion codigoEvento',
    };

    await this.eventosService.exportar(query, dispositivoAlarma.nombre);
  }

  public async modoDesactivado(dispositivoAlarma: IDispositivoAlarma) {
    this.dialog.open(ModoDesactivadoDispositivoComponent, {
      data: { alarma: dispositivoAlarma },
      width: '850px',
    });
  }

  public async accionesAlarma(dispositivoAlarma: IDispositivoAlarma) {
    this.dialog.open(AccionesAlarmaComponent, {
      data: { alarma: dispositivoAlarma },
      width: '850px',
    });
  }

  public async darAlta(dispositivoAlarma: IDispositivoAlarma) {
    const res = await this.dialogService.prompt(
      'Dar de Alta Alarma Domiciliaria',
      'Ingrese la fecha de alta de la alarma',
      'Fecha de Alta',
      'Dar de Alta',
      'Cancelar',
      'datetime',
    );
    if (!res) return;

    this.loading = true;
    const update: IUpdateDispositivoAlarma = {
      fechaAlta: res,
    };
    await this.service.update(dispositivoAlarma._id!, update);
    this.loading = false;
  }

  public async configurarComunicador(dispositivoAlarma: IDispositivoAlarma) {
    this.dialog.open(ConfigurarComunicadorComponent, {
      data: dispositivoAlarma,
      width: '850px',
    });
  }

  public async eventoTecnico(alarma: IDispositivoAlarma) {
    const data: ICEETData = {
      id: null, // Evento nuevo
      categoria: 'Alarma',
      idAlarma: alarma._id,
      idsCQAET:
        alarma?.idsClientesQuePuedenAtender ||
        this.clientePropio.config?.idsClientesQuePuedenAtenderEventosTecnicos ||
        null,
    };
    this.dialog.open(CrearEditarEventosTecnicosComponent, {
      data,
      width: '850px',
    });
  }

  public parseDomicilio(dato: IDispositivoAlarma) {
    if (dato?.domicilio?.direccion) {
      let text = dato.domicilio?.direccion;
      if (text.length >= 50) {
        text = text.slice(0, 50);
        text += '...';
      }
      return text;
    } else {
      return '';
    }
  }

  public parseCamaras(dato: IDispositivoAlarma) {
    let text = dato.camaras.map((camara) => camara.identificacion).join(', ');
    if (text) {
      if (text.length >= 50) {
        text = text.slice(0, 50);
        text += '...';
      }
      return text;
    } else {
      return '';
    }
  }

  public async eliminar(dispositivoAlarma: IDispositivoAlarma) {
    const res = await this.dialogService.confirm(
      'Eliminar Alarma Domiciliaria',
      `¿Desea eliminar la alarma ${dispositivoAlarma.modelo?.marca} ${dispositivoAlarma.modelo?.modelo} | ${dispositivoAlarma.domicilio?.direccion}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(dispositivoAlarma._id!);
      this.helper.notifSuccess('Alarma domiciliaria eliminada');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IDispositivoAlarma>[] = [
      //nombre
      {
        header: {
          label: 'Nombre',
        },
        row: {
          field: 'nombre',
        },
      },

      //Estado de la cuenta
      {
        header: {
          label: 'Estado de la cuenta',
        },
        row: {
          parse: (dato) => {
            if (
              dato.estadoCuenta == null ||
              dato.estadoCuenta === 'Habilitado'
            ) {
              return '✔️'; // El ícono
            } else if (dato.estadoCuenta === 'Suspendido') {
              return '❌'; // El ícono
            }
            return ''; // Valor por defecto
          },
          tooltip: (dato) => {
            if (
              dato.estadoCuenta == null ||
              dato.estadoCuenta === 'Habilitado'
            ) {
              return 'Habilitada'; // Tooltip
            } else if (dato.estadoCuenta === 'Suspendido') {
              return 'Suspendida'; // Tooltip
            }
            return ''; // Valor por defecto si no tiene tooltip
          },
        },
      },

      //Modo desactivado
      {
        header: {
          label: 'Modo de la cuenta',
        },
        row: {
          parse: (dato) => {
            const icon = dato.modoDesactivado?.dispositivoDesactivado
              ? '❌'
              : '✔️';
            return icon;
          },
          tooltip: (dato) => {
            const tooltip = dato.modoDesactivado?.dispositivoDesactivado
              ? 'Desactivada'
              : 'Activada';
            return tooltip; // Tooltip
          },
        },
      },

      //Fecha de alta
      {
        header: {
          label: 'Fecha de Alta',
          sortable: true,
        },
        row: {
          field: 'fechaAlta',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
          noData: 'Sin alta',
        },
      },

      //Alarma
      {
        header: {
          label: 'Alarma',
        },
        row: {
          parse(dato) {
            if (dato.modelo) {
              let text = `${dato.modelo?.marca} - ${dato.modelo?.modelo}`;
              if (text.length >= 50) {
                text = text.slice(0, 50);
                text += '...';
              }
              return text;
            }
            return '';
          },
        },
      },

      //Domicilio
      {
        header: {
          label: 'Domicilio',
          sortable: true,
        },
        row: {
          parse: (dato) => this.parseDomicilio(dato),
          noData: 'Sin Domicilio',
        },
      },
      {
        header: {
          label: 'Cámaras',
          sortable: true,
        },
        row: {
          parse: (dato) => this.parseCamaras(dato),
          noData: 'Sin Cámaras',
        },
      },

      ///ÚLTIMA COMUNICACIÓN
      {
        header: {
          label: 'Última Comunicación',
          sortable: true,
          sort: 'fechaUltimaComunicacion',
        },
        row: {
          html: (dato) => {
            if (dato.fechaUltimaComunicacion) {
              const fechaUltimaComunicacion = new Date(
                dato.fechaUltimaComunicacion,
              );
              if (dato.frecReporte) {
                const frecuenciaReporte = dato.frecReporte;
                const tiempoReporte = fechaUltimaComunicacion.getTime();
                const tiempoActual = new Date().getTime();
                const diff = tiempoActual - tiempoReporte;

                const frecuenciaEnMs = frecuenciaReporte * 60 * 1000;
                let color = 'green';

                if (diff > 2 * frecuenciaEnMs) {
                  color = 'red';
                } else if (diff > frecuenciaEnMs && diff < 2 * frecuenciaEnMs) {
                  color = 'yellow';
                }

                // Estilo y formato de la visualización del último reporte
                const fecha = HelperService.parseDate(fechaUltimaComunicacion);

                let html = `<div style="display: flex; gap: 1em; align-items: center">`;

                // Icono de color correspondiente con matTooltip
                html += `<span 
                           style="color: ${color}; font-size: 2em;" 
                           >&#x2B24;</span>`;

                // Mostrar la fecha de la última comunicación
                html += `<span>${fecha.toLocaleString()} </span>`;
                html += `</div>`;

                return html;
              } else {
                // Caso cuando no hay frecuencia de reporte definida
                const fecha = HelperService.parseDate(fechaUltimaComunicacion);

                let html = `<div style="display: flex; gap: 1em; align-items: center">`;

                // Icono con tooltip para sin frecuencia de reporte
                html += `<span 
                           style="color: black; font-size: 2em;" 
                           >&#x2B24;</span>`;

                // Mostrar la fecha de la última comunicación
                html += `<span>${fecha.toLocaleString()} </span>`;
                html += `</div>`;

                return html;
              }
            } else {
              return ''; // Si no hay fecha de última comunicación, no mostramos nada
            }
          },

          tooltip: (dato) => {
            if (dato.fechaUltimaComunicacion) {
              const fechaUltimaComunicacion = new Date(
                dato.fechaUltimaComunicacion,
              );
              if (dato.frecReporte) {
                const frecuenciaReporte = dato.frecReporte;
                const tiempoReporte = fechaUltimaComunicacion.getTime();
                const tiempoActual = new Date().getTime();
                const diff = tiempoActual - tiempoReporte;

                const frecuenciaEnMs = frecuenciaReporte * 60 * 1000;
                let tooltip = 'Comunicación reciente';

                if (diff > 2 * frecuenciaEnMs) {
                  tooltip = 'Sin comunicación reciente';
                } else if (diff > frecuenciaEnMs && diff < 2 * frecuenciaEnMs) {
                  tooltip = 'Comunicación algo retrasada';
                }

                return tooltip;
              } else {
                // Caso cuando no hay frecuencia de reporte definida

                const tooltip = 'No hay frecuencia de reporte';
                return tooltip;
              }
            } else {
              const tooltip = '';
              return tooltip; // Si no hay fecha de última comunicación, no mostramos nada
            }
          },

          noData: 'Sin comunicación',
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        accionesGrouped: [
          // Detalles
          {
            tipo: 'material',
            color: 'accent',
            icon: 'search',
            tooltip: 'Detalles',
            click: (dato) => this.detalles(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          // Exportar Eventos
          {
            tipo: 'material',
            color: 'accent',
            icon: 'download',
            tooltip: 'Exportar Eventos',
            click: (dato) => this.exportarEventos(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          // Dar de Alta
          {
            tipo: 'material',
            color: 'accent',
            icon: 'check_circle',
            tooltip: 'Dar de Alta',
            click: (dato) => this.darAlta(dato),
            oculta: (dato) => !!dato.fechaAlta,
            disabled: () => !this.helper.puedeEditar(),
          },
          // Editar
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          // Configurar Comunicador
          {
            tipo: 'material',
            color: 'accent',
            icon: 'tune',
            tooltip: 'Configurar Comunicador',
            click: (dato) => this.configurarComunicador(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          // Evento Técnico
          {
            tipo: 'material',
            color: 'accent',
            icon: 'engineering',
            tooltip: 'Servicio Técnico',
            click: (dato) => this.eventoTecnico(dato),
            disabled: () => !this.helper.verModuloEventosTecnicos(),
          },
          // Cambiar estado de la cuenta
          {
            tipo: 'material',
            color: 'accent',
            icon: 'manage_accounts',
            tooltip: 'Cambiar estado de la cuenta',
            click: (dato) => this.crearEstadoEntidad(dato),
            oculta() {
              return HelperService.getTipoCliente() === 'Final';
            },
            disabled: () => !this.helper.puedeEditar(),
          },
          // Modo Desactivado
          {
            tipo: 'material',
            color: 'accent',
            icon: 'do_not_disturb_on',
            tooltip: 'Modo Desactivado',
            click: (dato) => this.modoDesactivado(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          // Comandos Alarma
          {
            tipo: 'material',
            color: 'accent',
            icon: 'key',
            tooltip: 'Comandos Alarma',
            click: (dato) => this.accionesAlarma(dato),
            disabled: (dato) =>
              !this.helper.puedeEditar() ||
              !(
                dato?.comunicador?.formatoMensaje === 'Netio' ||
                dato?.comunicador?.formatoMensaje === 'Garnet-Titanium 4G-MAX-T'
              ),
          },
          // Eliminar
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }
  // Hooks

  async ngOnInit() {
    this.loading = true;
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listar();
    this.loading = false;
  }

  async ngOnDestroy() {
    this.alarmasDomiciliarias$?.unsubscribe();
  }
}
