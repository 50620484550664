import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from '../servicios/loading.service';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  SelectMode,
} from '@danielmoncada/angular-datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

export interface DateRange {
  start?: string;
  end?: string;
}

@Component({
  selector: 'app-datetime-picker',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  templateUrl: './datetime-picker.component.html',
  styleUrl: './datetime-picker.component.scss',
})
export class DatetimePickerComponent implements OnInit {
  @Input() public date?: string;
  @Output() public dateChange = new EventEmitter<string>();

  _date: Date = new Date();
  @Input() public maxDate = new Date();
  @Input() public placeholder = 'Fecha';
  @Input() public selectMode: SelectMode = 'single';

  constructor(public loading: LoadingService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onDateTimeChange(e: any) {
    const value = e.value as Date;
    this.dateChange.emit(value?.toISOString());
  }

  ngOnInit() {
    this._date = this.date ? new Date(this.date) : new Date();
    this.dateChange.emit(this._date.toISOString());
  }
}
