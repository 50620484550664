import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IDispositivoAlarma,
  IActivo,
  ICreateConfigEventoUsuario,
  IUpdateConfigEventoUsuario,
  IEstadoEntidad,
  estadoCuenta,
  ITracker,
} from 'modelos/src';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../../auxiliares/auxiliares.module';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { EstadoEntidadsService } from '../estado-entidad.service';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-crear-editar',
  templateUrl: './crear-editar.component.html',
  styleUrl: './crear-editar.component.scss',
})
export class CrearEditarEstadoEntidadComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public title?: string;
  public loading = false;

  public estados: estadoCuenta[] = ['Habilitado', 'Suspendido'];
  public motivos: string[] = [
    'Baja o suspensión de módulo',
    'Cambio de tituralidad',
    'Mail del gremio',
    'Sin Motivo',
    'Mudanza',
    'Solicitado por cliente',
    'Suspención por cobranzas',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      estadoEntidad?: IEstadoEntidad;
      activo?: IActivo;
      alarma?: IDispositivoAlarma;
      tracker?: ITracker;
    },
    public helper: HelperService,
    private service: EstadoEntidadsService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private listados: ListadosService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      // estado?: estadoCuenta;
      // idEntidad?: string; /// POR DISPOSITIVO
      // idCliente?: string; /// Back End
      // idUsuario?: string; /// Back End
      // nota?: string;
      // motivos?: string[];
      // vigencia?: string; // Desde cuando se aplica el estado
      estado: new FormControl(this.data.estadoEntidad?.estado, [
        Validators.required,
      ]),
      idEntidad: new FormControl(this.data.estadoEntidad?.idEntidad, [
        Validators.required,
      ]),
      nota: new FormControl(this.data.estadoEntidad?.nota),
      motivos: new FormControl(this.data.estadoEntidad?.motivos),
      vigencia: new FormControl(this.data.estadoEntidad?.vigencia, [
        Validators.required,
      ]),
    });
  }

  //
  public async onSubmit() {
    this.loading = true;
    const res = await this.dialogService.confirm(
      `Confirme la acción`,
      `¿Desea ${this.title.toLowerCase()} ?`,
    );
    if (!res) return;
    try {
      const data = this.getData();
      if (this.data?.estadoEntidad?._id) {
        // Update
        await this.service.update(this.data.estadoEntidad._id, data);
        this.helper.notifSuccess('Estado Entidad actualizada');
        this.dialog.closeAll();
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Estado Entidad creada');
        this.dialog.closeAll();
        this.loading = false;
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
      this.loading = false;
    }
  }

  public getData(): ICreateConfigEventoUsuario | IUpdateConfigEventoUsuario {
    const data: ICreateConfigEventoUsuario | IUpdateConfigEventoUsuario =
      this.form?.value;
    return data;
  }

  private patchIdEntidad() {
    if (this.data?.activo) {
      this.form.patchValue({ idEntidad: this.data.activo.idTracker });
    } else if (this.data?.alarma) {
      this.form.patchValue({ idEntidad: this.data.alarma._id });
    } else if (this.data?.tracker) {
      this.form.patchValue({ idEntidad: this.data.tracker._id });
    }
  }

  async ngOnInit() {
    this.title =
      this.data.estadoEntidad ||
      this.data.activo ||
      this.data.alarma ||
      this.data.tracker
        ? 'Editar estado de la cuenta'
        : 'Cambiar estado de la cuenta';
    this.createForm();
    this.patchIdEntidad();
  }

  public async ngOnDestroy(): Promise<void> {}
}
