import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import Hls from 'hls.js';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-videogular',
  templateUrl: './videogular.component.html',
  styleUrls: ['./videogular.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MatIcon,
    ButtonComponent,
  ],
})
export class VideogularComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor() {}

  @Input() containerSize!: { width: number; height: number };

  private videoPlayer: HTMLVideoElement;
  public name = signal(this.generateRandomNumber(1, 10000000000000).toString());
  public loading = signal(true);
  private hls: Hls;

  @Input() url: string;
  @Input() public controles?: boolean = false;
  @Input() public botonMultiple?: boolean = false;
  @Input() public maximizado?: boolean = false;
  @Output() public maximizadoEmiter? = new EventEmitter<boolean>();

  ngOnInit() {}

  ngOnDestroy() {
    console.log('destroy video component');
    this.videoPlayer.src = '';
    this.hls.destroy();
  }

  ngOnChange() {}

  ngAfterViewInit(): void {
    this.videoPlayer = document.getElementById(this.name()) as HTMLVideoElement;
    this.hls = new Hls();
    const hlsUrl = this.url;
    if (Hls.isSupported()) {
      this.hls.loadSource(hlsUrl);
      this.hls.attachMedia(this.videoPlayer);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.loading.set(false);
        this.videoPlayer.play();
      });
    } else if (this.videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
      // Para Safari y navegadores con soporte nativo de HLS
      this.videoPlayer.src = hlsUrl;
      this.videoPlayer.addEventListener('loadedmetadata', () => {
        this.loading.set(false);
        this.videoPlayer.play();
      });
    }
  }

  public botonClick() {
    console.log('emiteado');
    this.maximizadoEmiter.emit(true);
  }

  generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
