<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        @if (vehiculo) {
          <span>Editar {{ vehiculo?.identificacion }}</span>
        } @else {
          <span>Crear Vehículo</span>
        }
      </mat-card-title>
    } @else {
      <mat-card-title>
        @if (vehiculo) {
          <span>Editar {{ vehiculo?.identificacion }}</span>
        } @else {
          <span>Crear Vehículo</span>
        }
      </mat-card-title>
    }
  </mat-card-header>

  <form *ngIf="form" [formGroup]="form" autocomplete="off">
    <!-- Form -->
    <mat-card-content
      style="margin: 2em 0; overflow: auto"
      [class.cardContentDialog]="dialogRef"
      [class.cardContentFullscreen]="!dialogRef"
    >
      <!-- Debug -->
      <!-- {{ loading }}
        <br />
        {{ form.valid }}
        <br />
        {{ form.value | json }}
        <br /> -->

      <!-- identificacion - estado - cliente - tipo vehiculo -->
      <section class="layout">
        <!-- Identificacion -->
        <div class="grow1">
          <mat-form-field>
            <mat-label>Identificación</mat-label>
            <input matInput formControlName="identificacion" />
          </mat-form-field>
        </div>

        <!-- Estado -->
        <div class="grow1" [formGroup]="vehiculoForm">
          <mat-form-field>
            <mat-label>Estado</mat-label>
            <mat-select formControlName="estado">
              @for (e of estados; track e) {
                <mat-option [value]="e">{{ e }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Cliente -->
        @if (!helper.esClienteFinal() && !vehiculo) {
          <!-- Clientes -->
          <div style="width: 25%; display: flex">
            <ng-select
              [items]="clientes"
              bindLabel="nombre"
              bindValue="_id"
              formControlName="idCliente"
              placeholder="Cliente"
              [notFoundText]="'No hay clientes creados'"
              style="width: 95%"
              multiple="false"
              searchable="true"
              appendTo="body"
              required="true"
              (change)="clienteElegido($event)"
            >
              <!-- Listado de opciones -->
              <ng-template ng-option-tmp let-item="item">
                <div>
                  <span>
                    {{ item.nombre }}
                    @if (clientePropio._id === item._id) {
                      (Propio)
                    }
                  </span>
                  @if (clientePropio._id === item._id) {
                    <mat-divider></mat-divider>
                  }
                </div>
              </ng-template>
            </ng-select>
            <!-- Boton crear cliente -->
            @if (helper.verModuloAdministracion()) {
              <app-button
                style="margin-left: 5px"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="crearCliente()"
              ></app-button>
            }
          </div>
        }

        <!-- Tipo de vehiculo -->
        <div [formGroup]="vehiculoForm" style="width: 25%">
          <ng-select
            [items]="tipos"
            formControlName="tipo"
            placeholder="Tipo"
            [notFoundText]="'No hay tipos disponibles'"
          >
          </ng-select>
        </div>
      </section>

      <!-- Funciones - Tracker - Grupo - Chofer -->
      <section class="layout">
        <!-- Funciones -->
        <div class="grow1">
          <ng-select
            [items]="funciones"
            formControlName="funcion"
            placeholder="Función"
            [notFoundText]="'No hay funciones disponibles'"
          >
          </ng-select>
        </div>
        <!-- Tracker -->
        <div class="grow1">
          <div style="width: 100%; display: flex">
            <ng-select
              style="width: 90%"
              [items]="trackers"
              bindLabel="nombre"
              bindValue="_id"
              formControlName="idTracker"
              placeholder="Tracker"
              [notFoundText]="'No hay trackers disponibles'"
            >
            </ng-select>
            <app-button
              style="margin-left: 5px"
              [disabled]="!this.form.value.idCliente"
              matType="mat-fab"
              color="accent"
              icon="add"
              (click)="form?.value?.idCliente ? crearTracker() : null"
            ></app-button>
          </div>
        </div>
        <!-- Grupo -->
        <div class="grow1">
          <div style="width: 100%; display: flex">
            <ng-select
              style="width: 90%"
              [items]="grupos"
              bindLabel="nombre"
              bindValue="_id"
              formControlName="idGrupo"
              placeholder="Flota"
              [notFoundText]="'No hay grupos creados'"
              multiple="false"
              searchable="true"
              appendTo="body"
            >
            </ng-select>
            <app-button
              style="margin-left: 5px"
              [disabled]="!this.form.value.idCliente"
              matType="mat-fab"
              color="accent"
              icon="add"
              (click)="form?.value?.idCliente ? crearGrupo() : null"
            ></app-button>
          </div>
        </div>
        <!-- Chofer -->
        <div class="grow1" [formGroup]="vehiculoForm" style="display: flex">
          <ng-select
            style="width: 90%"
            [items]="choferes"
            bindLabel="usuario"
            bindValue="_id"
            formControlName="idChofer"
            placeholder="Chofer"
          >
          </ng-select>
          <app-button
            style="margin-left: 5px"
            [disabled]="!this.form.value.idCliente"
            matType="mat-fab"
            color="accent"
            icon="add"
            (click)="form?.value?.idCliente ? crearChofer() : null"
          ></app-button>
        </div>
      </section>

      <!-- Patente - marca - modelo - año - identificacion externa  -->
      <section>
        <div [formGroup]="vehiculoForm" class="layout">
          <!-- Patente -->
          <mat-form-field>
            <mat-label>Patente</mat-label>
            <input matInput formControlName="patente" />
          </mat-form-field>
          <!-- Marca -->
          <mat-form-field>
            <mat-label>Marca</mat-label>
            <input matInput formControlName="marca" />
          </mat-form-field>
          <!-- Modelo -->
          <mat-form-field>
            <mat-label>Modelo</mat-label>
            <input matInput formControlName="modelo" />
          </mat-form-field>
          <!-- Año -->
          <mat-form-field>
            <mat-label>Año</mat-label>
            <input matInput formControlName="anio" />
          </mat-form-field>
          <!-- Identificacion externa -->
          <mat-form-field>
            <mat-label>Identificación Externa</mat-label>
            <input matInput formControlName="idExterno" />
          </mat-form-field>
        </div>
      </section>
    </mat-card-content>

    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <!-- Volver -->
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <!-- Guardar -->
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        (click)="!(!this.form.valid || loading) ? onSubmit() : null"
      ></app-button>
    </mat-card-actions>
  </form>
</mat-card>
