<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        @if (id) {
          <span>Editar {{ dispositivoAlarma?.nombre }}</span>
        } @else {
          <span>Crear Alarma</span>
        }
      </mat-card-title>
    } @else {
      <mat-card-title>
        @if (id) {
          <span>Editar {{ dispositivoAlarma?.nombre }}</span>
        } @else {
          <span>Crear Alarma</span>
        }
      </mat-card-title>
    }
  </mat-card-header>

  <form *ngIf="form" [formGroup]="form" autocomplete="off">
    <!-- Form -->
    <mat-card-content
      style="margin: 2em 0; overflow: auto"
      [class.cardContentDialog]="dialogRef"
      [class.cardContentFullscreen]="!dialogRef"
    >
      <!-- Cliente - Numero Abonado - Nombre -->
      <section class="row-center" style="width: 100%; gap: 16px">
        @if (!dispositivoAlarma) {
          <div style="width: 100%; display: flex">
            <!-- Cliente-->
            <ng-select
              [items]="clientes"
              bindLabel="nombre"
              bindValue="_id"
              formControlName="idCliente"
              placeholder="Cliente"
              [notFoundText]="'No hay clientes creados'"
              style="width: 100%"
              multiple="false"
              searchable="true"
              (change)="onClienteChange($event)"
            >
              <!-- Listado de opciones -->
              <ng-template ng-option-tmp let-item="item">
                <div>
                  <span>
                    {{ item.nombre }}
                    @if (clientePropio._id === item._id) {
                      (Propio)
                    }
                  </span>
                  @if (clientePropio._id === item._id) {
                    <mat-divider></mat-divider>
                  }
                </div>
              </ng-template>
            </ng-select>
            @if (helper.verModuloAdministracion()) {
              <app-button
                style="margin-left: 5px"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="crearCliente()"
              ></app-button>
            }
          </div>
        }

        <!--Número de abonado-->
        <mat-form-field>
          <mat-label>Número de abonado</mat-label>
          <input matInput formControlName="numeroAbonado" />
        </mat-form-field>

        <!--Nombre-->
        <mat-form-field>
          <mat-label>Identificación de la alarma</mat-label>
          <input matInput formControlName="nombre" />
        </mat-form-field>
      </section>

      <!-- Modelo Comunicador - IdUnico Comunicador - Password Comunicador -->
      <section class="row-center" style="width: 100%; gap: 16px">
        <!-- Modelo Comunicador-->
        <div style="width: 100%; display: flex">
          <ng-select
            [items]="comunicadores"
            bindLabel="nombre"
            bindValue="_id"
            formControlName="idComunicador"
            placeholder="Modelo de comunicador"
            [notFoundText]="'No hay comunicadores creados'"
            style="width: 100%"
            multiple="false"
            searchable="true"
          >
            <ng-template ng-label-tmp let-item="item">
              <span>{{ item.marca }} - {{ item.modelo }} </span>
            </ng-template>

            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <div style="padding-bottom: 5px">
                <span>{{ item.marca }} - {{ item.modelo }} </span>
              </div>
            </ng-template>
          </ng-select>
          <app-button
            [disabled]="!this.form.value.idCliente"
            style="margin-left: 5px"
            matType="mat-fab"
            color="accent"
            icon="add"
            (click)="form?.value?.idCliente ? crearModeloComunicador() : null"
          ></app-button>
        </div>

        <!-- Id Unico comunicador -->
        <mat-form-field>
          <mat-label>ID único de comunicador</mat-label>
          <input matInput formControlName="idUnicoComunicador" />
        </mat-form-field>

        <!-- Password comunicador -->
        <mat-form-field>
          <mat-label>Password comunicador</mat-label>
          <input matInput formControlName="passwordComunicador" />
        </mat-form-field>
      </section>

      <section>
        <!-- frecuencia de comunicación -->
        <mat-form-field>
          <mat-label>Frecuencia de comunicación (minutos)</mat-label>
          <input matInput formControlName="frecReporte" />
        </mat-form-field>

        <!-- Modelo de Alarma -->
        <div style="width: 100%; display: flex">
          <ng-select
            [items]="modelosAlarmas"
            bindLabel="modelo"
            bindValue="_id"
            formControlName="idModelo"
            placeholder="Modelo de alarma"
            [notFoundText]="'No hay modelos de alarmas creados'"
            style="width: 100%"
            multiple="false"
            searchable="true"
          >
            <ng-template ng-label-tmp let-item="item">
              <span>{{ item.marca }} - {{ item.modelo }} </span>
            </ng-template>

            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <div style="padding-bottom: 5px">
                <span>{{ item.marca }} - {{ item.modelo }} </span>
              </div>
            </ng-template>
          </ng-select>
          <app-button
            [disabled]="!this.form.value.idCliente"
            style="margin-left: 5px"
            matType="mat-fab"
            color="accent"
            icon="add"
            (click)="form?.value?.idCliente ? crearModeloAlarma() : null"
          ></app-button>
        </div>
      </section>

      <!-- Clientes que pueden atender-->
      <ng-select
        [items]="clientesQuePuedenAtender"
        bindLabel="nombre"
        bindValue="_id"
        formControlName="idsClientesQuePuedenAtender"
        placeholder="Clientes que atienden los eventos"
        [notFoundText]="'no se encontraron clientes'"
        style="width: 100%"
        multiple="true"
        searchable="true"
      >
      </ng-select>

      <mat-accordion displayMode="flat">
        <!-- Sims -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> SIM </mat-panel-title>
          </mat-expansion-panel-header>

          <section class="row-center" style="width: 100%; gap: 16px">
            <!-- Sim 1 -->
            <div [formGroup]="sim1">
              <h3>SIM 1</h3>

              <mat-form-field>
                <mat-label>ICCID</mat-label>
                <input matInput formControlName="iccid" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Número de teléfono</mat-label>
                <input matInput formControlName="numero" type="tel" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Operador</mat-label>
                <mat-select formControlName="operador">
                  @for (s of operadores; track s) {
                    <mat-option [value]="s">{{ s }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>APN</mat-label>
                <input
                  type="text"
                  placeholder="datos.personal.com"
                  matInput
                  formControlName="apn"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete #auto="matAutocomplete">
                  @for (option of filteredOptionsSim1 | async; track option) {
                    <mat-option [value]="option">{{ option }}</mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Usuario</mat-label>
                <input matInput formControlName="usuario" type="text" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" type="text" />
              </mat-form-field>
            </div>

            <!-- Sim 2 -->
            <div [formGroup]="sim2">
              <h3>SIM 2</h3>

              <mat-form-field>
                <mat-label>ICCID</mat-label>
                <input matInput formControlName="iccid" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Número de teléfono</mat-label>
                <input matInput formControlName="numero" type="tel" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Operador</mat-label>
                <mat-select formControlName="operador">
                  @for (s of operadores; track s) {
                    <mat-option [value]="s">{{ s }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>APN</mat-label>
                <input
                  type="text"
                  placeholder="datos.personal.com"
                  matInput
                  formControlName="apn"
                  [matAutocomplete]="auto1"
                />
                <mat-autocomplete #auto1="matAutocomplete">
                  @for (option of filteredOptionsSim2 | async; track option) {
                    <mat-option [value]="option">{{ option }}</mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Usuario</mat-label>
                <input matInput formControlName="usuario" type="text" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" type="text" />
              </mat-form-field>
            </div>
          </section>
        </mat-expansion-panel>

        <!-- Camaras -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Cámaras </mat-panel-title>
          </mat-expansion-panel-header>
          <br />
          <section class="layout">
            <!-- Cámaras-->
            <ng-select
              [items]="camaras"
              bindLabel="identificacion"
              bindValue="_id"
              formControlName="idsCamaras"
              placeholder="Cámaras"
              [notFoundText]="'No hay cámaras creadas'"
              style="width: 100%"
              multiple="true"
              searchable="true"
              (change)="asignarCamarasDeAlarma($event)"
              appendTo="body"
            >
            </ng-select>
            <app-button
              [disabled]="!this.form.value.idCliente"
              matType="mat-fab"
              color="accent"
              icon="add"
              (click)="form?.value?.idCliente ? crearCamara() : null"
            ></app-button>
          </section>
          <!-- Contenido -->
          <div>
            <!-- Listado de Códigos -->
            <div
              style="padding: 0.5em; border-radius: 5px"
              formArrayName="camarasPorZona"
              cdkDropList
              (cdkDropListDropped)="drop($event)"
            >
              @for (f of camarasForm.controls; track f; let i = $index) {
                <div
                  class="layout"
                  [formGroupName]="i"
                  style="align-items: baseline"
                >
                  <div class="grow1 div-camaraalarma" cdkDrag>
                    <!-- Drag indicator -->
                    <div>
                      <div class="custom-placeholder" *cdkDragPlaceholder></div>
                      <mat-icon
                        cdkDragHandle
                        color="accent"
                        style="cursor: move"
                      >
                        drag_indicator
                      </mat-icon>
                    </div>
                    <!-- Cámara -->
                    <div
                      style="
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Cámara</mat-label>
                      <mat-label>{{
                        nombreCamara(f.value.idCamara)
                      }}</mat-label>
                    </div>

                    <!-- Canal -->
                    <div
                      style="
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Canal</mat-label>
                      <mat-label>{{
                        nombreCanalCamara(f.value.idCamara, f.value.canal)
                      }}</mat-label>
                    </div>

                    <!-- Partición -->
                    <div
                      style="
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Partición</mat-label>
                      <mat-label>{{ f.value.particion }}</mat-label>
                    </div>
                    <!-- Zona -->
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Zona</mat-label>
                      <mat-label>{{ f.value.zona }}</mat-label>
                    </div>
                    <!-- Editar -->
                    <button
                      mat-mini-fab
                      color="warn"
                      type="button"
                      (click)="editCamaraAlarma(i)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <!-- Eliminar -->
                    <button
                      mat-mini-fab
                      color="warn"
                      type="button"
                      (click)="deleteCamaraAlarma(i)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              }
              <div class="row-center">
                <app-button
                  [matType]="'mat-fab extended'"
                  color="accent"
                  (click)="addCamara()"
                  text="Cámara por Zona"
                  icon="add"
                ></app-button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Info Zonas -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Info Zonas </mat-panel-title>
          </mat-expansion-panel-header>
          <br />
          <!-- Contenido -->
          <div>
            <!-- Listado de zonas -->
            <div
              style="padding: 0.5em; border-radius: 5px"
              formArrayName="infoZonas"
              cdkDropList
              (cdkDropListDropped)="drop($event)"
            >
              @for (f of infoZonasForm.controls; track f; let i = $index) {
                <div
                  class="layout"
                  [formGroupName]="i"
                  style="align-items: baseline"
                >
                  <div class="grow1 div-camaraalarma" cdkDrag>
                    <!-- Drag indicator -->
                    <div>
                      <div class="custom-placeholder" *cdkDragPlaceholder></div>
                      <mat-icon
                        cdkDragHandle
                        color="accent"
                        style="cursor: move"
                      >
                        drag_indicator
                      </mat-icon>
                    </div>
                    <!-- Nombre -->
                    <ng-select
                      [items]="tiposEventos"
                      bindLabel="nombre"
                      bindValue="nombre"
                      formControlName="nombre"
                      placeholder="Nombre"
                      [notFoundText]="'No hay tipo de eventos creados'"
                      style="width: 100%"
                      multiple="false"
                      searchable="true"
                      appendTo="body"
                      [addTag]="addTag.bind(this)"
                      [loading]="creandoNuevoTag"
                      required
                    >
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.nombre | titlecase }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        {{ item.nombre | titlecase }}
                      </ng-template>
                    </ng-select>
                    <!-- Partición -->
                    <mat-form-field
                      style="
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Partición</mat-label>
                      <input
                        matInput
                        placeholder="1"
                        formControlName="particion"
                        type="number"
                      />
                    </mat-form-field>
                    <!-- Zona -->
                    <mat-form-field
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <mat-label>Zona</mat-label>
                      <input
                        matInput
                        placeholder="1"
                        formControlName="zona"
                        type="number"
                      />
                    </mat-form-field>
                    <!-- Eliminar -->
                    <button
                      mat-mini-fab
                      color="warn"
                      type="button"
                      (click)="deleteInfoZona(i)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              }
              <div class="row-center">
                <app-button
                  [matType]="'mat-fab extended'"
                  color="accent"
                  (click)="addInfoZona()"
                  text="Info Zona"
                  icon="add"
                ></app-button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <br />
      <!-- TODO: Hay un error en esta seccion al editar -->
      <!-- Domicilios / Ubicaciones -->
      <section class="column-center" style="width: 100%; gap: 16px">
        <!-- Domicilio Existente -->
        <mat-checkbox
          (change)="onCheckboxChange($event)"
          [checked]="this.domicilioExistente"
        >
          ¿Domicilio Existente?
        </mat-checkbox>
        @if (!domicilioExistente) {
          <!-- Dirección -->
          <app-autocomplete-direccion
            [form]="formDomicilio"
            formControlDireccion="direccion"
            formControlGeoJSON="circleGeoJSON"
            [direccion]="domicilio?.direccion"
            (geoJSONChange)="centrarA = $event"
            style="width: 100%"
          ></app-autocomplete-direccion>

          <div style="width: 100%; height: auto">
            <!-- Formulario para marcar el domicilio -->
            <app-map-draw
              [form]="geojson"
              tipo="Point"
              [centrarA]="centrarA"
            ></app-map-draw>
          </div>
        } @else {
          <!-- Domicilio -->
          <!-- el ngmodel tira error -->
          <ng-select
            [items]="ubicaciones"
            bindLabel="direccion"
            bindValue="_id"
            placeholder="Domicilio"
            [notFoundText]="'No hay domicilios'"
            multiple="false"
            searchable="true"
            (change)="onClick($event)"
            style="width: 100%"
          >
          </ng-select>
          <div style="width: 100%; height: auto">
            <!-- Formulario para dibujar polígonos -->
            <app-map-draw
              [form]="geojson"
              tipo="Point"
              [centrarA]="centrarA"
            ></app-map-draw>
          </div>
        }
      </section>
    </mat-card-content>

    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <!-- Volver -->
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <!-- Guardar -->
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        (click)="!(!this.form.valid || loading) ? onSubmit() : null"
      ></app-button>
    </mat-card-actions>
  </form>
</mat-card>
