<div
  style="
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  "
>
  @if (!loading) {
    @if (dialogRef && paramsService.getParams()["dialog"]) {
      <mat-card
        style="
          border: 1px solid black;
          overflow-x: hidden;
          height: 100%;
          position: relative;
        "
      >
        <!-- Header -->
        <mat-card-header style="border-radius: 2px 2px 0 0">
          <mat-card-title
            cdkDrag
            cdkDragHandle
            cdkDragRootElement=".cdk-overlay-pane"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span style="padding-left: 8px">
              {{ camarasRes[0].camara?.identificacion }}
            </span>

            <div (click)="closeDialog()" class="icon-close">
              <mat-icon>close</mat-icon>
            </div>
          </mat-card-title>
        </mat-card-header>

        <!-- Content -->
        <mat-card-content
          style="
            margin: 1em 0;
            overflow: auto;
            justify-content: center;
            display: flex;
            overflow-x: hidden;
          "
        >
          <mat-divider></mat-divider>
          <!--  -->
          <div>
            <div
              style="
                display: flex;
                flex-direction: row;
                gap: 0.5em;
                margin-top: 1em;
              "
            >
              @if (!maximizado) {
                <section class="camaras-todos">
                  @if (camaras && camarasRes[0]) {
                    <div *ngFor="let camara of camaras | async">
                      @if (camara.canal) {
                        <div style="display: flex; position: relative">
                          <div
                            (click)="select(camara.camara, camara.canal)"
                            style="display: flex; align-items: center"
                          >
                            <app-stream-camara
                              [esDialog]="false"
                              [camara]="camara.camara"
                              [canal]="camara.canal"
                              [mainStream]="false"
                            ></app-stream-camara>
                          </div>
                          <div
                            style="position: absolute; z-index: 1000"
                            class="letra"
                          >
                            {{ camara.canal }}
                          </div>
                        </div>
                      }
                    </div>
                  }
                </section>
              }
              @if (maximizado) {
                <div (click)="select()" style="height: 100%; display: flex">
                  <app-stream-camara
                    [esDialog]="false"
                    [camara]="camaraActual"
                    [canal]="canalActual"
                    [mainStream]="true"
                  ></app-stream-camara>
                  <div style="position: absolute; z-index: 1000" class="letra">
                    {{ canalActual }}
                  </div>
                </div>
              }
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    } @else {
      @if (!maximizado) {
        <section class="camaras-todos">
          <div *ngFor="let camara of camaras | async">
            @if (camara.canal) {
              <div
                style="
                  display: flex;
                  position: relative;
                  justify-content: center;
                "
              >
                <!-- Straem -->
                <div style="display: flex; align-items: center">
                  <app-stream-camara
                    [esDialog]="false"
                    [camara]="camara.camara"
                    [canal]="camara.canal"
                    [mainStream]="false"
                    [controles]="true"
                    [botonMultiple]="true"
                    [maximizado]="maximizado"
                    (camaraEmiter)="select($event?.camara, $event?.canal)"
                  ></app-stream-camara>
                </div>
                <!-- Numero de canal -->
                <div style="position: absolute; z-index: 1000" class="letra">
                  {{ camara.canal }}
                </div>
              </div>
            }
          </div>
        </section>
      }
      @if (maximizado) {
        <div
          style="
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          @if (fechaEvento) {
            <div
              style="
                width: 90%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 3px;
              "
            >
              <app-button
                matType="mat-fab extended"
                color="warn"
                text="⏪ 5 min"
                (click)="volverAtras(5)"
              ></app-button>
              <app-button
                matType="mat-fab extended"
                color="warn"
                text="Hora Evento"
                (click)="fecha = fechaParaCamara(fechaEvento)"
              ></app-button>
              <app-button
                matType="mat-fab extended"
                color="warn"
                text="Vivo"
                (click)="fecha = null"
              ></app-button>
            </div>
          }
          <div style="position: relative">
            <div style="position: absolute; z-index: 1000" class="letra">
              {{ canalActual }}
            </div>
            <app-stream-camara
              [esDialog]="false"
              [camara]="camaraActual"
              [canal]="canalActual"
              [mainStream]="true"
              [controles]="true"
              [botonMultiple]="true"
              [maximizado]="maximizado"
              [fecha]="fecha"
              (camaraEmiter)="select()"
            ></app-stream-camara>
          </div>
        </div>
      }
    }
  }
  @if (!maximizado) {
    <!-- Paginador -->
    <mat-paginator
      style="width: 100%"
      [length]="dataSource.data.length"
      [pageSize]="4"
      [pageSizeOptions]="[4]"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  }
</div>
