<mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
  <!-- Header -->
  <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
  </ng-container>
  @if (form) {
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card-content style="max-height: 60vh; overflow: auto">
        <!-- ESTADO  -->
        <mat-form-field>
          <mat-label>Estado</mat-label>
          <mat-select formControlName="estado">
            @for (e of estados; track e) {
              <mat-option [value]="e">{{ e }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <!-- Nota -->
        <mat-form-field>
          <mat-label>Nota</mat-label>
          <input matInput formControlName="nota" />
        </mat-form-field>
        <!-- Motivos -->
        <mat-form-field>
          <mat-label>Motivos</mat-label>
          <mat-select formControlName="motivos" multiple>
            @for (m of motivos; track m) {
              <mat-option [value]="m">{{ m }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <!-- Vigencia -->
        <mat-form-field>
          <mat-label>Vigencia</mat-label>
          <input
            matInput
            [owlDateTime]="dt1"
            formControlName="vigencia"
            [disabled]="true"
          />
          <button
            mat-icon-button
            matSuffix
            [owlDateTimeTrigger]="dt1"
            type="button"
          >
            <mat-icon>schedule</mat-icon>
          </button>
          <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        </mat-form-field>
      </mat-card-content>
      <!-- Botones -->
      <mat-card-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="this.dialog.closeAll()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || this.loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-card-actions>
    </form>
  }
</mat-card>
