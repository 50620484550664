<div (click)="bringToFront()">
  <mat-card style="border: 1px solid black; overflow-x: hidden">
    <!-- Header -->
    <mat-card-header
      style="border-radius: 2px 2px 0 0"
      [style.background-color]="colorPorCategoria()"
      [style.color]="helper.colorDeTextoSegunColor(colorPorCategoria())"
    >
      <!-- [cdkDragBoundary]="dragBoundary" -->
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
        (cdkDragStarted)="bringToFront()"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <span style="padding-left: 8px">
          {{ data?.titulo }} - {{ data?.alarma?.nombre }} -
          {{ data?.cliente?.nombre }}
        </span>
        @if (estadoDeCuenta) {
          <span>({{ estadoDeCuenta }})</span>
        }
        <div (click)="closeDialog()" class="icon-close">
          <mat-icon> close </mat-icon>
        </div>
      </mat-card-title>
    </mat-card-header>
    <!-- Content -->
    <mat-card-content
      style="
        margin: 1em 0;
        overflow: auto;
        max-height: 30vh;
        overflow-x: hidden;
      "
    >
      <!--  -->
      <div>
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 1.5em;
            justify-content: space-between;
            margin-bottom: 1em;
          "
        >
          <div style="display: flex; flex-direction: column">
            <!-- Activo -->
            @if (data.activo) {
              <span>{{ data.activo.identificacion }}</span>
            }

            <!-- Vehiculo -->
            @if (data.activo?.vehiculo) {
              <span>
                {{ data.activo?.vehiculo?.tipo }} -
                {{ data.activo?.vehiculo?.patente }}
              </span>
            }

            <!-- Alarma -->
            @if (data.alarma) {
              <!-- Comunicador - Alarma -->
              @if (data.alarma?.comunicador || data.alarma?.modelo) {
                <span>
                  {{ data.alarma?.comunicador?.marca }}
                  {{ data.alarma?.comunicador?.modelo }} -
                  {{ data.alarma?.modelo?.marca }}
                  {{ data.alarma?.modelo?.modelo }}
                </span>
              }
              <!-- Domicilio -->
              @if (data.alarma?.domicilio) {
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  {{ data.alarma?.domicilio.identificacion }}
                  @if (data.alarma?.domicilio.fotos?.length) {
                    <div
                      (click)="verFotos(); $event.stopPropagation()"
                      class="icon-map"
                      matTooltip="Ver fotos"
                      style="scale: 0.75"
                    >
                      <mat-icon>image</mat-icon>
                    </div>
                  }
                </div>
              }
            }
          </div>

          <!-- Ver mapa -->
          <div
            (click)="verUbicacion(); $event.stopPropagation()"
            class="icon-map"
            matTooltip="Ver ubicación"
          >
            <mat-icon>map</mat-icon>
          </div>

          <!-- Servicios Contratados -->
          <app-mostrar-servicios
            [linea]="true"
            [size]="35"
            [idCliente]="data.idCliente"
          ></app-mostrar-servicios>
        </div>

        <!-- Informacion del evento -->
        <mat-divider></mat-divider>
        <div style="display: flex; flex-direction: column; margin: 1em 0">
          <!-- Detalles -->
          <span>
            {{ data.descripcion }}
          </span>
        </div>

        <mat-divider></mat-divider>
        <br />
        <!-- Tratamientos - Notas - Contactos -->
        <section class="layout">
          <!-- Tratamientos -->
          <div
            style="
              max-height: 500px;
              width: 50%;
              overflow-x: hidden;
              border: 1px solid black;
              border-radius: 10px;
              padding: 0.5em;
            "
          >
            <span class="titulo"> Tratamientos </span>
            <div style="margin-top: 0.5em">
              @if (tratamientosEvento?.length) {
                @for (tratamiento of tratamientosEvento; track tratamiento) {
                  <div
                    style="display: flex; flex-direction: column"
                    [class.even]="$even"
                  >
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                      "
                    >
                      <span class="estado">
                        {{ tratamiento.estadoTecnico }}
                      </span>
                      <span style="font-size: 14px">
                        {{ tratamiento.fechaCreacion | date: "short" }}
                      </span>
                    </div>
                    <span class="usuario">
                      {{ tratamiento.usuario?.usuario || "Sistema" }}
                    </span>
                    @if (tratamiento.nota) {
                      <span style="margin-top: 0.25em">
                        {{ tratamiento.nota }}
                      </span>
                    }
                    @if (tratamiento.notaInterna) {
                      <span style="margin-top: 0.25em">
                        Nota Interna: {{ tratamiento.notaInterna }}
                      </span>
                    }
                  </div>
                  <mat-divider class="divider"></mat-divider>
                }
              } @else {
                <span>No hay tratamientos</span>
              }
            </div>
          </div>

          <!-- Notas -->
          <div
            style="
              max-height: 500px;
              width: 50%;
              overflow-x: hidden;
              border: 1px solid black;
              border-radius: 10px;
              padding: 0.5em;
            "
          >
            <span class="titulo">Notas</span>
            <div style="margin-top: 0.5em">
              @if (notas.length) {
                @for (nota of notas; track nota) {
                  <div
                    style="display: flex; flex-direction: column"
                    [class.even]="$even"
                  >
                    <span>{{ nota.informacion?.nota }}</span>
                  </div>
                  <mat-divider class="divider"></mat-divider>
                }
              } @else {
                <span>No hay notas</span>
              }
            </div>
          </div>

          <!-- Contactos -->
          <div
            style="
              max-height: 500px;
              width: 50%;
              overflow-x: hidden;
              border: 1px solid black;
              border-radius: 10px;
              padding: 0.5em;
            "
          >
            <span class="titulo">Contactos</span>
            <div style="margin-top: 0.5em">
              @if (contactos.length) {
                @for (contacto of contactos; track contacto) {
                  <div
                    style="display: flex; flex-direction: column"
                    [class.even]="$even"
                  >
                    <!-- Nombre del conctacto / telefono -->
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                      "
                    >
                      <span class="estado">
                        {{ contacto.informacion?.contacto }}
                      </span>
                      <span style="font-size: 14px">
                        {{ contacto.informacion?.telefono }}
                      </span>
                    </div>
                    <!-- Nota -->
                    @if (contacto.informacion?.nota) {
                      <span class="usuario">
                        {{ contacto.informacion?.nota }}
                      </span>
                    }

                    <!-- Palabras de la alarma -->
                    @if (
                      contacto.informacion?.palabraSeguridadNormal ||
                      contacto.informacion?.palabraSeguridadEmergencia
                    ) {
                      <span>
                        Clave:
                        {{ contacto.informacion?.palabraSeguridadNormal }}
                      </span>
                      <span>
                        Contra clave:
                        {{ contacto.informacion?.palabraSeguridadEmergencia }}
                      </span>
                    }
                    <!-- <span>Número de usuario: {{ contacto.informacion?.numeroUsuarioAlarma }}</span> -->
                  </div>
                  <mat-divider class="divider"></mat-divider>
                }
              } @else {
                <span>No hay contactos</span>
              }
            </div>
          </div>
        </section>
      </div>
    </mat-card-content>

    <!-- Acciones -->
    <mat-dialog-actions
      style="
        display: flex;
        justify-content: space-between;
        padding: 6px;
        gap: 8px;
      "
    >
      @if (estadoActual !== "Finalizado") {
        <!-- Agregar Nota  -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          (click)="agregarNota(); $event.stopPropagation()"
          text="Agregar nota"
          icon="add"
        ></app-button>
      }
      @if (
        usuarioPropio.roles.includes("Técnico") && estadoActual !== "Finalizado"
      ) {
        <!-- Finalizar -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Finalizar"
          icon="check_circle"
          (click)="finalizar(); $event.stopPropagation()"
        ></app-button>
      }

      @if (
        !usuarioPropio.roles.includes("Técnico") &&
        estadoActual !== "Finalizado"
      ) {
        <!-- Reasignar -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Reasignar"
          icon="engineering"
          (click)="reasignar(); $event.stopPropagation()"
        ></app-button>

        <!-- Finalizar del Todo -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Finalizar"
          icon="check_circle"
          (click)="finalizarDelTodo(); $event.stopPropagation()"
        ></app-button>
      }

      <!-- Finalizada -->
      @if (estadoActual === "Finalizado") {
        <div class="row-center" style="width: 100%">
          <h2 class="no-info">Servicio técnico finalizado</h2>
        </div>
      }
    </mat-dialog-actions>
  </mat-card>
</div>
