import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  IConfigReenvio,
  ICliente,
  ICreateConfigReenvio,
  IListado,
  IQueryParam,
  IUpdateConfigReenvio,
  IAgrupacionReenvio,
  ITracker,
  IDispositivoAlarma,
  MetodoReenvio,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ConfigReenviosService } from '../../../../../auxiliares/servicios/http/config-reenvios.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crear-editar-config-reenvio',
  templateUrl: './crear-editar-config-reenvio.component.html',
  styleUrl: './crear-editar-config-reenvio.component.scss',
  standalone: false,
})
export class CrearEditarConfigReenviosComponent implements OnInit, OnDestroy {
  public loading = false;
  public id: string | null = null;
  public title = '';
  public configreenvio?: IConfigReenvio;
  public configreenvio$?: Subscription;

  public clientes?: ICliente[];
  public clientes$?: Subscription;
  public trackers?: ITracker[];
  public trackers$?: Subscription;
  public alarmas?: IDispositivoAlarma[];
  public alarmas$?: Subscription;

  public form?: FormGroup;

  public tiposEntidades = ['Tracker', 'Alarma'];
  public agrupacionesReenvio: IAgrupacionReenvio[] = [
    'Entidad',
    'Todos los trackers del cliente',
    'Todas las alarmas del cliente',
  ];
  public metodosReenvio: MetodoReenvio[] = [
    'Básico',
    'Seguridad Evento Externo',
  ];

  get opcionesReenvio() {
    return this.form?.get('opcionesReenvio') as FormGroup;
  }

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarConfigReenviosComponent>,
    private route: ActivatedRoute,
    private listados: ListadosService,
    private service: ConfigReenviosService,
    public helper: HelperService,
    private dialogService: DialogService,
  ) {}

  private async listar() {
    if (this.id) {
      this.configreenvio$?.unsubscribe();
      this.configreenvio$ = this.listados
        .subscribe<IConfigReenvio>('configReenvio', this.id!)
        .subscribe((data) => {
          this.configreenvio = data;
          console.log(`listado de configreenvio`, data);
        });
      await this.listados.getLastValue('configReenvio', this.id!);
    }
  }

  private async listarClientes() {
    this.clientes$?.unsubscribe();
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
      limit: 0,
      includeChildren: true,
      childrenLevel: 0,
    };
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  private async listarTrackers() {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
      limit: 0,
      includeChildren: true,
      childrenLevel: 0,
    };
    this.trackers$ = this.listados
      .subscribe<IListado<ITracker>>('trackers', query)
      .subscribe((data) => {
        this.trackers = data.datos;
        console.log(`listado de trackers`, data);
      });
    await this.listados.getLastValue('trackers', query);
  }

  private async listarAlarmas() {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
      limit: 0,
      includeChildren: true,
      childrenLevel: 0,
    };
    this.alarmas$ = this.listados
      .subscribe<IListado<IDispositivoAlarma>>('dispositivosAlarmas', query)
      .subscribe((data) => {
        this.alarmas = data.datos;
        console.log(`listado de alarmas`, data);
      });
    await this.listados.getLastValue('dispositivosAlarmas', query);
  }

  private createForm() {
    // identificacion?: string;
    // // Permisos
    // global?: boolean;
    // idClientes?: string[];
    this.form = new FormGroup(
      {
        agrupacionReenvio: new FormControl(
          this.configreenvio?.agrupacionReenvio,
          Validators.required,
        ),
        idClienteReenvio: new FormControl(this.configreenvio?.idClienteReenvio),
        idEntidadReenvio: new FormControl(this.configreenvio?.idEntidadReenvio),
        opcionesReenvio: new FormGroup({
          metodo: new FormControl(
            this.configreenvio?.opcionesReenvio?.metodo || 'Básico',
          ),
          host: new FormControl(this.configreenvio?.opcionesReenvio?.host),
          puerto: new FormControl(this.configreenvio?.opcionesReenvio?.puerto),
          apikey: new FormControl(this.configreenvio?.opcionesReenvio?.apikey),
        }),
        // Solo para el form
        tipoEntidad: new FormControl(
          this.configreenvio?.tracker ? 'Tracker' : 'Alarma',
        ),
      },
      // { validators: this.clientesValidator() },
    );
  }

  private getData() {
    const data: ICreateConfigReenvio | IUpdateConfigReenvio = this.form?.value;
    return data;
  }

  public volver() {
    this.helper.volver();
  }

  // Actions

  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title} ConfigReenvio`,
      `¿Desea ${this.title.toLowerCase()} la configuración de reenvio?`,
    );
    if (!res) return;
    try {
      if (this.id) {
        // Update
        const update = this.getData() as IUpdateConfigReenvio;
        if (!update) return;
        await this.service.update(this.id, update);
        this.helper.notifSuccess('Configuración de reenvio editada');
      } else {
        // Create
        const create = this.getData() as ICreateConfigReenvio;
        if (!create) return;
        await this.service.create(create);
        this.helper.notifSuccess('Configuración de reenvio creada');
      }
      this.helper.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  private async iniciar() {
    await this.listar();
    this.createForm();
  }

  // Hooks
  async ngOnInit() {
    this.loading = true;
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar' : 'Crear';
    this.iniciar();
    await Promise.all([
      this.listarClientes(),
      this.listarTrackers(),
      this.listarAlarmas(),
    ]);
    this.loading = false;
  }

  ngOnDestroy() {
    this.configreenvio$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.trackers$?.unsubscribe();
    this.alarmas$?.unsubscribe();
  }
}
