<app-tabla
  [totalCount]="totalCount"
  [datos]="eventosHistorico"
  [columnas]="columnas"
  [name]="name"
  [pageSizeOptions]="helper.pageSizeOptions"
  [search]="search"
  [(query)]="query"
  (queryChange)="listar()"
  [paginatorArriba]="false"
  [showHijos]="true"
  [filtros]="filtros"
></app-tabla>

<!-- Loading -->
@if (listando) {
  <div style="display: flex; justify-content: center; margin: 1em 0">
    <mat-spinner color="accent"></mat-spinner>
  </div>
}
