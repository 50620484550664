@if (form) {
  <mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <!-- Header -->
      <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
      </ng-container>

      <!-- Content -->
      <mat-card-content>
        <!-- Cámara -->
        <ng-select
          [items]="data.camarasDeAlarma"
          bindLabel="identificacion"
          bindValue="_id"
          formControlName="idCamara"
          placeholder="Cámara"
          [notFoundText]="'No hay cámaras asignadas'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          appendTo="body"
          (change)="camaraElegida()"
        >
        </ng-select>

        <!-- Canal -->
        <ng-select
          [items]="canalesCamaraElegida"
          formControlName="canal"
          bindValue="numero"
          placeholder="Canal de la cámara"
          [notFoundText]="'No hay cámaras seleccionada'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          appendTo="body"
        >
          <ng-template ng-label-tmp let-item="item">
            Numero Canal:
            {{ item.numero }} Nombre: {{ item.nombre }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            Numero Canal:
            {{ item.numero }} Nombre: {{ item.nombre }}
          </ng-template>
        </ng-select>

        @if (configsEventos.length !== 0) {
          @if (!personalizado) {
            <ng-select
              [items]="configsEventos"
              placeholder="Codigos Eventos"
              bindLabel="minutosDeGracia"
              style="width: 100%"
              multiple="false"
              searchable="true"
              appendTo="body"
              (change)="configElegida($event)"
            >
              <ng-template ng-label-tmp let-item="item">
                Particion:
                {{ item.configZona.particion }} Zona: {{ item.configZona.zona }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                Particion:
                {{ item.configZona.particion }} Zona: {{ item.configZona.zona }}
              </ng-template>
            </ng-select>
          }
          <mat-checkbox
            (change)="cambiarPersonalizado($event.checked)"
            [checked]="personalizado"
          >
            Personalizado</mat-checkbox
          >
        }
        @if (personalizado || configsEventos.length === 0) {
          <!-- Partición -->
          <mat-form-field style="margin-top: 1em">
            <mat-label>Partición de la alarma</mat-label>
            <input matInput formControlName="particion" />
          </mat-form-field>

          <!-- Zona -->
          <mat-form-field>
            <mat-label>Zona de la alarma</mat-label>
            <input matInput formControlName="zona" />
          </mat-form-field>
        }
      </mat-card-content>

      <mat-divider style="margin-top: 1em"></mat-divider>

      <!-- Acciones -->
      <mat-dialog-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="dialogRef.close()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-dialog-actions>
    </form>
  </mat-card>
}
