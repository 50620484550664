import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private apiLoaded = false;

  constructor() {}

  loadApi(): Promise<void> {
    if (!this.apiLoaded) {
      this.apiLoaded = true;
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src =
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyDpJVs3PWZ13MRU-UsOlx8gi_pXYVNE7PE';
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error('Error al cargar la API de Google Maps'));
        document.head.appendChild(script);
      });
    }
    return null; //Promise.resolve();
  }
}
