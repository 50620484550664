/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  IActivo,
  ICreateEnvioVehiculo,
  ICreateTratamientoEvento,
  IEnvioVehiculo,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { TratamientoEventosService } from '../../../../../auxiliares/servicios/http/tratamientos-eventos.service';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios';
import { EnviosVehiculosService } from '../../../../../auxiliares/servicios/http/envios-vehiculos';

@Component({
  selector: 'app-enviar-movil',
  templateUrl: './enviar-movil.component.html',
  styleUrl: './enviar-movil.component.scss',
  standalone: false,
})
export class EnviarMovilComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  public formTratamiento?: FormGroup;
  public formEnvio?: FormGroup;
  //listar
  public enviosVehiculos?: IEnvioVehiculo[];
  public enviosVehiculos$?: Subscription;
  public vehiculos?: IActivo[];
  public vehiculos$?: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { idsEventos: string[] },
    public helper: HelperService,
    private service: TratamientoEventosService,
    private envioVehiculoService: EnviosVehiculosService,
    public dialogRef: MatDialogRef<EnviarMovilComponent>,
    private listados: ListadosService,
  ) {}

  private createForm() {
    this.formTratamiento = new FormGroup({
      idsEventos: new FormControl(this.data?.idsEventos),
      nota: new FormControl(),
      notaInterna: new FormControl(),
      estado: new FormControl('En Atención'),
    });
    this.formEnvio = new FormGroup({
      idsEventos: new FormControl(this.data?.idsEventos),
      detalles: new FormControl(),
      idConductor: new FormControl(),
      idActivo: new FormControl(),
      estado: new FormControl('Asignado'),
    });
  }

  public async listarVehiculos() {
    const filter: IFilter<IActivo> = {
      funcion: 'Seguridad Privada',
      'vehiculo.idChofer': { $exists: true, $ne: null },
    } as any;
    const populate: IPopulate = {
      path: 'vehiculo.chofer',
      select: 'datosPersonales.nombre usuario',
    };

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', query)
      .subscribe((data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos`, data);
        this.ajustarDisponibles();
      });
    await this.listados.getLastValue('vehiculos', query);
  }

  public async listarEnviarVehiculo() {
    const filter: IFilter<IEnvioVehiculo> = {
      estado: { $nin: ['Finalizado', 'Rechazado'] },
    };
    const populate: IPopulate = {};

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };
    this.enviosVehiculos$?.unsubscribe();
    this.enviosVehiculos$ = this.listados
      .subscribe<IListado<IEnvioVehiculo>>('enviosVehiculos', query)
      .subscribe((data) => {
        this.enviosVehiculos = data.datos;
        console.log(`listado de envios vehiculos`, data);
        this.ajustarDisponibles();
      });
    await this.listados.getLastValue('enviosVehiculos', query);
  }

  private ajustarDisponibles() {
    if (this.vehiculos && this.enviosVehiculos) {
      this.vehiculos = this.vehiculos
        .map((v) => {
          const res = this.enviosVehiculos.find((e) => e.idActivo === v._id);
          if (!res) return v;
          else return null;
        })
        .filter((v) => v !== null);
      console.log('Disponibles: ', this.vehiculos);
    }
  }
  //Buscar
  public buscarVehiculo = (term: string, item: IActivo) => {
    if (item.identificacion?.toLowerCase().includes(term.toLowerCase()))
      return true;
    if (
      item.vehiculo?.chofer?.datosPersonales?.nombre
        ?.toLowerCase()
        .includes(term.toLowerCase())
    )
      return true;
    if (
      item.vehiculo?.chofer?.usuario?.toLowerCase().includes(term.toLowerCase())
    )
      return true;
    return false;
  };

  public asignarVehiculoConductor(data: IActivo) {
    this.formEnvio.patchValue({ idActivo: data._id });
    this.formEnvio.patchValue({ idConductor: data.vehiculo.chofer._id });
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const dataTratamiento = this.getData();
      dataTratamiento.nota = dataTratamiento.nota
        ? `Envio de Movil - ${dataTratamiento.nota}`
        : 'Envio de Movil';
      const dataEnvio = this.getDataEnvio();
      await Promise.all([
        this.envioVehiculoService.create(dataEnvio),
        this.service.create(dataTratamiento),
      ]);
      this.helper.notifSuccess('Creado correctamente');
      this.dialogRef.close(dataTratamiento);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateTratamientoEvento {
    const data: ICreateTratamientoEvento = this.formTratamiento?.value;
    return data;
  }
  public getDataEnvio(): ICreateEnvioVehiculo {
    const data: ICreateEnvioVehiculo = this.formEnvio?.value;
    return data;
  }

  async ngOnInit() {
    this.createForm();
    await Promise.all([this.listarVehiculos(), this.listarEnviarVehiculo()]);
    this.ajustarDisponibles();
    this.loading = false;
  }

  ngOnDestroy() {
    this.enviosVehiculos$?.unsubscribe();
    this.vehiculos$.unsubscribe();
  }
}
