import { Injectable } from '@angular/core';
import {
  ICamara,
  IListado,
  IQueryParam,
  IUpdateCamara,
  ICreateCamara,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';
import { HttpCamarasService } from '../http-camaras.service';

@Injectable({
  providedIn: 'root',
})
export class CamarasService {
  constructor(
    private http: HttpClientService,
    private httpCamaras: HttpCamarasService,
  ) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<ICamara>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/camaras`, { params });
  }

  public getById(id: string): Promise<ICamara> {
    return this.http.get(`/camaras/${id}`);
  }

  public create(dato: ICreateCamara): Promise<ICamara> {
    return this.http.post(`/camaras`, dato);
  }

  public update(id: string, dato: IUpdateCamara): Promise<ICamara> {
    return this.http.put(`/camaras/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/camaras/${id}`);
  }

  public live(
    id: string,
    canal: string,
  ): Promise<{
    mainStream: string;
    subStream: string;
  }> {
    return this.httpCamaras.get(`/camaras/live/${id}/${canal}`);
  }

  public record(
    id: string,
    canal: string,
    fecha: string,
  ): Promise<{
    mainStream: string;
    subStream: string;
  }> {
    return this.httpCamaras.get(`/camaras/record/${id}/${canal}/${fecha}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public streamingChannels(id: string): Promise<any> {
    return this.httpCamaras.get(`/camaras/streaming/channels/${id}`);
  }
}
