/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import {
  Agrupacion,
  Dia,
  Frecuencia,
  IActivo,
  ICategoriaEvento,
  ICodigoDispositivo,
  IConfigEventoUsuario,
  ICreateConfigEventoUsuario,
  ICreateTipoEvento,
  IDispositivoAlarma,
  IFilter,
  IGrupo,
  IListado,
  IQueryParam,
  ITipoEvento,
  IUbicacion,
  IUpdateConfigEventoUsuario,
  IUsuario,
  TipoEntidad,
  TipoEnvio,
} from 'modelos/src';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DateRange } from '../../../../auxiliares/date-range-selector/date-range-selector.component';
import { ConfigEventoUsuarioService } from '../../../../auxiliares/servicios/http/config-evento-usuario.service';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { ParamsService } from '../../../../auxiliares/servicios/params.service';
import { TiposEventosService } from '../../../../auxiliares/servicios/http/tipos-eventos.service';
import { CrearEditarCategoriaEventoComponent } from '../../../entidades/configuracion/categorias-eventos/crear-editar-categoria-evento/crear-editar-categoria-evento.component';

interface ITipoCondicion {
  tipo: TipoEntidad;
  valor: string;
}

export interface IParamEventoUsuario {
  tipoEntidad?: TipoEntidad;
  tipoEvento?: ITipoEvento;
  categoriaEvento?: ICategoriaEvento;
  agrupacion?: 'Grupo' | 'Entidad';
  idGrupo?: string;
  idEntidad?: string;
  tipoCondicion?: string;
  crear?: 'Dialog' | 'Page';
  botonCrear?: boolean;
  alarma?: string | IDispositivoAlarma;
  [key: string]: any;
}

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-crear-editar-config-evento-usuario-dialog',
  templateUrl: './crear-editar-config-evento-usuario-dialog.component.html',
  styleUrls: ['./crear-editar-config-evento-usuario-dialog.component.scss'],
})
export class CrearEditarConfigEventoUsuarioDialogComponent
  implements OnInit, OnDestroy
{
  public loading = false;
  public form?: FormGroup;
  public title?: string;
  public height = 'unset';
  public maxHeight = 'unset';

  public range?: DateRange = {};
  public opcionesDeEventoAlarma = [
    'Codigo Unico',
    'Codigos de Armado',
    'Codigos de Desarmado',
  ];
  public tiposEnvio: TipoEnvio[] = [
    // 'SMS',
    // 'WhatsApp',
    // 'Llamada',
    'Notificacion Push',
  ];
  public tiposAgrupaciones: {
    label: string;
    value: Agrupacion;
  }[] = [
    { label: 'Grupo', value: 'Grupo' },
    { label: 'Individual', value: 'Entidad' },
  ];
  public tiposEntidades: { label: string; value: TipoEntidad }[] = [
    { label: 'Activo', value: 'Activo' },
    { label: 'Vehículo', value: 'Vehiculo' },
    { label: 'Alarma', value: 'Alarma' },
  ];
  public tiposFrecuencias: { label: string; value: Frecuencia }[] = [
    { label: 'Única', value: 'Unica' },
    { label: 'Continua', value: 'Continua' },
    { label: 'Única en un periodo', value: 'Unica en un periodo' },
    { label: 'Continua en un periodo', value: 'Continua en un periodo' },
    { label: 'Cronograma', value: 'Cronograma' },
  ];
  public dias: Dia[] = [
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
    'Domingo',
  ];
  public tiposCondicion: ITipoCondicion[] = [
    { tipo: 'Activo', valor: 'Ubicación' },
    { tipo: 'Activo', valor: 'Velocidad' },
    { tipo: 'Activo', valor: 'Detenido' },
    { tipo: 'Vehiculo', valor: 'Ubicación' },
    { tipo: 'Vehiculo', valor: 'Velocidad' },
    { tipo: 'Vehiculo', valor: 'Detenido' },
    { tipo: 'Alarma', valor: 'Llega evento' },
    { tipo: 'Alarma', valor: 'No llega evento' },
  ];

  // Datos de input al componente
  private id?: string;
  @Input() public edit?: IConfigEventoUsuario;
  @Input() public params?: IParamEventoUsuario = {};
  public codigosAlarma: ICodigoDispositivo[] = [];
  public mostrarZona: boolean;

  // Entidades
  public grupos: IGrupo[] = [];
  public tiposEventosActivos: ITipoEvento[] = [];
  public tiposEventosAlarmas: ITipoEvento[] = [];
  public categoriasEvento: ICategoriaEvento[] = [];

  public ubicacions: IUbicacion[] = [];
  public activos: IActivo[] = [];
  public vehiculos: IActivo[] = [];
  public alarmas: IDispositivoAlarma[] = [];
  public usuarios: IUsuario[] = [];

  // Listado Continuo
  public grupos$?: Subscription;
  public categoriasEvento$: Subscription;
  public tiposEventos$?: Subscription;
  public usuarios$?: Subscription;
  public ubicacions$?: Subscription;
  public configEventoUsuario$?: Subscription;
  //Add Tag
  public creandoNuevoTag?: boolean = false;

  get tipoEntidad() {
    return this.form?.get('tipoEntidad')?.value as TipoEntidad;
  }
  get configZona() {
    return this.form?.get('configZona') as FormGroup;
  }
  get configUbicacionActivo() {
    return this.form
      ?.get('condicion')
      ?.get('activo')
      ?.get('ubicacion') as FormGroup;
  }
  get configVelocidadActivo() {
    return this.form
      ?.get('condicion')
      ?.get('activo')
      ?.get('velocidad') as FormGroup;
  }
  get configDetenidoActivo() {
    return this.form
      ?.get('condicion')
      ?.get('activo')
      ?.get('detenido') as FormGroup;
  }
  get condicionAlarma() {
    return this.form?.get('condicion')?.get('alarma') as FormGroup;
  }
  get configLLegaEventoAlarma() {
    return this.form
      ?.get('condicion')
      ?.get('alarma')
      ?.get('llega evento') as FormControl;
  }
  get configNoLlegaEventoAlarma() {
    return this.form
      ?.get('condicion')
      ?.get('alarma')
      ?.get('no llega evento') as FormControl;
  }
  get tipoCondicion() {
    return this.form?.get('tipoCondicion')?.value as
      | 'Ubicación'
      | 'Velocidad'
      | 'Detenido'
      | 'Llega evento'
      | 'No llega evento';
  }

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      edit: IConfigEventoUsuario;
      params: IParamEventoUsuario;
    },
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarConfigEventoUsuarioDialogComponent>,
    private service: ConfigEventoUsuarioService,
    private listadosService: ListadosService,
    private tipoEventoService: TiposEventosService,
    private paramsService: ParamsService,
    public helper: HelperService,
    private dialog: MatDialog,
    public route: ActivatedRoute,
  ) {}

  private createForm(): void {
    this.title = this.edit?._id
      ? 'Editar Configuración de Evento'
      : 'Crear Configuración de Evento';

    const codigosArmados = this.edit?.alarma?.modelo?.codigos?.codigos?.filter(
      (c) => c.armado === true,
    );
    const codigosDesarmados =
      this.edit?.alarma?.modelo?.codigos?.codigos?.filter(
        (c) => c.desarmado === true,
      );
    let textTipo = null;
    if (
      this.edit?.condicion?.alarma?.['no llega evento']?.length > 0 ||
      this.edit?.condicion?.alarma?.['no llega evento']?.length > 0
    ) {
      textTipo = 'Codigo Unico';
    }
    if (this.edit?.condicion?.alarma?.['no llega evento']?.length > 1) {
      if (
        this.edit?.condicion?.alarma?.['no llega evento']?.find((c) =>
          codigosArmados.find((cod) => cod.codigo === c),
        )
      )
        textTipo = 'Codigos de Armado';
      if (
        this.edit?.condicion?.alarma?.['no llega evento']?.find((c) =>
          codigosDesarmados.find((cod) => cod.codigo === c),
        )
      )
        textTipo = 'Codigos de Desarmado';
    }
    if (this.edit?.condicion?.alarma?.['llega evento']?.length > 1) {
      if (
        this.edit?.condicion?.alarma?.['llega evento']?.find((c) =>
          codigosArmados.find((cod) => cod.codigo === c),
        )
      )
        textTipo = 'Codigos de Armado';
      if (
        this.edit?.condicion?.alarma?.['llega evento']?.find((c) =>
          codigosDesarmados.find((cod) => cod.codigo === c),
        )
      )
        textTipo = 'Codigos de Desarmado';
    }
    const condicion = new FormGroup({
      activo: new FormGroup({
        ubicacion: new FormGroup({
          idUbicacion: new FormControl(
            this.edit?.condicion?.activo?.ubicacion?.idUbicacion,
          ),
          dentro: new FormControl(
            this.edit?.condicion?.activo?.ubicacion?.dentro !== false
              ? true
              : false,
          ),
          fuera: new FormControl(
            this.edit?.condicion?.activo?.ubicacion?.fuera !== false
              ? true
              : false,
          ),
        }),
        velocidad: new FormGroup({
          'superior a': new FormControl(
            this.edit?.condicion?.activo?.velocidad?.['superior a'] || 60,
          ),
        }),
        detenido: new FormGroup({
          'mas de': new FormControl(
            this.edit?.condicion?.activo?.detenido?.['mas de'] || 15,
          ),
        }),
      }),
      alarma: new FormGroup({
        'llega evento': new FormControl(
          this.edit?.condicion?.alarma?.['llega evento']?.length === 1
            ? this.edit?.condicion?.alarma?.['llega evento'][0]
            : null,
        ),
        'no llega evento': new FormControl(
          this.edit?.condicion?.alarma?.['no llega evento']?.length === 1
            ? this.edit?.condicion?.alarma?.['no llega evento'][0]
            : null,
        ),
        ///
        tipoCodigo: new FormControl(textTipo),
      }),
    });

    this.form = new FormGroup({
      tipoEnvio: new FormControl(this.edit?.tipoEnvio || 'Notificacion Push'),
      tipoEntidad: new FormControl(
        this.edit?.tipoEntidad ||
          this.params.tipoEntidad ||
          this.tiposEntidades[0].value,
      ),
      idTipoEvento: new FormControl(this.edit?.idTipoEvento),
      idCategoriaEvento: new FormControl(this.edit?.idCategoriaEvento),
      condicion,
      agrupacion: new FormControl(
        this.edit?.agrupacion || this.params.agrupacion || 'Entidad',
      ),
      // idCliente: new FormControl(this.data?.idCliente),
      idGrupo: new FormControl(this.edit?.idGrupo || this.params.idGrupo),
      idEntidad: new FormControl(this.edit?.idEntidad || this.params.idEntidad),
      idsUsuarios: new FormControl(this.edit?.idsUsuarios),
      generarSiNoSeCumple: new FormControl(this.edit?.generarSiNoSeCumple),
      generarSoloUnaVez: new FormControl(this.edit?.generarSoloUnaVez),
      validaDesde: new FormControl(this.edit?.validaDesde),
      validaHasta: new FormControl(this.edit?.validaHasta),
      notificar: new FormControl(this.edit?.notificar),
      atender: new FormControl(this.edit?.atender),
      dias: new FormControl(this.edit?.dias),
      horaInicio: new FormControl(this.edit?.horaInicio || '00:00'),
      horaFin: new FormControl(this.edit?.horaFin || '23:59'),
      frecuencia: new FormControl(this.edit?.frecuencia),
      configZona: new FormGroup({
        particion: new FormControl(this.edit?.configZona?.particion),
        zona: new FormControl(this.edit?.configZona?.zona),
      }),
      // Solo para el form, no está en el modelo
      tipoCondicion: new FormControl(this.condicionInicial()),
    });
  }

  //
  public setCodigo(cod: ICodigoDispositivo) {
    if (cod) {
      this.mostrarZona = cod.mostrarZona;
    } else {
      this.mostrarZona = false;
    }
  }

  private getData() {
    const data: IUpdateConfigEventoUsuario | ICreateConfigEventoUsuario =
      this.form?.value;

    // Parsea lo relacionado a la frecuencia
    const frecuencia = this.form?.get('frecuencia')?.value;
    if (frecuencia === 'Unica') {
      data.generarSoloUnaVez = true;
      data.validaDesde = null;
      data.validaHasta = null;
      data.generarSiNoSeCumple = null;
      data.dias = null;
      data.horaInicio = null;
      data.horaFin = null;
    } else if (frecuencia === 'Continua') {
      data.generarSoloUnaVez = null;
      data.validaDesde = null;
      data.validaHasta = null;
      data.generarSiNoSeCumple = null;
      data.dias = null;
      data.horaInicio = null;
      data.horaFin = null;
    } else if (frecuencia === 'Unica en un periodo') {
      data.generarSoloUnaVez = true;
      data.validaDesde = this.range?.start;
      data.validaHasta = this.range?.end;
      data.dias = null;
      data.horaInicio = null;
      data.horaFin = null;
    } else if (frecuencia === 'Continua en un periodo') {
      data.generarSoloUnaVez = null;
      data.generarSiNoSeCumple = null;
      data.validaDesde = this.range?.start;
      data.validaHasta = this.range?.end;
      data.dias = null;
      data.horaInicio = null;
      data.horaFin = null;
    } else if (frecuencia === 'Cronograma') {
      data.generarSoloUnaVez = null;
      data.validaDesde = null;
      data.validaHasta = null;
      data.generarSiNoSeCumple = null;
    }

    // Parsea lo relacionado a la condición
    const tipoCondicion = this.form?.get('tipoCondicion')?.value;
    if (this.tipoEntidad === 'Activo' || this.tipoEntidad === 'Vehiculo') {
      if (tipoCondicion === 'Ubicación') {
        data.condicion = {
          activo: {
            ubicacion: this.configUbicacionActivo?.value,
          },
        };
      } else if (tipoCondicion === 'Velocidad') {
        data.condicion = {
          activo: {
            velocidad: this.configVelocidadActivo?.value,
          },
        };
      } else if (tipoCondicion === 'Detenido') {
        data.condicion = {
          activo: {
            detenido: this.configDetenidoActivo?.value,
          },
        };
      }
    } else if (this.tipoEntidad === 'Alarma') {
      if (tipoCondicion === 'Llega evento') {
        if (this.condicionAlarma.value.tipoCodigo === 'Codigos de Armado') {
          const codigos = this.codigosAlarma
            .filter((c) => c.armado === true)
            .map((c) => c.codigo);
          data.condicion = {
            alarma: {
              'llega evento': codigos,
            },
          };
        } else if (
          this.condicionAlarma.value.tipoCodigo === 'Codigos de Desarmado'
        ) {
          const codigos = this.codigosAlarma
            .filter((c) => c.desarmado === true)
            .map((c) => c.codigo);
          data.condicion = {
            alarma: {
              'llega evento': codigos,
            },
          };
        } else {
          data.condicion = {
            alarma: {
              'llega evento': [this.configLLegaEventoAlarma?.value],
            },
          };
        }
      } else if (tipoCondicion === 'No llega evento') {
        if (this.condicionAlarma.value.tipoCodigo === 'Codigos de Armado') {
          const codigos = this.codigosAlarma
            .filter((c) => c.armado === true)
            .map((c) => c.codigo);
          data.condicion = {
            alarma: {
              'no llega evento': codigos,
            },
          };
        } else if (
          this.condicionAlarma.value.tipoCodigo === 'Codigos de Desarmado'
        ) {
          const codigos = this.codigosAlarma
            .filter((c) => c.desarmado === true)
            .map((c) => c.codigo);
          data.condicion = {
            alarma: {
              'no llega evento': codigos,
            },
          };
        } else {
          data.condicion = {
            alarma: {
              'no llega evento': [this.configNoLlegaEventoAlarma?.value],
            },
          };
        }
      }
    }
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.edit?._id) {
        await this.service.update(this.edit?._id, data);
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.volver();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  private condicionInicial() {
    if (this.params.tipoCondicion) return this.params.tipoCondicion;
    if (this.edit?.condicion?.activo?.ubicacion) return 'Ubicación';
    if (this.edit?.condicion?.activo?.velocidad) return 'Velocidad';
    if (this.edit?.condicion?.activo?.detenido) return 'Detenido';
    if (this.edit?.condicion?.alarma?.['llega evento']) return 'Llega evento';
    if (this.edit?.condicion?.alarma?.['no llega evento'])
      return 'No llega evento';

    return 'Ubicación';
  }

  //

  public async addTag(t: string) {
    if (!this.creandoNuevoTag) {
      this.creandoNuevoTag = true;
      const tipoEvento: ICreateTipoEvento = {
        nombre: t,
        categoria:
          this.form.value.tipoEntidad === 'Alarma' ? 'Alarma' : 'Tracker',
      };
      try {
        const res = await this.tipoEventoService.create(tipoEvento);
        this.form.patchValue({ idTipoEvento: res._id });
        this.creandoNuevoTag = false;
      } catch {
        this.creandoNuevoTag = false;
      }
    }
  }

  public volver() {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      HelperService.volver();
    }
  }

  public searchUsuario = (term: string, item: IUsuario) => {
    if (item.usuario?.toLowerCase().includes(term.toLowerCase())) return true;
    if (
      item.datosPersonales?.nombre?.toLowerCase().includes(term.toLowerCase())
    )
      return true;
    if (
      item.datosPersonales?.telefono?.toLowerCase().includes(term.toLowerCase())
    )
      return true;
    if (item.datosPersonales?.email?.toLowerCase().includes(term.toLowerCase()))
      return true;
    return false;
  };

  public searchCodigo = (term: string, item: ICodigoDispositivo) => {
    if (item.codigo?.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.descripcion?.toLowerCase().includes(term.toLowerCase()))
      return true;
    return false;
  };

  public changeAlarma(alarma: IDispositivoAlarma) {
    this.codigosAlarma = alarma?.modelo?.codigos?.codigos;
  }

  public changeTipoCodigo() {
    if (this.tipoEntidad === 'Alarma') {
      this.condicionAlarma.patchValue({
        'llega evento': [],
        'no llega evento': [],
      });
      if (this.condicionAlarma.value.tipoCodigo === 'Codigo Unico') {
        this.tipoCondicion === 'Llega evento'
          ? this.condicionAlarma
              .get('llega evento')
              .setValidators([Validators.required])
          : this.condicionAlarma.get('llega evento').setValidators(null);
        this.tipoCondicion === 'No llega evento'
          ? this.condicionAlarma
              .get('no llega evento')
              .setValidators([Validators.required])
          : this.condicionAlarma.get('no llega evento').setValidators(null);
      } else {
        this.condicionAlarma.get('no llega evento').setValidators(null);
        this.condicionAlarma.get('llega evento').setValidators(null);
      }
      this.condicionAlarma.get('llega evento').updateValueAndValidity();
      this.condicionAlarma.get('no llega evento').updateValueAndValidity();
    }
  }

  private changeTipoEntidad(entidad: string) {
    entidad === 'Alarma'
      ? this.condicionAlarma
          .get('tipoCodigo')
          .setValidators([Validators.required])
      : this.condicionAlarma.get('tipoCodigo').setValidators(null);
    this.condicionAlarma.get('tipoCodigo').updateValueAndValidity();
  }

  // Listados
  private async listarTipoEvento(): Promise<void> {
    this.tiposEventos$?.unsubscribe();
    this.tiposEventos$ = this.listadosService
      .subscribe<IListado<ITipoEvento>>('tiposEventos', {})
      .subscribe((data) => {
        const tiposEventos = data.datos;
        this.tiposEventosActivos = tiposEventos.filter(
          (t) => t.categoria === 'Tracker',
        );
        this.tiposEventosAlarmas = tiposEventos.filter(
          (t) => t.categoria === 'Alarma',
        );
        console.log(`listado de tiposEventos`, data);
      });
    await this.listadosService.getLastValue('tiposEventos', {});
  }

  public async listarCategorias(): Promise<void> {
    this.categoriasEvento$?.unsubscribe();
    this.categoriasEvento$ = this.listadosService
      .subscribe<IListado<ICategoriaEvento>>('categoriaEventos', {})
      .subscribe((data) => {
        this.categoriasEvento = data.datos;
        console.log(`listado de categoriaEventos`, data);
      });
    await this.listadosService.getLastValue('categoriaEventos', {});
  }

  private async listarGrupos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre idCliente',
      sort: 'nombre',
      includeChildren: true,
    };
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listadosService
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listadosService.getLastValue('grupos', query);
  }

  private async listarUsuarios(): Promise<void> {
    const query: IQueryParam = {
      select: 'usuario datosPersonales.telefono permisos',
      sort: 'usuario',
      includeChildren: true,
    };
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listadosService
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.usuarios = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listadosService.getLastValue('usuarios', query);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Listar activos, vehículos, alarmas
  //Activos:
  SearchActivos$: Observable<IActivo[]>; // Observable para los resultados de búsqueda.
  loadingActivos: boolean = false; // Para mostrar el indicador de carga si lo deseas.
  SearchTermActivos$ = new Subject<string>(); // Subject para emitir el término de búsqueda

  // Función para emitir el término de búsqueda cuando el usuario escribe
  searchActivos(term: string): void {
    this.SearchTermActivos$.next(term); // Emitir el término al Subject
  }

  //Vehiculos:
  SearchVehiculos$: Observable<IActivo[]>; // Observable para los resultados de búsqueda.
  loadingVehiculos: boolean = false; // Para mostrar el indicador de carga si lo deseas.
  SearchTermVehiculos$ = new Subject<string>(); // Subject para emitir el término de búsqueda

  // Función para emitir el término de búsqueda cuando el usuario escribe
  searchVehiculos(term: string): void {
    this.SearchTermVehiculos$.next(term); // Emitir el término al Subject
  }

  //Alarmas:
  SearchAlarmas$: Observable<IActivo[]>; // Observable para los resultados de búsqueda.
  loadingAlarmas: boolean = false; // Para mostrar el indicador de carga si lo deseas.
  SearchTermAlarmas$ = new Subject<string>(); // Subject para emitir el término de búsqueda

  // Función para emitir el término de búsqueda cuando el usuario escribe
  searchAlarmas(term: string): void {
    this.SearchTermVehiculos$.next(term); // Emitir el término al Subject
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // private async listarAlarmas(): Promise<void> {
  //   const populate = {
  //     path: 'modelo',
  //     select: 'idCodigos',
  //     populate: {
  //       path: 'codigos',
  //       select: 'codigos',
  //     },
  //   };
  //   const query: IQueryParam = {
  //     select: 'nombre idModelo',
  //     sort: 'nombre',
  //     populate: JSON.stringify(populate),
  //     includeChildren: true,
  //   };
  //   this.alarmas$?.unsubscribe();
  //   this.alarmas$ = this.listadosService
  //     .subscribe<IListado<IActivo>>('dispositivosAlarmas', query)
  //     .subscribe((data) => {
  //       this.alarmas = data.datos;
  //       console.log(`listado de alarmas`, data);
  //     });
  //   await this.listadosService.getLastValue('dispositivosAlarmas', query);
  // }

  private async listarUbicaciones(): Promise<void> {
    const filter: IFilter<IUbicacion> = {};
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      select: 'identificacion',
      sort: 'identificacion',
      includeChildren: true,
    };
    this.ubicacions$?.unsubscribe();
    this.ubicacions$ = this.listadosService
      .subscribe<IListado<IUbicacion>>('ubicacions', query)
      .subscribe((data) => {
        this.ubicacions = data.datos;
        console.log(`listado de ubicacions`, data);
      });
    await this.listadosService.getLastValue('ubicacions', query);
  }

  private async listarConfigEventoUsuario(): Promise<void> {
    if (this.id) {
      this.configEventoUsuario$?.unsubscribe();
      this.configEventoUsuario$ = this.listadosService
        .subscribe<IConfigEventoUsuario>('configEventoUsuario', this.id)
        .subscribe((data) => {
          this.edit = data;
          console.log(`listado de configEventoUsuario`, data);
        });
      await this.listadosService.getLastValue('configEventoUsuario', this.id);
    }
  }

  //

  //CREAR CATEGORÍA:

  public crearCategoria() {
    const dialog = this.dialog.open(CrearEditarCategoriaEventoComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idCategoriaEvento: res });
      }
    });
  }

  async ngOnInit(): Promise<void> {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //SUSCRIPCIONES A ACTIVOS, VEHÍCULOS Y ALARMAS:

    //ACTIVOS:
    this.SearchActivos$ = this.SearchTermActivos$.pipe(
      debounceTime(200), // Esperar 200ms después de que el usuario deje de escribir
      distinctUntilChanged(), // Evitar solicitudes si el término no ha cambiado
      switchMap((term) => {
        this.loadingActivos = true; // Activar el indicador de carga

        // Si el término tiene menos de 3 caracteres, no realizamos la búsqueda.
        // Si el término está vacío, devolver un observable vacío sin activar el indicador de carga.
        if (!term || term.length < 3) {
          this.loadingActivos = false; // Desactivar el indicador de carga si el término está vacío o tiene menos de 3 caracteres
          return of([]); // Retorna una lista vacía
        }
        this.loadingActivos = true; // Activar el indicador de carga solo cuando realmente estamos buscando
        // Crear filtro cuando el término no es vacío
        const filter: IFilter<IActivo> = {
          identificacion: { $regex: term, $options: 'i' },
          categoria: 'Normal',
        };

        const queryActivos: IQueryParam = {
          includeChildren: true,
          childrenLevel: 0,
          filter: JSON.stringify(filter),
        };

        return this.listadosService.listarActivos$(queryActivos); // Llamada al servicio
      }),
    );

    // Suscripción para actualizar el indicador de carga.
    this.SearchActivos$.subscribe({
      next: () => {
        this.loadingActivos = false; // Desactivar el indicador de carga
      },
      error: () => {
        this.loadingActivos = false; // Desactivar en caso de error también
      },
    });

    //VEHÍCULOS
    this.SearchVehiculos$ = this.SearchTermVehiculos$.pipe(
      debounceTime(200), // Esperar 200ms después de que el usuario deje de escribir
      distinctUntilChanged(), // Evitar solicitudes si el término no ha cambiado
      switchMap((term) => {
        this.loadingVehiculos = true; // Activar el indicador de carga

        // Si el término tiene menos de 3 caracteres, no realizamos la búsqueda.
        // Si el término está vacío, devolver un observable vacío sin activar el indicador de carga.
        if (!term || term.length < 3) {
          this.loadingVehiculos = false; // Desactivar el indicador de carga si el término está vacío o tiene menos de 3 caracteres
          return of([]); // Retorna una lista vacía
        }
        this.loadingVehiculos = true; // Activar el indicador de carga solo cuando realmente estamos buscando
        // Crear filtro cuando el término no es vacío
        const filter: IFilter<IActivo> = {
          identificacion: { $regex: term, $options: 'i' },
          categoria: 'Vehículo',
        };

        const queryVehiculos: IQueryParam = {
          includeChildren: true,
          childrenLevel: 0,
          filter: JSON.stringify(filter),
        };

        return this.listadosService.listarActivos$(queryVehiculos); // Llamada al servicio Nota: los vehículos son un tipo de activo
      }),
    );
    // Suscripción para actualizar el indicador de carga.
    this.SearchVehiculos$.subscribe({
      next: () => {
        this.loadingVehiculos = false; // Desactivar el indicador de carga
      },
      error: () => {
        this.loadingVehiculos = false; // Desactivar en caso de error también
      },
    });

    //ALARMAS
    this.SearchAlarmas$ = this.SearchTermAlarmas$.pipe(
      debounceTime(200), // Esperar 200ms después de que el usuario deje de escribir
      distinctUntilChanged(), // Evitar solicitudes si el término no ha cambiado
      switchMap((term) => {
        this.loadingAlarmas = true; // Activar el indicador de carga

        // Si el término tiene menos de 3 caracteres, no realizamos la búsqueda.
        // Si el término está vacío, devolver un observable vacío sin activar el indicador de carga.
        if (!term || term.length < 3) {
          this.loadingAlarmas = false; // Desactivar el indicador de carga si el término está vacío o tiene menos de 3 caracteres
          return of([]); // Retorna una lista vacía
        }
        this.loadingVehiculos = true; // Activar el indicador de carga solo cuando realmente estamos buscando
        // Crear filtro cuando el término no es vacío
        const filter: IFilter<IDispositivoAlarma> = {
          nombre: { $regex: term, $options: 'i' },
        };
        const populate = {
          path: 'modelo',
          select: 'idCodigos',
          populate: {
            path: 'codigos',
            select: 'codigos',
          },
        };
        const queryAlarmas: IQueryParam = {
          includeChildren: true,
          childrenLevel: 0,
          filter: JSON.stringify(filter),
          populate: JSON.stringify(populate),
        };

        return this.listadosService.listarDispositivosAlarmas$(queryAlarmas); // Llamada al servicio Nota: los vehículos son un tipo de activo
      }),
    );

    // Suscripción para actualizar el indicador de carga.
    this.SearchAlarmas$.subscribe({
      next: () => {
        this.loadingAlarmas = false; // Desactivar el indicador de carga
      },
      error: () => {
        this.loadingAlarmas = false; // Desactivar en caso de error también
      },
    });
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Params
    this.edit = this.paramsService.getParams()['edit'];
    this.params = this.paramsService.getParams()['params'];
    //
    this.codigosAlarma =
      (this.params?.alarma as IDispositivoAlarma)?.modelo?.codigos?.codigos ||
      [];
    this.mostrarZona =
      this.edit?.configZona?.zona || this.edit?.configZona?.particion
        ? true
        : false;
    await this.listarConfigEventoUsuario();
    if (this.edit) {
      this.range = {
        start: this.edit?.validaDesde,
        end: this.edit?.validaHasta,
      };
    }
    this.createForm();
    this.form
      .get('tipoCondicion')
      ?.valueChanges.subscribe(() => this.changeTipoCodigo());
    if (this.form.value.tipoEntidad === 'Alarma')
      this.changeTipoEntidad('Alarma');
    this.form
      .get('tipoEntidad')
      ?.valueChanges.subscribe((value) => this.changeTipoEntidad(value));
    await Promise.all([
      this.listarCategorias(),
      this.listarTipoEvento(),
      this.listarGrupos(),
      this.listarUsuarios(),
      this.listarUbicaciones(),
    ]);
  }

  ngOnDestroy(): void {
    this.categoriasEvento$?.unsubscribe();
    this.grupos$?.unsubscribe();
    this.usuarios$?.unsubscribe();
    this.ubicacions$?.unsubscribe();
    this.configEventoUsuario$?.unsubscribe();
    this.tiposEventos$.unsubscribe();
  }
}
