<!-- Título -->
<!-- Título y botón de volver -->
<!-- Fila para el título y el botón "Volver" -->
<div style="display: flex; align-items: center; width: 100%">
  <!-- Botón de Volver alineado a la izquierda -->
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.volver()"
    text="Volver"
    icon="arrow_back"
    style="margin-right: 10px"
  ></app-button>
  <!-- Ajusta el espacio entre el botón y el título -->

  <!-- Título centrado -->
  <span class="title1" style="flex-grow: 1; text-align: center">
    {{ alarma?.nombre }}
  </span>
</div>
<mat-tab-group>
  <!-- Info -->
  <mat-tab label="Info">
    <ng-template matTabContent>
      <app-detalles-alarma-info></app-detalles-alarma-info>
    </ng-template>
  </mat-tab>

  <!-- Historial Eventos -->
  <mat-tab label="Historial Eventos">
    <ng-template matTabContent>
      <app-listado-eventos-historico
        [id]="alarma._id"
        tipo="Alarma"
        [nombreEntidad]="alarma.nombre"
      ></app-listado-eventos-historico>
    </ng-template>
  </mat-tab>
  @if (alarma?.idsCamaras) {
    <!-- Cámaras -->
    <mat-tab label="Cámaras">
      <ng-template matTabContent>
        <br />
        <div
          style="
            height: 70vh;
            display: flex;
            justify-content: center;
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <app-camaras-multiple
            style="width: 100%; height: 100%"
            [data]="alarma.idsCamaras"
          ></app-camaras-multiple>
        </div>
      </ng-template>
    </mat-tab>
  }
  <!-- Codigos -->
  <mat-tab label="Códigos Eventos">
    <ng-template matTabContent>
      <app-listado-configs-eventos
        [dispositivo]="alarma"
        tipo="Alarma"
      ></app-listado-configs-eventos>
    </ng-template>
  </mat-tab>
  <!-- Control Horario -->
  <mat-tab label="Control Horario">
    <ng-template matTabContent>
      @if (params) {
        <app-listado-config-evento-usuario
          [params]="params"
        ></app-listado-config-evento-usuario>
      }
    </ng-template>
  </mat-tab>
  <!-- Notas -->
  <mat-tab label="Notas">
    <ng-template matTabContent>
      <app-listado-notas
        [idAsignado]="this.alarma._id"
        tipo="Nota"
        tipoDispositivo="Alarma"
      ></app-listado-notas>
    </ng-template>
  </mat-tab>
  <!-- Contacto -->
  <mat-tab label="Contactos">
    <ng-template matTabContent>
      <app-listado-notas
        [idAsignado]="this.alarma._id"
        tipo="Contacto"
        tipoDispositivo="Alarma"
      ></app-listado-notas>
    </ng-template>
  </mat-tab>
  <!-- Servicio Técnico -->
  <mat-tab label="Servicio Técnico">
    <ng-template matTabContent>
      <app-eventos-tecnicos [alarma]="alarma"></app-eventos-tecnicos>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<!-- Botones -->
<div class="row-flex-end" style="gap: 10px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.volver()"
    text="Volver"
    icon="arrow_back"
  ></app-button>
</div>
