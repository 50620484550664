<div style="display: flex; flex-direction: column">
  <small style="max-width: 80%; color: gray"
    >Recorridos del vehiculo seleccionado en la fecha seleccionada</small
  >
  <ng-select
    [items]="vehiculos"
    [(ngModel)]="vehiculo"
    bindLabel="identificacion"
    placeholder="Vehículo"
    [notFoundText]="'No hay Vehículos'"
    multiple="false"
    searchable="true"
    appendTo="body"
    (change)="listarViajes()"
  >
  </ng-select>
  <div style="display: flex; gap: 3px">
    <div style="width: 100%">
      <app-date-range-selector
        [(dateRange)]="range"
        (dateRangeChange)="listarViajes()"
        [maxDate]="null"
        [placeholder]="'Fecha'"
        [selectMode]="'single'"
        matTooltip="La fecha se busca con ±12 horas a lo seleccionado"
      ></app-date-range-selector>
    </div>
  </div>
  <div style="max-height: fit-content; overflow: auto">
    @for (inter of interaccionesBusqueda; track inter) {
      <mat-expansion-panel
        hideToggle
        [expanded]="interaccionesBusquedaSelected === inter"
        [ngClass]="interaccionesBusquedaSelected === inter ? 'ac-85' : ''"
        (expandedChange)="onRecorridoSelected(inter, $event)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            @if (inter.startTime !== inter.endTime) {
              {{ inter.startTime | date: "short" }} >>>
              {{ inter.endTime | date: "HH:mm" }}
            } @else {
              {{ inter.startTime | date: "short" }}
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    }
  </div>

  @if (buscando) {
    <div style="height: 100%; width: 100%; margin-top: 10%" align="center">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  }
</div>
