import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IInformacion } from 'modelos/src';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';

@Component({
  selector: 'app-detalles-nota',
  templateUrl: './detalles-nota.component.html',
  styleUrl: './detalles-nota.component.scss',
  imports: [CommonModule, AuxiliaresModule],
})
export class DetallesNotaComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IInformacion,
    private helper: HelperService,
  ) {}

  public async copiarAlPortapapeles(t: string) {
    await this.helper.copiarAlPortapapeles(t);
  }
}
