import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICategoriaUbicacion,
  ICreateUbicacion,
  IGeoJSONCircle,
  IGeoJSONPoint,
  IUbicacion,
  IUpdateUbicacion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UbicacionService } from '../../../../auxiliares/servicios/http/ubicacion.service';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { MapDrawComponent } from '../../../../auxiliares/map-draw/map-draw.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ParamsService } from '../../../../auxiliares/servicios/params.service';

@Component({
  imports: [CommonModule, AuxiliaresModule, MapDrawComponent],
  selector: 'app-crear-editar-ubicacion',
  templateUrl: './crear-editar-ubicacion.component.html',
  styleUrl: './crear-editar-ubicacion.component.scss',
})
export class CrearEditarUbicacionComponent implements OnInit, OnDestroy {
  public loading = false;
  public title?: string;
  public categoria?: ICategoriaUbicacion;
  public form?: FormGroup;
  public id?: string | null;
  public ubicacion?: IUbicacion;
  public ubicacion$?: Subscription;

  public centrarA?: IGeoJSONCircle | IGeoJSONPoint;

  get geojson() {
    return this.form?.get('geojson') as FormGroup;
  }

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarUbicacionComponent>,
    private service: UbicacionService,
    private route: ActivatedRoute,
    private listados: ListadosService,
    public helper: HelperService,
    private dialog: MatDialog,
    private paramsService: ParamsService,
  ) {}

  private createForm() {
    let type: 'Point' | 'LineString' | 'Polygon' | 'Circle' | 'MultiPolygon';
    if (this.ubicacion) {
      // Editando
      if ((this.ubicacion.geojson as IGeoJSONCircle).radius) {
        type = 'Circle';
      } else {
        type = this.ubicacion?.geojson?.type;
      }
    } else {
      // Creando
      switch (this.categoria) {
        case 'Terminal':
          type = 'Polygon';
          break;
        case 'Domicilio':
          type = 'Point';
          break;
      }
    }

    this.form = new FormGroup({
      identificacion: new FormControl(this.ubicacion?.identificacion, [
        Validators.required,
      ]),
      categoria: new FormControl(this.categoria, [Validators.required]),
      direccion: new FormControl(this.ubicacion?.direccion),
      geojson: new FormGroup({
        type: new FormControl(type, [Validators.required]),
        coordinates: new FormControl(this.ubicacion?.geojson?.coordinates),
      }),
    });
  }

  private async listar() {
    if (this.id) {
      this.ubicacion$?.unsubscribe();
      this.ubicacion$ = this.listados
        .subscribe<IUbicacion>('ubicacion', this.id)
        .subscribe((data) => {
          this.ubicacion = data;
          console.log(`listado de ubicacion`, data);
        });
      await this.listados.getLastValue('ubicacion', this.id);
    }
  }

  public async onSubmit() {
    this.loading = true;
    try {
      let res = null;
      if (this.id) {
        // Update
        const data = this.getData();
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Actualizado correctamente');
      } else {
        // Create
        const data = this.getData();
        res = await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.volver(res?._id);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public volver(res?: string) {
    if (this.dialogRef) {
      this.dialogRef.close(res);
    } else {
      HelperService.volver();
    }
  }

  public getData(): ICreateUbicacion | IUpdateUbicacion {
    const data: ICreateUbicacion | IUpdateUbicacion = this.form?.value;
    return data;
  }

  async ngOnInit() {
    if (this.paramsService.getParams()['categoria']) {
      this.categoria = this.paramsService.getParams()[
        'categoria'
      ] as ICategoriaUbicacion;
      this.ubicacion = this.paramsService.getParams()['ubicacion'];
      this.id = this.ubicacion._id;
    } else {
      this.route.params.subscribe(async (params) => {
        this.categoria = params?.['categoria'] as ICategoriaUbicacion;
        this.id = params?.['id'];
        console.log(params);
      });
    }
    this.title = this.id
      ? `Editar ubicación de ${this.categoria?.toLowerCase()}`
      : `Crear ubicación de ${this.categoria?.toLowerCase()}`;
    this.createForm();
    if (!this.ubicacion) await this.listar();
  }

  ngOnDestroy(): void {
    this.ubicacion$?.unsubscribe();
  }
}
