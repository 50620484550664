<div
  style="
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1%;
  "
>
  <div id="flotante" class="ol-flotante">
    <app-flotante-vehiculos
      style="width: 100%"
      [vehiculos]="reportes?.length"
      [cantMapas]="cantMapas"
      [mostrandoBusqueda]="mostrandoBusqueda"
      (cantMapasChange)="cambioCantMapas($event)"
      (busquedaClick)="busquedaClick()"
    ></app-flotante-vehiculos>
  </div>
  @if (!mostrandoBusqueda) {
    @if (reporteSeleccionado) {
      <div id="isla" class="ol-isla">
        <app-isla
          [(reporte)]="reporteSeleccionado"
          [(trackeo)]="trackeo"
          (reporteChange)="onClose()"
        ></app-isla>
      </div>
    }
    @if (!loadingMapa) {
      <!-- no estoy orgulloso de esto :( -->
      <div class="layout-mapa">
        @if (cantMapas === 9) {
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(5)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="5"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(6)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="6"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(7)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="7"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(8)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="8"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 8) {
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(5)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="5"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(6)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="6"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(7)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="7"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 7) {
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(5)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="5"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(6)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="6"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 6) {
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(5)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="5"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 5) {
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 4) {
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 3) {
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 2) {
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 1) {
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
      </div>
    }
  } @else {
    <div class="layout-fila" style="order: 1">
      <app-vehiculo-busquedas style="width: 100%"></app-vehiculo-busquedas>
    </div>
  }
</div>
