import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { INota, TipoNota } from 'modelos/src';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { DialogService } from '../../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { NotasService } from '../../../../../../auxiliares/servicios/http/notas.service';

@Component({
  selector: 'app-crear-editar-nota-alarma-completo',
  templateUrl: './crear-editar-nota-alarma-completo.component.html',
  styleUrl: './crear-editar-nota-alarma-completo.component.scss',
  standalone: false,
})
export class CrearNotaAlarmaCompletoComponent implements OnInit {
  //DEMAS
  public loading = false;
  public id?: string;
  public idEntidad?: string;

  public tipo?: TipoNota;
  public tipoAsignado?: string;
  public form?: FormGroup;
  public title?: string;
  public editar: boolean = false;
  public nota?: INota;
  public totalCount?: number;

  public contacto?: boolean = false;
  public usuario?: boolean = false;

  //Subss
  public notas$: Subscription;

  public tiposNota: TipoNota[] = ['Nota', 'Contacto'];

  public getPermanente() {
    return this.form?.value?.permanente;
  }

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: { form?: FormGroup; tipo: TipoNota },
    public dialogRef: MatDialogRef<CrearNotaAlarmaCompletoComponent>,
    public helper: HelperService,
    private service: NotasService,
    public dialog: MatDialog,
    private dialogService: DialogService,
  ) {}

  get informacion() {
    return this.form?.get('informacion') as FormGroup;
  }

  private createForm() {
    const tipoNota = this.nota?.tipo || this.data?.tipo || '';

    this.form = new FormGroup({
      orden: new FormControl(this.nota?.orden, [
        Validators.required,
        Validators.pattern('^[0-9]*$'), // Solo números
      ]),
      idAsignado: new FormControl(this.nota?.idAsignado || this.idEntidad),
      permanente: new FormControl(
        this.nota?.permanente === false ? false : true,
      ),
      vigenciaDesde: new FormControl(this.nota?.vigenciaDesde),
      vigenciaHasta: new FormControl(this.nota?.vigenciaHasta),
      tipo: new FormControl(tipoNota),
      informacion: new FormGroup({
        contacto: new FormControl(
          this.nota?.informacion?.contacto,
          tipoNota === 'Contacto' ? Validators.required : null,
        ),
        telefono: new FormControl(this.nota?.informacion?.telefono),
        email: new FormControl(this.nota?.informacion?.email),
        palabraSeguridadNormal: new FormControl(
          this.nota?.informacion?.palabraSeguridadNormal,
        ),
        palabraSeguridadEmergencia: new FormControl(
          this.nota?.informacion?.palabraSeguridadEmergencia,
        ),
        nota: new FormControl(
          this.nota?.informacion?.nota,
          tipoNota === 'Nota' ? Validators.required : null,
        ),
        numeroUsuarioAlarma: new FormControl(
          this.nota?.informacion?.numeroUsuarioAlarma,
        ),
      }),
    });
  }

  //
  public async onSubmit() {
    this.dialogRef.close(this.form);
  }

  async ngOnInit() {
    this.loading = true;
    if (this.data.form) {
      this.editar = true;
      this.form = this.data?.form;
    } else {
      this.editar = false;
    }
    this.tipo = this.data?.tipo ? this.data?.tipo : 'Contacto';
    this.tipoAsignado = '';
    if (this.editar && this.data.tipo === 'Contacto') {
      this.title = 'Editar Contacto';
      this.contacto = true;
      this.usuario = true;
    } else if (this.editar && this.data.tipo === 'Nota') {
      this.title = 'Editar Nota';
    } else {
      if (this.data.tipo === 'Nota') this.title = 'Crear Nota';
      if (this.data.tipo === 'Contacto') this.title = 'Crear Contacto';
      this.createForm();
    }
    this.loading = false;
  }
}
