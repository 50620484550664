import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IActivo,
  ICreateDespacho,
  ICronograma,
  IDespacho,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IRecorrido,
  IUsuario,
  Periodo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { DespachosService } from '../despachos.service';
import { Platform } from '@angular/cdk/platform';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';

/// Custom adapter para formatear la fecha en el datepicker
export class FormattedDateAdapter extends NativeDateAdapter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  override format(date: Date, displayFormat: any): string {
    const fechaCorregida = new Date(date);
    fechaCorregida.setHours(fechaCorregida.getHours() + 3);
    const days = fechaCorregida.getDate();
    const months = fechaCorregida.getMonth() + 1;
    const year = fechaCorregida.getFullYear();
    return days + '/' + months + '/' + year;
  }
}

@Component({
  selector: 'app-despachos',
  templateUrl: './despachos.component.html',
  styleUrl: './despachos.component.scss',
  providers: [
    {
      provide: DateAdapter,
      useClass: FormattedDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
  ],
  standalone: false,
})
export class DespachosComponent implements OnInit, OnDestroy {
  public hoy?: Date;
  public cronograma?: ICronograma;
  public cronogramas: ICronograma[] = [];
  private cronogramas$?: Subscription;
  public vehiculos: IActivo[] = [];
  private vehiculos$?: Subscription;
  public choferes: IUsuario[] = [];
  private choferes$?: Subscription;
  public recorridos: IRecorrido[] = [];
  private recorridos$?: Subscription;
  public despachos: IDespacho[] = [];
  private despachos$?: Subscription;
  public filtro: number;

  public periodoSeleccionado?: Periodo;

  // Datos del Despacho
  public fecha?: string;
  public hora?: string; /// SALE
  public salio?: string; /// SALIÓ
  public idCronograma?: string;
  public idVehiculo?: string;
  public idChofer?: string;
  public idsRecorridos?: string[];
  public idRecorridoActual?: string;
  public completado?: boolean;
  public cancelado = false;

  constructor(
    private listados: ListadosService,
    private dialog: DialogService,
    private service: DespachosService,
    private helper: HelperService,
  ) {
    this.hoy = new Date();
    this.fecha = this.hoy.toISOString().split('T')[0];
    setInterval(() => {
      this.hoy = new Date();
    }, 1000);
  }

  public async listarCronogramas(): Promise<void> {
    const populate: IPopulate = {
      path: 'ubicacion',
      select: 'identificacion',
    };
    const query = {
      sort: 'nombre',
      limit: 0,
      populate: JSON.stringify(populate),
    };
    this.cronogramas$?.unsubscribe();
    this.cronogramas$ = this.listados
      .subscribe<IListado<ICronograma>>('cronogramas', query)
      .subscribe((data) => {
        this.cronogramas = data.datos;
        console.log(`listado de cronogramas`, data);
      });
    await this.listados.getLastValue('cronogramas', query);
  }

  // Vehículos
  private async listarVehiculos() {
    const query: IQueryParam = {
      sort: 'identificacion',
      limit: 0,
      select: 'identificacion patente',
    };
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', query)
      .subscribe((data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos`, data);
      });
    await this.listados.getLastValue('vehiculos', query);
  }
  // Choferes
  private async listarChoferes() {
    const filter: IFilter<IUsuario> = {
      roles: 'Chofer Colectivo',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'datosPersonales.nombre',
      limit: 0,
      select: 'datosPersonales roles identificacionInterna',
    };
    this.choferes$?.unsubscribe();
    this.choferes$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.choferes = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }
  // Recorridos
  private async listarRecorridos() {
    const query: IQueryParam = {
      sort: 'nombre',
      limit: 0,
      select: 'nombre nombreFlota destino por',
    };
    this.recorridos$?.unsubscribe();
    this.recorridos$ = this.listados
      .subscribe<IListado<IRecorrido>>('recorridos', query)
      .subscribe((data) => {
        this.recorridos = data.datos;
        console.log(`listado de recorridos`, data);
      });
    await this.listados.getLastValue('recorridos', query);
  }
  // Despachos
  private async listarDespachos() {
    const filter: IFilter<IDespacho> = {
      fecha: this.fecha,
    };
    const query: IQueryParam = {
      sort: '-fecha',
      limit: 0,
      filter: JSON.stringify(filter),
    };
    this.despachos$?.unsubscribe();
    this.despachos$ = this.listados
      .subscribe<IListado<IRecorrido>>('despachos', query)
      .subscribe((data) => {
        this.despachos = data.datos;
        console.log(`listado de despachos`, data);
      });
    await this.listados.getLastValue('despachos', query);
  }

  public seleccionarPeriodo(periodo: Periodo) {
    const res = this.checkHora(periodo);
    if (!res) {
      this.periodoSeleccionado = periodo;
      this.hora = periodo.desde;
      this.salio = periodo.desde;
      this.idCronograma = this.cronograma?._id;
      this.idVehiculo = this.periodoSeleccionado?.datos['idVehiculo'];
      this.idChofer = this.periodoSeleccionado?.datos['idChofer'];
      this.idsRecorridos = this.periodoSeleccionado?.datos['idsRecorridos'];
      this.idRecorridoActual =
        this.periodoSeleccionado?.datos['idRecorridoActual'];
      this.completado = true;
    } else {
      this.helper.notifError('Ya existe un despacho para este periodo');
    }
  }

  public async seleccionarCronograma() {
    await this.listarDespachos();
  }
  /**
   *
   * @param p Periodo
   * @returns true si las horas son iguales
   */
  public checkHora(p: Periodo): boolean {
    if (!this.despachos.length) {
      return false;
    }
    const despacho = this.despachos.find((d) => d.hora === p.desde);

    if (!despacho) {
      return false;
    }

    if (p.desde === despacho.hora) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param p Periodo
   * @returns true si está completado
   */
  public checkCompletado(p: Periodo): boolean {
    if (!this.despachos.length) {
      return false;
    }
    const despacho = this.despachos.find((d) => d.hora === p.desde);

    if (!despacho) {
      return false;
    }

    if (despacho.completado === true) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param p Periodo
   * @returns true si está cancelado
   */
  public checkCancelado(p: Periodo): boolean {
    if (!this.despachos.length) {
      return false;
    }
    const despacho = this.despachos.find((d) => d.hora === p.desde);

    if (!despacho) {
      return false;
    }

    if (despacho.cancelado === true) {
      return true;
    } else {
      return false;
    }
  }

  public clickModificado(p: Periodo) {
    event.stopPropagation();
    if (!p.datos['modificado']) {
      p.datos['modificado'] = true;
      const despacho = this.despachos.find((d) => d.hora === p.desde);
      p.datos['salio'] = despacho.salio;
      p.datos['idVehiculoD'] = despacho.idActivo;
      p.datos['idChoferD'] = despacho.idChofer;
      p.datos['idsRecorridosD'] = despacho.idsRecorridos;
    } else p.datos['modificado'] = false;
  }

  public async crearDespacho() {
    try {
      const res = await this.dialog.confirm(
        'Despacho',
        '¿Desea confirmar el despacho?',
      );
      if (!res) return;
      const despacho: ICreateDespacho = {
        fecha: this.fecha,
        hora: this.hora,
        salio: this.salio,
        idCronograma: this.idCronograma,
        idActivo: this.idVehiculo,
        idChofer: this.idChofer,
        idsRecorridos: this.idsRecorridos,
        completado: this.completado,
        cancelado: this.cancelado,
      };
      await this.service.create(despacho);

      this.helper.notifSuccess('Despacho creado');
      this.limpiar();
    } catch (error) {
      this.helper.notifError(error);
      console.error(error);
    }
  }

  public async cancelarDespacho() {
    try {
      const res = await this.dialog.confirm(
        'Despacho',
        '¿Desea cancelar el despacho?',
      );
      if (!res) return;
      const despacho: ICreateDespacho = {
        fecha: this.fecha,
        hora: this.hora,
        salio: this.salio,
        idCronograma: this.idCronograma,
        //idVehiculo: this.idVehiculo,
        idChofer: this.idChofer,
        idsRecorridos: this.idsRecorridos,
        completado: this.completado,
        cancelado: true,
      };
      await this.service.create(despacho);

      this.helper.notifSuccess('Despacho cancelado');
      this.limpiar();
    } catch (error) {
      this.helper.notifError(error);
      console.error(error);
    }
  }

  private limpiar() {
    this.hora = undefined;
    this.idCronograma = undefined;
    this.idVehiculo = undefined;
    this.idChofer = undefined;
    this.idsRecorridos = undefined;
    this.completado = undefined;
    this.periodoSeleccionado = undefined;
  }

  public completarChofer(id: string): string {
    const chofer = this.choferes.find((c) => c._id === id);
    if (chofer)
      return (
        `${chofer?.identificacionInterna || ''} - ${chofer?.datosPersonales.nombre || chofer?.usuario}` ||
        ''
      );
    else return null;
  }

  public completarVehiculo(id: string): string {
    const vehiculo = this.vehiculos.find((v) => v._id === id);
    return vehiculo?.identificacion || '';
  }

  public completarRecorrido(id: string): string {
    const recorrido = this.recorridos.find((r) => r._id === id);
    return recorrido?.nombre || '';
  }

  public selectColor(c: string): string {
    return `border-color: ${c}`;
  }

  public checkearDespacho(): string {
    return this.completado ? 'done' : 'close';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async parseFecha(e: any) {
    console.log(`Parse Fecha`, new Date(e.value).toISOString().split('T')[0]);
    const fecha = new Date(e.value);
    fecha.setHours(fecha.getHours() + 3);
    this.fecha = fecha.toISOString().split('T')[0]; // ESTO ESTÁ UN DÍA ATRÁS porque la fecha actual es a las 00:00 y le resta 3 horas
    await this.listarDespachos();
  }

  public async modificarFecha(i: number) {
    const fecha = new Date(this.fecha); /// ESTO ESTÁ UN DÍA ATRÁS porque la fecha actual es a las 00:00 y le resta 3 horas
    /// Sumo 3 horas para que sea el día actual
    fecha.setHours(fecha.getHours() + 3);
    fecha.setDate(fecha.getDate() + i);
    this.fecha = fecha.toISOString().split('T')[0];
    await this.listarDespachos();
  }

  public validarDespacho() {
    if (this.salio !== this.periodoSeleccionado.desde) {
      this.completado = false;
    } else if (
      this.idVehiculo !== this.periodoSeleccionado.datos['idVehiculo']
    ) {
      this.completado = false;
    } else if (this.idChofer !== this.periodoSeleccionado.datos['idChofer']) {
      this.completado = false;
    } else if (
      this.idsRecorridos !== this.periodoSeleccionado.datos['idsRecorridos']
    ) {
      this.completado = false;
    } else {
      this.completado = true;
    }
  }

  ///HOOKS
  async ngOnInit() {
    await Promise.all([
      this.listarVehiculos(),
      this.listarChoferes(),
      this.listarRecorridos(),
      this.listarCronogramas(),
    ]);
  }

  ngOnDestroy() {
    this.cronogramas$?.unsubscribe();
    this.vehiculos$?.unsubscribe();
    this.choferes$?.unsubscribe();
    this.recorridos$?.unsubscribe();
    this.despachos$?.unsubscribe();
  }
}
