import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  ICreateRecorrido,
  IFilter,
  IGrupo,
  IListado,
  IParada,
  IQueryParam,
  IRecorrido,
  IUbicacion,
  IUpdateRecorrido,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { RecorridosService } from '../recorridos.service';
import { Coordinate } from 'ol/coordinate';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CrearEditarAgrupacionesComponent } from '../../../../standalone/grupos/crear-editar-grupos/crear-editar-grupos.component';
import { CrearEditarUbicacionComponent } from '../../../../standalone/ubicaciones/crear-editar-ubicacion/crear-editar-ubicacion.component';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';

@Component({
  selector: 'app-crear-editar-recorrido',
  templateUrl: './crear-editar-recorrido.component.html',
  styleUrl: './crear-editar-recorrido.component.scss',
  standalone: false,
})
export class CrearEditarRecorridoComponent implements OnInit, OnDestroy {
  public loading = false;
  public title = '';
  public form?: FormGroup;
  public id?: string | null;

  public recorrido?: IRecorrido;

  // Openlayers
  public lineString: Coordinate[] = [];
  public points: Coordinate[] = [];

  public paradas: IParada[] = [];

  public grupos?: IGrupo[];
  public grupos$?: Subscription;

  public terminalesSeleccionadas?: IUbicacion[];
  public terminales?: IUbicacion[];
  public terminales$?: Subscription;

  public dias = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  public hoy = new Date();
  public hoyALas0 = new Date(
    this.hoy.getFullYear(),
    this.hoy.getMonth(),
    this.hoy.getDate(),
    0,
    0,
    0,
  );

  get franjaHoraria() {
    return this.form?.get('franjaHoraria') as FormArray;
  }

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarRecorridoComponent>,
    public helper: HelperService,
    private route: ActivatedRoute,
    private service: RecorridosService,
    private listados: ListadosService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private paramsService: ParamsService,
  ) {}

  public async listarGrupos() {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
      limit: 0,
    };
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listados
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listados.getLastValue('grupos', query);
  }

  public async listarTerminales() {
    const filter: IFilter<IUbicacion> = {
      categoria: 'Terminal',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'nombre',
      select: 'nombre polygonGeoJSON identificacion',
      limit: 0,
    };
    this.terminales$?.unsubscribe();
    this.terminales$ = this.listados
      .subscribe<IListado<IUbicacion>>('ubicacions', query)
      .subscribe((data) => {
        this.terminales = data.datos;
        console.log(`listado de ubicacions`, data);
      });
    await this.listados.getLastValue('ubicacions', query);
  }

  private createForm() {
    this.form = new FormGroup({
      // Select
      idGrupo: new FormControl(this.recorrido?.idGrupo),
      nombreGrupo: new FormControl(this.recorrido?.nombreFlota),
      idsUbicaciones: new FormControl(this.recorrido?.idsUbicaciones),
      // Inputs
      idExterno: new FormControl(this.recorrido?.idExterno),
      nombre: new FormControl(this.recorrido?.nombre, Validators.required),
      color: new FormControl(this.recorrido?.color),
      destino: new FormControl(this.recorrido?.destino),
      por: new FormControl(this.recorrido?.por),
      duracion: new FormControl(this.recorrido?.duracion),
      recorridoOl: new FormControl(
        this.recorrido?.recorridoOl,
        Validators.required,
      ),
      paradas: new FormControl(this.recorrido?.paradas),
      franjaHoraria: new FormArray(this.addNewFranjaHoraria()),
    });
  }

  private addNewFranjaHoraria() {
    const franjas = [];
    if (this.recorrido?.franjaHoraria) {
      for (const r of this.recorrido.franjaHoraria) {
        franjas.push(
          new FormGroup({
            dia: new FormControl(r.dia, [Validators.required]),
            desde: new FormControl(r.desde, [Validators.required]),
            hasta: new FormControl(r.hasta, [Validators.required]),
            frecuenciaMinutos: new FormControl(r.frecuenciaMinutos, [
              Validators.required,
            ]),
          }),
        );
      }
    } else {
      franjas.push(this.addFranjaHorariaFormGroup());
    }
    return franjas;
  }

  public addFranja() {
    this.franjaHoraria.push(this.addFranjaHorariaFormGroup());
  }

  private addFranjaHorariaFormGroup() {
    // dia?: number; // Número de 0 a 6, siendo 0 el domingo
    // desde?: string;
    // hasta?: string;
    // frecuenciaMinutos?: number;
    return new FormGroup({
      dia: new FormControl(null, [Validators.required]),
      desde: new FormControl(null, [Validators.required]),
      hasta: new FormControl(null, [Validators.required]),
      frecuenciaMinutos: new FormControl(15, [Validators.required]),
    });
  }

  public deleteFranja(i: number) {
    this.franjaHoraria.removeAt(i);
  }

  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.recorrido ? 'Editar' : 'Crear'} Recorrido`,
      `¿Desea ${this.recorrido ? 'editar' : 'crear'} el recorrido?`,
    );
    if (!res) return;
    this.loading = true;
    try {
      if (this.recorrido) {
        // Update
        const data = this.getData();
        if (!data) return;
        await this.service.update(this.recorrido._id, data);
        this.helper.notifSuccess('Recorrido actualizado');
      } else {
        // Create
        const data = this.getData();
        if (!data) return;
        await this.service.create(data);
        this.helper.notifSuccess('Recorrido creado');
      }
      this.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  private getData() {
    const data: ICreateRecorrido | IUpdateRecorrido = this.form?.value;
    return data;
  }

  public onLineStringChange(e: Coordinate[]) {
    this.lineString = e;
    this.form?.patchValue({ recorridoOl: e });
  }

  public onPointsChange(e: Coordinate[]) {
    this.points = e;
  }

  public onParadasChange(paradas: IParada[]) {
    this.form?.patchValue({ paradas });
  }

  public onColorChange(event: string, control: string) {
    this.form?.get(control)?.patchValue(event);
  }

  public onGrupoChange(event: IRecorrido) {
    if (!event) return;
    this.form?.patchValue({
      nombreGrupo: event.nombre,
    });
  }

  public onTerminalesChange(event: IUbicacion[]) {
    this.terminalesSeleccionadas = event;
  }
  public volver() {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      HelperService.volver();
    }
  }

  public crearGrupo() {
    const dialog = this.dialog.open(CrearEditarAgrupacionesComponent, {
      data: { categoria: 'Flota' },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idGrupo: res });
      }
    });
  }

  public crearTerminal() {
    this.paramsService.setParams({ categoria: 'Terminal' });
    const dialog = this.dialog.open(CrearEditarUbicacionComponent, {
      width: '60vh',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        const term = this.form.value.idsUbicaciones;
        term.push(res);
        this.form.patchValue({ idsUbicaciones: term });
      }
    });
  }

  /// HOOKS

  async ngOnInit() {
    this.recorrido = this.paramsService.getParams()['recorrido'];
    if (this.recorrido) {
      this.title = 'Editar Recorrido';
      //Nuevo
      this.lineString = this.recorrido?.recorridoOl || [];
      this.paradas = this.recorrido?.paradas || [];
    } else {
      this.title = 'Crear Recorrido';
    }
    this.createForm();
    await Promise.all([this.listarGrupos(), this.listarTerminales()]);

    if (this.recorrido) {
      if (this.recorrido?.idsUbicaciones) {
        this.terminalesSeleccionadas = this.terminales?.filter((t) =>
          this.recorrido?.idsUbicaciones?.includes(t._id!),
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.grupos$?.unsubscribe();
    this.terminales$?.unsubscribe();
  }
}
