import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IEstadoEntidad,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule, DatePipe } from '@angular/common';
import { AuxiliaresModule } from '../../../../../auxiliares/auxiliares.module';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ConfigEventoUsuarioService } from '../../../../../auxiliares/servicios/http/config-evento-usuario.service';
import { IRegExpSearch } from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { IColumnas } from '../../../../../auxiliares/tabla/tabla.component';
import { CrearEditarEstadoEntidadComponent } from '../crear-editar/crear-editar.component';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-estado-entidad',
  templateUrl: './listado.component.html',
})
export class ListadoEstadoEntidadsComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoEstadoEntidadsComponent.name;
  public estadoEntidads: IEstadoEntidad[] = [];
  public estadoEntidads$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IEstadoEntidad>[];
  public search: IRegExpSearch = {
    fields: ['estado'],
  };
  // FILTROS

  public filter: IFilter<IEstadoEntidad> = {};
  public populate: IPopulate[] = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'usuario',
      select: 'usuario datosPersonales.nombre',
    },
    {
      path: 'alarma',
      select: 'nombre',
    },
    {
      path: 'tracker',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ConfigEventoUsuarioService,
    public dialog: MatDialog,
  ) {}

  public async listar(): Promise<void> {
    let filter: IFilter<IEstadoEntidad> = {};
    if (this.query.filter) {
      filter = JSON.parse(this.query.filter);
    }
    this.query.filter = JSON.stringify(filter);
    //
    this.estadoEntidads$?.unsubscribe();
    this.estadoEntidads$ = this.listados
      .subscribe<IListado<IEstadoEntidad>>('estadoEntidads', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.estadoEntidads = data.datos;
        console.log(`listado de estadoEntidads`, data);
      });
    await this.listados.getLastValue('estadoEntidads', this.query);
  }

  public async editar(estado: IEstadoEntidad) {
    this.dialog.open(CrearEditarEstadoEntidadComponent, {
      data: {
        estadoEntidad: estado,
      },
    });
  }

  public async eliminar(estado: IEstadoEntidad) {
    const res = await this.dialogService.confirm(
      'Confirme la Acción',
      `¿Desea eliminar el stado?`,
    );
    if (!res) return;
    try {
      await this.service.delete(estado._id!);
      this.helper.notifSuccess('estado eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const cols: IColumnas<IEstadoEntidad>[] = [
      {
        header: {
          label: 'Fecha de Creación',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: {
          label: 'Fecha de Vigencia',
          sortable: true,
        },
        row: {
          field: 'vigencia',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: {
          label: 'Estado',
          sortable: true,
        },
        row: {
          field: 'estado',
          noData: 'Sin Estado',
        },
      },
      {
        header: {
          label: 'Nota',
          sortable: true,
        },
        row: {
          field: 'nota',
          noData: 'Sin Nota',
        },
      },
      {
        header: {
          label: 'Motivos',
          sortable: true,
        },
        row: {
          lista(dato) {
            return dato.motivos;
          },
        },
      },
      {
        header: {
          label: 'Usuario',
          sortable: true,
        },
        row: {
          parse(dato) {
            return (
              dato?.usuario?.datosPersonales?.nombre || dato.usuario.usuario
            );
          },
          noData: 'Sin Usuario',
        },
      },
    ];

    // Entidad
    cols.push({
      header: {
        label: 'Entidad',
        sortable: true,
      },
      row: {
        parse(dato) {
          return dato?.alarma?.nombre || dato?.tracker?.nombre;
        },
        noData: 'Sin Entidad',
      },
    });
    // Acciones
    // cols.push({
    //   header: {
    //     label: 'Acciones',
    //   },
    //   row: {
    //     acciones: [
    //       {
    //         tipo: 'material',
    //         color: 'accent',
    //         icon: 'edit',
    //         tooltip: 'Editar',
    //         click: (dato) => this.editar(dato),
    //       },
    //       {
    //         tipo: 'material',
    //         color: 'warn',
    //         icon: 'delete',
    //         tooltip: 'Eliminar',
    //         click: (dato) => this.eliminar(dato),
    //         disabled() {
    //           return LoginService.getUsuario()?._id === 'admin';
    //         },
    //       },
    //     ],
    //   },
    // });
    this.columnas = cols;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    Promise.all([this.listar()]);
  }

  async ngOnDestroy() {
    this.estadoEntidads$?.unsubscribe();
  }
}
