import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICliente,
  IConfigReenvio,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import { ConfigReenviosService } from '../../../../../auxiliares/servicios/http/config-reenvios.service';

@Component({
  selector: 'app-listado-config-reenvios',
  templateUrl: './listado-config-reenvios.component.html',
  styleUrl: './listado-config-reenvios.component.scss',
  standalone: false,
})
export class ListadoConfigReenviosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoConfigReenviosComponent.name;
  public datos: IConfigReenvio[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<IConfigReenvio>[];

  //Subs
  public datos$?: Subscription;
  public clientes$?: Subscription;

  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;

  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear configuración de reenvio',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  // FILTROS
  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];

  //Query
  public filter: IFilter<IConfigReenvio> = {};
  public populate: IPopulate = [
    { path: 'cliente', select: 'nombre' },
    { path: 'clienteReenvio', select: 'nombre' },
    { path: 'tracker', select: 'nombre' },
    { path: 'dispositivoAlarma', select: 'nombre' },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'activo',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ConfigReenviosService,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}

  //Listados

  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      await Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listados
      .subscribe<IListado<IConfigReenvio>>('configReenvios', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de configreenvios`, data);
      });
    await this.listados.getLastValue('configReenvios', this.query);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(configreenvio: IConfigReenvio) {
    this.navigator.navigate(['editar', configreenvio._id], {
      relativeTo: this.route,
    });
  }

  public async eliminar(configreenvio: IConfigReenvio) {
    const res = await this.dialogService.confirm(
      'Eliminar configuración de reenvio',
      `¿Desea eliminar la configuración de reenvio?`,
    );
    if (!res) return;
    try {
      await this.service.delete(configreenvio._id!);
      this.helper.notifSuccess('configuración de reenvio eliminada');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IConfigReenvio>[] = [
      // Agrupacion
      {
        header: {
          label: 'Agrupación',
        },
        row: {
          field: 'agrupacionReenvio',
        },
      },

      // Seleccion
      {
        header: {
          label: 'Selección',
        },
        row: {
          parse(dato) {
            return (
              dato.clienteReenvio?.nombre ||
              dato.tracker?.nombre ||
              dato.dispositivoAlarma?.nombre
            );
          },
        },
      },
      // Opciones de reenvio
      {
        header: {
          label: 'Opciones de reenvio',
        },
        row: {
          parse(dato) {
            if (dato.opcionesReenvio?.metodo === 'Básico') {
              return `${dato.opcionesReenvio?.metodo} ${dato.opcionesReenvio?.host}:${dato.opcionesReenvio?.puerto}`;
            }
            if (dato.opcionesReenvio.metodo === 'Seguridad Evento Externo') {
              return `${dato.opcionesReenvio?.metodo}`;
            }
            return '';
          },
          noData: 'Sin opciones de reenvio',
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.datos$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
