/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ITratamientoEvento,
  IQueryParam,
  IPopulate,
  IFilter,
  IListado,
  ICreateTratamientoEvento,
  IEvento,
  INota,
  ICliente,
  IServicioContratado,
  estadoEvento,
  IDispositivoAlarma,
  ICamaraAlarma,
} from 'modelos/src';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { AgregarNotaComponent } from '../agregar-nota/agregar-nota.component';
import { PonerEnEsperaComponent } from '../poner-en-espera/poner-en-espera.component';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { LoginService } from '../../../../login/login.service';
import { MapaFacilDialogComponent } from '../../../../../auxiliares/mapa-facil-dialog/mapa-facil-dialog.component';
import { MapaFacilData } from '../../../../../auxiliares/mapa-facil/interfaces';
import { fromLonLat } from 'ol/proj';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TratamientoEventosService } from '../../../../../auxiliares/servicios/http/tratamientos-eventos.service';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';
import { EnviarMovilComponent } from '../enviar-movil/enviar-movil.component';

@Component({
  selector: 'app-tratamiento',
  templateUrl: './tratamiento.component.html',
  styleUrl: './tratamiento.component.scss',
  standalone: false,
})
export class TratamientoComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  // public dragBoundary = document.getElementById('router');

  public tratando: boolean = false;
  public estadoActual?: estadoEvento;
  public alarma?: IDispositivoAlarma;
  public alarma$?: Subscription;
  public evento: IEvento;
  public eventosDispositivo: IEvento[];
  public ultimosEventos: IEvento[];
  public ultimosEventos$: Subscription;
  public idsEventosDispositivoSeleccionados?: string[];
  public evento$: Subscription;
  public cliente: ICliente;
  public cliente$: Subscription;
  public estadoDeCuenta?: string = 'Al día';
  public serviciosContratados: IServicioContratado[] = [];
  public mostrandoCamara: boolean = false;
  public tratamientosEvento: ITratamientoEvento[] = [];
  public tratamientosEvento$: Subscription;
  public notas: INota[] = [];
  public contactos: INota[] = [];
  public notas$: Subscription;

  public camaraInicialAlarma: ICamaraAlarma = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEvento,
    private listados: ListadosService,
    public helper: HelperService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<TratamientoComponent>,
    private dialogService: DialogService,
    private service: TratamientoEventosService,
    private cdk: OverlayContainer,
    private el: ElementRef,
    private paramsService: ParamsService,
  ) {}

  private async listarNotas(): Promise<void> {
    const idEntidad = this.data.activo?._id || this.data.alarma?._id;
    const idCliente = this.data.cliente?._id;
    const filter: IFilter<INota> = {
      idAsignado: { $in: [idEntidad, idCliente] },
      $or: [
        { permanente: true },
        {
          vigenciaDesde: { $lte: new Date().toISOString() },
          vigenciaHasta: { $gte: new Date().toISOString() },
        },
      ],
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'orden',
    };
    this.notas$?.unsubscribe();
    this.notas$ = this.listados
      .subscribe<IListado<INota>>('notas', query)
      .subscribe((data) => {
        // Filtrado de notas y contactos
        this.notas = data.datos.filter((nota) => nota.tipo === 'Nota');
        this.contactos = data.datos.filter((nota) => nota.tipo === 'Contacto');
      });
    await this.listados.getLastValue('notas', query);
  }

  private async listarCliente(): Promise<void> {
    const idCliente = this.data.cliente?._id;
    console.log(`evento`, this.data);

    const filter: IFilter<ICliente> = {
      $and: [{ _id: idCliente }],
    };
    const populate: IPopulate = {
      path: 'serviciosContratados',
      select: 'nombre icono',
    };

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      select: 'idServiciosContratados nombre estadoDeCuenta',
      populate: JSON.stringify(populate),
      includeChildren: true,
    };
    this.cliente$?.unsubscribe();
    this.cliente$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente = data.datos[0];
        // this.estadoDeCuenta = this.cliente.estadoDeCuenta;
        this.serviciosContratados = this.cliente.serviciosContratados;
        console.log(`listado de cliente`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  private async listarTratamientos(): Promise<void> {
    if (!this.idsEventosDispositivoSeleccionados?.length) return;

    const populate: IPopulate[] = [
      {
        path: 'usuario',
        select: 'usuario',
      },
    ];
    const filter: IFilter<ITratamientoEvento> = {
      idsEventos: { $in: this.idsEventosDispositivoSeleccionados },
      estado: { $ne: 'Sin Tratamiento' },
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      sort: '-fechaCreacion',
    };
    this.tratamientosEvento$?.unsubscribe();
    this.tratamientosEvento$ = this.listados
      .subscribe<IListado<ITratamientoEvento>>('tratamientosEventos', query)
      .subscribe((data) => {
        this.tratamientosEvento = JSON.parse(JSON.stringify(data.datos));
        this.estadoActual = this.tratamientosEvento[0]?.estado;
        console.log(`listado de tratamientosEventos`, data);
      });
    await this.listados.getLastValue('tratamientosEventos', query);
  }

  private async listarEventos(): Promise<void> {
    const filter: IFilter<IEvento> = this.data?.activo?._id
      ? { idActivo: this.data?.activo?._id }
      : { idAlarma: this.data?.alarma?._id };

    if (this.data?.estado === 'En Espera') {
      filter.estado = {
        $nin: [
          'Sin Tratamiento',
          'Pendiente',
          'En Atención',
          'Liberada',
          'Finalizada',
        ],
      };
    } else {
      filter.estado = { $nin: ['Finalizada', 'En Espera'] };
    }

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
      sort: '-fechaCreacion',
    };
    this.evento$?.unsubscribe();
    this.evento$ = this.listados
      .subscribe<IListado<IEvento>>('eventos', query)
      .subscribe(async (data) => {
        const idsUsuariosAtendiendo = data.datos
          .map((e) => e.idsUsuariosAtendiendo)
          .flat();

        this.tratando = idsUsuariosAtendiendo?.includes(
          LoginService.getUsuario()._id,
        );
        console.log(
          `tratando evento`,
          this.tratando,
          `usuarios atendiendo `,
          idsUsuariosAtendiendo,
        );

        this.eventosDispositivo = data.datos;

        if (!this.idsEventosDispositivoSeleccionados) {
          this.idsEventosDispositivoSeleccionados = this.eventosDispositivo.map(
            (e) => e._id,
          );
        }

        console.log(`listado de eventos`, data);
        await this.listarTratamientos();
      });

    await this.listados.getLastValue('eventos', query);
  }

  private async listarUltimosEventos(): Promise<void> {
    const filter: IFilter<IEvento> = this.data?.activo?._id
      ? { idActivo: this.data?.activo?._id }
      : { idAlarma: this.data?.alarma?._id };

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
      sort: '-fechaCreacion',
      limit: 3,
    };
    this.ultimosEventos$?.unsubscribe();
    this.ultimosEventos$ = this.listados
      .subscribe<IListado<IEvento>>('eventos', query)
      .subscribe(async (data) => {
        console.log(`listado de eventos`, data);
        this.ultimosEventos = data.datos;
      });

    await this.listados.getLastValue('eventos', query);
  }

  public async espera() {
    const config: MatDialogConfig = {
      data: this.idsEventosDispositivoSeleccionados,
      panelClass: 'no-scroll-dialog',
      width: '600px',
      disableClose: true,
    };
    const dialog = this.dialog.open(PonerEnEsperaComponent, config);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.dialogRef.close(res);
      }
    });
  }

  public async liberar() {
    const config: MatDialogConfig<{
      idsEventos: string[];
      estado: estadoEvento;
    }> = {
      data: {
        idsEventos: this.idsEventosDispositivoSeleccionados,
        estado: 'Liberada',
      },
      panelClass: 'no-scroll-dialog',
      width: '600px',
      disableClose: true,
    };
    const dialog = this.dialog.open(AgregarNotaComponent, config);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.dialogRef.close(res);
      }
    });
  }

  public async finalizar() {
    const config: MatDialogConfig<{
      idsEventos: string[];
      estado: estadoEvento;
    }> = {
      data: {
        idsEventos: this.idsEventosDispositivoSeleccionados,
        estado: 'Finalizada',
      },
      panelClass: 'no-scroll-dialog',
      width: '600px',
      disableClose: true,
    };
    const dialog = this.dialog.open<
      AgregarNotaComponent,
      any,
      ITratamientoEvento
    >(AgregarNotaComponent, config);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.dialogRef.close(res);
      }
    });
  }

  public agregarNota() {
    const config: MatDialogConfig<{
      idsEventos: string[];
      estado: estadoEvento;
    }> = {
      data: {
        idsEventos: this.idsEventosDispositivoSeleccionados,
        estado: 'En Atención',
      },
      panelClass: 'no-scroll-dialog',
      width: '600px',
      disableClose: true,
    };
    this.dialog.open(AgregarNotaComponent, config);
  }

  public async tratar() {
    const res = await this.dialogService.confirm(
      `Confirme la acción`,
      this.idsEventosDispositivoSeleccionados.length === 1
        ? `¿Tratar evento ${this.data?.valores?.titulo} de ${this.data?.cliente?.nombre}?`
        : `Tratar eventos?`,
    );
    if (!res) return;
    const tratamiento: ICreateTratamientoEvento = {
      estado: 'Pendiente',
      idsEventos: this.idsEventosDispositivoSeleccionados,
    };
    await this.service.create(tratamiento);
    this.tratando = true;
  }

  public verUbicacion() {
    const lng =
      (this.data.alarma?.domicilio?.geojson?.coordinates[0] as number) ||
      (this.data.valores?.['geojson']?.coordinates[0] as number);
    const lat =
      (this.data.alarma?.domicilio?.geojson?.coordinates[1] as number) ||
      (this.data.valores?.['geojson']?.coordinates[1] as number);

    const coord = fromLonLat([lng, lat]);
    const data: MapaFacilData = {
      markers: [{ coor: coord, label: 'puntoSimple' }],
      satelliteView: true,
    };
    this.dialog.open(MapaFacilDialogComponent, {
      minWidth: '1010px',
      data,
    });
  }

  public verFotos() {
    alert('Ver fotos');
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public bringToFront(): void {
    const dialogsContainer = Array.from(
      this.cdk.getContainerElement().childNodes,
    );
    const dialogContainerTagName = 'MAT-DIALOG-CONTAINER';
    const parentEl = this.el.nativeElement.closest(dialogContainerTagName);
    const dialogId = parentEl?.getAttribute('id');
    const previousDialog = this.cdk.getContainerElement().lastElementChild;
    const focusedDialog = (dialogsContainer as HTMLElement[]).find(
      (el: HTMLElement) => {
        return el.querySelector(`#${dialogId}`);
      },
    );
    // para evitar que el scroll se resetee si es el mismo dialog
    if (focusedDialog !== previousDialog) {
      if (focusedDialog) {
        // appendChild moves the element to the end of the container
        this.cdk.getContainerElement().appendChild(focusedDialog);
      } else {
        console.error('Dialog not found with id:', dialogId);
      }
    }
  }

  public marcado(evento: IEvento) {
    return this.idsEventosDispositivoSeleccionados?.includes(evento._id);
  }

  public marcarTodos(checked: boolean) {
    if (checked) {
      this.idsEventosDispositivoSeleccionados = this.eventosDispositivo.map(
        (e) => e._id,
      );
    } else this.idsEventosDispositivoSeleccionados = [];
  }

  public cambiarMarcado(marcado: boolean, evento: IEvento) {
    if (marcado) {
      this.idsEventosDispositivoSeleccionados.push(evento?._id);
    } else {
      this.idsEventosDispositivoSeleccionados =
        this.idsEventosDispositivoSeleccionados.filter((s) => s !== evento._id);
    }
  }

  public camaraInicial() {
    if (
      +this.data?.valores['particion'] &&
      +this.data?.valores['zona'] &&
      this.data?.alarma?.camarasPorZona
    ) {
      const res = this.data.alarma.camarasPorZona.find(
        (c) =>
          +c.particion === +this.data?.valores['particion'] &&
          +c.zona === +this.data?.valores['zona'],
      );
      if (res) this.camaraInicialAlarma = res;
    }
  }
  /// Movil

  public tieneEnvioMovil() {
    return this.serviciosContratados.find((s) => s.nombre === 'Envio Movil') &&
      this.data.alarma._id
      ? true
      : false;
  }

  public async enviarMovil() {
    const config: MatDialogConfig<{
      idsEventos: string[];
    }> = {
      data: {
        idsEventos: this.idsEventosDispositivoSeleccionados,
      },
      panelClass: 'no-scroll-dialog',
      width: '600px',
      disableClose: true,
    };
    this.dialog.open(EnviarMovilComponent, config);
  }

  //

  async ngOnInit() {
    this.paramsService.setParams({ dialog: false });
    this.estadoActual = this.data?.estado;

    // Agregar un console.log para verificar el orden
    console.log('Notas ordenadas:', this.notas);
    const usuario = LoginService.getUsuario();
    if (this.data.idsUsuariosAtendiendo.includes(usuario._id)) {
      this.tratando = true;
    }

    await Promise.all([
      this.listarEventos(),
      this.listarNotas(),
      this.listarCliente(),
      this.listarUltimosEventos(),
    ]);
    this.camaraInicial();
    this.loading = false;
  }

  async ngOnDestroy() {
    this.tratamientosEvento$?.unsubscribe();
    this.notas$?.unsubscribe();
    this.cliente$?.unsubscribe();
    this.evento$?.unsubscribe();
  }
}
