/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import * as Highcharts from 'highcharts';
import { LoginService } from '../../../../../login/login.service';
import { ICliente } from 'modelos/src';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';

@Component({
  selector: 'app-jerarquia-cliente',
  templateUrl: './jerarquia-cliente.component.html',
  styleUrl: './jerarquia-cliente.component.scss',
  standalone: false,
})
export class JerarquiaClienteComponent implements OnInit, OnChanges {
  public chartOptions?: Highcharts.Options;
  @Input() clientes?: ICliente[];
  @Input() clienteElegido?: ICliente = null;
  private miCliente = LoginService.getCliente();
  constructor() {}

  public async graficoJerarquia() {
    const conexiones: string[][] = [];
    const nodos: { id: string; name: string }[] = [];
    const niveles: { level: number; color?: string }[] = [];
    if (
      this.clienteElegido !== null &&
      this.clienteElegido?._id !== this.miCliente._id &&
      this.clienteElegido?.idPadre
    ) {
      let idPadre = this.clienteElegido.idPadre;
      conexiones.push([idPadre, this.clienteElegido._id]);
      niveles.push({
        level: this.clienteElegido.nivel + 1,
        color: this.clienteElegido.config.tema.primaryColor,
      });
      nodos.push({
        id: this.clienteElegido._id,
        name: this.clienteElegido.nombre,
      });
      while (idPadre) {
        const clientePadre = this.clientes.find((c) => c._id === idPadre);
        if (clientePadre) {
          if (!niveles.find((n) => n.level === clientePadre.nivel))
            niveles.push({
              level: clientePadre.nivel + 1,
              // color: clientePadre?.config?.tema?.primaryColor
              //   ? clientePadre?.config?.tema?.primaryColor
              //   : '#111111',
            });
          conexiones.push([clientePadre.idPadre, idPadre]);
          nodos.push({
            id: clientePadre._id,
            name: clientePadre.nombre,
          });
          idPadre = clientePadre?.idPadre;
        } else {
          conexiones[conexiones.length - 1] = [
            conexiones[conexiones.length - 1][1],
          ];
          idPadre = null;
        }
      }
    }

    const color = this.miCliente.config.tema.accentColor;
    const textColor = HelperService.invertirColor(color);

    this.chartOptions = {
      chart: {
        height: 400,
        inverted: true,
        backgroundColor: 'rgba(0,0,0,0)',
      },
      title: {
        text: '',
      },
      accessibility: {
        point: {
          descriptionFormat:
            '{add index 1}. {toNode.name}' +
            '{#if (ne toNode.name toNode.id)}, {toNode.id}{/if}, ' +
            'reports to {fromNode.id}',
        },
      },
      series: [
        {
          type: 'organization',
          name: 'Clientes',
          keys: ['from', 'to'],
          data: conexiones,
          levels: niveles,
          nodes: nodos,
          colorByPoint: false,
          color: color,
          dataLabels: {
            color: textColor,
          },
          borderColor: 'rgba(0,0,0,0)',
          nodeWidth: 'auto',
          link: {
            lineWidth: 6,
            color,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      ],
      tooltip: {
        enabled: false,
      },
    } as any;
  }

  // Hooks

  async ngOnInit() {
    await this.graficoJerarquia();
  }

  async ngOnChanges(c: SimpleChanges): Promise<void> {
    if (
      c['clientes'] &&
      c['clientes'].currentValue !== c['clientes'].previousValue
    ) {
      await this.graficoJerarquia();
    }

    if (
      c['clienteElegido'] &&
      c['clienteElegido'].currentValue !== c['clienteElegido'].previousValue
    ) {
      await this.graficoJerarquia();
    }
  }
}
