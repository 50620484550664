@if (dialogRef && esDialog) {
  <div (click)="bringToFront()">
    <mat-card style="border: 1px solid black; overflow-x: hidden">
      <!-- Header -->
      <mat-card-header style="border-radius: 2px 2px 0 0">
        <mat-card-title
          cdkDrag
          cdkDragHandle
          cdkDragRootElement=".cdk-overlay-pane"
          [cdkDragBoundary]="dragBoundary"
          (cdkDragStarted)="bringToFront()"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span style="padding-left: 8px">
            {{ camara?.identificacion }}
          </span>

          <div (click)="closeDialog()" class="icon-close">
            <mat-icon> close </mat-icon>
          </div>
        </mat-card-title>
      </mat-card-header>

      <!-- Content -->
      <mat-card-content
        style="
          margin: 1em 0;
          overflow: auto;
          justify-content: center;
          display: flex;
          overflow-x: hidden;
        "
      >
        <!--  -->
        <div>
          <mat-divider></mat-divider>

          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 0.5em;
              margin-top: 1em;
            "
          >
            @for (stream of streams; track stream) {
              <app-videogular
                [url]="stream"
                [controles]="controles"
                [botonMultiple]="botonMultiple"
                [maximizado]="maximizado"
                (maximizadoEmiter)="botonClick()"
              ></app-videogular>
            }
          </div>
        </div>
      </mat-card-content>

      <!-- Acciones -->
      <mat-dialog-actions
        style="
          display: flex;
          justify-content: space-between;
          padding: 6px;
          gap: 8px;
        "
      >
      </mat-dialog-actions>
    </mat-card>
  </div>
} @else {
  @for (stream of streams; track stream) {
    <app-videogular
      [url]="stream"
      [controles]="controles"
      [botonMultiple]="botonMultiple"
      [maximizado]="maximizado"
      (maximizadoEmiter)="botonClick()"
    ></app-videogular>
  }
}
