import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IDispositivoLorawan,
  ICreateDispositivoLorawan,
  IUpdateDispositivoLorawan,
  IGetDeviceProfileChirpstack,
} from 'modelos/src';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';

@Injectable({
  providedIn: 'root',
})
export class DispositivosLorawanService {
  constructor(private http: HttpClientService) {}

  //OBTENER TODOS LOS DISPOSITIVOS
  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IDispositivoLorawan>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/dispositivoslorawan`, { params });
  }

  //OBTENER DISPOSITIVO POR ID
  public getById(id: string): Promise<IDispositivoLorawan> {
    return this.http.get(`/dispositivoslorawan/${id}`);
  }

  //OBTENER DISPOSITIVO POR DEVEUI
  public getByDeveui(deveui: string): Promise<IDispositivoLorawan> {
    return this.http.get(`/dispositivoslorawan/deveui/${deveui}`);
  }

  //CREAR DISPOSITIVO
  public create(dato: ICreateDispositivoLorawan): Promise<IDispositivoLorawan> {
    return this.http.post(`/dispositivoslorawan`, dato);
  }

  //ACTUALIZAR DISPOSITIVO
  public update(
    id: string,
    dato: IUpdateDispositivoLorawan,
  ): Promise<IDispositivoLorawan> {
    return this.http.put(`/dispositivoslorawan/${id}`, dato);
  }

  //ELIMINAR DISPOSITIVO POR ID
  public deleteById(id: string): Promise<void> {
    return this.http.delete(`/dispositivoslorawan/${id}`);
  }

  //ELIMINAR DISPOSITIVO POR DEVEUI
  public deleteByDeveui(deveui: string): Promise<void> {
    return this.http.delete(`/dispositivoslorawan/deveui/${deveui}`);
  }

  //OBTENER DEVICE PROFILES CARGADOS EN CHIRPSTACK
  public getDeviceProfiles(): Promise<IListado<IGetDeviceProfileChirpstack>> {
    return this.http.get(`/chirpstack/device-profiles`);
  }
}
