import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  IActivo,
  IListado,
  IQueryParam,
  IQueryTraccar,
  Stop,
  Trip,
} from 'modelos/src';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DateRange } from '../../../../../../auxiliares/date-range-selector/date-range-selector.component';
import { ListadosService } from '../../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { LoadingService } from '../../../../../../auxiliares/servicios/loading.service';

@Component({
  selector: 'app-vehiculo-detenido',
  templateUrl: './vehiculo-detenido.component.html',
  styleUrl: './vehiculo-detenido.component.scss',
  standalone: false,
})
export class VehiculoDetenidoComponent implements OnInit, OnDestroy {
  public interaccionesBusqueda: Stop[] = [];
  @Input() public interaccionesBusquedaSelected: Stop;
  @Output() public interaccionesBusquedaSelectedChange =
    new EventEmitter<Stop>();
  public displayedColumns: string[] = ['puntos'];
  public buscando: boolean = false;
  public range?: DateRange = {};
  public minutosMinimos? = new FormControl();
  @Output() public interaccionesBusquedaQuery = new EventEmitter<Stop[]>();

  public query: IQueryParam = {
    sort: 'nombre',
    page: 0,
    includeChildren: true,
    childrenLevel: 0,
  };

  public vehiculos: IActivo[] = [];
  public vehiculo: IActivo = null;
  public vehiculos$?: Subscription;
  public interaccionesBusqueda$: Subscription;

  constructor(
    public loading: LoadingService,
    public dialog: MatDialog,
    private listados: ListadosService,
    public helper: HelperService,
  ) {}

  public async listarVehiculos(): Promise<void> {
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', this.query)
      .subscribe(async (data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos `, data);
      });
    await this.listados.getLastValue('vehiculos', this.query);
  }
  public async listarParadas() {
    console.log(this.range);
    if (!this.range || !this.vehiculo) {
      return;
    }
    const date = new Date(this.range.start);
    const start = new Date(date.getTime() - 12 * 60 * 60 * 1000).toISOString();
    const end = new Date(date.getTime() + 12 * 60 * 60 * 1000).toISOString();
    const query: IQueryTraccar = {
      idVehiculo: this.vehiculo._id!,
      from: start,
      to: end,
    };
    this.interaccionesBusqueda$?.unsubscribe();
    this.interaccionesBusqueda$ = this.listados
      .subscribe<Trip[]>('stops', query)
      .subscribe((data) => {
        // sort by descending startTime
        if (data) {
          this.interaccionesBusqueda = data.sort((a, b) => {
            return a.startTime! < b.startTime! ? -1 : 1;
          });
          this.minutosMinimos.value
            ? (this.interaccionesBusqueda = this.interaccionesBusqueda.filter(
                (p) => {
                  const duration = Math.floor(p.duration / 60000);
                  return duration >= this.minutosMinimos.value;
                },
              ))
            : null;
          console.log(`listado de stops`, data);
          this.interaccionesBusquedaQuery.emit(this.interaccionesBusqueda);
        }
      });
    await this.listados.getLastValue('stops', query);
  }

  public onParadasSelected(parada: Stop, expanded: boolean) {
    if (expanded) {
      this.interaccionesBusquedaSelected = parada;
      this.interaccionesBusquedaSelectedChange.emit(parada);
    }
  }

  async ngOnInit() {
    await this.listarVehiculos();
  }

  ngOnDestroy() {
    this.vehiculos$?.unsubscribe();
    this.interaccionesBusqueda$?.unsubscribe();
    this.interaccionesBusquedaQuery.emit(null);
  }
}
