import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IDispositivoAlarma,
  IQueryParam,
  IFilter,
  IPopulate,
  IListado,
  IGeoJSONPoint,
  ICamara,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import {
  MapaFacilData,
  MarkerMapa,
} from '../../../../auxiliares/mapa-facil/interfaces';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';

export interface IDispositivoAlarmaConColor extends IDispositivoAlarma {
  color?: string; // Se agrega la propiedad `color`
}
@Component({
  selector: 'app-detalles-alarma-info',
  templateUrl: './detalles-alarma-info.component.html',
  styleUrl: './detalles-alarma-info.component.scss',
  standalone: false,
})
export class DetallesAlarmaInfoComponent implements OnInit {
  public id: string | null = null;
  public title = '';
  public alarma: IDispositivoAlarmaConColor;
  public alarmas$: Subscription;
  public datoMapa?: MapaFacilData;

  public camaraElegida: ICamara;
  public canalElegido: string;
  public camaraLista: boolean = true;

  public loading = true;
  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private route: ActivatedRoute,
  ) {}

  //////////////////////////////////////////////////////////////////////////
  //Asignación de color según la frecuencia de reporte
  private calcularColor(): string {
    if (this.alarma?.fechaUltimaComunicacion) {
      const fechaUltimaComunicacion = new Date(
        this.alarma.fechaUltimaComunicacion,
      );
      if (this.alarma.frecReporte) {
        const frecuenciaReporte = this.alarma.frecReporte;
        const tiempoReporte = fechaUltimaComunicacion.getTime();
        const tiempoActual = new Date().getTime();
        const diff = tiempoActual - tiempoReporte;
        const frecuenciaEnMs = frecuenciaReporte * 60 * 1000;
        let color = 'green';

        if (diff > 2 * frecuenciaEnMs) {
          color = 'red';
        } else if (diff > frecuenciaEnMs && diff < 2 * frecuenciaEnMs) {
          color = 'yellow';
        }

        return color;
      } else {
        const color = 'black';
        return color;
      }
    }

    return ''; // Si no hay comunicación, color por defecto
  }
  ///////////////////////////////////////////////////////////////////////////////

  public async listar(): Promise<void> {
    const filter: IFilter<IDispositivoAlarma> = { _id: this.id };
    const populate: IPopulate[] = [
      {
        path: 'cliente',
        select: 'nombre',
      },
      {
        path: 'modelo',
        select: 'modelo marca',
      },
      {
        path: 'domicilio',
        select: 'geojson direccion',
      },
      {
        path: 'comunicador',
        select: 'modelo marca',
      },
      {
        path: 'camaras',
        select: 'identificacion canales fechaCreacion idModeloDispositivo',
        populate: { path: 'modeloDispositivo', select: 'modelo marca' },
      },
    ];
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };
    this.alarmas$?.unsubscribe();
    this.alarmas$ = this.listados
      .subscribe<IListado<IDispositivoAlarma>>('dispositivosAlarmas', query)
      .subscribe({
        next: (data) => {
          if (data?.datos && data.datos.length > 0) {
            this.alarma = data.datos[0];
            this.alarma.color = this.calcularColor(); // Calculamos el color y lo asignamos // Asumiendo que data.datos es un array
            console.log('Listado de dispositivosAlarmas', data);
          } else {
            console.log('No se encontraron alarmas');
          }
        },
        error: (err) => {
          console.error('Error al cargar alarmas', err);
        },
      });
    await this.listados.getLastValue('dispositivosAlarmas', query);
  }

  public async datadelmapa(): Promise<void> {
    if (this.alarma?.domicilio) {
      const cordinate = OpenLayersService.lonLatToCoordinate(
        (this.alarma?.domicilio?.geojson as IGeoJSONPoint).coordinates[0],
        (this.alarma?.domicilio?.geojson as IGeoJSONPoint).coordinates[1],
      );
      const marker: MarkerMapa = {
        label: 'puntoSimple',
        coor: cordinate,
      };
      const mapaFacil: MapaFacilData = { markers: [marker] };
      this.datoMapa = mapaFacil;
    }
  }

  public camarasData(dato: IDispositivoAlarma) {
    return dato.camaras.map((camara) => camara.identificacion).join(', ');
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    await this.listar();
    await this.datadelmapa();
    this.loading = false;
  }
}
