import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IEvento,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import {
  IRegExpSearch,
  IFiltroTabla,
} from '../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  IExportarBoton,
} from '../../../../auxiliares/tabla/tabla.component';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { EventosDetallesComponent } from '../eventos-detalles/eventos-detalles.component';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { EventosService } from '../../../../auxiliares/servicios/http/eventos.service';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-eventos-historico',
  templateUrl: './listado-eventos-historico.component.html',
  styleUrl: './listado-eventos-historico.component.scss',
})
export class ListadoEventosHistoricoComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  @Input() public id: string = null;
  @Input() public tipo: 'Alarma' | 'Activo' | 'Cliente';
  @Input() nombreEntidad: string;
  public name = ListadoEventosHistoricoComponent.name;
  public eventosHistorico: IEvento[] = [];
  public eventosHistorico$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IEvento>[];

  public botonExportar: IExportarBoton = {
    accion: () => this.exportarEventos(),
    mostrar: true,
    icon: 'download',
    tipo: 'material',
    tooltip: 'Exportar',
  };
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['valores.titulo', 'valores.mensaje'],
  };
  private activo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idActivo',
    },
    label: 'Activo',
    selectLabel: 'identificacion',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarActivos$',
    searchOn: ['identificacion'],
  };
  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  private titulo: IFiltroTabla = {
    elementos: [
      'Activo fuera de ubicación',
      'Activo dentro de ubicación',
      'Activo Activada',
    ],
    filter: {
      field: 'valores.titulo',
    },
    label: 'Evento',
    tipo: 'select',
  };
  private fecha: IFiltroTabla = {
    filter: {
      field: 'fechaCreacion',
    },
    label: 'Fecha',
    tipo: 'dateRange',
  };
  public filtros: IFiltroTabla[] = [
    this.titulo,
    this.fecha,
    this.activo,
    this.cliente,
  ];

  // FILTROS

  public populate: IPopulate[] = [
    {
      path: 'activo',
    },
    {
      path: 'alarma',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    sort: '-fechaCreacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private eventosService: EventosService,
  ) {}

  public async exportarEventos() {
    const rango = await this.dialogService.dateRange(
      'Exportar Eventos',
      'Seleccione el rango de fechas para exportar los eventos',
    );
    if (!rango) return;

    const filter: IFilter<IEvento> = {
      fechaCreacion: {
        $gte: rango?.start,
        $lte: rango?.end,
      },
    };
    if (this.tipo === 'Cliente') filter.idCliente = this.id;
    if (this.tipo === 'Alarma') filter.idAlarma = this.id;
    if (this.tipo === 'Activo') filter.idActivo = this.id;
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'fechaCreacion',
      page: 0,
      limit: 10000,
      includeChildren: true,
      select: 'valores.titulo valores.mensaje fechaCreacion codigoEvento',
    };

    await this.eventosService.exportar(query, this.nombreEntidad);
  }

  public async listar(): Promise<void> {
    let filter: IFilter<IEvento>;
    if (this.query.filter) filter = JSON.parse(this.query.filter);
    else filter = {};
    if (this.tipo === 'Cliente') filter.idCliente = this.id;
    if (this.tipo === 'Alarma') filter.idAlarma = this.id;
    if (this.tipo === 'Activo') filter.idActivo = this.id;

    this.query.filter = JSON.stringify(filter);
    this.eventosHistorico$?.unsubscribe();
    this.eventosHistorico$ = this.listados
      .subscribe<IListado<IEvento>>('eventosHistorico', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.eventosHistorico = data.datos;
        console.log(`listado de eventosHistorico`, data);
      });
    await this.listados.getLastValue('eventosHistorico', this.query);
  }
  // Acciones
  public detalles(evento: IEvento) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '700px',
      data: { idEvento: evento._id },
    };
    this.dialog.open(EventosDetallesComponent, config);
  }
  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IEvento>[] = [
      // Fecha
      {
        header: {
          label: 'Fecha',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: ['dd/MM/yyyy HH:mm'],
          },
        },
      },
      // Nombre evento
      {
        header: {
          label: 'Evento',
        },
        row: {
          parse(dato) {
            return dato.valores?.titulo;
          },
        },
      },
      // Detalles
      {
        header: {
          label: 'Detalles',
        },
        row: {
          parse(dato) {
            return dato.valores?.['mensaje'];
          },
        },
      },

      // Notificar
      {
        header: {
          label: 'Notificar',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.notificar) return 'Si';
            else return 'No';
          },
        },
      },
    ];
    //Activo o Alarma
    if (this.tipo === 'Cliente') {
      {
        columnas.push({
          header: {
            label: 'Dispositivo',
            sortable: true,
          },
          row: {
            parse(dato) {
              return dato?.activo?.identificacion || dato?.alarma?.nombre;
            },
          },
        });
      }
    }
    // Cliente
    if (HelperService.getTipoCliente() !== 'Final' && this.tipo !== 'Cliente') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
            sortable: true,
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'search',
            tooltip: 'Detalles',
            click: (dato) => this.detalles(dato),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    Promise.all([this.listar()]);
  }

  async ngOnDestroy() {
    this.eventosHistorico$?.unsubscribe();
  }
}
