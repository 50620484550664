import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICliente } from 'modelos/src';

import {
  ICreateDispositivoLorawan,
  IUpdateDispositivoLorawan,
  IDispositivoLorawan,
  IGetDeviceProfileChirpstack,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DispositivosLorawanService } from '../luminarias.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

@Component({
  selector: 'app-crear-editar-luminaria',
  templateUrl: './crear-editar-luminaria.component.html',
  styleUrl: './crear-editar-luminaria.component.scss',
  standalone: false,
})
export class CrearEditarDispositivoLorawanComponent
  implements OnInit, OnDestroy
{
  public id?: string | null;
  public title?: string;
  public dispositivolorawan?: IDispositivoLorawan;
  public dispositivolorawan$?: Subscription;

  public isEditing?: boolean;

  public clientes?: ICliente[];
  public clientes$?: Subscription;
  public deviceprofiles?: IGetDeviceProfileChirpstack[];

  public form?: FormGroup;
  public formOTAA: FormGroup;
  public formABP: FormGroup;

  public supportsOTAA: boolean | null = null; // Inicialmente es null

  constructor(
    private route: ActivatedRoute,
    private listados: ListadosService,
    private service: DispositivosLorawanService,
    public helper: HelperService,
    private dialogService: DialogService,
  ) {}

  private isOTAA(idDeviceProfile: string): boolean | null {
    const device_profile = this.deviceprofiles.find(
      (d) => d.id === idDeviceProfile,
    );
    return device_profile ? device_profile.supportsOtaa : null; // Si no se encuentra, devuelve null
  }

  //////////////////////////////////////////////////////////////////////////////
  //Form genérico para crear el dispositivo

  private createForm() {
    // Asegúrate de que dispositivolorawan no sea null antes de usarlo.

    this.form = new FormGroup({
      name: new FormControl(this.dispositivolorawan?.name, Validators.required),
      deveui: new FormControl(
        this.dispositivolorawan?.deveui,
        Validators.required, // Deshabilitar `deveui` al editar
      ),
      joineui: new FormControl(
        this.dispositivolorawan?.joineui,
        Validators.required,
      ),
      description: new FormControl(this.dispositivolorawan?.description),
      deviceProfileId: new FormControl(
        this.dispositivolorawan?.deviceProfileId,
        Validators.required,
      ),
      idCliente: new FormControl(this.dispositivolorawan?.idCliente),
      isDisabled: new FormControl(this.dispositivolorawan?.isDisabled || false),
      skipFcntCheck: new FormControl(
        this.dispositivolorawan?.skipFcntCheck || false,
      ),
    });

    this.updateFormForDeviceProfile();

    // Suscripción a los cambios en el campo `deviceProfileId`
    this.form
      ?.get('deviceProfileId')
      ?.valueChanges.subscribe((deviceProfileId) => {
        this.supportsOTAA = this.isOTAA(deviceProfileId); // Actualiza soporte OTAA
        this.updateFormForDeviceProfile(); // Actualiza formulario según soporte OTAA/ABP
      });
  }

  private updateFormForDeviceProfile() {
    // Verifica si supportsOTAA está disponible
    if (this.supportsOTAA === null) {
      // Asegúrate de que supportOTAA se establezca correctamente antes de proceder
      const deviceProfileId = this.form?.get('deviceProfileId')?.value;
      this.supportsOTAA = this.isOTAA(deviceProfileId); // Actualiza soporte OTAA basado en el ID del perfil
    }

    if (this.supportsOTAA) {
      // Si soporta OTAA, mostrar el formulario de OTAA y ocultar el de ABP
      this.formOTAA = new FormGroup({
        appkey: new FormControl(
          this.dispositivolorawan?.appkey,
          Validators.required,
        ),
      });
      this.formABP = null; // Limpiar formulario ABP si no es necesario
      console.log('Formulario OTAA creado:', this.formOTAA?.value);
    } else {
      // Si no soporta OTAA, mostrar el formulario de ABP
      this.formABP = new FormGroup({
        devAddr: new FormControl(
          this.dispositivolorawan?.devAddr,
          Validators.required,
        ),
        appSKey: new FormControl(
          this.dispositivolorawan?.appSKey,
          Validators.required,
        ),
        fCntUp: new FormControl(this.dispositivolorawan?.fCntUp),
        nFCntDown: new FormControl(this.dispositivolorawan?.nFCntDown),
        nwkskey: new FormControl(
          this.dispositivolorawan?.nwkskey,
          Validators.required,
        ),
      });
      this.formOTAA = null; // Limpiar formulario OTAA si no es necesario
      console.log('Formulario ABP creado:', this.formABP?.value);
    }
  }
  /////////////////////////////////////////////////////////////////////////
  //LISTAR
  private async listar(): Promise<void> {
    this.dispositivolorawan$?.unsubscribe();
    this.dispositivolorawan$ = this.listados
      .subscribe<IDispositivoLorawan>('dispositivoLorawan', this.id!)
      .subscribe((data) => {
        this.dispositivolorawan = data;
        console.log('listado de dispositivos lorawan', data);
      });
    await this.listados.getLastValue('dispositivoLorawan', this.id!);
  }

  private async listarDeviceProfiles() {
    try {
      const listado = await this.service.getDeviceProfiles();
      if (listado && Array.isArray(listado)) {
        this.deviceprofiles = listado;
        console.log('Device Profiles cargados:', listado);
      } else {
        console.error('La respuesta no contiene un array de perfiles');
      }
    } catch (error) {
      console.error('Error al obtener los perfiles:', error);
    }
  }

  // private async listarClientes() {
  //   this.clientes$?.unsubscribe();
  //   const query: IQueryParam = {
  //     sort: 'nombre',
  //     select: 'nombre',
  //     limit: 0,
  //     includeChildren: true,
  //     childrenLevel: 0,
  //   };
  //   this.clientes$ = this.listados
  //     .subscribe<IListado<ICliente>>('clientes', query)
  //     .subscribe((data) => {
  //       this.clientes = data.datos;
  //       console.log(`listado de clientes`, data);
  //     });
  //   await this.listados.getLastValue('clientes', query);
  // }
  /////////////////////////////////////////////////////////////////////////

  //Generar valores aleatorios
  public generarRandomHexString(length: number): string {
    const characters = '0123456789ABCDEF';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length),
      );
    }
    return result;
  }

  /////////////////////////////////////////////////////////////////////////////////
  public crearRandomDeveui() {
    const random = this.generarRandomHexString(16); // 16 caracteres hexadecimales
    // Devolver los valores en un objeto
    this.form.patchValue({ deveui: random });
  }

  public crearRandomJoineui() {
    const random = this.generarRandomHexString(16); // 16 caracteres hexadecimales
    // Devolver los valores en un objeto
    this.form.patchValue({ joineui: random });
  }

  public crearRandomAppkey() {
    const random = this.generarRandomHexString(32); // 32 caracteres hexadecimales
    // Devolver los valores en un objeto
    this.formOTAA.patchValue({ appkey: random });
  }

  public crearRandomAppSKey() {
    const random = this.generarRandomHexString(32); // 32 caracteres hexadecimales
    // Devolver los valores en un objeto
    this.formABP.patchValue({ appSKey: random });
  }

  public crearRandomNwkSKey() {
    const random = this.generarRandomHexString(32); // 32 caracteres hexadecimales
    // Devolver los valores en un objeto
    this.formABP.patchValue({ nwkskey: random });
  }

  public crearRandomDevAddr() {
    const random = this.generarRandomHexString(8); // 32 caracteres hexadecimales
    // Devolver los valores en un objeto
    this.formABP.patchValue({ devAddr: random });
  }

  ////////////////////////////////////////////////////////////////////////////
  // Actions

  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title}`,
      `¿Desea ${this.title.toLowerCase()}?`,
    );
    if (!res) return;
    try {
      if (this.id) {
        // Update
        const update = this.getData();
        await this.service.update(this.id, update);
        this.helper.notifSuccess('Luminaria Editada');
      } else {
        // Create
        const create = this.getData();
        await this.service.create(create);
        this.helper.notifSuccess('Luminaria Creada');
      }
      this.helper.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  private getData() {
    // Primero, obtén el valor de 'form', 'formABP' y 'formOTAA'
    const formData = this.form?.value; // Valor del formulario principal
    const formABPData = this.formABP?.value; // Valor de formABP (si existe)
    const formOTAADate = this.formOTAA?.value; // Valor de formOTAA (si existe)

    // Combina todos los datos en un solo objeto
    const data: ICreateDispositivoLorawan | IUpdateDispositivoLorawan = {
      ...formData, // Información del formulario principal
      ...formABPData, // Información del formulario ABP, si existe
      ...formOTAADate, // Información del formulario OTAA, si existe
    };

    console.log('Datos combinados:', data);

    return data;
  }

  // Hooks
  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    if (this.id) {
      await this.listar();
      this.isEditing = true;
    } else {
      this.isEditing = false;
    }

    this.title = this.id
      ? `Editar ${this.dispositivolorawan?.name}`
      : 'Crear Luminaria';
    await this.listarDeviceProfiles();
    this.createForm();
  }

  ngOnDestroy() {
    this.dispositivolorawan$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
