import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICliente,
  ICreateActivo,
  IListado,
  IQueryParam,
  IUpdateActivo,
  IActivo,
  ITracker,
  IFilter,
  ICategoriaActivo,
  IGrupo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ActivosService } from '../../../../../auxiliares/servicios/http/activos.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { CrearEditarTrackerComponent } from '../../../administracion/trackers/crear-editar-tracker/crear-editar-tracker.component';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';
import { CrearEditarClienteComponent } from '../../../administracion/clientes/crear-editar-cliente/crear-editar-cliente.component';
import { CrearEditarAgrupacionesComponent } from '../../../../standalone/grupos/crear-editar-grupos/crear-editar-grupos.component';
import { LoginService } from '../../../../login/login.service';

@Component({
  selector: 'app-crear-editar-activo',
  templateUrl: './crear-editar-activo.component.html',
  styleUrl: './crear-editar-activo.component.scss',
  standalone: false,
})
export class CrearEditarActivoComponent implements OnInit, OnDestroy {
  public clientePropio = LoginService.getCliente();
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  ////////////////////////////////////////////////////////////////////////////
  public clientes?: ICliente[] = [];
  public clientes$?: Subscription;
  public trackers?: ITracker[] = [];
  public trackers$?: Subscription;
  public grupos?: IGrupo[];
  public grupos$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { edit?: IActivo; categoria: ICategoriaActivo },
    public dialog: MatDialog,
    private listados: ListadosService,
    public helper: HelperService,
    private service: ActivosService,
    public dialogRef: MatDialogRef<CrearEditarActivoComponent>,
    public paramsService: ParamsService,
  ) {}

  ///////////////////////////////////////////////////////////////////////
  //FORM

  private createForm() {
    const categoria: ICategoriaActivo = this.data.categoria || 'Normal';

    this.form = new FormGroup({
      identificacion: new FormControl(
        this.data?.edit?.identificacion,
        Validators.required,
      ),
      categoria: new FormControl(
        this.data?.edit?.categoria || categoria,
        Validators.required,
      ),
      idCliente: new FormControl(
        this.data?.edit?.idCliente,
        Validators.required,
      ),
      idTracker: new FormControl(
        this.data?.edit?.idTracker,
        Validators.required,
      ),
      idsClientesQuePuedenAtender: new FormControl(
        this.data?.edit?.idsClientesQuePuedenAtender,
      ),
    });
  }

  ///////////////////////////////////////////////////////////////////////////
  //LISTAR
  private async listarClientes(): Promise<void> {
    const filter: IFilter<ICliente> = {
      'config.moduloActivos.activo': true,
      'config.moduloActivos.compartirActivos': true,
    } as unknown;
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      includeChildren: true,
      filter: JSON.stringify(filter),
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        // Obtiene el cliente propio
        const clientePropio = data.datos.find((c) => {
          return c._id === LoginService.getCliente()._id;
        });
        // quita el cliente propio
        const clientes = data.datos.filter((c) => {
          return c._id !== LoginService.getCliente()._id;
        });
        // Agrega el cliente propio al inicio
        clientes.unshift(clientePropio);

        this.clientes = clientes;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  public async listarTrackers() {
    if (this.form?.value?.idCliente) {
      const filter: IFilter<ITracker> = {
        $and: [
          { idCliente: this.form?.value?.idCliente },
          {
            $or: [
              {
                asignadoA: null,
              },
              {
                asignadoA: { $exists: false },
              },
            ],
          },
        ],
      };
      if (this.data?.edit?._id) {
        filter.$and[1].$or.push({
          asignadoA: this.data?.edit?.idTracker,
        });
      }
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        sort: 'identificacion',
        limit: 0,
        includeChildren: true,
      };
      //
      this.trackers$?.unsubscribe();
      this.trackers$ = this.listados
        .subscribe<IListado<ITracker>>('trackers', query)
        .subscribe((data) => {
          const trackers = JSON.parse(JSON.stringify(data.datos));
          this.trackers = trackers;
          console.log(`listado de trackers`, data);
        });
      await this.listados.getLastValue('trackers', query);
    }
  }

  public async listarGrupos() {
    if (this.form?.value?.idCliente) {
      const filter: IFilter<IGrupo> = {
        categoria: 'Activo',
        idCliente: this.form.value.idCliente,
      };
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        sort: 'nombre',
        select: 'nombre idCliente',
        limit: 0,
        includeChildren: true,
      };
      this.grupos$?.unsubscribe();
      this.grupos$ = this.listados
        .subscribe<IListado<IGrupo>>('grupos', query)
        .subscribe((data) => {
          this.grupos = data.datos;
          console.log(`listado de grupos`, data);
        });
      await this.listados.getLastValue('grupos', query);
    }
  }
  /////////////////////////////////////////////////////////////////////////
  ///CREAR

  public crearTracker() {
    this.paramsService.setParams({
      desdeActivo: true,
      idCliente: this.form.value.idCliente,
    });

    const dialog = this.dialog.open(CrearEditarTrackerComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idTracker: res });
      }
    });
  }
  public crearCliente() {
    const dialog = this.dialog.open(CrearEditarClienteComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idCliente: res });
      }
    });
  }

  public crearGrupo() {
    const dialog = this.dialog.open(CrearEditarAgrupacionesComponent, {
      data: { categoria: 'Activo' },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idGrupo: res });
      }
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?.edit?._id) {
        // Update
        await this.service.update(this.data.edit._id, data);
        this.helper.notifSuccess('Actualizado correctamente');
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public async clienteElegido(cliente: ICliente) {
    if (cliente) {
      this.form.patchValue({ idTracker: null });
      await this.listarTrackers();
    } else {
      this.form.patchValue({ idTracker: null });
    }
  }

  public getData(): ICreateActivo | IUpdateActivo {
    const data: ICreateActivo | IUpdateActivo = this.form?.value;
    if (data.idTracker) {
      const tracker = this.trackers?.find((t) => t._id === data.idTracker);
      if (tracker.traccar?.id) {
        data.idUnicoTraccar = tracker.traccar.id;
      } else {
        data.idUnicoTraccar = null;
      }
    } else {
      data.idUnicoTraccar = null;
    }
    return data;
  }

  /////////////////////////////////////////////////////////////////////////////
  //HOOKS
  async ngOnInit() {
    this.createForm();
    this.title = this.data?.edit?._id
      ? `Editar ${this.data?.edit?.identificacion}`
      : 'Crear Activo';

    await Promise.all([
      this.listarTrackers(),
      this.listarClientes(),
      this.listarGrupos(),
    ]);
    if (!this.helper.esClienteFinal()) {
      await this.listarClientes();
    }
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
    this.trackers$?.unsubscribe();
  }
}
