<div style="display: flex; flex-direction: column">
  <small style="max-width: 80%; color: gray"
    >Busqueda de vehículos que pasaron y/o se mantuvieron en una zona
    seleccionada</small
  >
  <!-- Dirección -->
  <app-autocomplete-direccion
    (geoJSONChange)="puntoDeCirculo.emit($event)"
    style="width: 100%"
  ></app-autocomplete-direccion>
  <div style="display: flex; gap: 3px">
    <div style="width: 40%">
      <app-date-range-selector
        [(dateRange)]="range"
        (dateRangeChange)="circuloDeBusquedaChange(circuloDeBusqueda)"
        [maxDate]="null"
        [placeholder]="'Desde'"
        [selectMode]="'single'"
      ></app-date-range-selector>
    </div>
    <mat-form-field style="width: 30%">
      <mat-label>Horas</mat-label>
      <input
        type="number"
        matInput
        matTooltip="Horas desde la fecha elegida."
        [formControl]="horasBusqueda"
        (change)="circuloDeBusquedaChange(circuloDeBusqueda)"
        min="1"
        max="24"
      />

      <mat-error
        *ngIf="horasBusqueda.hasError('min') || horasBusqueda.hasError('max')"
      >
        El valor debe estar entre 1 y 24.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        type="number"
        matInput
        [formControl]="minutosMinimos"
        matTooltip="Tiempo mínimo que un vehículo debe permanecer detenido en la zona."
        placeholder="Tiempo en zona (minutos)"
        (change)="circuloDeBusquedaChange(circuloDeBusqueda)"
      />
    </mat-form-field>
  </div>
</div>
<table mat-table [dataSource]="interaccionesBusqueda" class="mat-elevation-z8">
  <ng-container matColumnDef="vehículos">
    <th mat-header-cell *matHeaderCellDef>Vehículos</th>
    <td mat-cell *matCellDef="let element">
      {{ element.vehiculo.identificacion }} --
      @if (element.entro === element.salio) {
        {{ element.entro | date: "HH:mm" }}
      } @else {
        (
        {{ element.entro | date: "HH:mm" }} --
        {{ element.salio | date: "HH:mm" }})
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
@if (buscando) {
  <div style="height: 100%; width: 100%; margin-top: 10%" align="center">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
}
